var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"text-align":"center","color":"#ff5252 !important"}},[_vm._v(_vm._s(_vm.form.globalError))]),_c('projects-pick-table',{ref:"projectsTableRef",attrs:{"projectsList":_vm.projectsList,"projectsListChecked":_vm.projectsListChecked,"errorMessages":_vm.form.fields.boardingIds.errors}}),_c('v-dialog',{ref:"offBoardingDateDialog",attrs:{"return-value":_vm.form.fields.offBoardingDate.value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form.fields.offBoardingDate, "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.form.fields.offBoardingDate, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"{L Off-Boarding date}","append-icon":"event","error":_vm.form.fields.offBoardingDate.errors.length > 0,"error-messages":_vm.form.fields.offBoardingDate.errors,"readonly":""},model:{value:(_vm.form.fields.offBoardingDate.value),callback:function ($$v) {_vm.$set(_vm.form.fields.offBoardingDate, "value", $$v)},expression:"form.fields.offBoardingDate.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.form.fields.offBoardingDate.modal),callback:function ($$v) {_vm.$set(_vm.form.fields.offBoardingDate, "modal", $$v)},expression:"form.fields.offBoardingDate.modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.form.fields.offBoardingDate.value),callback:function ($$v) {_vm.$set(_vm.form.fields.offBoardingDate, "value", $$v)},expression:"form.fields.offBoardingDate.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.form.fields.offBoardingDate.modal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.offBoardingDateDialog.save(_vm.form.fields.offBoardingDate.value)}}},[_vm._v("OK")])],1)],1),_c('v-dialog',{ref:"contractEndDateDialog",attrs:{"return-value":_vm.form.fields.contractEndDate.value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form.fields.contractEndDate, "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.form.fields.contractEndDate, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"{L Contract end date}","append-icon":"event","error":_vm.form.fields.offBoardingDate.errors.length > 0,"error-messages":_vm.form.fields.offBoardingDate.errors,"readonly":""},model:{value:(_vm.form.fields.contractEndDate.value),callback:function ($$v) {_vm.$set(_vm.form.fields.contractEndDate, "value", $$v)},expression:"form.fields.contractEndDate.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.form.fields.contractEndDate.modal),callback:function ($$v) {_vm.$set(_vm.form.fields.contractEndDate, "modal", $$v)},expression:"form.fields.contractEndDate.modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.form.fields.contractEndDate.value),callback:function ($$v) {_vm.$set(_vm.form.fields.contractEndDate, "value", $$v)},expression:"form.fields.contractEndDate.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.form.fields.contractEndDate.modal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.contractEndDateDialog.save(_vm.form.fields.contractEndDate.value)}}},[_vm._v("OK")])],1)],1),_c('v-dialog',{ref:"projectEndDateDialog",attrs:{"return-value":_vm.form.fields.projectEndDate.value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.form.fields.projectEndDate, "value", $event)},"update:return-value":function($event){return _vm.$set(_vm.form.fields.projectEndDate, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"{L Project end date}","append-icon":"event","error":_vm.form.fields.offBoardingDate.errors.length > 0,"error-messages":_vm.form.fields.offBoardingDate.errors,"readonly":""},model:{value:(_vm.form.fields.projectEndDate.value),callback:function ($$v) {_vm.$set(_vm.form.fields.projectEndDate, "value", $$v)},expression:"form.fields.projectEndDate.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.form.fields.projectEndDate.modal),callback:function ($$v) {_vm.$set(_vm.form.fields.projectEndDate, "modal", $$v)},expression:"form.fields.projectEndDate.modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.form.fields.projectEndDate.value),callback:function ($$v) {_vm.$set(_vm.form.fields.projectEndDate, "value", $$v)},expression:"form.fields.projectEndDate.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.form.fields.projectEndDate.modal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.projectEndDateDialog.save(_vm.form.fields.projectEndDate.value)}}},[_vm._v("OK")])],1)],1),(_vm.allowRatingUpdate)?_c('div',[_c('nullable-rating',{model:{value:(_vm.form.fields.rating.value),callback:function ($$v) {_vm.$set(_vm.form.fields.rating, "value", $$v)},expression:"form.fields.rating.value"}}),_c('v-textarea',{attrs:{"auto-grow":true,"rows":1,"clearable":true,"label":"{L Rating comment}"},model:{value:(_vm.form.fields.ratingComment.value),callback:function ($$v) {_vm.$set(_vm.form.fields.ratingComment, "value", $$v)},expression:"form.fields.ratingComment.value"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }