<template>
    <v-layout style="margin-top: -15px;">
        <v-toolbar
            color="primary"
            dark
        >
            <v-toolbar-title style="width: 100%; text-align: center;">{{ pageTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-container style="max-width: 480px;">        
            <v-col v-if="state=='DEFAULT'" flex-column style="padding: 0px;">
                <!-- change password header -->
                <v-flex xs12 style="padding: 20px 30px 10px 30px;">
                    <strong> {{ pageHeader }} </strong>
                </v-flex>
                <v-flex xs12 style="padding: 0px 30px 10px 30px;">
                    {{ pageContent }}
                </v-flex>

                <v-flex xs12 style="padding: 5px 30px 10px 30px;">
                    <v-dialog
                        ref="dialog"
                        v-model="showDatePicker"
                        :return-value.sync="birthDate"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="birthDate"
                                :label=lview.birthDate
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :error-messages="errors.birthDate.label"
                            ></v-text-field>
                        </template>

                        <v-date-picker v-model="date" scrollable :max="new Date().toISOString().substr(0, 10)">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="showDatePicker = false"> Cancel </v-btn>
                            <v-btn text color="primary" @click="$refs.dialog.save(date); errors.birthDate.label = ''"> OK </v-btn>
                        </v-date-picker>
                    </v-dialog>

                    <v-text-field prepend-inner-icon="lock"
                        v-model="passwords.new.value"
                        type="password"
                        :label=lview.newPassword
                        :rules="[validationRules.newPassword]"
                        :error-messages="passwords.new.errorMessages"
                        @input="passwords.new.errorMessages = ''"
                        color="rgba(4, 202, 90, 1)"></v-text-field>
                    
                    <v-text-field prepend-inner-icon="lock"
                        v-model="passwords.confirmNew.value"
                        type="password"
                        :label=lview.repeatPassword
                        :error-messages="passwords.confirmNew.errorMessages"
                        :rules="[validationRules.repeatNewPassword]"
                        @input="passwords.confirmNew.errorMessages = ''"
                        color="rgba(4, 202, 90, 1)"></v-text-field>
                </v-flex>

                <v-flex xs12 style="display: flex; padding: 5px 30px 10px 30px;">
                    <v-btn color="primary" @click="saveChanges" style="margin: 0 auto;">
                        {{ lview.saveChanges }}
                    </v-btn>
                </v-flex>

                <v-snackbar
                    v-model="passwordUpdateNotifier"
                    fixed
                    class="snackbar-offline-notifier"
                    :color="snackbarColor"
                >
                    <v-flex xs12 class="align-self-center" style="font-size: 16px; text-align: center;">
                        <div>{{ passwordUpdateNotification }}</div>
                    </v-flex>
                </v-snackbar>
            </v-col>

            <v-dialog persistent v-model="informationDialog.visible"
                max-width="295">
                <v-card>
                    <v-card-title style="font-size: 20px; font-weight: 550;">{{ informationDialog.title }}</v-card-title>

                    <v-card-text style="text-align:justify">{{ informationDialog.message }}</v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="green darken-1" style="font-size: 12px;"
                            text
                            @click="informationDialog.visible = !informationDialog.visible">
                            {{ lbuttons.understood }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-layout v-if="state == 'ERROR'" style="margin-top: 156px;" class="content-middler" column justify-center align-center>
                <v-icon size="100">sentiment_dissatisfied</v-icon>
                <div class="title" style="width:90%; text-align: center; margin-top:10px;">{{ globalErrorMessage }}</div>
            </v-layout>
        </v-container>
    </v-layout>
</template>

<script>
import axios from 'axios';
import fob_config from '../fob_config.js';
import jwt_decode from 'jwt-decode';

export default {
    data: function(){
        return {
            pageTitle: "",
            pageHeader: "",
            pageContent: "",
            state: "DEFAULT",

            birthDate: "",
            showDatePicker: "",
            date: "",

            passwords: {
                new: {
                    value: "",
                    errorMessages: ""
                },
                confirmNew: {
                    value: "",
                    errorMessages: ""
                }
            },
            passwordUpdateNotifier: false,
            passwordUpdateNotification: "",
            snackbarColor: "",
            globalErrorMessage: "",

            validationRules: {
                newPassword: value => {
                    if(typeof(value) === 'undefined'){
                        return true;
                    }

                    if(value.length == 0){
                        return true;
                    }
                    let isOK = this.validatePassword(value);
                    return isOK || this.lview.invalidPasswordStructure;
                },
                repeatNewPassword: value => {
                    if(typeof(value) === 'undefined'){
                        return true;
                    }

                    if(value.length == 0){
                        return true;
                    }
                    return value == this.passwords.new.value || this.lview.incorrectlyRepeatedPassword;
                }
            },
            errors: {
                birthDate: {
                    trials: undefined,
                    label: "",
                }
            },

            informationDialog: {
                visible: false,
                title: "",
                message: "",
            }
        }
    },
    methods: {
        saveChanges: async function(){
            if (this.passwords.new.value != this.passwords.confirmNew.value ||
                this.passwords.new.value.length == 0 ||
                this.passwords.confirmNew.value.length == 0 ||
                this.birthDate == ""){

                if(this.passwords.new.value.length == 0){
                    this.passwords.new.errorMessages = this.lview.passwordNotProvided;
                }
                else {
                    this.passwords.new.errorMessages = "";
                }

                if(this.passwords.confirmNew.value.length == 0 || this.passwords.new.value != this.passwords.confirmNew.value){
                    this.passwords.confirmNew.errorMessages = this.lview.incorrectlyRepeatedPassword;
                }
                else {
                    this.passwords.confirmNew.errorMessages = "";
                }

                if(this.birthDate == ""){
                    this.errors.birthDate.label = this.lview.birthDateNotProvided;
                } 
                else {
                    this.errors.birthDate.label = "";
                }
                return;
            }

            let passwordCorrect = this.validatePassword(this.passwords.new.value);
            if (!passwordCorrect){
                return;
            }

            this.passwords.new.errorMessages = "";
            this.passwords.confirmNew.errorMessages = "";
            this.errors.birthDate.label = "";

            let formData = new FormData();

            formData.append('date_of_birth', this.birthDate);
            formData.append('password1', this.passwords.new.value);
            formData.append('password2', this.passwords.confirmNew.value);
            formData.append('token', this.$route.params.token);
            this.$emit('set-state', 'loading_overlayer', this.lview.updatingInProgress);

            var that = this;

            axios({
                method: 'PATCH',
                url: fob_config.api_url + "/api/password_reset_request/",
                data: formData
            }).then(response => {
                this.$emit('set-state', 'default');
                this.birthDate = "";
                this.passwords.new = "";
                this.passwords.confirmNew = "";
                this.errors.birthDate.label = "";

                this.passwordUpdateNotification = this.lview.updatedSuccessfully;
                this.snackbarColor = 'success';
                this.passwordUpdateNotifier = true;

                setTimeout(function(){
                    that.$router.push("/login");
                }, 5000);
            }).catch(error => {
                // 404, 400, 403, 500
                this.$emit('set-state', 'default');
                if(error.isAxiosError){
                    if(error.response.status == 404 || error.response.status == 400 || error.response.status == 403){
                        this.passwordUpdateNotification = this.lview.couldNotUpdate;
                        this.passwordUpdateNotifier = true;
                        this.snackbarColor = 'error';
                        if (error.response.data.errors != undefined){
                            if(error.response.data.errors.date_of_birth != undefined){
                                this.errors.trials = error.response.data.errors.date_of_birth[0];
                                if(this.errors.trials == 1){
                                    this.error.birthDate.label = this.lview.incorrectBirthDateOneLeft.replace("%d", this.errors.trials);
                                }
                                else if(this.errors.trials > 1){
                                    this.errors.birthDate.label = this.lview.incorrectBirthDate.replace("%d", this.errors.trials);
                                }
                                else if(this.errors.trials == 0){
                                    this.errors.birthDate.label = "";
                                    this.informationDialog.title = this.lview.tryAgainChangePasswordLaterTitle;
                                    this.informationDialog.message = this.lview.tryAgainChangePasswordLaterMessage;
                                    this.informationDialog.visible = true;
                                }
                            }
                        }
                    }
                    else if(error.response.status == 429){
                        // żądanie zdławione
                        this.informationDialog.title = this.lmessages.networkError;
                        this.informationDialog.message = this.lmessages.tryAgainLater;
                        this.informationDialog.visible = true;
                    }
                    else if(error.response.status == 500){
                        this.state = "ERROR";
                    }
                }
            });
        },
        validatePassword: function(value){
            console.log(typeof(value));
            if (typeof(value) != 'string'){
                return false;
            }
            let specialSigns = "@$!%*?&#";

            let checks = {l: false, u: false, d: false, s: false};
            for(var i = 0; i < value.length; i++){
                if (specialSigns.includes(value[i])){
                    checks.s = true;
                } else if ('0' <= value[i] && value[i] <= '9'){
                    checks.d = true;
                } else if ('a' <= value[i] && value[i] <= 'z'){
                    checks.l = true;
                } else if ('A' <= value[i] && value[i] <= 'Z'){
                    checks.u = true;
                }
            }
            let isOK = (value.length >= 8) && checks.u && (checks.d || checks.s) && checks.l; 
            return isOK;
        },
        isTokenValid: async function(token) {
            return await axios({
                url: fob_config.api_url + "/api/v2/token-verification/" + token,
                method: "POST",
            });
        }
    },
    mounted: function(){
        this.$store.dispatch('logout');
        this.pageTitle = this.lview.title;
        this.passwords.new.errorMessages = null;
        this.passwords.confirmNew.errorMessages = null;

        this.isTokenValid(this.$route.params.token).then(response => {
            console.log(response);
            if (response.data.message == "OK") {
                let decoded = jwt_decode(this.$route.params.token);
                this.pageHeader = this.lview.headerPrefix + " " + decoded.username;
                this.pageContent = this.lview.pageContent;
            }
            else {
                this.state = "ERROR";
                this.globalErrorMessage = this.lview.linkOutdated;
            }
        }).catch(error => {
            if (error.isAxiosError) {
                this.state = "ERROR";
                this.globalErrorMessage = this.lmessages.errorOccured;
            }
        });
    },
    beforeDestroy: function(){
        this.$emit('set-display', 'default');
        this.$emit('set-title', '');
    },
    computed: {
        lview: {
            get: function(){
                return this.$t('views.settingPassword');
            }
        },
        lbuttons: {
            get: function(){
                return this.$t('commons.buttons');
            }
        },
        lmessages: {
            get: function(){
                return this.$t('commons.messages');
            }
        }
    }
}
</script>

<style scoped>
.snackbar-offline-notifier{
    height: 30px !important;
    margin-bottom: 70px;
    top: calc(90% - 35px) !important;
    -webkit-font-smoothing: antialiased; 
    text-rendering: geometricPrecision;
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    transition: 0.5s !important;
    background-color: var(--v-primary-base);
}
</style>