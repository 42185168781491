<template>
  <div class="AlertWithDetails">
    <v-alert
      ref="alert"
      v-model="alert"
      :icon="icon"
      :type="type"
      dismissible
      transition="slide-y-reverse-transition"
      @input="onClose"
    >
      <v-layout style="width: 100%;" row v-ripple @click="onLayoutClick" v-if="details != null">
        <v-flex xs10 style="word-wrap: break-word;">
          {{ text }}
        </v-flex>
        <v-flex xs2>
          <v-layout align-center justify-center style="height:100%">
            <v-icon class="showMoreButton">expand_less</v-icon>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row @click="onLayoutClick" v-else>
        <v-flex xs12>
          {{ text }}
        </v-flex>
      </v-layout>
    </v-alert>

    <v-dialog 
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark :color="type">
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ lview.details }}</v-toolbar-title>
        </v-toolbar>

        <div v-html="details" style='padding: 10px; text-align:justify'></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  export default {
    name: 'alertWithDetails',
    data() {
      return {
        alert: false,
        dialog: false,
        typeAlertColor: "rgba(4, 202, 90, 1)",
      }
    },
    mounted() {
      this.alert = true;
    },
    computed: {
      lview: {
        get: function(){
          return this.$t('views.commons.alertWithDetails');
        }
      }
    },
    props: {
      type: {
        type: String,
        default: undefined
      },
      icon: {
        type: String,
        default: undefined,
      },
      text: {
        type: String,
        required: true
      },
      details: {
        type: String,
        default: null,
      }
    },
    methods: {
      onClose(){
        this.$emit('close');
        setTimeout(() => {
          this.$emit('closed');
        }, 300);
      },
      onLayoutClick(){
        if(this.details != null){
          this.dialog = true;
        }
      },
    }
  };
</script>

<style>
  .AlertWithDetails .v-alert__dismissible{
    align-self: center;
    padding-left: 16px;
    margin-left: 0;
    width: 40px;
    height: 24px;

  }
  .AlertWithDetails .v-alert__dismissible > i{
    margin-left:0;
  }
</style>

<style scoped>
  .showMoreButton{
    color: #FFF;
    font-size: 24px;
  }
</style>