<template>
    <v-layout>
        <!-- change password header -->
        <v-flex xs12 style="padding: 20px 30px 10px 30px;">
            <strong> {{ pageHeader }} </strong>
        </v-flex>
        <v-flex xs12 style="padding: 0px 30px 10px 30px;">
            {{ pageContent }}
        </v-flex>

        <v-container style="max-width: 480px; float: left;">
            <v-flex xs12 style="padding: 5px 30px 10px 30px;">
                <v-text-field prepend-inner-icon="lock"
                    v-model="passwords.old.value"
                    type="password"
                    :label=lview.oldPassword
                    :rules="[validationRules.passwordOld]"
                    :error-messages="passwords.old.errorMessages"
                    @input="passwords.old.errorMessages = ''"
                    color="rgba(4, 202, 90, 1)"></v-text-field>

                <v-text-field prepend-inner-icon="lock"
                    v-model="passwords.new.value"
                    type="password"
                    :label=lview.newPassword
                    :rules="[validationRules.newPassword]"
                    :error-messages="passwords.new.errorMessages"
                    @input="passwords.new.errorMessages = ''; validateRepeatPassword();"
                    color="rgba(4, 202, 90, 1)"></v-text-field>
                
                <v-text-field prepend-inner-icon="lock"
                    v-model="passwords.confirmNew.value"
                    type="password"
                    :label=lview.repeatPassword
                    :rules="[validationRules.repeatNewPassword]"
                    :error-messages="passwords.confirmNew.errorMessages"
                    @input="passwords.confirmNew.errorMessages = ''"
                    color="rgba(4, 202, 90, 1)"></v-text-field>
            </v-flex>

            <v-flex xs12 style="display: flex; padding: 5px 30px 10px 30px;">
                <v-btn outlined @click="$router.go(-1)" style="float: left; margin-right: 10px;">
                    {{ lview.cancel }}
                </v-btn>

                <v-btn color="primary" @click="saveChanges" style="float: right; margin-left: 30px;">
                    {{ lview.saveChanges }}
                </v-btn>
            </v-flex>
        </v-container>

        <v-snackbar
          v-model="passwordUpdateNotifier"
		  fixed
		  class="snackbar-offline-notifier"
          :color="snackbarColor"
      	>
			<v-flex xs12 class="align-self-center" style="font-size: 16px; text-align: center;">
                <div>{{ passwordUpdateNotification }}</div>
            </v-flex>
      	</v-snackbar>
    </v-layout>
</template>

<script>
import axios from 'axios';
export default {
    data: function(){
        return {
            pageHeader: "",
            pageContent: "",

            passwords: {
                old: {
                    value: "",
                    errorMessages: ""
                },
                new: {
                    value: "",
                    errorMessages: ""
                },
                confirmNew: {
                    value: "",
                    errorMessages: ""
                }
            },
            passwordUpdateNotifier: false,
            passwordUpdateNotification: "",
            snackbarColor: "",

            validationRules: {
                passwordOld: value => {
                    if (value.length == 0){
                        return true;
                    }
                    let isOK = this.validatePassword(value);
                    return isOK || this.lview.invalidPasswordStructure;
                },
                newPassword: value => {
                    if(value.length == 0){
                        return true;
                    }
                    let isOK = this.validatePassword(value);
                    return isOK || this.lview.invalidPasswordStructure;
                },
                repeatNewPassword: value => {
                    return value == this.passwords.new.value || this.lview.incorrectlyRepeatedPassword;
                }
            },

            errors: {
                oldPassword: ""
            }
        }
    },
    methods: {
        saveChanges: async function(){
            if (this.passwords.new.value != this.passwords.confirmNew.value ||
                this.passwords.old.value.length == 0 ||
                this.passwords.new.value.length == 0 ||
                this.passwords.confirmNew.value.length == 0 ||
                this.passwords.new.value == this.passwords.old.value){

                if(this.passwords.new.value.length == 0){
                    this.passwords.new.errorMessages = this.lview.newPasswordNotProvided;
                }
                if(this.passwords.old.value.length == 0){
                    this.passwords.old.errorMessages = this.lview.oldPasswordNotProvided;
                }
                if(this.passwords.confirmNew.value.length == 0 || this.passwords.new.value != this.passwords.confirmNew.value){
                    this.passwords.confirmNew.errorMessages = this.lview.incorrectlyRepeatedPassword;
                }
                if(this.passwords.new.value == this.passwords.old.value){
                    this.passwords.new.errorMessages = this.lview.newPasswordCannotBeTheSameAsOldOne;
                }
                return;
            }

            this.passwords.new.errorMessages = "";
            this.passwords.old.errorMessages = "";
            this.passwords.confirmNew.errorMessages = "";

            let formData = new FormData();

            formData.append('old_password', this.passwords.old.value);
            formData.append('password1', this.passwords.new.value);
            formData.append('password2', this.passwords.confirmNew.value);

            this.$emit('set-state', 'loading_overlayer', this.lview.updatingInProgress);

            axios({
                method: 'POST',
                url: localStorage.getItem("current_env") + "/api/edit-password/",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                },
                data: formData
            }).then(response => {
                this.$emit('set-state', 'default');
                this.passwordUpdateNotifier = true;
                this.passwordUpdateNotification = this.lview.updatedSuccessfully;
                this.passwords.old.value = "";
                this.passwords.new.value = "";
                this.passwords.confirmNew.value = "";
                this.snackbarColor = "success";
                this.passwords.old.errorMessages = "";
                this.passwords.new.errorMessages = "";
                this.passwords.confirmNew.errorMessages = "";
            }).catch(error => {
                this.$emit('set-state', 'default');
                if (error.isAxiosError){
                    if(error.response.status == 400){
                        this.passwords.old.errorMessages = this.lview.incorrectPassword;
                        this.passwordUpdateNotifier = true;
                        this.passwordUpdateNotification = this.lview.couldNotUpdate;
                        this.passwords.old.value = "";
                        this.passwords.new.value = "";
                        this.passwords.confirmNew.value = "";
                        this.snackbarColor = "red darken-2";
                    }
                    else if(error.response.status == 403 || error.response.status == 401){
                        this.passwordUpdateNotifier = true;
                        this.passwordUpdateNotification = this.lview.couldNotUpdate;
                        this.passwords.old.value = "";
                        this.passwords.new.value = "";
                        this.passwords.confirmNew.value = "";
                        this.snackbarColor = "red darken-2";
                    }
                    else if(error.response.status == 500){
                        this.$emit('set-state', 'ERROR');
                    }
                }
            });
        },
        validatePassword: function(value){
            let specialSigns = "@$!%*?&#";
            let checks = {l: false, u: false, d: false, s: false};
            for(var i = 0; i < value.length; i++){
                if (specialSigns.includes(value[i])){
                    checks.s = true;
                } else if ('0' <= value[i] && value[i] <= '9'){
                    checks.d = true;
                } else if ('a' <= value[i] && value[i] <= 'z'){
                    checks.l = true;
                } else if ('A' <= value[i] && value[i] <= 'Z'){
                    checks.u = true;
                }
            }
            let isOK = (value.length >= 8) && checks.u && (checks.d || checks.s) && checks.l;
            return isOK;
        },
        validateRepeatPassword: function(){
            if (this.passwords.old.value != ''){
                if (this.passwords.new.value == this.passwords.old.value){
                    this.passwords.new.errorMessages = this.lview.newPasswordCannotBeTheSameAsOldOne;
                }
                else{
                    this.passwords.new.errorMessages = '';
                }
            }
            let password = this.passwords.new.value;
            let passwordRepeat = this.passwords.confirmNew.value;
            if (password == passwordRepeat){
                this.passwords.confirmNew.errorMessages = "";
            }
            else {
                this.passwords.confirmNew.errorMessages = this.lview.incorrectlyRepeatedPassword;
            }
        }
    },
    mounted: function(){
        this.$emit('set-display', 'BACK_WINDOW');
        this.$emit('set-title', this.lview.title);

        this.pageHeader = this.lview.headerPrefix + " " + localStorage.getItem('application_user_username');
        this.pageContent = this.lview.pageContent;
    },
    beforeDestroy: function(){
        this.$emit('set-display', 'default');
        this.$emit('set-title', '');
    },
    computed: {
        lview: {
            get: function(){
                return this.$t('views.changePassword');
            }
        }
    }
}
</script>

<style scoped>
.snackbar-offline-notifier{
    height: 30px !important;
    margin-bottom: 70px;
    top: calc(90% - 35px) !important;
    -webkit-font-smoothing: antialiased; 
    text-rendering: geometricPrecision;
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    transition: 0.5s !important;
    background-color: var(--v-primary-base);
}
</style>