<template>
  <div style="width: 100%;">
    <terminate-boarding-form
      style="padding:20px;"
      :allowRatingUpdate="true"
      :projectsList="[ { id: 1, name: 'Test1', boardingIds: [ 202 ] }, { id: 2, name: 'Test2', boardingIds: [ 194 ] } ]"
      ref="form"
    ></terminate-boarding-form>

    <v-btn small @click="submitForm()">Submit</v-btn>
  </div>

</template>

<script>
  import axios from 'axios'

  export default {
    name: 'dev',
    mounted: async function(){
      var that = this;
    },
    data() {
      return {
        projectsTableRef: null,
      }
    },
    methods: {
      update(newValue){
        this.rating = newValue; console.log(newValue);
      },
      async submitForm(){
        var result = await this.$refs.form.submit();

        return result;
      }
    }
  }
</script>