import axios from "axios";

class FormHandler {
    /**
     * 
     * @param {Object} formConfig Configuration of the form. Structure:
     * {
     *      action: <action_url>, // sent with POST method on submit, or GET, when requested initial data,
     *      headers: <array_of_headers>,
     *      globalError: <global_error> // any non-field specific errors will be assigned here
     *      fields: {
     *          "<field_name>": {
     *              name: <field_name>,
     *              value: <field_value>,
     *              errors: <field_errors_list>
     *          }
     *      }
     * } 
     */
    constructor(formConfig){
        this.formConfig = formConfig;
    }

    async getFormData(requestData){
        var apiUrl = this.formConfig.action;
        var headers = this.formConfig.headers;

        var promise = $.ajax({
            type: "GET",
            url: apiUrl,
            headers: headers,
            data: requestData
        });

        return promise;
    }

    /**
     * Used for finding a field based on its API name
     * @param {String} name Field's API name
     */
    findFormFieldByApiName(name){
        var fields = this.formConfig.fields;

        for (var property in fields){
            if(fields[property].name.toUpperCase() === name.toUpperCase()){
                return fields[property];
            }
        }
        return null;
    }

    /**
     * Used for filling form with feedback data from API
     * @param {Object} formData Object structure:
     *  [
     *      {
     *          name: <name>,
     *          value: <value>,
     *          errors: <array_of_strings>
     *      }
     *  ]
     */
    fillFormWithApiData(formData){
        var inputs = formData.inputs;
        var input;
        var field;

        this.formConfig.globalError = formData.global_errors_text;

        for (var i = 0; i < inputs.length; i++){
            input = inputs[i];

            field = this.findFormFieldByApiName(input.name);
            if(field != null){
                field.value = input.value;
                field.errors = input.errors;
            }
            else{
                console.log("Missing input. Input with name " + input.name + " not found.");
            }
        }
    }

    async submitAndFillForm(callback = null){
        var data = await this.submitForm();
        this.fillFormWithApiData(data.data.form_data);
        if(callback != null){
            callback(data);
        }
        return data;
    }

    async submitForm(){
        var data = {};
        var field;
        var apiUrl = this.formConfig.action;
        var headers = this.formConfig.headers;

        for(var property in this.formConfig.fields){
            field = this.formConfig.fields[property];
            data[field.name] = field.value;
        }

        var promise = axios({
            method: "POST",
            url: apiUrl,
            headers: headers,
            data: data
        });

        var feedback = await promise;
        return feedback;
    }
}

export default FormHandler