<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<change-boarding-plans-dialog
  ref='yourReference'

  ...
></change-boarding-plans-dialog>
$refs.youReference.openDialog();

props:
  - workers {Array}, determines worker ids to modify in the database
	[
	  {
		workerId,
		status,
		untilDate,
		projectId,
		project,
		boardings {Array}
		{
		  boardingId: {Number},
		  projectId: {Number}
		}
	  }
	]

  - projectsDictionary {Array}, avaible list of projects to pick from
	[
	  {
		id,
		name
	  }
	]

  - defaultProject {Number}, project picked by default [optional]
  - defaultDate {String}, default date assigned when date is null [optional]
  - date {String}, date assigned while dialog is being open [optional]
  - defaultManagerId {Integer}
  - managersDictionary {Array}
  [
	{
	  managerId: (...) {int},
	  firstName: (...) {String},
	  middleName: (...) {String},
	  lastName: (...) {String},
	  fullName: (...) {String}, <optional>
	}
  ]


events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - error

errors:
  structure: {
	errorCodeShort,
	errorCodeLong,
	title,
	message,
	details
  }

  errorsId: CHNGBRD

-->

<template>
	<v-dialog persistent v-model="visible" max-width="290">
		<v-card dense>
			<div class="ChangeBoardingPlansDialog">
				<v-card-title style="text-align:center; padding-bottom:0">
					<span class="headline">{{ lview.title }}</span>
				</v-card-title>

				<v-card-text>
					<v-card elevation="1" align-center class="projects-table">
						<v-card-title primary-title class="projects-table-title" style="position:relative">
                            {{ lview.chooseProject }}
                        </v-card-title>

						<v-list
							style="width:100%; max-height: 110px; overflow-y: auto; overflow-x: hidden"
						>
							<v-list-item style="padding:0 5px" v-for="project in tableProjects" :key="project.id">
								<v-flex xs12>
									<v-checkbox
										v-model="checkboxes[project.id.toString()]"
										color="rgba(4, 202, 90, 1)"
										style="margin-top: 0; margin-bottom: 0;"
										:label="project.name"
									></v-checkbox>
								</v-flex>
							</v-list-item>
						</v-list>
					</v-card>

					<!--<v-layout align-center>
						<div @click="onSelectClick()" style="width: 100%;">
							<v-select ref="locationSelect"
									v-model="pickedChangingLocation"
									:items="selectForChangingLocations"
									item-text="name"
									item-value="id"
									:label="lview.chooseProject"
									style="margin-top: 10px;"
									multiple></v-select>
						</div>
						<v-spacer></v-spacer>
					</v-layout>-->

					<v-layout align-center>
						<v-checkbox hide-details color="rgba(4, 202, 90, 1)" v-model="datePickerEnabled"></v-checkbox>

						<v-dialog ref="modifyBoardingDialog"
								v-model="datePicker.visible"
								:return-value.sync="datePicker.date"
								persistent
								width="290px">
							<template v-slot:activator="{ on }">
								<div @click="
									if(!datePickerEnabled){
										datePickerEnabled = true;
										datePicker.visible = true;
									}">
									<v-text-field hide-details
												v-model="datePicker.date"
												:label="lview.changeDate"
												readonly
												v-on="on"
												:disabled="!datePickerEnabled"></v-text-field>
								</div>
								<v-icon>event</v-icon>
							</template>

							<v-date-picker color="rgba(4, 202, 90, 1)" v-model="datePicker.date" scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="rgba(4, 202, 90, 1)" @click="datePicker.visible = false">{{ lbuttons.cancel }}</v-btn>
								<v-btn text
									color="rgba(4, 202, 90, 1)"
									@click="$refs.modifyBoardingDialog.save(datePicker.date)">{{ lbuttons.confirm }}</v-btn>
							</v-date-picker>
						</v-dialog>
					</v-layout>

					<v-divider style='margin-top:30px; margin-bottom:-5px'></v-divider>

					<v-layout align-center>
						<v-checkbox hide-details color="rgba(4, 202, 90, 1)" v-model="changingManagerEnabled"></v-checkbox>

						<div @click="onManagerSelectClick()">
							<v-select ref="managerSelect"
									v-model="pickedChangingManager"
									:items="selectForChangingManagers"
									item-text="fullName"
									item-value="managerId"
									:label="lview.changeManager"
									style="margin-top: 10px;"
									color="rgba(4, 202, 90, 1)"
									:disabled="!changingManagerEnabled"></v-select>
						</div>

						<v-spacer></v-spacer>
					</v-layout>

					<!-- TEMPORARY REMOVAL OF NOT YET COMPLETE FUNCTIONALITY - CODE STABILIZATION
					<v-select v-model="pickedChangingReason"
							:items="selectForChangingReasons"
							:label="lview.reason"
							style="margin-top: 10px;"></v-select>
					-->
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="green darken-1" @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
					<v-btn text color="green darken-1" @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
				</v-card-actions>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
	import axios from "axios";
	import fob_config from '../../fob_config.js';

	export default {
		name: "changeBoardingPlansDialog",
		mounted: function () { },
		data() {
			return {
				visible: false,
				tableProjects: [],
				checkboxes: {},

				changingWorkerEnabled: false,
				changingLocationEnabled: false,
				changingManagerEnabled: false,

				selectForChoosingWorker: [],
				selectForChangingManagers: [],

				pickedChangingWorker: [],
				pickedChangingManager: null,

				selectForChangingReasons: ["Opóźnienie transportu", "Inne"],
				pickedChangingReason: null,

				datePickerEnabled: false,
				datePicker: {
					date: new Date()
						.toJSON()
						.slice(0, 10)
						.replace("/-/g", "/"), //set default date as today's in format : YY-MM-DD
					visible: false
				}
			};
		},
		computed: {
			lview: {
				get: function () {
					return this.$t("views.commons.changeBoardingPlansDialog");
				}
			},
			lbuttons: {
				get: function () {
					return this.$t("commons.buttons");
				}
			},
			lmessages: {
				get: function () {
					return this.$t("commons.messages");
				}
			},
			lerrors: { get: function() { return this.$t('errors'); } },
		},
		props: {
			workers: {
				type: Array,
				required: true
			},

			defaultDate: {
				type: String,
				default: new Date()
					.toJSON()
					.slice(0, 10)
					.replace("/-/g", "/")
			},
			date: {
				type: String,
				default: null
			},

			/*projectsDictionary: {
				type: Array,
				required: true
			},
			defaultProject: {
				type: Number,
				default: null
			},*/

			assignedProjects: {
				type: Array,
				default: () => []
			},
			markedProjectsIds: {
				type: Array,
				default: () => []
			},

			defaultManagerId: {
				type: Number,
				required: false,
				default: null
			},

			managersDictionary: {
				type: Array,
				required: true
			}
		},
		methods: {
			openDialog() {
				if (this.assignedProjects.length == 0) {
					this.$emit("error", {
						errorCodeShort: "OFFBRD#2",
						errorCodeLong: "NO_PROJECTS_PROVIDED",
						title: this.lview.errorNoProjectsAssignedTitle,
						message: this.lview.errorNoProjectsAssignedMessage,
						details: null
					});
					this.closeDialog();
					return;
     			 }

				this.selectForChangingManagers = this.managersDictionary;
				this.changingManagerEnabled = false;
				this.pickedChangingManager = this.defaultManagerId;

				if (this.date == null) this.datePicker.date = this.defaultDate;
				else this.datePicker.date = this.date;

				this.datePickerEnabled = false;

				this.setupCheckboxes();
				this.tableProjects.splice(0, this.tableProjects.length);
				for (var i = 0; i < this.assignedProjects.length; i++) {
					this.tableProjects.push({
					id: this.assignedProjects[i].id,
					name: this.assignedProjects[i].name,
					sortName:
						this.assignedProjects[i].id == -1
						? "#"
						: this.assignedProjects[i].name
					});

					this.tableProjects.sort((a, b) => {
					if (a.sortName < b.sortName) return -1;
					else if (a.sortName == b.sortName) return 0;
					else return 1;
					});
				}

				this.visible = true;
			},

			closeDialog() {
				this.visible = false;
			},

			getWorkerById(id) {
				for (var i = 0; i < this.workers.length; i++) {
					if (this.workers[i].id == id)
						return this.workers[i];
				}
				return null;
			},

			onAcceptDialog: async function () {
				this.$emit("request-sending-start");
				//console.log("debug");
				var errorOccured = false;
				var workers = this.workers;

				var successes = 0;

				// Preparing modifying query
				var modifier = {};
				var requestDataOnBoarding = {};
				var requestDataOffBoarding = {};
				var response;

				var requestsManager = [];

				var requestManagerChange = null;
				// Modify manager
				if (this.changingManagerEnabled) {
					modifier.manager = null;
					modifier.managerId = null;
					if (this.pickedChangingManager) {
						modifier.manager = this.pickedChangingManager;
						modifier.managerId = this.pickedChangingManager;
					} else {
						var workerTmp = this.getWorkerById(this.pickedChangingWorker);
						if (workerTmp != null)
							modifier.manager = workerTmp.managerId;
							modifier.managerId = workerTmp.managerId;
					}
					
					if(modifier.manager != null){
						for(var i = 0; i < workers.length; i++){
							try {
								requestsManager.push({
									worker: workers[i],
									promise: axios({
										url: localStorage.getItem("current_env") + "/api/v2/boardings/worker/" + workers[i].workerId,
										method: "PATCH",
										headers: {
											"Content-Type": "application/json",
											Authorization: "Bearer " + localStorage.getItem("jwt")
										},
										data: {
											worker: {
												parent_id: modifier.manager
											}
										}
									})
								});
							} catch(err){

							}
						}
					}
				}

				var packages = [];
				if(this.datePickerEnabled){
					// Separating onBoarding ids from offBoarding ids

					// Copy so that we do not perform any operations on the original array
					var workers = this.copySourceWorkers();
					var worker;

					/* TO REMOVE
						for (var key in this.checkboxes) {
							if (this.checkboxes[key]) {
								projectIds.push(parseInt(key));
							}
						}
						
						for (var i = 0; i < workers.length; i++) {
							worker = workers[i];
							for(var j=0; j<worker.boardings.length; j++){
								if(projectIds.indexOf(wokrer.boardings[j].projectId) != -1){
									if (worker.boardings[j].status == "ON_BOARDING" || worker.boardings[j].status == 1) 
										onBoardingIds.push(worker.boardings[j].boardingId);
									else 
										offBoardingIds.push(worker.boardings[j].boardingId);
								}
							}
						}
					*/

					// Selecting boardings
					var projectIds = [];
					for (var key in this.checkboxes) {
						if (this.checkboxes[key]) {
							projectIds.push(parseInt(key));
						}
					}

					var boardings = this.filterBoardings(projectIds);

					// Sending requests
					var errors = [];
					var modified = [];
					var boarding;
					var date = this.datePicker.date;
					var status;
					var requestDataOnBoard = {
						on_boarding_date: date
					};
					var requestDataOffBoard = {
						off_boarding_date: date
					};
					var requestData = [];
					
					var onBoardingProjectAssignmentIds = [], offBoardingProjectAssignmentIds = [];
					var onBoardings = [], offBoardings = [];
					for (var i = 0; i < boardings.length; i++) {
						if (boardings[i].status == 1) {
							onBoardingProjectAssignmentIds.push(boardings[i].projectAssignmentId);
							onBoardings.push(boardings[i]);
						}
						else {
							offBoardingProjectAssignmentIds.push(boardings[i].projectAssignmentId);
							offBoardings.push(boardings[i]);
						}
					}

					if (onBoardingProjectAssignmentIds.length > 0) {
						var onBoardingResponsePromise = axios({
							method: "PATCH",
							url: fob_config.api_url + "/api/v2/boardings/projects-assignment/",
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: {
								projects_assignment_id: onBoardingProjectAssignmentIds,
								boarding: requestDataOnBoarding
							}
						});

						try {
							var onBoardingResponse = await onBoardingResponsePromise;

							for(var onIdx = 0; onIdx < onBoardingResponse.data.boardings.length; onIdx++) {
								onBoardings[onIdx].boardingId = onBoardingResponse.data.boardings[onIdx].id;
								onBoardings[onIdx].onBoardingDate = new Date(date);
								onBoardings[onIdx].recordId = onBoardingResponse.data.boardings[onIdx].worker + "_1_" + date;
								successes++;
							}
							//boarding.boardingId = response.data.id;
							//boarding.onBoardingDate = new Date(date);
							//boarding.recordId = response.data.boarding.worker + "_1_" + date;
						} catch (error) {
							this.$emit("request-sending-end");
							errorOccured = true;
							if (error.isAxiosError) {
								if (error.response.status == 403) {
									this.$emit('error', {
										title: this.lmessages.forbiddenOperation,
										message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
										errorCodeShort: "CNFBRD#5",
										errorCodeLong: "REQUEST_FAILED",
										details: String(error)
									});
								}
								else if (error.response.data != null && error.response.data != undefined) {
									if (error.response.data.boarding != null && error.response.data.boarding != undefined) {
										if (error.response.data.boarding.other_boarding != null && error.response.data.boarding.other_boarding != undefined) {
											this.$emit('error', {
												title: this.lerrors.boarding.other_boarding.header,
												message: this.lerrors.boarding.other_boarding.string,
												errorCodeShort: this.lerrors.boarding.other_boarding.code,
												errorCodeLong: this.lerrors.boarding.other_boarding.code,
												details: String(error)
											});
										}

										if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined){
											this.$emit('error', {
												title: this.lerrors.boarding.off_boarding_date.header,
												message: this.lerrors.boarding.off_boarding_date.string,
												errorCodeShort: this.lerrors.boarding.off_boarding_date.code,
												errorCodeLong: this.lerrors.boarding.off_boarding_date.code,
												details: String(error)
											});
										}
									}

									if (error.response.data.projects_assignment_id != null && error.response.data.projects_assignment_id != undefined) {
										this.$emit('error', {
											title: this.lerrors.projects_assignment_id.header,
											message: this.lerrors.projects_assignment_id.string,
											errorCodeShort: this.lerrors.projects_assignment_id.code,
											errorCodeLong: this.lerrors.projects_assignment_id.code,
											details: String(error)
										});									
									}
								}
							}
							else {
								errors.push(error);

								this.$emit('error', {
									title: this.lview.errorOccured,
									message: this.lview.errorMessage2 + packages[i].boarding.boardingId,
									errorCodeShort: "CHNGBRD#2",
									errorCodelong: this.lview.errorCodeLong,
									details: String(error)
								});
							}
						}
					}

					if (offBoardingProjectAssignmentIds.length > 0) {
						var offBoardingResponsePromise = axios({
							method: "PATCH",
							url: fob_config.api_url + "/api/v2/boardings/projects-assignment/",
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("jwt")
							},
							data: {
								projects_assignment_id: offBoardingProjectAssignmentIds,
								boarding: requestDataOffBoarding
							}
						});

						try {
							var offBoardingResponse = await offBoardingResponsePromise;
							for (var offIdx = 0; offIdx < offBoardingResponse.data.boardings.length; offIdx++) {
								offBoardings[offIdx].boardingId = offBoardingResponse.data.boardings[offIdx].id;
								offBoardings[offIdx].offBoardingDate = new Date(date);
								offBoardings[offIdx].recordId = offBoardingResponse.data.boardings[offIdx].worker + "_7_" + date;
								successes++;
							}
						} catch (error) {
							this.$emit("request-sending-end");
							errorOccured = true;
							if (error.isAxiosError) {
								if (error.response.status == 403) {
									this.$emit('error', {
										title: this.lmessages.forbiddenOperation,
										message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
										errorCodeShort: "CNFBRD#5",
										errorCodeLong: "REQUEST_FAILED",
										details: String(error)
									});
								}
								else if (error.response.data != null && error.response.data != undefined) {
									if (error.response.data.boarding != null && error.response.data.boarding != undefined) {
										if (error.response.data.boarding.other_boarding != null && error.response.data.boarding.other_boarding != undefined) {
											this.$emit('error', {
												title: this.lerrors.boarding.other_boarding.header,
												message: this.lerrors.boarding.other_boarding.string,
												errorCodeShort: this.lerrors.boarding.other_boarding.code,
												errorCodeLong: this.lerrors.boarding.other_boarding.code,
												details: String(error)
											});
										}

										if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined){
											this.$emit('error', {
												title: this.lerrors.boarding.off_boarding_date.header,
												message: this.lerrors.boarding.off_boarding_date.string,
												errorCodeShort: this.lerrors.boarding.off_boarding_date.code,
												errorCodeLong: this.lerrors.boarding.off_boarding_date.code,
												details: String(error)
											});
										}
									}
									
									if (error.response.data.projects_assignment_id != null && error.response.data.projects_assignment_id != undefined) {
										this.$emit('error', {
											title: this.lerrors.projects_assignment_id.header,
											message: this.lerrors.projects_assignment_id.string,
											errorCodeShort: this.lerrors.projects_assignment_id.code,
											errorCodeLong: this.lerrors.projects_assignment_id.code,
											details: String(error)
										});									
									}
								}
							}
							else {
								errors.push(error);

								this.$emit('error', {
									title: this.lview.errorOccured,
									message: this.lview.errorMessage2 + packages[i].boarding.boardingId,
									errorCodeShort: "CHNGBRD#2",
									errorCodelong: this.lview.errorCodeLong,
									details: String(error)
								});
							}
						}
					}

					/*for (var i = 0; i < boardings.length; i++) {
						// Packages
						if(boardings[i].status == 1){
							packages.push({
								boarding: boardings[i],
								promise: axios({
									method: "PATCH",
									url: localStorage.getItem("current_env") + "/api/v2/boardings/" + boardings[i].projectAssignmentId,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Bearer " + localStorage.getItem("jwt")
									},
									data: {
										boarding: requestDataOnBoard 
									}
								})
							});
						}
						else {
							packages.push({
								boarding: boardings[i],
								promise: axios({
									method: "PATCH",
									url: localStorage.getItem("current_env") + "/api/v2/boardings/" + boardings[i].projectAssignmentId,
									headers: {
										"Content-Type": "application/json",
										Authorization: "Bearer " + localStorage.getItem("jwt")
									},
									data: {
										boarding: requestDataOffBoard 
									}
								})
							});
						}
					}*/
				}

				for(var i = 0; i < requestsManager.length; i++){
					try{
						await requestsManager[i].promise;
						requestsManager[i].worker.managerId = modifier.manager;

						successes++;
					} catch(error){
						errorOccured = true;
						this.$emit('request-sending-end');
						if (error.isAxiosError) {
							if (error.response.status == 403) {
								this.$emit('error', {
									title: this.lmessages.forbiddenOperation,
									message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
									errorCodeShort: "CNFBRD#5",
									errorCodeLong: "REQUEST_FAILED",
									details: String(error)
								});
							}
							else if (error.response.data != null && error.response.data != undefined) {
								if (error.response.data.worker != null && error.response.data.worker != undefined) {
									if (error.response.data.worker.parent_id != null && error.response.data.worker.parent_id != undefined) {
										for (var i=0; i < this.lerrors.worker.parent_id.length; i++) {
											if (this.lerrors.worker.parent_id[i].code == error.response.data.worker.parent_id[0].code) {
												this.$emit('error', {
													title: this.lerrors.worker.parent_id[i].header,
													message: this.lerrors.worker.parent_id[i].string,
													errorCodeShort: this.lerrors.worker.parent_id.code,
													errorCodeLong: this.lerrors.worker.parent_id.code,
													details: String(error)
												});										
											}
										}
									}
								}
							}
							else { // any other axios error
								this.$emit('error', {
									title: this.lmessages.errorOccuredTitle,
									errorCodeShort: error.toString(),
									errorCodeLong: error.toString(),
									message: this.lmessages.errorOccured,
									details: error.toString()
								});
							}
						}
						else {
							//console.log(JSON.stringify(error));
							errors.push(error);

							this.$emit('error', {
								title: this.lview.errorOccured,
								message: this.lview.errorMessage + requestsManager[i].worker.workerId,
								errorCodeShort: "CHNGBRD#1",
								errorCodelong: this.lview.errorCodeLong,
								details: String(error)
							});
						}
					}
				}
				/*for (var i = 0; i < packages.length; i++) {
					try {
						response = await packages[i].promise;

						console.log(response);
						var boarding = packages[i].boarding;
						if(packages[i].boarding.status == 7){
							boarding.boardingId = response.data.id;
							boarding.offBoardingDate = new Date(date);
							boarding.recordId = response.data.boarding.worker + "_7_" + date;
						}
						else{
							boarding.boardingId = response.data.id;
							boarding.onBoardingDate = new Date(date);
							boarding.recordId = response.data.boarding.worker + "_1_" + date;
						}
						successes++;
					} catch (error) {
						if (error.isAxiosError) {
							if (error.response.data.boarding.other_boarding != null && error.response.data.boarding.other_boarding != undefined) {
								if (error.response.data.boarding.other_boarding.length > 0) {
									this.$emit('error', {
										title: this.lview.errorOccured,
										message: error.response.data.boarding.other_boarding[0].string,
										errorCodeShort: error.response.data.boarding.other_boarding[0].code,
										errorCodeLong: error.response.data.boarding.other_boarding[0].code,
										details: String(error)
									});
								}
							}

							if (error.response.data.boarding.off_boarding_date != null && error.response.data.boarding.off_boarding_date != undefined){
								if (error.response.data.boarding.off_boarding_date.length > 0) {
									this.$emit('error', {
										title: this.lview.errorOccured,
										message: error.response.data.boarding.off_boarding_date[0].string,
										errorCodeShort: error.response.data.boarding.off_boarding_date[0].code,
										errorCodeLong: error.response.data.boarding.off_boarding_date[0].code,
										details: String(error)
									});
								}
							}
						}
						else {
							errors.push(error);
							errorOccured = true;

							this.$emit('error', {
								title: this.lview.errorOccured,
								message: this.lview.errorMessage2 + packages[i].boarding.boardingId,
								errorCodeShort: "CHNGBRD#2",
								errorCodelong: this.lview.errorCodeLong,
								details: String(error)
							});
						}

						//console.log(JSON.stringify(error));
					}
				}*/

				// Finishing up the request
				this.$emit('request-sending-end');

				if (successes > 0) {
					// Event emits modified workers, in case only a copy is passed to dialog box
					this.$emit('request-sending-success', workers);
					this.closeDialog();
				}
			},

			/**
			 * Filters boarding for provided project ids
			 * 
			 * @param {Array} projectIds Array of integers
			 * @returns {Array} Array of boardings for provided project ids
			 */
			filterBoardings: function(projectIds) {
				var result = [];

				var boarding;
				for(var i = 0; i < this.workers.length; i++){
					for(var j = 0; j < this.workers[i].boardings.length; j++){
						boarding = this.workers[i].boardings[j];

						if(projectIds.indexOf(boarding.projectId) != -1){
							result.push(boarding);
						}
					}
				}

				return result;
			},

			/**
			 * Finds where is untilDate for project of given id
			 * @param {array} objectToEdit array of objects to edit. Objects require the same structure as objects in sourceData
			 * @param {Integer} id id of project which untilDate index we want to find
			*/
			getIndexOfUntilDateByProjectId(objectToEdit, id) {
				for (var i = 0; i < objectToEdit.projectId.length; i++) {
					if (objectToEdit.projectId[i] == id) {
						return i;
					}
				}
				return null;
			},

			/**
			 * Edits parameters of provided objects to edit
			 *
			 * @param {array} objectsToEdit array of objects to edit. Objects require the same structure as objects in sourceData
			 * @param {newParams} object with exclusively the fields the function is supposed to edit, e.x: { firstName: "Newfirstname", untilDate: new Date("2019-07-06") }. Only these fields will be modified
			 */
			editSourceData(objectsToEdit, newParams) {
				var keys = Object.keys(newParams);
				//console.log(newParams);
				
				//if(newParams.boardings != null){
				//	console.log(newParams.boardings);
					/*for(var i=0; i<objectsToEdit.length; i++){
						worker = objectsToEdit[i];
						for(var j=0; j<worker.boardings.length; j++){
							if( newParams.projectId.includes(worker.boardings[j].project_id) ){
								if(worker.boardings[j].status == 1){
									worker.boardings[j].onBoardingDate = newParams.boardings.onBoardingDate;
								}
								else if(worker.boardings[j].status == 7){
									worker.boardings[j].offBoardingDate = newParams.boardings.offBoardingDate;
								}
							}
						}
					}*/
				//}
				
				if(newParams.manager != null){
					for(var i=0; i<objectsToEdit.length; i++){
						objectsToEdit[i].managerId = newParams.manager;
					}
				}
				/*
				for (var i = 0; i < objectsToEdit.length; i++) {
					for (var j = 0; j < keys.length; j++) {
						if (keys[j] == "onBoardingDate" || keys[j] == "offBoardingDate") {
							for (var k = 0; k < newParams.projectId.length; k++) {
								var index = this.getIndexOfUntilDateByProjectId(objectsToEdit[i], newParams.projectId[k]);
								if (index != null) {
									objectsToEdit[i].boardings[index] = newParams[keys[j]][0];
								}
							}
						}
						else if (keys[j] != "projectId") {
							objectsToEdit[i][keys[j]] = newParams[keys[j]];
						}
					}
				}
				*/
			},

			copySourceWorkers() {
				var result = [];
				for (var i = 0; i < this.workers.length; i++) {
					result.push(this.workers[i]);
				}
				return result;
			},

			onWorkerSelectClick() {
				if (!this.changingWorkerEnabled) {
					this.changingWorkerEnabled = true;
				}
			},

			onManagerSelectClick() {
				if (!this.changingManagerEnabled) {
					this.changingManagerEnabled = true;
					this.$nextTick(() => this.$refs.managerSelect.focus());
					this.$nextTick(() => this.$refs.managerSelect.activateMenu());
				}
			},

			onSelectClick() {
				if (!this.changingLocationEnabled) {
					this.changingLocationEnabled = true;
					this.$nextTick(this.$refs.locationSelect.focus);
					this.$nextTick(this.$refs.locationSelect.activateMenu);
				}
			},

			/**
			 * Finds project name in projects dictionary
			 *
			 * @param {Integer} projectId Id to look for
			 * @param {Array} projectId array with projects ids to look for
			 * @returns {String} Project name if foound, null otherwise
			 */
			getProjectName(projectId) {
				if (typeof (projectId) == "object") {
					let names = [];
					for (var i = 0; i < projectId.length; i++) {
						for (var j = 0; j < this.assignedProjects.length; j++) {
							if (this.assignedProjects[j].id == projectId[i]) {
								names.push(this.assignedProjects[j].name);
							}
						}
					}
					let prjname = "";
					for (var i = 0; i < names.length - 1; i++) {
						prjname += names[i] + ", ";
					}
					prjname += names[names.length - 1];
					return prjname;
				}
				else if (typeof (projectId) == "Integer") {
					for (var i = 0; i < this.assignedProjects.length; i++) {
						if (this.assignedProjects[i].id == projectId) {
							return this.assignedProjects[i].name;
						}
					}
				}
				return null;
			},

			setupCheckboxes() {
				this.checkboxes = {};

				for (var i = 0; i < this.assignedProjects.length; i++) {
					this.checkboxes[this.assignedProjects[i].id] = false;
				}
				for (var i = 0; i < this.markedProjectsIds.length; i++) {
					this.checkboxes[this.markedProjectsIds[i]] = true;
				}
			},
		}
	};
</script>

<style>
div.ChangeBoardingPlansDialog .projects-table .v-subheader {
  padding: 0;
}

div.ChangeBoardingPlansDialog .projects-table .v-list__tile {
  padding: 0;
}
</style>

<style>
	.projects-table{
		padding: 0;
		margin-bottom: 10px;
	}
	.projects-table-title{
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  		padding: 5px;
	}
</style>