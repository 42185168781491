<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"

<terminate-boarding-form
    :projectsList="Array"
    ref='yourReference'
></terminate-boarding-form>
$refs.yourReference.reload();
$refs.yourReference.submit();

-->

<template>
    <div>
        <div style="text-align:center; color:#ff5252 !important">{{ form.globalError }}</div>

        <projects-pick-table
            :projectsList="projectsList"
            :projectsListChecked="projectsListChecked"
            :errorMessages="form.fields.boardingIds.errors"
            ref="projectsTableRef"
        ></projects-pick-table>

        <!-- Off boarding date -->
        <v-dialog
            ref="offBoardingDateDialog"
            v-model="form.fields.offBoardingDate.modal"
            :return-value.sync="form.fields.offBoardingDate.value"
            persistent
            width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="form.fields.offBoardingDate.value"
                label="{L Off-Boarding date}"
                append-icon="event"
                :error="form.fields.offBoardingDate.errors.length > 0"
                :error-messages="form.fields.offBoardingDate.errors"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="form.fields.offBoardingDate.value" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="form.fields.offBoardingDate.modal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.offBoardingDateDialog.save(form.fields.offBoardingDate.value)">OK</v-btn>
            </v-date-picker>
        </v-dialog>

        <!-- Contract end date -->
        <v-dialog
            ref="contractEndDateDialog"
            v-model="form.fields.contractEndDate.modal"
            :return-value.sync="form.fields.contractEndDate.value"
            persistent
            width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="form.fields.contractEndDate.value"
                label="{L Contract end date}"
                append-icon="event"
                :error="form.fields.offBoardingDate.errors.length > 0"
                :error-messages="form.fields.offBoardingDate.errors"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="form.fields.contractEndDate.value" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="form.fields.contractEndDate.modal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.contractEndDateDialog.save(form.fields.contractEndDate.value)">OK</v-btn>
            </v-date-picker>
        </v-dialog>

        <!-- Project end date -->
        <v-dialog
            ref="projectEndDateDialog"
            v-model="form.fields.projectEndDate.modal"
            :return-value.sync="form.fields.projectEndDate.value"
            persistent
            width="290px"
        >
            <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="form.fields.projectEndDate.value"
                label="{L Project end date}"
                append-icon="event"
                :error="form.fields.offBoardingDate.errors.length > 0"
                :error-messages="form.fields.offBoardingDate.errors"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker v-model="form.fields.projectEndDate.value" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="form.fields.projectEndDate.modal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.projectEndDateDialog.save(form.fields.projectEndDate.value)">OK</v-btn>
            </v-date-picker>
        </v-dialog>

        <div v-if="allowRatingUpdate">
            <!-- Ratings -->
            <nullable-rating
                v-model="form.fields.rating.value"
            ></nullable-rating>

            <v-textarea
                v-model="form.fields.ratingComment.value"
                :auto-grow="true"
                :rows="1"
                :clearable="true"
                label="{L Rating comment}"
            ></v-textarea>
        </div>
    </div>
</template>

<script>
import FormHandler from '@/libs/FormHandler'

export default {
    name: "terminateBoardingForm",
    data: () => ({
        workerIds: [],
        projectIds: [],
        formHandler: null,

        /**
         * Form configuration (default)
         * Filled upon form reload
         */
        form: {
            action: localStorage.getItem("current_env") + "/api/forms/terminate_boardings/",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            globalError: "",
            fields: {
                boardingIds: {
                    name: "boarding_ids",
                    value: [],
                    errors: []
                },
                offBoardingDate: {
                    modal: null,
                    name: "off_boarding_date",
                    value: null,
                    errors: []
                },
                contractEndDate: {
                    modal: null,
                    name: "contract_end_date",
                    value: null,
                    errors: []
                },
                projectEndDate: {
                    modal: null,
                    name: "project_end_date",
                    value: null,
                    errors: []
                },
                rating: {
                    name: "rating",
                    value: null,
                    errors: []
                },
                ratingComment: {
                    name: "rating_comment",
                    value: null,
                    errors: []
                }
            }
        }
    }),
    mounted: function() {
        this.reload();
        this.formHandler = new FormHandler(this.form);
    },
    beforeMount: function() {

    },
    watch: {
    
    },
    computed: {
    },
    props: {
        allowRatingUpdate: {
            type: Boolean,
            default: true
        },
        /**
         * Array item structure
         *  {
         *      id: <Integer>,
         *      name: <String>,
         *      // This array defines, which boardings will be updated upon form confirmation with this project selected
         *      boardingIds: <Array of Integers>
         *  }
         */
        projectsList: {
            type: Array,
            required: true
        },
        /**
         * Array of integers
         */
        projectsListChecked: {
            type: Array,
            default: () => []
        },
        offBoardingDate: {
            type: Date,
            default: null
        },
        contractEndDate: {
            type: Date,
            default: null
        },
        projectEndDate: {
            type: Date,
            default: null
        },
        rating: {
            type: Number,
            default: null
        },
        ratingComment: {
            type: String,
            default: null
        }
    },
    methods: {
        reload(){
            this.$refs.projectsTableRef.reload();

            var fields = this.form.fields;
            fields.offBoardingDate.value = this.offBoardingDate;
            fields.contractEndDate.value = this.contractEndDate;
            fields.projectEndDate.value = this.projectEndDate;
            fields.rating.value = this.rating;
            fields.ratingComment.value = this.ratingComment;
        },
        getBoardingIds(){
            var checkedProjects = this.$refs.projectsTableRef.getValue();
            var boardingIds = [];
            for(var i = 0; i < this.projectsList.length; i++){
                if(checkedProjects.includes(this.projectsList[i].id)){
                    for(var j = 0; j < this.projectsList[i].boardingIds.length; j++){
                        
                        boardingIds.push(this.projectsList[i].boardingIds[j]);
                    }
                }
            }
            return boardingIds;
        },
        async submit(){
            this.form.fields.boardingIds.value = this.getBoardingIds();

            var feedback = await this.formHandler.submitAndFillForm();
            return feedback;
        }
    }
}
</script>