<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.2"
__author__ = "Marcin Roszczyk, .."
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->

<template>
	<v-container style="background-color: white; max-width: 480px;">
		<v-layout row wrap>
			<v-container>
				<v-layout justify-center align-center class="login__top-logo-layout">
					<img class="login__top-logo" v-bind:src="'/static/img/fast_logo.png'" />
				</v-layout>

				<v-col class="align-self-center" style="height: 100px; padding: 0 10%; margin-top: 55px; margin-bottom: 65px;">
					<!-- email -->
					<v-text-field prepend-inner-icon="mail_outline"
						v-model="email"
						type="text"
						:rules="[validationRules.email]"
						:label=lview.yourEmail
						:error-messages="errorMessages.email"
						color="rgba(4, 202, 90, 1)">
					</v-text-field>

					<!-- date of birth -->
					<v-dialog
						ref="dialog"
						v-model="showDatePicker"
						:return-value.sync="birthDate"
						persistent
						width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="birthDate"
								:label=lview.birthDate
								prepend-inner-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
								:error-messages="errorMessages.birthDate"
							></v-text-field>
						</template>

						<v-date-picker v-model="date" scrollable :max="new Date().toISOString().substr(0, 10)">
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="showDatePicker = false"> Cancel </v-btn>
							<v-btn text color="primary" @click="$refs.dialog.save(date)"> OK </v-btn>
						</v-date-picker>
					</v-dialog>

					<span v-if="message1" style="color:red"> {{ message1 }} </span>
				</v-col>

				<v-snackbar
					v-model="passwordResetNotifier"
					fixed
					class="snackbar-offline-notifier"
					:color="snackbarColor"
				>
					<v-flex xs12 class="align-self-center" style="font-size: 16px; text-align: center;">
						<div>{{ passwordResetNotification }}</div>
					</v-flex>
				</v-snackbar>

				<v-layout justify-center mt-3>
					<v-btn large color="primary" @click="handleSubmit" style="width:250px; color: white;">{{ lview.resetPassword }}</v-btn>
				</v-layout>

				<v-layout justify-center>
					<v-btn text small @click="handleCancel" color="grey" style="width:250px; margin-top: 20px;">{{ lview.cancelBack }}</v-btn>
				</v-layout>
			</v-container>

			<v-container style="position:relative; bottom:0; text-align: center; height: auto;" >
				<img v-bind:src="logo"/>
			</v-container>
			<v-layout style="font-size:12px" justify-center mt-4>						
				<v-row no-gutters>
					<v-col cols="12" align="center">
						<a class="a-link-normal" target="_blank" rel="noopener" href="https://www.fastonboarding.pl/centrum-pomocy/">
						{{ lview.needHelp }}
						</a>
					</v-col>
				</v-row>					
			</v-layout>			
		</v-layout>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import store from '../store.js'
	import fob_config from '../fob_config.js'

	export default {
		data() {
			return {
				email: "",
				birthDate: "",

				showDatePicker: false,
				date: "",

				current_env: fob_config.api_url,
				logo: fob_config.client_logo,
				passwordResetNotification: "",
				passwordResetNotifier: false,
				snackbarColor: "",
				errorMessages: {
					email: "",
					birthDate: "",
				},
				message1: '',
				validationRules: {
					email: value => {
						if(value == ''){
							return true;
						}
						let regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
						if (regex.test(value)){
							return true;
						} else {
							return this.lview.incorrectEmailStructure;
						}
					}
				}
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.passwordReset'); } },
			lbuttons: { get: function () { return this.$t('commons.buttons'); } },
			lmessages: { get: function () { return this.$t('commons.messages'); } },
		},
		methods: {
			handleSubmit: function(){
				if (this.email != "" && this.birthDate != ""){
					let formData = new FormData();
					var that = this;

					formData.append("email", this.email);
					formData.append("date_of_birth", this.birthDate);
					this.$emit('set-state', 'LOADING_OVERLAYER', this.lmessages.resettingPassword);
					axios({
						url: this.current_env + "/api/password_reset_request/",
						method: "POST",
						data: formData
					}).then(response => {
						this.$emit('set-state', 'default');
						this.passwordResetNotification = this.lview.linkSent;
						this.passwordResetNotifier = true;
						this.snackbarColor = "success";

						setTimeout(function(){
							that.$router.push("/login");
						}, 5000);
					}).catch(error => {
						this.$emit('set-state', 'default');
						if(error.isAxiosError){
							if(error.response == undefined || error.response == null){
								//this.$emit('set-state', 'ERROR');
								this.message1 = this.lmessages.networkError;
								return;
							}
							if(error.response.status == 404 || error.response.status == 400){
								this.passwordResetNotification = this.lview.couldNotResetPassword;
								this.passwordResetNotifier = true;
								this.snackbarColor = "error";
							}
							else if(error.response.status == 401){
								this.passwordResetNotification = this.lview.sendMailError;
								this.passwordResetNotifier = true;
								this.snackbarColor = "error";
							}
							else if(error.response.status == 500){
								//this.$emit('set-state', 'ERROR');
								this.message1 = this.lmessages.internalServerError;
							}
						}
					});
				}
				else {
					if(this.email == ""){
						this.errorMessages.email = this.lview.emailNotProvided;
					}
					if(this.birthDate == ""){
						this.errorMessages.birthDate = this.lview.birthDateNotProvided;
					}
				}
			},
			handleCancel(e) {
				this.$router.push('/login')
			}
		},
		mounted(){
			this.message1 = '';
			localStorage.clear();
		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	h1,
	h2 {
		font-weight: normal;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #35495E;
	}

	.login__top-logo-layout {
		width: 100%;
		height: 100px;
	}

	.login__top-logo {
		width: 90%;
		height: auto;
	}

	.login {
		border-radius: 5px;
		padding: 1.5rem;
		width: auto;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		position: relative;
	}
</style>
