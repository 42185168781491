<template>
    <v-layout>
        <v-divider></v-divider>
        <v-list>
            <v-list-item two-line @click="$router.push('/preferences')">
                <v-list-item-content>
                    <v-list-item-title> {{ lview.settings.preferences.title }} </v-list-item-title>
                    <v-list-item-subtitle> {{ lview.settings.preferences.description }} </v-list-item-subtitle>
                </v-list-item-content>
                <v-icon> mdi-greater-than </v-icon>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item two-line @click="$router.push('/change-password')">
                <v-list-item-content>
                    <v-list-item-title> {{ lview.settings.changePassword.title }} </v-list-item-title>
                    <v-list-item-subtitle> {{ lview.settings.changePassword.description }} </v-list-item-subtitle>
                </v-list-item-content>
                <v-icon> mdi-greater-than </v-icon>
            </v-list-item>
            <v-divider></v-divider>
        </v-list>
    </v-layout>
</template>

<script>
export default {
    data: function(){
        return {
        }
    },
    methods: {

    },
    mounted: function(){
        this.$emit('set-title', this.lview.title);
    },
    beforeDestroy: function(){
        this.$emit('set-display', 'default');
        this.$emit('set-title', '');
    },
    computed: {
        lview: {
            get: function(){
                return this.$t('views.settings');
            }
        }
    }
}
</script>

<style scoped>

</style>