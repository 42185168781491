<template>
    <v-layout style="margin-top: 10px;">
        <v-data-table
            :headers="dataTableHeaders"
            :items="dataTableItems"
            id="dataTable"
            item-key="id" 
            group-by="module"
            :expanded.sync="expanded"
        >
            <template v-slot:top>
                <v-flex xs12 style="padding-top: 10px;">
                    <div style="display: flex; flex-wrap: wrap;">
                        <!-- role name field -->
                        <v-text-field
                            v-model="roleName"
                            :label="lviews.roleName"
                            :disabled = nameDisabled
                            style="height: 66px;"
                            clearable
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"
                        ></v-text-field>
                    </div>
                </v-flex>
            </template>
            <template v-slot:group.header="{items, isOpen, toggle}">
                <th colspan="6">
                    <v-icon @click="toggle"
                        >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ items[0].module }}
                    <v-icon @click="checkAllInModule(items[0].module)"
                    >{{'mdi-check-all' }}
                    </v-icon>
                </th>
            </template>

            <template v-slot:item.name="{ item }">
                <v-icon 
                    v-if="item.has_projects"
                    @click="() => { clickedProject(item); }"
                    >{{ item.is_open ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                </v-icon>
                {{ item.name }}
            </template>

            <template v-slot:item.add="{ item }">
                <v-simple-checkbox
                v-model="item.add"
                :disabled="item.add_disabled"
                style="text-align:center;"
                ></v-simple-checkbox>
            </template>

            <template v-slot:item.read="{ item }">
                <v-simple-checkbox
                v-model="item.read"
                :disabled="item.read_disabled"
                style="text-align:center;"
                ></v-simple-checkbox>
            </template>

            <template v-slot:item.edit="{ item }">
                <v-simple-checkbox
                v-model="item.edit"
                :disabled="item.edit_disabled"
                style="text-align:center;"
                ></v-simple-checkbox>
            </template>

            <template v-slot:item.delete="{ item }">
                <v-simple-checkbox
                v-model="item.delete"
                :disabled="item.delete_disabled"
                style="text-align: center;"
                ></v-simple-checkbox>
            </template>

            <template v-slot:item.check_all="{ item }">
                <v-icon @click="checkAllInRow(item)"
                >{{'mdi-check-all' }}
                </v-icon>
            </template>

            <template v-slot:expanded-item="{ headers, item }" style="margin:0; padding:0;">
                <td :colspan="headers.length" style="width:20%; padding:0 0; border-right:0">
                    <v-data-table
                        :headers="dataTableHeaders"
                        :items="item.projects"
                        id="dataTableProjects"
                        item-key="id"
                        hide-default-footer
                    >
                        <template v-slot:item.name="{ item }">
                            {{ item.name }}
                        </template>

                        <template v-slot:item.add="{ item }">
                            <v-simple-checkbox
                            v-model="item.add"
                            :disabled="item.add_disabled"
                            style="text-align:center;"
                            ></v-simple-checkbox>
                        </template>

                        <template v-slot:item.read="{ item }">
                            <v-simple-checkbox
                            v-model="item.read"
                            :disabled="item.read_disabled"
                            style="text-align:center;"
                            ></v-simple-checkbox>
                        </template>

                        <template v-slot:item.edit="{ item }">
                            <v-simple-checkbox
                            v-model="item.edit"
                            :disabled="item.edit_disabled"
                            style="text-align:center;"
                            ></v-simple-checkbox>
                        </template>

                        <template v-slot:item.delete="{ item }">
                            <v-simple-checkbox
                            v-model="item.delete"
                            :disabled="item.delete_disabled"
                            style="text-align: center;"
                            ></v-simple-checkbox>
                        </template>

                        <template v-slot:item.check_all="{ item }">
                            <v-icon @click="checkAllInRow(item)"
                            >{{'mdi-check-all' }}
                            </v-icon>
                        </template>
                    </v-data-table>
                    <!-- <v-simple-table id="dataTableProjects" style="padding:0; margin:0; ">
                        <template v-slot:default>
                            <tbody>
                                <tr  v-for="project in item.projects" :key="project.name" >
                                    <td>{{project.name}}</td>
                                    <td>
                                    <v-simple-checkbox
                                    v-model="project.add"
                                    style="text-align: center;"
                                    ></v-simple-checkbox>
                                    </td>
                                    <td>
                                    <v-simple-checkbox
                                    v-model="project.read"
                                    style="text-align: center;"
                                    ></v-simple-checkbox>
                                    </td>
                                    <td>
                                    <v-simple-checkbox
                                    v-model="project.edit"
                                    style="text-align: center;"
                                    ></v-simple-checkbox>
                                    </td>
                                    <td>
                                    <v-simple-checkbox
                                    v-model="project.delete"
                                    style="text-align: center;"
                                    ></v-simple-checkbox>
                                    </td>
                                    <td>
                                    <v-icon @click="checkAllInRow(project)"
                                    >{{'mdi-check-all' }}
                                    </v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table> -->
                </td>
            </template>
        </v-data-table>
        <v-layout align-center justify-end class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
            <v-btn
                primary
                color="white"
                @click="rejectRoleChanges()"
                style='vertical-align:top; margin-right:20px'
                class="smart-button-width"
            >
                {{lviews.cancel}}
            </v-btn>
            <v-btn
                primary
                color="primary"
                @click="saveRoleChanges()"
                style='vertical-align:top;'
                class="smart-button-width"
            >
                {{lviews.save}}
            </v-btn>
        </v-layout>
        <v-snackbar
            v-model="snackbar.visible"
            :color="snackbar.color"
        >
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-layout>
</template>
<script>
import axios from 'axios';
import fob_config from '../fob_config.js';
export default {
    name: 'DataSharingConfiguration',
    data() {
        return{
            expanded:[],
            roleName:"",
            nameDisabled: false,
            dataTableHeaders:[
                { id: 0, text: '{L Dane }', align: 'left', sortable: false, value: 'name'},
                { id: 1, text: '{L Dodawanie }', align: 'center', sortable: false, value: 'add' },
                { id: 2, text: '{L Odczyt }', align: 'center', sortable: false, value: 'read' },
                { id: 3, text: '{L Aktualizacja }', align: 'center', sortable: false, value: 'edit' },
                { id: 4, text: '{L Usuwanie }', align: 'center', sortable: false, value: 'delete' },
                { id: 5, text: '{L Zaznacz wszystko }', align: 'center', sortable: false, value: 'check_all' },
            ],
            dataTableProjectHeaders:[
                { id: 0, text: '{L Projekt }', align: 'left', sortable: false, value: 'name'},
                { id: 1, text: '{L Dodawanie }', align: 'center', sortable: false, value: 'add' },
                { id: 2, text: '{L Odczyt }', align: 'center', sortable: false, value: 'read' },
                { id: 3, text: '{L Aktualizacja }', align: 'center', sortable: false, value: 'edit' },
                { id: 4, text: '{L Usuwanie }', align: 'center', sortable: false, value: 'delete' },
                { id: 5, text: '{L Zaznacz wszystko }', align: 'center', sortable: false, value: 'check_all' },
            ],
            dataTableItems:[
                {
                id: 0, 
                module:"{L Dokumenty }", 
                name:"{L Swoje rekordy }", 
                add: true, 
                read: true, 
                edit: false, 
                delete: false, 
                add_disabled: false, 
                read_disabled: false, 
                edit_disabled: false, 
                delete_disabled: false,
                check_all:false, 
                has_projects:true,
                is_open: false,
                projects: [
                    {
                        id: 0,
                        name:"Projekt 1",
                        add: false,
                        read: false,
                        edit: false,
                        delete: false,
                        add_disabled: false, 
                        read_disabled: false, 
                        edit_disabled: false, 
                        delete_disabled: false,
                        check_all: false,
                    },
                    {
                        id: 1,
                        name:"Projekt 2",
                        add: false,
                        read: false,
                        edit: false,
                        delete: false,
                        add_disabled: false, 
                        read_disabled: false, 
                        edit_disabled: false, 
                        delete_disabled: false,
                        check_all: false,
                    },
                ]
                },
                // {id: 1, module:"{L Dokumenty }", name:"{L Wszystkie rekordy }", add: true, read: true, edit: false, delete: false, add_disabled: false, read_disabled: false, edit_disabled: false, delete_disabled: false,check_all:false},
                // {id: 2, module:"{L Pliki }", name:"{L Wszystkie rekordy }", add: true, read: true, edit: false, delete: false, add_disabled: false, read_disabled: false, edit_disabled: false, delete_disabled: false, check_all:false},
            ],
            snackbar: {
                visible: false,
                color: "primary",
                message: ""
            },
        };
    },
    methods:{
        clickedProject(value) {
            if(this.expanded.includes(value)){
                var index = this.expanded.indexOf(value);
                this.expanded.splice(index,1);
                value.is_open = false;
            }
            else{
                this.expanded.push(value)
                value.is_open = true;
            }
        },
        saveRoleChanges: async function(){
            var group_id = this.$route.params.group_id;
            let newPermissions = [];
            
            let lang = this.$cookies.get("language");
            if (lang == null){
                lang = "pl";
            }
            let apiPermissionsLink = fob_config.api_url + "/api/v2/admin/permissions";
            let responsePromise = axios({
                method: "GET",
                url: apiPermissionsLink,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                // handle errors
                // #TODO Handle 403 error (Forbiden)
                return null;
            }
            let permissionsData = response.data;
            let itemsCount = 0;
            for (let i = 0; i < permissionsData.ds.length; i++){
                for(let k = 0; k < permissionsData.ds[i].type.length; k++){
                    if (this.dataTableItems[itemsCount].module == permissionsData.ds[i][lang] && this.dataTableItems[itemsCount].name == permissionsData.ds[i].type[k][lang]){
                        let permissionSuffixes = [];
                        if(this.dataTableItems[itemsCount].add){
                            permissionSuffixes.push("add");
                        }
                        if(this.dataTableItems[itemsCount].read){
                            permissionSuffixes.push("view");
                        }
                        if(this.dataTableItems[itemsCount].edit){
                            permissionSuffixes.push("change");
                        }
                        if(this.dataTableItems[itemsCount].delete){
                            permissionSuffixes.push("delete");
                        }
                        let newId = this.getPermissionsId(permissionSuffixes, permissionsData.ds[i].type[k].permissions)
                        for (let j = 0; j<newId.length; j++){
                            newPermissions.push(newId[j])
                        }
                    }
                    itemsCount++;
                }
            }
            if (group_id == null || group_id == undefined){
                let groupNewData = {
                    permissions: newPermissions,
                    name: this.roleName,
                    perm_type: 1
                }
                let apiGroupsPostLink = fob_config.api_url + "/api/v2/admin/groups/";
                responsePromise = axios({
                    method: "POST",
                    url: apiGroupsPostLink,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    data:groupNewData
                });
            }
            else{
                let groupNewData = {
                    permissions: newPermissions
                }
                console.log(groupNewData)
                let apiGroupsLink = fob_config.api_url + "/api/v2/admin/groups/"+group_id;
                responsePromise = axios({
                    method: "PATCH",
                    url: apiGroupsLink,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    data:groupNewData
                });
            }
            response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR');
                // 
                // #TODO handle errors
                return null;
            }
            this.nameDisabled = true;
            this.snackbar.color = "success";
            this.snackbar.message = this.lviews.changesUpdated;
            this.snackbar.visible = true;

            this.$router.go(-1);
        },
        getPermissionsId: function(permissionSuffixes, typePermissions){
            let newId = [];
            for (let i = 0; i < typePermissions.length; i++) {
                for (let p = 0; p < permissionSuffixes.length; p++) {
                    if ( typePermissions[i].codename.includes(permissionSuffixes[p]) ) {
                        newId.push(typePermissions[i].id)
                    }
                }
            }
            return newId;
        },
        rejectRoleChanges: function(){
            this.$router.go(-1);
        },
        getGroupPermissions: function(group, typePermissions) {
            let groupPermissionsConfig = {
                add: false,
                view: false,
                change: false,
                delete: false
            };

            if (group.permissions == null) {
                return groupPermissionsConfig;
            }

            for (let i = 0; i < typePermissions.length; i++) {
                let hasPermission = group.permissions.includes(typePermissions[i].id);
                if (hasPermission) {
                    let permissionSuffixes = ["add", "view", "change", "delete"];
                    for (let p = 0; p < permissionSuffixes.length; p++) {
                        if ( typePermissions[i].codename.includes(permissionSuffixes[p]) ) {
                            groupPermissionsConfig[ permissionSuffixes[p] ] = true;
                        }
                    }
                }
            }

            return groupPermissionsConfig;
        },
        checkAllInRow: function(item){
            let rowCheck = true;
            if(!item.add_disabled && !item.add){
                rowCheck = false;
            }
            if(!item.read_disabled && !item.read){
                rowCheck = false;
            }
            if(!item.edit_disabled && !item.edit){
                rowCheck = false;
            }
            if(!item.delete_disabled && !item.delete){
                rowCheck = false;
            }
            
            if(!rowCheck){
                if(!item.add_disabled){item.add = true;}
                if(!item.read_disabled){item.read = true;}
                if(!item.edit_disabled){item.edit = true;}
                if(!item.delete_disabled){item.delete = true;}
            }
            else{
                if(!item.add_disabled){item.add = false;}
                if(!item.read_disabled){item.read = false;}
                if(!item.edit_disabled){item.edit = false;}
                if(!item.delete_disabled){item.delete = false;}
            }
        },
        checkAllInModule: function(module){
            let allCheck = true;
            for(let i=0; i<this.dataTableItems.length; i++){
                if(this.dataTableItems[i].module == module){
                    if(!this.dataTableItems[i].add_disabled && !this.dataTableItems[i].add){
                        allCheck = false;
                    }
                    if(!this.dataTableItems[i].read_disabled && !this.dataTableItems[i].read){
                        allCheck = false;
                    }
                    if(!this.dataTableItems[i].edit_disabled && !this.dataTableItems[i].edit){
                        allCheck = false;
                    }
                    if(!this.dataTableItems[i].delete_disabled && !this.dataTableItems[i].delete){
                        allCheck = false;
                    }
                    for(let j=0; j<this.dataTableItems[i].projects.length; j++){
                        if(!this.dataTableItems[i].projects[j].add_disabled && !this.dataTableItems[i].projects[j].add){
                            allCheck = false;
                        }
                        if(!this.dataTableItems[i].projects[j].read_disabled && !this.dataTableItems[i].projects[j].read){
                            allCheck = false;
                        }
                        if(!this.dataTableItems[i].projects[j].edit_disabled && !this.dataTableItems[i].projects[j].edit){
                            allCheck = false;
                        }
                        if(!this.dataTableItems[i].projects[j].delete_disabled && !this.dataTableItems[i].projects[j].delete){
                            allCheck = false;
                        }
                    }
                }
            }
            if(allCheck){
                for(let i=0; i<this.dataTableItems.length; i++){
                    if(this.dataTableItems[i].module == module){
                        if(!this.dataTableItems[i].add_disabled){this.dataTableItems[i].add = false;}
                        if(!this.dataTableItems[i].read_disabled){this.dataTableItems[i].read = false;}
                        if(!this.dataTableItems[i].edit_disabled){this.dataTableItems[i].edit = false;}
                        if(!this.dataTableItems[i].delete_disabled){this.dataTableItems[i].delete = false;}
                        for(let j=0; j<this.dataTableItems[i].projects.length; j++){
                            if(!this.dataTableItems[i].projects[j].add_disabled){this.dataTableItems[i].projects[j].add = false;}
                            if(!this.dataTableItems[i].projects[j].read_disabled){this.dataTableItems[i].projects[j].read = false;}
                            if(!this.dataTableItems[i].projects[j].edit_disabled){this.dataTableItems[i].projects[j].edit = false;}
                            if(!this.dataTableItems[i].projects[j].delete_disabled){this.dataTableItems[i].projects[j].delete = false;}
                        }
                    }
                }
            }
            else{
                for(let i=0; i<this.dataTableItems.length; i++){
                    if(this.dataTableItems[i].module == module){
                        if(!this.dataTableItems[i].add_disabled){this.dataTableItems[i].add = true;}
                        if(!this.dataTableItems[i].read_disabled){this.dataTableItems[i].read = true;}
                        if(!this.dataTableItems[i].edit_disabled){this.dataTableItems[i].edit = true;}
                        if(!this.dataTableItems[i].delete_disabled){this.dataTableItems[i].delete = true;}
                        for(let j=0; j<this.dataTableItems[i].projects.length; j++){
                            if(!this.dataTableItems[i].projects[j].add_disabled){this.dataTableItems[i].projects[j].add = true;}
                            if(!this.dataTableItems[i].projects[j].read_disabled){this.dataTableItems[i].projects[j].read = true;}
                            if(!this.dataTableItems[i].projects[j].edit_disabled){this.dataTableItems[i].projects[j].edit = true;}
                            if(!this.dataTableItems[i].projects[j].delete_disabled){this.dataTableItems[i].projects[j].delete = true;}
                        }
                    }
                }
            }
        },
        fetchPageData: async function(){
            let lang = this.$cookies.get("language");
            if (lang == null){
                lang = "pl";
            }
            let apiPermissionsLink = fob_config.api_url + "/api/v2/admin/permissions";
            let responsePromise = axios({
                method: "GET",
                url: apiPermissionsLink,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                // handle errors
                // #TODO Handle 403 error (Forbiden)
                return null;
            }
            let permissionsData = response.data;
            var add_disable = false;
            var read_disable = false;
            var edit_disable = false;
            var delete_disable = false;
            var group_id = this.$route.params.group_id;
            var id = 0;
            if (group_id == null || group_id == undefined){
                let newItems = [];
                for(var i = 0; i < permissionsData.ds.length; i++){
                    add_disable = false;
                    read_disable = false;
                    edit_disable = false;
                    delete_disable = false;
                    for(var k = 0; k < permissionsData.ds[i].type.length; k++){
                        if(permissionsData.ds[i].type[k].permissions.length != 4){
                            add_disable = true;
                            read_disable = true;
                            edit_disable = true;
                            delete_disable = true;
                            for(var j = 0; j < permissionsData.mdl[i].permissions.length; j++){
                                if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "add"){
                                    add_disable = false;
                                }
                                if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "view"){
                                    read_disable = false;
                                }
                                if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "change"){
                                    edit_disable = false;
                                }
                                if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "delete"){
                                    delete_disable = false;
                                }
                            }
                        }
                        newItems.push({
                            id:id,
                            module:permissionsData.ds[i][lang],
                            name:permissionsData.ds[i].type[k][lang],
                            add:false,
                            read:false,
                            edit:false,
                            delete:false, 
                            add_disabled:add_disable, 
                            read_disabled:read_disable, 
                            edit_disabled:edit_disable, 
                            delete_disabled:delete_disable, 
                            check_all: false,
                        })
                        id = id+1;
                    }
                }
                this.dataTableItems = newItems;
                this.roleName = "";
            }
            else{
                let apiGroupLink = fob_config.api_url + "/api/v2/admin/groups/" + group_id;
                let responsePromise = axios({
                    method: "GET",
                    url: apiGroupLink,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    }
                });
                let response = null;
                try {
                    response = await responsePromise;
                } catch (error) {
                    this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                    // handle errors
                    // #TODO Handle 403 error (Forbiden)
                    return null;
                }

                let groupData = response.data;
                if (groupData.type == 0){
                    this.$emit('set-state', 'ERROR', this.lviews.noSuchRecord);
                }
                else{
                    let newItems = [];
                    for(var i = 0; i < permissionsData.ds.length; i++){
                        for(var k = 0; k < permissionsData.ds[i].type.length; k++){
                            add_disable = false;
                            read_disable = false;
                            edit_disable = false;
                            delete_disable = false;
                            if(permissionsData.ds[i].type[k].permissions.length != 4){
                                add_disable = true;
                                read_disable = true;
                                edit_disable = true;
                                delete_disable = true;
                                for(var j = 0; j < permissionsData.ds[i].type[k].permissions.length; j++){
                                    if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "add"){
                                        add_disable = false;
                                    }
                                    if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "view"){
                                        read_disable = false;
                                    }
                                    if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "change"){
                                        edit_disable = false;
                                    }
                                    if(permissionsData.ds[i].type[k].permissions[j].name.split("_").pop() == "delete"){
                                        delete_disable = false;
                                    }
                                }
                            }

                            let structuredPermissions = this.getGroupPermissions(groupData, permissionsData.ds[i].type[k].permissions);
                            newItems.push({
                                id:id,
                                module: permissionsData.ds[i][lang],
                                name: permissionsData.ds[i].type[k][lang],
                                add: structuredPermissions.add,
                                read: structuredPermissions.view,
                                edit: structuredPermissions.change,
                                delete: structuredPermissions.delete, 
                                add_disabled: add_disable, 
                                read_disabled: read_disable, 
                                edit_disabled: edit_disable, 
                                delete_disabled: delete_disable,
                                check_all: false,
                            });
                            id = id + 1;
                        }
                    }
                    this.dataTableItems = newItems;
                    this.roleName = groupData.name;
                    this.nameDisabled = true;
                }
            }

        }
    },
    mounted() {
        this.$emit('set-title', this.lviews.dsConfigTitle);
        this.$emit('set-display', 'BACK_WINDOW');
        let userPermissions = localStorage.getItem("user_permissions");
        if (!userPermissions.includes("fob.admin_view")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return;
        }
        // this.fetchPageData();
    },
    computed: {
        lviews: {
            get: function() {
                return this.$t('views.dsConfig');
            }
        }
    },
        beforeMount() {
        this.screenWidth = window.innerWidth;

        this.dataTableHeaders[0].text = this.lviews.data;
        this.dataTableHeaders[1].text = this.lviews.add;
        this.dataTableHeaders[2].text = this.lviews.read;
        this.dataTableHeaders[3].text = this.lviews.edit;
        this.dataTableHeaders[4].text = this.lviews.delete;
        this.dataTableHeaders[5].text = this.lviews.checkAll;

        this.dataTableProjectHeaders[0].text = this.lviews.project;
        this.dataTableProjectHeaders[1].text = this.lviews.add;
        this.dataTableProjectHeaders[2].text = this.lviews.read;
        this.dataTableProjectHeaders[3].text = this.lviews.edit;
        this.dataTableProjectHeaders[4].text = this.lviews.delete;
        this.dataTableProjectHeaders[5].text = this.lviews.checkAll;

    },
}
</script>
<style>
#dataTable table thead th {
  height: 32px;
  margin:0px;
  padding:0px;
}
#dataTable table thead tr {
  background: #53d16b;
}
#dataTable table thead th {
  font-weight: bold;
}
#dataTable table tbody tr td{
    width: 12%;
}
@media only screen and (max-width: 605px){
        #dataTable table tbody tr td {
            width: 100%;
        }
    }
#dataTable table tbody tr td:nth-child(1) {
  background: white;
  justify-content:start;
  width: 40%;
  border-right: 1px solid rgb(84, 84, 84);
}
@media only screen and (max-width: 605px){
        #dataTable table tbody tr td:nth-child(1) {
            width: 100%;
        }
    }
@media only screen and (min-width: 1px){
        #dataTableProjects table thead {
            display: none;
        }
    }
@media only screen and (max-width: 605px){
        #dataTableProjects table tbody {
            width: 100%;
            max-width:600px;
        }
    }
#dataTableProjects table tbody tr td{
    width: 12%;
}
@media only screen and (max-width: 605px){
        #dataTableProjects table tbody tr td {
            width: 100%;
            max-width:600px;

        }
    }
#dataTable table tbody tr td:nth-child(1) {
  background: white;
  justify-content:start;
  width: 40%;
  border-right: 1px solid rgb(84, 84, 84);
}
@media only screen and (max-width: 605px){
        #dataTable table tbody tr td:nth-child(1) {
            width: 100%;
        }
    }
#dataTable table tbody tr td {
  border-bottom: 1px solid rgb(146, 141, 141);
}
</style>
