<!--
SharedMasters 2021 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2021, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.4.0"
__author__ = "Adrian Bączek, .."
__maintainer__ = "Adrian Bączek"
__email__ = "adrian@sharedmasters.com"
__status__ = "DEV"

USAGE
-----
<user-dialog
    ref="yourRef"
    :userId="userIdProp"
    :userData="userDataProp"
    :roles="rolesListProp"
    :dataAccess="dataAccessProp"
    @refresh-data="someEventHandler"
></user-dialog>

// update or assign props
$nextTick(() => { $refs.userDialog.openDialog(); });

props:
    - userId - {Number} - it can be 
        1) any number that is application user id
        2) null if we want to create new one

    - userData - {Object} - object with all user data. It contains all informations specified by UserProfile model + additional values presented below
        {
            name: {String},                     specific user's fullname
            birthDate: {String},                specific user's birth date
            role: {String},                     merged into one string processed list of user's roles
            rolesIds [{Number}, ...],           list of user roles identifiers
            dataAccess: 'Wszystkie rekordy',
            status: {Boolean},                  information whether user is active or not (boolean)
            statusText: {String}                string value for user active status
            lastLogin: {String},                user last login            
        }

    - roles - {Array} - List of possible roles in FOB. One item's structure is presented below
        [
            {
                id: {Number},       role identifier
                name: {String}      role name
            }
        ]
        
    - dataAccess - TODO

events:
    - refresh-data - sends to parent component info to reload its data
-->

<template>
    <v-dialog
        v-model="userDialog.visible"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <!-- user dialog content -->
        <v-card>
            <v-toolbar
                dark
                color="primary"
            >
                <v-toolbar-title> {{ userDialog.title }} </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn
                    icon
                    dark
                    @click="closeDialog"
                >
                    <v-icon> mdi-close </v-icon>
                </v-btn>
            </v-toolbar>

            <!-- dialog content -->
            <v-card-text style="padding-top: 20px; padding-bottom: 0px;" v-if="userDialog.state != dialogStates.ERROR">
                <!-- worker section -->
                <v-card-text
                    style='font-weight: bold; background-color: #bdbdbd; color: black;'
                    text
                >
                    {{ lviews.worker }}
                </v-card-text>

                <v-card-text style="padding-left: 15px; padding-right: 15px;" >
                    <div v-if="userDialog.workerState == assignedWorkerStates.NOT_ASSIGNED">
                        <v-radio-group 
                            v-model="workerOptionChosen"
                            :error="userDialog.errors.workerOption.isError"
                            :error-messages="userDialog.errors.workerOption.errorMessage"

                            @change="userDialog.errors.workerOption.isError = false; userDialog.errors.workerOption.errorMessage = null"
                        >
                            <v-radio
                                v-for="workerOption in workerOptions"
                                :key="workerOption.id"
                                :label="workerOption.label"
                                :value="workerOption.id"
                            ></v-radio>
                        </v-radio-group>

                        <fob-worker-autocompleter
                            v-model="userDialog.user.workerId"
                            v-if="workerOptionChosen == 3"
                            style="padding-top: 0px; padding-bottom: 0px; max-width: 400px;"
                            :label="lviews.pickWorker"
                            :propApiLink="workerAutocompleteLink"
                            :error="userDialog.errors.workerId.isError"
                            :errorMessages="userDialog.errors.workerId.errorMessage"
                            @input="
                                userDialog.errors.workerId.isError = false;
                                userDialog.errors.workerId.errorMessages = null;
                                userDialog.errors.workerOption.isError = false;
                                userDialog.errors.workerOption.errorMessages = null;
                            "
                        ></fob-worker-autocompleter>

                        <div v-if="workerOptionChosen == 3 && userDialog.user.workerId == null" style="margin-bottom:10px; color:orange">
                            <v-icon small color="orange">mdi-alert-circle-outline</v-icon> {{ lviews.assignWorkerMessage }}
                        </div>

                        <div>
                            <v-icon small>mdi-information-outline</v-icon> {{ lviews.assigningWorkerEnabledPermissionsMessage }}
                        </div>
                    </div>

                    <div v-if="userDialog.workerState == assignedWorkerStates.ASSIGNED">
                        <v-flex xs12 v-if="userDialog.user.workerId != null">
                            <v-btn :disabled="!userDeletable" @click="openYesNoDialog"> {{ lviews.delete }} </v-btn>
                            <div v-if="!userDeletable" style="margin-top: 10px;"> {{ lviews.otherUserFunctionalitiesMessage }} </div>
                        </v-flex>
                    </div>
                </v-card-text>
                <v-divider></v-divider>

                <!-- basic data -->
                <v-card-text
                    style='font-weight: bold; background-color: #bdbdbd; color: black;'
                    text
                >
                    {{ lviews.headers.basicData }}
                </v-card-text>

                <v-card-text style="padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
                    <v-row>
                        <!-- last name edition -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.lastName }} </div>
                            <v-text-field
                                class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                v-model="userDialog.user.lastName"
                                :error="userDialog.errors.lastName.isError"
                                :error-messages="userDialog.errors.lastName.errorMessage"
                                :rules="validationRules.lastNameRules"
                                outlined
                                dense
                                height="10px"
                                required
                                maxlength="30"
                                counter
                                @input="() => { userDialog.errors.lastName.valueEntered = true; setFieldError('lastName', false, ''); }"
                            ></v-text-field>
                        </v-flex>

                        <!-- first name edition -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.firstName }} </div>
                            <v-text-field
                                class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                v-model="userDialog.user.firstName"
                                :error="userDialog.errors.firstName.isError"
                                :error-messages="userDialog.errors.firstName.errorMessage"
                                :rules="validationRules.firstNameRules"
                                outlined
                                dense
                                height="10px"
                                required
                                maxlength="30"
                                counter
                                @input="() => { setFieldError('firstName', false, ''); }"
                            ></v-text-field>
                        </v-flex>
                    </v-row>

                    <v-row>
                        <!-- email edition -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.email }} </div>
                            <v-text-field
                                class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                v-model="userDialog.user.email"
                                :error="userDialog.errors.email.isError"
                                :error-messages="userDialog.errors.email.errorMessage"
                                :rules="validationRules.emailRules"
                                outlined
                                dense
                                height="10px"
                                required
                                maxlength="254"
                                counter
                                @input="() => { setFieldError('email', false, ''); }"
                            ></v-text-field>
                        </v-flex>

                        <!-- phone number edition -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.phoneNumber }} </div>

                            <v-text-field
                                class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                v-model="userDialog.user.phoneNumber"
                                :error="userDialog.errors.phoneNumber.isError"
                                :error-messages="userDialog.errors.phoneNumber.errorMessage"
                                :rules="validationRules.phoneNumberRules"
                                outlined
                                dense
                                height="10px"
                                maxlength="128"
                                counter
                                @input="() => { setFieldError('phoneNumber', false, ''); }"
                            ></v-text-field>
                        </v-flex>
                    </v-row>
                    <v-row>
                        <!-- birth date edition -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.birthDate }} </div>
                            <v-dialog
                                v-model="userDialog.birthDateOpened"
                                width="290px"
                                persistent
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                        v-model="userDialog.user.birthDate"
                                        append-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        required
                                        height="10px"
                                        v-bind="attrs"
                                        v-on="on"
                                        :error="userDialog.errors.birthDate.isError"
                                        :error-messages="userDialog.errors.birthDate.errorMessage"
                                        @change="() => { setFieldError('birthDate', false, ''); }"
                                    ></v-text-field>
                                </template>

                                <v-card>
                                    <v-date-picker
                                        v-model="userDialog.user.birthDate"
                                        @input="userDialog.birthDateOpened = false;"
                                    ></v-date-picker>
                                </v-card>
                            </v-dialog>

                        </v-flex>

                        <!-- pesel edition -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.pesel }} </div>
                            <v-text-field
                                type="tel"
                                class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                v-model="userDialog.user.pesel"
                                outlined
                                dense
                                counter
                                height="10px"
                                :error="userDialog.errors.pesel.isError"
                                :error-messages="userDialog.errors.pesel.errorMessage"
                                @input="() => { setFieldError('pesel', false, ''); }"
                                @keypress="isDigit($event)"
                                maxlength="11"
                            ></v-text-field>
                        </v-flex>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>

                <!-- permissions -->
                <v-card-text
                    color="black"
                    style='font-weight: bold; background-color: #bdbdbd; color: black;'
                    text
                >
                    {{ lviews.headers.security }}
                </v-card-text>

                <v-card-text style="padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
                    <v-row>
                        <!-- role -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.role }} </div>
                            <v-select
                                v-model="userDialog.user.role"
                                :items="roles"
                                :rules="validationRules.roleRules"
                                required
                                outlined
                                dense
                                height="10px"
                                multiple
                                :error="userDialog.errors.role.isError"
                                :error-messages="userDialog.errors.role.errorMessage"
                                @input="() => { setFieldError('role', false, ''); }"
                                class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                item-value="id"
                                item-text="name"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="userDialog.user.role.length === 1" style = "margin:0;">
                                        <span class = "role-name-cut-when-too-long">{{ item.name }}</span>
                                    </v-chip>

                                    <v-chip v-else-if="userDialog.user.role.length > 1 && index===0" style = "margin:0;">
                                        <span class = "role-name-cut-when-too-long">{{ item.name }} (...)</span>
                                    </v-chip> 
                                </template>
                            </v-select>
                        </v-flex>

                        <!-- data access -->
                        <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.dataAccess }} </div>
                            <v-select
                                v-model="userDialog.user.dataAccess"
                                :items="dataAccess"
                                :rules="validationRules.accessRules"
                                required
                                outlined
                                dense
                                height="10px"
                                multiple
                                class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                                :error="userDialog.errors.dataAccess.isError"
                                :error-messages="userDialog.errors.dataAccess.errorMessage"
                                @input="() => { setFieldError('dataAccess', false, ''); }"
                                item-value="id"
                                item-text="name"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="userDialog.user.dataAccess.length === 1" style = "margin:0;">
                                        <span class = "role-name-cut-when-too-long">{{ item.name }}</span>
                                    </v-chip>

                                    <v-chip v-else-if="userDialog.user.dataAccess.length > 1 && index===0" style = "margin:0;">
                                        <span class = "role-name-cut-when-too-long">{{ item.name }} (...)</span>
                                    </v-chip> 
                                </template>
                            </v-select>
                        </v-flex>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>

                <!-- login -->
                <v-card-text
                    color="black"
                    style='font-weight: bold; background-color: #bdbdbd; color: black;'
                    text
                >
                    {{ lviews.headers.login }}
                </v-card-text>

                <v-card-text style="padding-left: 15px; padding-right: 15px;">
                    <v-row>
                        <v-flex class = "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lviews.login }} </div>

                            <v-text-field
                                v-model="userDialog.user.login"
                                required
                                outlined
                                dense
                                height="10px"
                                :error="userDialog.errors.login.isError"
                                :error-messages="userDialog.errors.login.errorMessage"
                                :rules="validationRules.loginRules"
                                @input="() => { setFieldError('login', false, ''); }"
                                class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                            ></v-text-field>

                            <v-checkbox
                                style="margin-top: 0px;"
                                v-if="userId == null"
                                v-model="userDialog.user.sendActivationMail"
                                :label="lviews.sendActivationMail"
                            ></v-checkbox>
                        </v-flex>
                    </v-row>
                </v-card-text>

                <!-- Global errors -->
                <div class="global-errors" v-if="userDialog.globalErrors != null && userDialog.globalErrors.length != 0">
                    <span v-for="(gerr, gerrKey) in userDialog.globalErrors" :key="gerrKey">
                        {{ gerr }}<br />
                    </span>
                </div>

                <v-divider></v-divider>
            </v-card-text>

            <!-- error displaying -->
            <v-card-text v-else>
                <v-layout style="padding-top: 80px; padding-bottom: 10px;" class="content-middler" column justify-center align-center>
                    <v-icon size="100"> sentiment_dissatisfied </v-icon>
                    <div class="title" style="width:90%; text-align: center; margin-top:10px;"> 
                        {{ globalErrorMessage }}
                    </div>
                </v-layout>
            </v-card-text>

            <!-- dialog bottom buttons -->
            <v-card-actions class="justify-end" style="padding: 10px 24px 35px 24px;" v-if="userDialog.state != dialogStates.ERROR">
                <v-btn @click="userDialog.cancelClick"> {{ lviews.cancel }} </v-btn>
                <v-btn color="primary" @click="userDialog.confirmClick"> {{ lviews.save }} </v-btn>
            </v-card-actions>

            <!-- error understood button -->
            <v-card-actions class="justify-center" style="padding: 10px 24px 35px 24px;" v-else>
                <v-btn color="primary" @click="userDialog.cancelClick"> OK </v-btn>
            </v-card-actions>
        </v-card>


        <!-- remove yes/no dialog -->
        <v-dialog 
            persistent 
            v-model="yesNoDialogVisible"
            max-width="295"
        >
            <v-card>
                <v-card-title class="headline">{{ lviews.yesNoDialogTitle }}</v-card-title>

                <v-card-text style="text-align:justify">{{ removeUserWorkerContent }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="grey"
                        text
                        @click="() => { yesNoDialogVisible = false; }">
                        {{ lviews.cancel }}
                    </v-btn>

                    <v-btn color="red darken-1"
                        text
                        @click="removeUser">
                        {{ lviews.delete }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import fobConfig from '../../fob_config';
import PhoneNumber from 'awesome-phonenumber';

export default {
    data: function() {
        return {
            userDialog: {
                visible: false,
                user: {
                    id: null,
                    workerId: null,

                    // basic data
                    lastName: "",
                    firstName: "",
                    email: null,
                    phoneNumber: null,

                    // personal data
                    birthDate: "",
                    pesel: null,

                    // security
                    role: [],
                    dataAccess: [],

                    // login
                    login: "",
                    sendActivationMail: false,
                },

                birthDateOpened: false,

                globalErrors: null,
                errors: {
                    workerId: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false
                    },
                    workerOption: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false
                    },
                    lastName: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    firstName: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    email: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    phoneNumber: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    birthDate: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    pesel: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    role: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    dataAccess: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                    login: {
                        isError: false,
                        errorMessage: "",
                        valueEntered: false,
                    },
                },

                validators: {
                    lastName: this.validateLastName,
                    firstName: this.validateFirstName,
                    email: this.validateEmail,
                    phoneNumber: this.validatePhoneNumber,
                    birthDate: this.validateBirthDate,
                    role: this.validateRole,
                    dataAccess: this.validateDataAccess,
                    login: this.validateLogin,
                    workerId: this.validateWorkerId
                },

                title: "",
                cancelClick: () => {},
                confirmClick: () => {},
                state: null,
                workerState: null
            },

            userSourceObj: {
                workerId: null,
                id: null,

                // basic data
                lastName: "",
                firstName: "",
                email: "",
                phoneNumber: "",

                // personal data
                birthDate: "",
                pesel: null,

                // security
                role: "",
                dataAccess: "",

                // login
                username: "",
            },

            dialogStates: {
                NEW: 0,
                EXISTING: 1,
                ERROR: 2,
            },
            assignedWorkerStates: {
                NOT_ASSIGNED: 0,
                ASSIGNED: 1
            },

            validationRules: {
                lastNameRules: [ v => this.validateLastName(v) || this.userDialog.errors.lastName.errorMessage ],
                firstNameRules: [ v => this.validateFirstName(v) || this.userDialog.errors.firstName.errorMessage ],
                emailRules: [ v => this.validateEmail(v) || this.userDialog.errors.email.errorMessage ],
                roleRules: [ v => this.validateRole(v) || this.userDialog.errors.role.errorMessage ],
                accessRules: [ v => this.validateDataAccess(v) || this.userDialog.errors.dataAccess.errorMessage ],
                loginRules: [ v => this.validateLogin(v) || this.userDialog.errors.login.errorMessage ],
                phoneNumberRules: [ v => this.validatePhoneNumber(v) || this.userDialog.errors.phoneNumber.errorMessage ],
                birthDateRules: [ v => this.validateBirthDate(v) || this.userDialog.errors.birthDate.errorMessage ],
            },

            // worker radio button option
            workerOptionChosen: null,
            workerOptions: [
                {
                    id: 1,
                    label: "" // no worker
                },
                {
                    id: 2,
                    label: "" // new worker
                },
                {
                    id: 3,
                    label: "" // existing worker
                }
            ],
            existingWorkerId: null,
            globalErrorMessage: "",

            userDeletable: false,
            displayRadioButtons: false,
            removeUserWorkerContent: "",
            workerAutocompleteLink: "",
            yesNoDialogVisible: false,
        }
    },

    methods: {
        /**
         * Turns error value on/off according to passed field value (one of userDialog keys).
         * @param field {String} Name of userDialog key for which error is turned on/off.
         * @param value {Boolean} Value for 'isError' attribute for specified field.
         * @param message {String} Error message (may be empty for turning error off).
         */
        setFieldError: function(field, value, message) {
            this.userDialog.errors[field].isError = value;
            this.userDialog.errors[field].errorMessage = message;
        },

        // FIELD VALIDATORS
        /**
         * Validates last name field value and updates last name error field data.
         * @param lastNameValue {String} Last name value to validate.
         * @returns {Boolean} Boolean value whether last name value is valid or not.
         */
        validateLastName: function(lastNameValue) {
            if (this.userDialog.errors.lastName.valueEntered) {
                if (lastNameValue == "" || lastNameValue == null || lastNameValue == undefined) {
                    this.setFieldError("lastName", true, this.lviews.validators.lastName.lastNameRequired);
                    return false;
                }

                if (lastNameValue.length > 50) {
                    this.setFieldError("lastName", true, this.lviews.validators.lastName.lastNameTooLong);
                    return false;
                }

                let result = /^[A-Z][a-z]+$/.test(lastNameValue);
                if (!result) {
                    this.setFieldError("lastName", true, this.lviews.validators.lastName.invalidLastNameStructure);
                    return false;
                }

                this.setFieldError("lastName", false, "");
                return true;
            }
            return true;
        },

        /**
         * Validates first name field value and updates first name error field data.
         * @param firstNameValue {String} First name value to validate.
         * @returns {Boolean} Boolean value whether first name value is valid or not.
         */
        validateFirstName: function(firstNameValue) {
            if (this.userDialog.errors.firstName.valueEntered) {
                if (firstNameValue == "" || firstNameValue == null || firstNameValue == undefined) {
                    this.setFieldError("firstName", true, this.lviews.validators.firstName.firstNameRequired);
                    return false;
                }
                
                if (firstNameValue.length > 30) {
                    this.setFieldError("firstName", true, this.lviews.validators.firstNameTooLong);
                    return false;
                }
                
                let result = /^[A-Z][a-z]+$/.test(firstNameValue);
                if (!result) {
                    this.setFieldError("firstName", true, this.lviews.validators.firstName.invalidFirstNameStructure);
                    return false;
                }
                
                this.setFieldError("firstName", false, "");
                return true;
            }
            return true;
        },

        /**
         * Validates email field value and updates email error field data.
         * @param emailValue {String} Email value to validate.
         * @returns {Boolean} Boolean value whether email value is valid or not.
         */
        validateEmail: function(emailValue) {
            if (this.userDialog.errors.firstName.valueEntered) {
                if (emailValue == "" || emailValue == null || emailValue == undefined) {
                    this.setFieldError("email", true, this.lviews.validators.email.emailRequired);
                    return false;
                }

                let result = /.+@.+\..+/.test(emailValue);
                if (!result) {
                    this.setFieldError("email", true, this.lviews.validators.email.invalidEmailStructure);
                    return false;
                }

                this.setFieldError("email", false, "");
                return true;
            }
            return true;
        },

        /**
         * Validates role field value and updates role error field data.
         * @param roleArray {[Number]} Array of role identifiers to validate.
         * @returns {Boolean} Boolean value whether role field is filled correctly or not.
         */
        validateRole: function(roleArray) {
            if (this.userDialog.errors.role.valueEntered) {
                if (roleArray == null || roleArray == undefined || roleArray == "" || !roleArray) {
                    this.setFieldError("role", true, this.lviews.validators.role.roleRequired);
                    return false;
                }

                this.setFieldError("role", false, "");
                return true;
            }
            return true;
        },

        /**
         * Validates data access field value and updates data access error field data.
         * @param dataAccessArray {[Number]} Array of data access permissions identifiers to validate.
         * @returns {Boolean} Boolean value whether data access field is filled correctly or not.
         */
        validateDataAccess: function(dataAccessArray) {
            if (this.userDialog.errors.dataAccess.valueEntered) {
                if (dataAccessArray == null || dataAccessArray == undefined || dataAccessArray == "" || !dataAccessArray) {
                    this.setFieldError("dataAccess", true, this.lviews.validators.dataAccess.dataAccessRequired);
                    return false;
                }

                this.setFieldError("dataAccess", false, "");
                return true;
            }
            return true;
        },

        /**
         * Validates login field value and updates login error field data.
         * @param loginValue {String} User's login to validate.
         * @returns {Boolean} Boolean value whether login field is valid or not.
         */
        validateLogin: function(loginValue) {
            if (this.userDialog.errors.login.valueEntered) {
                if (loginValue == null || loginValue == "" || loginValue == undefined || !loginValue) {
                    this.setFieldError("login", true, this.lviews.validators.login.loginFieldValueRequired);
                    return false;
                }

                this.setFieldError("login", false, "");
                return true;
            }
            return true;
        },

        /**
         * Validates phone number field value (PL, GB, US, DE, UA) and updates phone number error field data.
         * @param phoneNumberValue {String} User's phone number to validate.
         * @returns {Boolean} Boolean value whether phone number is valid or not.
         */
        validatePhoneNumber: function(phoneNumberValue) {
            if (this.userDialog.errors.phoneNumber.valueEntered) {
                if (phoneNumberValue != null) {
                    let validators = [
                        new PhoneNumber(phoneNumberValue, "PL"),
                        new PhoneNumber(phoneNumberValue, "GB"),
                        new PhoneNumber(phoneNumberValue, "DE"),
                        new PhoneNumber(phoneNumberValue, "UA"),
                        new PhoneNumber(phoneNumberValue, "US")
                    ];

                    let anyValidatorValid = false, value;
                    validators.forEach(validator => {
                        value = validator.isValid();
                        if (value) {
                            anyValidatorValid = true;
                        }
                    });

                    if (!anyValidatorValid) {
                        this.setFieldError("phoneNumber", true, this.lviews.validators.phoneNumber.invalidPhoneNumberStructure);
                        return false;
                    }

                    this.setFieldError("phoneNumber", false, "");
                    return true;
                }
                this.setFieldError("phoneNumber", false, "");
                return true;
            }
            return true;
        },

        /**
         * Validates birth date field value and updates birth date error field data.
         * @param birthDateValue {String} User's birth date to validate.
         * @returns {Boolean} Boolean value whether birth date is valid or not (whether field is empty or not).
         */
        validateBirthDate: function(birthDateValue) {
            if (this.userDialog.errors.birthDate.valueEntered) {
                if (birthDateValue == null || birthDateValue == undefined || birthDateValue == "" || !birthDateValue) {
                    this.setFieldError("birthDate", true, this.lviews.validators.birthDate.birthDateRequired);
                    return false;
                } 

                this.setFieldError("birthDate", false, "");
                return true;
            }
            return true;
        },
        validateWorkerId: function(workerId) {
            if (workerId == null || typeof(workerId) === 'undefined' || workerId == ''){
                if(this.workerOptionChosen == 3){
                    this.setFieldError("workerId", true, this.lviews.requiredField);

                    return false;
                }
            }
            this.setFieldError("workerId", false, "");
            return true;
        },

        // OTHER METHODS

        /**
         * Adapts user details response data to userDialog.user structure
         */
        assignEditUserDataUsingProps: function() {
            // assign modifying object data
            console.log(this.userData);
            this.userDialog.user.workerId = this.userData.worker;
            this.userDialog.user.id = this.userData.application_user.id;
            this.userDialog.user.lastName = this.userData.last_name;
            this.userDialog.user.firstName = this.userData.first_name;
            this.userDialog.user.email = this.userData.personal_email;
            this.userDialog.user.phoneNumber = this.userData.personal_mobile_number;
            this.userDialog.user.birthDate = this.userData.birth_date;
            this.userDialog.user.pesel = this.userData.pesel;
            this.userDialog.user.role = this.userData.rolesIds;
            this.userDialog.user.dataAccess = this.userData.dataAccessIds;
            this.userDialog.user.login = this.userData.application_user.username;

            // assign source object data -> to verify whether changes were provided or not
            this.userSourceObj.workerId = this.userData.worker;
            this.userSourceObj.id = this.userData.application_user.id;
            this.userSourceObj.lastName = this.userData.last_name;
            this.userSourceObj.firstName = this.userData.first_name;
            this.userSourceObj.email = this.userData.personal_email;
            this.userSourceObj.phoneNumber = this.userData.personal_mobile_number;
            this.userSourceObj.birthDate = this.userData.birth_date;
            this.userSourceObj.pesel = this.userData.pesel;
            this.userSourceObj.role = this.userData.rolesIds;
            this.userSourceObj.dataAccess = this.userData.dataAccessIds;
            this.userSourceObj.login = this.userData.application_user.username;

            // assigning whether user can be deleted or not
            this.userDeletable = this.userData.deletable;
        },

        /**
         * Resets user dialog
         */
        resetUserDialog: function () {
            this.userDialog.user = {
                id: null,
                workerId: null,

                // basic data
                lastName: "",
                firstName: "",
                email: null,
                phoneNumber: null,

                // personal data
                birthDate: "",
                pesel: null,

                // security
                role: [],
                dataAccess: [],

                // login
                login: "",
                sendActivationMail: false,
            };

            this.userSourceObj = {
                id: null,
                workerId: null,

                // basic data
                lastName: "",
                firstName: "",
                email: null,
                phoneNumber: null,

                // personal data
                birthDate: "",
                pesel: null,

                // security
                role: [],
                dataAccess: [],

                // login
                username: "",
                sendActivationMail: false,
            };

            this.userDialog.globalErrors = null;
            let errorKeys = Object.keys(this.userDialog.errors);
            errorKeys.forEach(key => {
                this.setFieldError(key, false, "");
                this.userDialog.errors[key].valueEntered = false;
            });

        },

        /**
         * Opens create new / edit user dialog
         */
        openDialog: async function() {
            if (this.userId == null) {
                this.displayRadioButtons = true;
                this.workerOptionChosen = 1;
                this.resetUserDialog();
                this.userDialog.title = this.lviews.addDialogTitle;
                this.userDialog.state = this.dialogStates.NEW;
                this.userDialog.workerState = this.assignedWorkerStates.NOT_ASSIGNED;
            }
            else {
                this.resetUserDialog();
                this.userDialog.title = this.lviews.editDialogTitle;
                this.userDialog.state = this.dialogStates.EXISTING;
                this.assignEditUserDataUsingProps();
                console.log(this.userDialog.user.workerId);
                if (this.userDialog.user.workerId == null) {
                    this.workerOptionChosen = 1;
                    this.displayRadioButtons = true;
                    this.userDialog.workerState = this.assignedWorkerStates.NOT_ASSIGNED;
                }
                else {
                    this.displayRadioButtons = false;
                    this.userDialog.workerState = this.assignedWorkerStates.ASSIGNED;
                }
            }
            this.userDialog.visible = true;
        },

        /**
         * Closes edit user dialog
         */
        closeDialog: function() {
            this.userDialog.visible = false;
        },

        /**
         * Creates object with user edit request data
         */
        getEditUserRequestData: function() {
            let updateRequestData = {
                user_profile: {},
                auth_user: {
                    permissions: [],
                }
            };

            // user_profile
            if (this.userSourceObj.lastName != this.userDialog.user.lastName) {
                updateRequestData.user_profile["last_name"] = this.userDialog.user.lastName;
            }
            if (this.userSourceObj.firstName != this.userDialog.user.firstName) {
                updateRequestData.user_profile["first_name"] = this.userDialog.user.firstName;
            }
            if (this.userSourceObj.email != this.userDialog.user.email) {
                updateRequestData.user_profile["personal_email"] = this.userDialog.user.email;
            }
            if (this.userSourceObj.phoneNumber != this.userDialog.user.phoneNumber) {
                updateRequestData.user_profile["personal_mobile_number"] = this.userDialog.user.phoneNumber;
            }
            if (this.userSourceObj.pesel != this.userDialog.user.pesel) {
                if (this.userDialog.user.pesel == "" || this.userDialog.user.pesel == null) {
                    updateRequestData.user_profile["pesel"] = null;
                }
                else {
                    updateRequestData.user_profile["pesel"] = this.userDialog.user.pesel;
                }
            }
            if (this.userSourceObj.birthDate != this.userDialog.user.birthDate) {
                updateRequestData.user_profile["birth_date"] = this.userDialog.user.birthDate;
            }

            // auth_user
            updateRequestData.auth_user["group"] = [...this.userDialog.user.role, ...this.userDialog.user.dataAccess];
            if (this.userSourceObj.login != this.userDialog.user.login) {
                updateRequestData.auth_user["username"] = this.userDialog.user.login;
            }
            
            // currently worker was unassigned, so check if it should be assigned after this request
            if (this.userDialog.user.workerId == null) {
                updateRequestData['worker_option'] = this.workerOptionChosen;
                if (this.workerOptionChosen == 3) {
                    updateRequestData['worker'] = this.existingWorkerId;
                }
            }
            return updateRequestData;
        },

        /**
         * Saves user changes / Creates new user in database
         */
        saveChanges: async function() {
            let anyErrorOccured = false;
            let keys = Object.keys(this.userDialog.validators);
            for (let i = 0; i < keys.length; i++) {
                let validator = this.userDialog.validators[ keys[i] ];
                this.userDialog.errors[ keys[i] ].valueEntered = true;
                let result = validator(this.userDialog.user[ keys[i] ]);
                if (!result) {
                    anyErrorOccured = true;
                }
            }

            if (anyErrorOccured) {
                this.userDialog.globalErrors = [ this.lviews.errorsInForm ];
                return;
            }

            let response = null;
            if (this.userId == null) {
                // create new user
                let createRequestData = {
                    user_profile: {
                        first_name: this.userDialog.user.firstName,
                        last_name: this.userDialog.user.lastName,
                        personal_email: this.userDialog.user.email,
                        personal_mobile_number: this.userDialog.user.phoneNumber,
                        pesel: (this.userDialog.user.pesel == "" || this.userDialog.user.pesel == null) ? null : this.userDialog.user.pesel,
                        birth_date: this.userDialog.user.birthDate
                    },
                    auth_user: {
                        username: this.userDialog.user.login,
                        permissions: [],
                        send_email: this.userDialog.user.sendActivationMail ? 1 : 0,
                        group: [...this.userDialog.user.role, ...this.userDialog.user.dataAccess]
                    },
                    worker_option: this.workerOptionChosen,
                };

                if (this.workerOptionChosen == 3) {
                    createRequestData['worker'] = this.existingWorkerId;
                }

                this.$emit("show-overlayer", this.lviews.sendingUserCreateRequest);
                try {
                    response = await axios({
                        url: fobConfig.api_url + "/api/v2/admin/user/",
                        method: "POST",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('jwt'),
                            'Content-Type': "application/json"
                        },
                        data: createRequestData
                    });
                } catch (error) {
                    this.$emit("hide-overlayer");
                    response = null;
                    this.handleError(error);
                }
            }
            else {
                // edit existing user
                let updateRequestData = this.getEditUserRequestData();
                this.$emit("show-overlayer", this.lviews.sendingUserUpdateRequest);
                try {
                    response = await axios({
                        url: fobConfig.api_url + "/api/v2/admin/user/" + this.userId,
                        method: "PATCH",
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem('jwt'),
                            'Content-Type': "application/json"
                        },
                        data: updateRequestData
                    });
                } catch (error) {
                    this.$emit("hide-overlayer");
                    response = null;
                    this.handleError(error);
                }
            }
            this.$emit("hide-overlayer");
            if (response != null) {
                this.closeDialog();
                this.$emit('refresh-data');
            }
        },

        /**
         * Handles received error
         * @param error {Object} Caught error to be handled.
         */
        handleError: function(error) {
            console.log(error.response);
            switch(error.response.status) {
                case 400:
                    this.userDialog.globalErrors = [];
                    var any = false;
                    if (error.response.data.worker) {


                        this.globalErrorMessage = this.lviews.errors.error400.errors.worker[ error.response.data.worker[0].code ];
                        any = true;
                        this.userDialog.state = this.dialogStates.ERROR;
                    }
                    if (error.response.data.worker_option) {
                        this.globalErrorMessage = this.lviews.errors.error400.errors.worker_option[ error.response.data.worker_option[0].code ]
                        any = true;
                        this.userDialog.state = this.dialogStates.ERROR;
                    }

                    // AuthUser errors handling
                    if (error.response.data.auth_user) {
                        let d = error.response.data.auth_user;
                        if (d.username && d.username[0].code == "unique"){
                            this.userDialog.errors.login.isError=true;
                            this.userDialog.errors.login.errorMessage=this.lviews.loginTaken;
                        }
                        else {
                            this.globalErrorMessage = this.lviews.errors.anyError.message;
                            this.userDialog.state = this.dialogStates.ERROR;
                        }
                        any = true;
                    }

                    // UserProfile errors handling
                    if (error.response.data.user_profile) {
                        let localAny = false; // any error raised within this if (let means the variable won't exist outside this if)
                        let d = error.response.data.user_profile;
                        if(d.last_name) { // last name generic error
                            this.userDialog.errors.lastName.isError = true;
                            this.userDialog.errors.lastName.errorMessage = this.lviews.invalidLastName;
                            localAny = true;
                        }
                        if(d.first_name) { // first name generic error
                            this.userDialog.errors.firstName.isError = true;
                            this.userDialog.errors.firstName.errorMessage = this.lviews.invalidFirstName;
                            localAny = true;
                        }
                        if(d.personal_email) { // e-mail generic error
                            this.userDialog.errors.email.isError = true;
                            this.userDialog.errors.email.errorMessage = this.lviews.invalidEmail;
                            localAny = true;
                        }
                        if(d.personal_mobile_number) { // phone number generic error
                            this.userDialog.errors.phoneNumber.isError = true;
                            this.userDialog.errors.phoneNumber.errorMessage = this.lviews.invalidPhoneNumber;
                            localAny = true;
                        }
                        if(d.birth_date) { // birth date generic error
                            this.userDialog.errors.birthDate.isError = true;
                            this.userDialog.errors.birthDate.errorMessage = this.lviews.invalidBirthDate;
                            localAny = true;
                        }
                        if(d.pesel){
                            if(d.pesel[0].code == "unique"){ // Pesel already assigned in DB
                                this.userDialog.errors.pesel.isError = true;
                                this.userDialog.errors.pesel.errorMessage = this.lviews.peselAssignedToOtherWorker;
                            }
                            else { // Pesel generic error
                                this.userDialog.errors.pesel.isError = true;
                                this.userDialog.errors.pesel.errorMessage = this.lviews.invalidPesel;
                            }
                            localAny = true;
                        }

                        if(!localAny){
                            this.userDialog.globalErrors.push(this.lviews.unrecognizedErrorInBasicDataSection);
                        }

                        any = true;
                    }

                    if(!any){
                        this.userDialog.globalErrors.push(this.lviews.unrecognizedErrorOcurredWhileSendingForm);
                    }
                    else if(this.userDialog.globalErrors.length == 0){
                        // Show it so the user knows there was an error
                        this.userDialog.globalErrors.push(this.lviews.errorsInForm);
                    }

                    break;

                case 401:
                    // this.globalErrorMessage = this.lviews.errors.error401.message;
                    // this.userDialog.state = this.dialogStates.ERROR;

                    this.$router.push("/login?next=/user-list");

                    break;
                case 403:
                    // this.globalErrorMessage = this.lviews.errors.error403.message;
                    // this.userDialog.state = this.dialogStates.ERROR;

                    this.$router.push("/login?next=/user-list");

                    break;
                case 404:
                    this.userDialog.globalErrors = [
                        this.lviews.requestedUserNotFound
                    ];

                    break;
                case 429:
                    this.userDialog.globalErrors = [
                        this.lviews.youSentTooManyRequests
                    ];

                    break;
                default:
                    this.userDialog.globalErrors.push(this.lviews.unrecognizedErrorOcurredWhileSendingForm);

                    break;
            }
        },

        /**
         * Updates existing worker id in order to create/update user (with assigned worker instance).
         * @param newExistingWorkerId {Number} New worker id.
         */
        updateExistingWorkerId: function(newExistingWorkerId) {
            this.existingWorkerId = newExistingWorkerId;
        },

        isDigit(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;

            if (charCode >= 48 && charCode <= 57){
                return true;
            }
            evt.preventDefault();
        },

        /**
         * Send request to remove specific user
         */
        removeUser: async function() {
            this.yesNoDialogVisible = false;
            let response = null;
            this.$emit("show-overlayer", this.lviews.sendingUserRemoveRequest);
            try {
                response = await axios({
                    url: fobConfig.api_url + "/api/v2/admin/user/" + this.userId,
                    method: "DELETE",
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem('jwt'),
                    },
                });
            } catch (error) {
                this.$emit("hide-overlayer");
                response = null;
                this.handleError(error);
            }
            this.$emit("hide-overlayer");

            if (response != null) {
                this.userDialog.user.workerId = null;
                this.userSourceObj.workerId = null;
            }
        },

        /**
         * Opens yes-no-dialog in order to ensure whether user wants to remove worker or not
         */
        openYesNoDialog: function() {
            this.removeUserWorkerContent = this.lviews.yesNoDialogContent.replace("{username}", this.userDialog.user.login);
            this.yesNoDialogVisible = true;
        },
    },

    mounted: function() {
        this.workerAutocompleteLink = fobConfig.api_url + "/api/v2/admin/worker/";
        
        this.workerOptions[0].label = this.lviews.noWorker;
        this.workerOptions[1].label = this.lviews.newWorker;
        this.workerOptions[2].label = this.lviews.existingWorker;

        this.userDialog.cancelClick = this.closeDialog;
        this.userDialog.confirmClick = this.saveChanges;
    },

    computed: {
        lviews: {
            get: function() {
                return this.$t("views.userDialog");
            }
        },
    },

    props: {
        userId: {
            type: Number,
            required: false,
            default: null
        },

        userData: {
            type: Object,
            required: false,
            default: null
        },

        roles: {
            type: Array,
            required: true
        },

        dataAccess: {
            type: Array,
            required: true
        },
    },
}
</script>

<style>
.required:after {
    content:" *";
    color: red;
}
</style>

<style scoped>
    .global-errors {
        color: red;
        text-align: center;
        width: 100%;
        margin: 10px 0;
    }
</style>