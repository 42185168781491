var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"margin-top":"10px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.dataTablesHeadersRendered,"items":_vm.dataTablesItems,"options":_vm.options,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-flex',{staticStyle:{"padding-top":"10px"},attrs:{"xs12":""}},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('v-text-field',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12",staticStyle:{"height":"66px"},attrs:{"label":_vm.lviews.search,"clearable":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12",staticStyle:{"display":"flex","height":"66px","padding-top":"0px","padding-bottom":"0px","padding-left":"0px"}},[_c('v-select',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-8 col-xs-10",attrs:{"items":_vm.statuses,"label":_vm.lviews.status},on:{"change":_vm.updatePageData},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('v-btn',{staticStyle:{"margin-top":"10px"},attrs:{"color":"primary","small":"","fab":""},on:{"click":_vm.openModifyDataTableColumnsDialog}},[_c('v-icon',[_vm._v(" mdi-settings ")])],1)],1)],1)])]},proxy:true},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',{staticStyle:{"min-width":"250px"}},_vm._l((item.options),function(group,groupIndex){return _c('div',{key:groupIndex},[_vm._l((group),function(groupItem,groupItemKey){return _c('v-list-item',{key:groupItemKey,staticClass:"worker-document-menu-option",attrs:{"disabled":groupItem.disabled}},[_c('v-flex',{style:(groupItem.style),on:{"click":function () { groupItem.action(item); }}},[_c('v-icon',{style:(groupItem.style)},[_vm._v(" "+_vm._s(groupItem.icon)+" ")]),_vm._v(" "+_vm._s(groupItem.title)+" ")],1)],1)}),(groupIndex < item.options.length - 1)?_c('v-divider',{staticStyle:{"border-bottom":"1px solid lightgray"}}):_vm._e()],2)}),0)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.statusText)+" ")])]}},{key:"footer",fn:function(){return [_c('worker-document-data-table-footer',{attrs:{"lviews":_vm.lviews,"itemsPerPageOptions":_vm.itemsPerPageOptions,"pageNumber":_vm.options.page,"itemsPerPageNumber":_vm.options.itemsPerPage,"totalItemsNumber":_vm.serverSideTotalItemsNumber},on:{"update-page-number":_vm.updatePageNumber,"update-page-size":_vm.updatePageSize}})]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"width":"350","persistent":""},model:{value:(_vm.modifyDataTablesColumnsDialog),callback:function ($$v) {_vm.modifyDataTablesColumnsDialog=$$v},expression:"modifyDataTablesColumnsDialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.lviews.chooseColumns)+" ")]),_c('v-list',_vm._l((_vm.dataTableHeadersSelectItems),function(option,index){return _c('v-list-item',{key:index},[_c('v-checkbox',{staticStyle:{"padding":"0","margin":"0"},attrs:{"label":option.text,"value":option.value},model:{value:(_vm.dataTableHeadersSelected),callback:function ($$v) {_vm.dataTableHeadersSelected=$$v},expression:"dataTableHeadersSelected"}})],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"align-self-center",staticStyle:{"margin-bottom":"15px"},attrs:{"text":"","color":"primary"},on:{"click":function () { _vm.modifyDataTablesColumnsDialog = false; }}},[_vm._v(" "+_vm._s(_vm.lviews.cancel)+" ")]),_c('v-btn',{staticClass:"align-self-center",staticStyle:{"margin-bottom":"15px"},attrs:{"text":"","color":"primary","disabled":_vm.dataTableHeadersSelected.length == 1},on:{"click":function () { _vm.updateDataTablesColumns(); _vm.modifyDataTablesColumnsDialog = false; }}},[_vm._v(" "+_vm._s(_vm.lviews.save)+" ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('user-dialog',{ref:"userDialog",attrs:{"userId":_vm.userDialogUserId,"userData":_vm.userDialogUserData,"roles":_vm.userRolesData,"dataAccess":_vm.dataAccessData},on:{"refresh-data":_vm.updatePageData,"show-overlayer":_vm.showOverlayer,"hide-overlayer":_vm.hideOverlayer}}),(_vm.canUserAddNewUsers)?_c('v-btn',{staticClass:"add-new-users-button",attrs:{"color":"primary","fab":""},on:{"click":_vm.createNewUser}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"320"},model:{value:(_vm.userActiveStatusChangeDialog.visible),callback:function ($$v) {_vm.$set(_vm.userActiveStatusChangeDialog, "visible", $$v)},expression:"userActiveStatusChangeDialog.visible"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-h5"},[_vm._v(_vm._s(_vm.userActiveStatusChangeDialog.title))]),_c('v-card-text',{staticStyle:{"text-align":"justify"}},[_vm._v(_vm._s(_vm.userActiveStatusChangeDialog.content))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function () { _vm.userActiveStatusChangeDialog.visible = false; }}},[_vm._v(" "+_vm._s(_vm.lviews.cancel)+" ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.changeUserActiveStatus}},[_vm._v(" "+_vm._s(_vm.lviews.yes)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }