<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.2"
__author__ = "Marcin Roszczyk, .."
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->

<template>
	<v-container style="background-color: white; max-width: 480px; ">
		<v-layout row wrap>
			<v-container>
				<v-layout style="width:45%; margin-left:55%; margin-bottom:-5px;">
					<v-select :label="language"
							  :items="languageSelect"
							  style="margin-left:3px;"
							  v-model="selectedLanguage"
							  v-on:change="changeLanguage">
					</v-select>
				</v-layout>

				<v-layout justify-center align-center class="login__top-logo-layout">
					<img class="login__top-logo" v-bind:src="'/static/img/fast_logo.png'" />
				</v-layout>

				<v-progress-circular indeterminate color="rgba(4, 202, 90, 1)" v-if="isActiveSpinner">
				</v-progress-circular>
				<v-form>
					<v-text-field id="id_usr"
								  prepend-inner-icon="person"
								  :label="lview.username"
								  name="username"
								  autocomplete="username"
								  color="rgba(4, 202, 90, 1)"
								  v-model="login"></v-text-field>

					<v-text-field id="id_passwd"
								  prepend-inner-icon="lock"
								  v-model="password"
								  :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" 
								  :label="lview.password"
								  name="password"
								  autocomplete="current-password"
								  color="rgba(4, 202, 90, 1)"
								  @click:append="show1 = !show1"></v-text-field>
					<span v-if="message1" style="color:red"> {{ message1 }} </span>

					<v-layout justify-center mt-3>
						<v-btn large color="primary" type="submit" @click="handleSubmit" style="width:250px; color: white;">{{ lview.login }}</v-btn>
					</v-layout>

					<v-layout justify-center>
						<v-btn text small @click="passwordReset" color="grey" style="width:250px; margin-top: 20px;">{{ lview.forgotPassword }}</v-btn>
					</v-layout>

					<v-layout justify-center mt-3>
						<img v-bind:src="logo" /> 
					</v-layout>

					<v-layout style="font-size:12px" justify-center mt-4>						
						<v-row no-gutters>
							<v-col cols="4" align="right" style="padding-right: 4px;">
								<a class="a-link-normal" target="_blank" rel="noopener" v-bind:href="'https://www.fastonboarding.pl/regulamin/'+tenant+'/'">
								{{ lview.infoRegulations }}
								</a>
							</v-col>
							<v-col cols="4" align="center">
								<a class="a-link-normal" target="_blank" rel="noopener" href="https://www.fastonboarding.pl/polityka-prywatnosci/">
								{{ lview.infoPrivacy }}
								</a>								
							</v-col>
							<v-col cols="4" align="left" style="padding-left: 4px;">
								<a class="a-link-normal" target="_blank" rel="noopener" href="https://www.fastonboarding.pl/centrum-pomocy/">
								{{ lview.infoHelp }}
								</a>
							</v-col>
						</v-row>					
					</v-layout>
					<br>
					<v-layout justify-center mt-3>
						<span style="font-size:10px;"> <a target="_blank" rel="noopener" href="https://fastonboarding.pl">© 2019-2021 FOB</a> {{ version }}</span>
					</v-layout>
				<v-btn
				elevation="2"
				@click="notifyMe"
				> Włącz powiadomienia </v-btn>
				</v-form>
			</v-container>
		</v-layout>
	</v-container>
</template>

<script>
	import axios from 'axios'
	import store from '../store.js'
	import fob_config from '../fob_config.js'


	export default {
		data() {
			return {
				firebase_token: "null",
				nextUrl: null,
				show1: false,
				rules: {
					required: value => !!value || 'Wymagane.',
					min: v => v.length >= 8 || 'Minimum 8 znaków',
					emailMatch: () => ('Email i hasło nie pasują')
				},
				login: "",
				password: "",
				message1: '',
				isActiveSpinner: false,
				language: this.$t('views.login.language'),
				languageSelect: this.$t('views.login.languageSelect'),
				languageDictionary: this.$t('views.login.languageDictionary'),
				selectedLanguage: this.$t('views.login.selectedLanguage'),
                // ENVIRONMENTS
				current_env: fob_config.api_url,
				logo: fob_config.client_logo,
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.login'); } },
			lmessages: { get: function () { return this.$t('commons.messages'); } },
		},
		props: {
			version: {
				type: String,
				required: true,
			},
			tenant: {
				type: String,
				default: '',
			},
			appConfig: {
				type: Object,
				required: true
			}
		},
		mounted() {
			// console.log('---- Login mounted!');
			let isUserLoggedIn = this.$store.getters.isLoggedIn;
			if (isUserLoggedIn) {
				console.log("LOGGED IN");
				this.$router.push(this.getDefaultRedirection());
			} else {
				this.message1 = '';
				this.$store.dispatch('logout');

				// Checking for "next" url
				if (typeof(this.$route.query.next) !== 'undefined'){
					this.nextUrl = this.$route.query.next;
				}
				else{
					this.nextUrl = null;
				}

				if (typeof(this.$route.query.username) !== 'undefined'){
					this.login = this.$route.query.username;
					document.getElementById("id_passwd").focus();
					document.getElementById("id_passwd").select();
				}
				else {
					document.getElementById("id_usr").focus();
					document.getElementById("id_usr").select();
				}

				// this.focusInput();
				// this.$nextTick(() => this.$refs.usr.focus());
				
			}

			// this.$nextTick(() => document.getElementById("id_usr").focus());
			// document.getElementById("id_usr").select();
		},
		beforeMount(){
			let isUserLoggedIn = this.$store.getters.isLoggedIn;
			if (!isUserLoggedIn) {
				localStorage.clear();
			}
		},
		methods: {
			notifyMe() {
			if (("Notification" in window)) {
				// Otherwise, we need to ask the user for permission
				if (Notification.permission !== "denied" || Notification.permission !== "default") {
					Notification.requestPermission().then(function (permission) {
					if (permission === "granted") {
						window.location.reload();
					}
					});
				}
			}

			// At last, if the user has denied notifications, and you
			// want to be respectful there is no need to bother them any more.
			},
			getDefaultRedirection(){
				// prioritized directions
				let redirections = [
					{ linkTo: "/boarding", permission: "fob.boardings_view" },
					{ linkTo: "/employees", permission: "fob.boardings_view" },
					{ linkTo: "/my-documents", permission: "fob.my_docs_view" },
					{ linkTo: "/workers-documents", permission: 'fob.worker_docs_view' },
					{ linkTo: "/user-profile", permission: "fob.workers_view" },
					{ linkTo: "/about", permission: null },
					{ linkTo: "/settings", permission: null },
				]

				let userPermissions = localStorage.getItem("user_permissions");
				for(var i = 0; i < redirections.length; i++) {
					if (redirections[i].permission == null || userPermissions.includes(redirections[i].permission)) {
						return redirections[i].linkTo;
					}
				}
			},

			async handleSubmit(e) {
				this.firebase_token = localStorage.getItem('firebase_token');
				this.message1 = ''
				e.preventDefault()
				if (this.password.length > 0) {
					//check whether user has ever logged in or not
					localStorage.setItem('current_env', this.current_env)
					this.$emit('set-state', 'LOADING_OVERLAYER', this.lmessages.logging);
					//console.log(">>>> Authenticating user with backend ... :" + this.current_env)
					this.$store.dispatch("login", {
						username: this.login,
						password: this.password

					}).then((resp) => {
						this.$emit('set-state', 'default');
						if (!JSON.parse(localStorage.getItem('user_account_activated'))){
							localStorage.removeItem('user_account_activated');
							this.$store.dispatch('logout');
							return;
						}

						// register device
						let firebase_token = localStorage.getItem('firebase_token');
						if (firebase_token != null && firebase_token != undefined) {
							try {
								let response = axios({
									method: "POST",
									url: localStorage.getItem("current_env") + "/api/v2/firebase/register/",
									headers: {
										"Content-Type": "application/json",
										Authorization: "Bearer " + localStorage.getItem("jwt")
									},
									data: {
										registration_id: localStorage.getItem('firebase_token'),
									}
								});
							} catch(error) {
							}
						}
						
						if(this.nextUrl == null)
							this.$router.push(this.getDefaultRedirection());
						else
							this.$router.push(this.nextUrl);
					}).catch(err => {
						this.$emit('set-state', 'default');
						/*if (err == "NOT ACTIVATED") {
							localStorage.setItem('tmp_username', this.login);
							localStorage.setItem('tmp_pass', this.password);
							this.$router.push('first-logging');
						}
						else */
						//console.log(err);
						if (err.detail != undefined) { // throttling
							this.message1 = this.lview.error429message;
						}
						else {
							switch(err.code){
								case "no_active_account":
									this.message1 = this.lview.invalidLoginOrPassword;
									break;

								case "account_not_activated":
									this.message1 = this.lview.accountNotActivated;
									break;
								
								case "login_method_disabled":
									this.message1 = this.lview.loginMethodBlockedByAdministrator;
									break;

								case "account_missing_authuserconfig":
									this.message1 = this.lview.invalidAccountConfig;
									break;

								case "account_disabled_by_administrator":
									this.message1 = this.lview.accountDisabled;
									break;

								case "multiple_accounts_assigned_to_email":
									this.message1 = this.lview.multipleAccountsAssignedToEmail;
									break;

								case "unassigned_user_profile":
									this.message1 = this.lview.unassignedUserProfile;
									break;
								case "unassigned_worker":
									this.message1 = this.lview.unassignedWorker;
									break;
								case "no_user_permissions":
									this.message1 = this.lview.couldNotLoginNoPermissions;
									break;
								
								default:
									this.message1 = err.string;
							}
						}
						this.isActiveSpinner = false
					})
				} else {
					this.message1 = this.lview.missingInput;
				}
			},
			passwordReset() {
				this.$router.push('password-reset')

			},

			changeLanguage() {
				let lang = (this.selectedLanguage.split(' '))[0];
				var key = "";
				var idx = 0;

				for (var i = 0; i < this.languageDictionary.length; i++) {
					if (this.languageDictionary[i].language == lang) {
						key = this.languageDictionary[i].key;
						idx = i;
						break;
					}
				}

				this.$cookies.set('language', key);
				let language = this.$cookies.get('language');
				this.$i18n.locale = language;
				
				this.language = this.$t('views.login.language');
				this.languageSelect = this.$t('views.login.languageSelect');
				this.languageDictionary = this.$t('views.login.languageDictionary');
				this.selectedLanguage = this.$t('views.login.selectedLanguage');
			}
		},

		/*login: function () {
			const {
				username,
				password
			} = this
			this.$store.dispatch(AUTH_REQUEST, {
				username,
				password
			}).then(() => {
				this.$router.push('/')
			})
		}*/
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	h1,
	h2 {
		font-weight: normal;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #35495E;
	}

	.login {
		border-radius: 5px;
		padding: 1.5rem;
		width: auto;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		position: relative;
	}
</style>

<style scoped>
	.login__top-logo {
		width: 90%;
		height: auto;
	}

	.login__top-logo-layout {
		width: 100%;
		height: 100px;
	}
</style>
