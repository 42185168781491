import jwt_decode from 'jwt-decode'


export function login() {
  window.location.href = `https://{YOUR-AUTH0-DOMAIN}.auth0.com/authorize?scope=full_access&audience={YOUR-API-IDENTIFIER}&response_type=id_token%20token&client_id={YOUR-AUTH0-CLIENT-ID}&redirect_uri={YOUR-CALLBACK-URL}&nonce=`;
}


export function logout() {
  clearIdToken();
  clearAccessToken();
  router.go('/');
}

export function requireAuth(to, from, next) {

}

export function getAccessToken() {
  return localStorage.getItem('jwt');
}

function clearAccessToken() {
  localStorage.removeItem('jwt');
}


// Decode id_token to verify the nonce
function decodeIdToken(token) {
  const jwt = decode(token);
  verifyNonce(jwt.nonce);
}

// Function to generate a nonce which will be used to mitigate replay attacks

export default function isLoginValid() {
  const accessToken = getAccessToken();
  if (accessToken) {
    return isTokenExpired(accessToken)

  } else
    return false
}

//   function getTokenExpirationDate(encodedToken) {
//     const token = decode(encodedToken);
//     if (!token.exp) {
//       return null;
//     }
//     const date = new Date(0);
//     date.setUTCSeconds(token.exp);
//     return date;
//   }

//   function isTokenExpired(token) {
//     const expirationDate = getTokenExpirationDate(token);
//     return expirationDate < new Date();
//   }


export function isTokenExpired(token) {
  // checking the token is expired

  const decoded = jwt_decode(token);
  const exp = decoded.exp
  if ((exp - (Date.now() / 1000)) < 0) {
    return false
  } else
    return true
}
