// tenant definition
const DEF_TENANT = "demo"; // PORR deployment

// Rest of settings
module.exports = {
    app_tenant: DEF_TENANT,
    app_ver: "1.4_09_05_2021 (" + DEF_TENANT + ")",
    //api_url: "https://" + DEF_TENANT + "-desktop.fastonboarding.com",
    api_url: 'https://localhost:8000', // local DEBUG overwrite
    service_worker_ver: "1.0_25_09_2019DEV",
    service_worker_update_timout: 1000 * 60 * 60,
    debug: false,
    client_logo: "/static/img/ideal_logo.png",
    page_title: "Fast OnBoarding",
};