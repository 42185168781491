 // Sharedmasters 2019 - Tmiesheet Application

 // __author__ = "Marcin Roszczyk, .."
 // __copyright__ = "Copyright 2019, Shared Masters - Timesheet Project"
 // __license__ = "GPL"
 // __version__ = "0.0.1-pre-beta"
 // __maintainer__ = "Marcin Roszczyk"
 // __email__ = "marcin@sharedmasters.com"
 // __status__ = "DEV"

 import Vue from 'vue'
 import Vuex from 'vuex'
 import axios from 'axios'
 import jwt_decode from 'jwt-decode'
 import createPersistedState from "vuex-persistedstate";

 Vue.use(Vuex)

 export default new Vuex.Store({
     plugins: [createPersistedState()],
     state: {
         status: '',
         token: localStorage.getItem('jwt') || '',
         user_profile_id: localStorage.getItem('user_profile_id') || '',
         is_loggedIn: localStorage.getItem('jwt') || '',
         pending: false,
         markedRecord: false,
         myDocumentsCache: {
             grouping: -1,
             sorting: -1,
             searchValue: ""
         }
     },
     getters: {
         isLoggedIn: state => {
            if(state.token != '' && state.token != null && typeof(state.token) !== 'undefined'){
                var decoded;
                try{
                    decoded = jwt_decode(state.token);
                } catch(error){
                    return false;
                }
                const exp = decoded.exp;
                if ((exp - (Date.now() / 1000)) < 0) {
                    return false;
                } else
                return true;
            }
            else{
                return false;
            }
         },
         authStatus: state => state.status,
         userProfile: state => state.user_profile_id,
         getStateName: state => state.status,
         markedRecord: state => state.markedRecord,
         myDocumentsCache: state => state.myDocumentsCache,
     },
     mutations: {

         auth_request(state) {
             state.status = 'authenticating'
             state.pending = true
         },
         auth_success(state, token, ) {
             state.status = 'success';
             state.token = token
             state.is_loggedIn = true
             state.pending = false
         },
         auth_error(state) {
             state.status = 'error';

         },
         logout(state) {
             state.status = 'not_authenticated';
             state.token = '';
             state.user_profile_id = '';
             state.is_loggedIn = false
         },
         updateMarkedRecordFlag(state, payload) {
             state.markedRecord = payload.isMarked;
         },
         updateMyDocumentsCache(state, payload) {
             state.myDocumentsCache.searchValue = payload.searchValue;
             state.myDocumentsCache.sorting = payload.sorting;
             state.myDocumentsCache.grouping = payload.grouping;
         }
     },

     actions: {
         login({
             commit
         }, user) {
             return new Promise((resolve, reject) => {
                 commit('auth_request')
                 //console.log("-----> STORE requesting AXIOS ")
                 axios({
                         url: localStorage.getItem('current_env') + "/api/login/",
                         headers: {
                             'Accept-Language': 'en'
                         },
                         data: user,
                         method: 'POST'
                     })
                     .then(resp => {
                         const token = resp.data.access
                         const user = resp.data.username

                         // The code below should be refactored to serialize entire object instead of each attribute - requires API change
                         //  

                         //persist data in case user refreshes the page
                         localStorage.setItem('jwt', token);
                         localStorage.setItem('application_user_id', resp.data.application_user.id);
                         localStorage.setItem('application_user_username', resp.data.username);
                         localStorage.setItem('application_user_first_name', resp.data.application_user.first_name);
                         localStorage.setItem('application_user_last_name', resp.data.application_user.last_name);
                         localStorage.setItem('application_user_email', resp.data.application_user.email);
                         localStorage.setItem('application_user_groups', resp.data.application_user.groups);
                         localStorage.setItem('user_account_activated', resp.data.account_activated);
                         localStorage.setItem('user_permissions', resp.data.user_permissions);

                         if (resp.data.user_profile != '') { // user_profile is empty in case Application User has no associate UserProfile
                            this.state.user_profile_id = resp.data.user_profile.id
                            localStorage.setItem('user_profile_id', resp.data.user_profile.id)
                            localStorage.setItem('user_profile_first_name', resp.data.user_profile.first_name)
                            localStorage.setItem('user_profile_middle_name', resp.data.user_profile.middle_name)
                            localStorage.setItem('user_profile_last_name', resp.data.user_profile.last_name);
                            localStorage.setItem('user_profile_personal_mobile_number', resp.data.user_profile.personal_mobile_number);
                            localStorage.setItem('user_profile_business_mobile_number', resp.data.user_profile.business_mobile_number);
                            localStorage.setItem('user_profile_personal_email', resp.data.user_profile.personal_email)
                            localStorage.setItem('user_profile_secondary_email', resp.data.user_profile.secondary_email)
                            localStorage.setItem('user_profile_birth_date', resp.data.user_profile.birth_date)
                            localStorage.setItem('user_profile_pesel', resp.data.user_profile.pesel)

                            if (typeof resp.data.user_profile.primary_postal_address !== 'undefined' && resp.data.user_profile.primary_postal_address != null) {
                                localStorage.setItem('user_profile_personal_primary_address_id', resp.data.user_profile.primary_postal_address.id)
                                localStorage.setItem('user_profile_personal_primary_address_street_name', resp.data.user_profile.primary_postal_address.street_name)
                                localStorage.setItem('user_profile_personal_primary_address_street_number', resp.data.user_profile.primary_postal_address.street_number)
                                localStorage.setItem('user_profile_personal_primary_address_property_number', resp.data.user_profile.primary_postal_address.property_number)
                                localStorage.setItem('user_profile_personal_primary_address_zip_code', resp.data.user_profile.primary_postal_address.zip_code)
                                localStorage.setItem('user_profile_personal_primary_address_city', resp.data.user_profile.primary_postal_address.city)
                                localStorage.setItem('user_profile_personal_primary_address_region', resp.data.user_profile.primary_postal_address.region)
                                localStorage.setItem('user_profile_personal_primary_address_country', resp.data.user_profile.primary_postal_address.country)
                            }

                            if (resp.data.workers != "" && resp.data.workers != null) {
                                localStorage.setItem('workers', JSON.stringify(resp.data.workers));
                            }

                            localStorage.setItem('projects', JSON.stringify(resp.data.projects)),
                            localStorage.setItem('clients', JSON.stringify(resp.data.clients)),
                            localStorage.setItem('work_types', JSON.stringify(resp.data.work_types))
                            localStorage.setItem('timesheet_types', JSON.stringify(resp.data.timesheet_types))
                            localStorage.setItem('timesheet_statuses', JSON.stringify(resp.data.timesheet_statuses))

                         } else {
                            localStorage.setItem('user_profile_id', '')
                            this.state.user_profile_id = ''
                         }
                         //console.log('--->>>>>>>>>. User Profile ID', resp.data.user_profile.id)
                         const user_profile_id = resp.data.user_profile.id
                         commit('auth_success', token)

                         axios.defaults.headers.common['Authorization'] = token
                         resolve(resp)
                     })
                     .catch(err => {
                         // Error
                        if (err.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            // console.log(error.response.data);
                            // console.log(error.response.status);
                            // console.log(error.response.headers);
                            //console.log('!!!! Login Error !');
                            //console.log(err);
                            commit('auth_error');
                            localStorage.removeItem('jwt');

                            reject(err.response.data);

                        } else if (err.request) {
                             // The request was made but no response was received
                             // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                             // http.ClientRequest in node.js
                             //console.log(err.request);
                             //console.log('!!!! ERROR - Axios request! ')
                             //console.log(err)
                             commit('auth_error')
                             localStorage.removeItem('jwt')
                             reject('NETWORK_ERROR')

                        } else {
                             // Something happened in setting up the request that triggered an Error
                             //console.log('! Axios Error due to wrong request ')
                             //console.log(err)
                             //console.log('Error', err.message);
                        }
                        // console.log(err.config); // DEBUG only - otherwise in case of errors it will reveal user password in console
                        commit('auth_error')
                        localStorage.removeItem('jwt')
                        reject(err)
                     })
             })
         },
         logout({
             commit
         }) {
             return new Promise((resolve, reject) => {
                 commit('logout')
                 localStorage.clear()
                 //console.log(">>>> Logging out >>>> JWT removed >>")
                 delete axios.defaults.headers.common['Authorization']
                 resolve()
             })
         },

     }
 })