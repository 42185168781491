<template>
  <v-layout>
      <v-dialog
        v-model="dialog"
        width="290"
        style="z-index:400"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title
            class="headline"
            primary-title
          >
            {{ computedTitle }}
          </v-card-title>

          <v-card-text>
            <div height="300px">
              <div v-html="computedMessage"></div>
                <v-expansion-panels>
                  <v-expansion-panel inset>
                    <v-expansion-panel-content style="margin: 0; margin-top:20px; width:100%; max-width:100%">
                      <template v-slot:header>
                        <div>{{ lmessages.showDetails }}</div>
                      </template>

                      <v-card>
                        <v-card-text>
                          <div v-html="computedDetails"></div>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
            </div>
          </v-card-text>

          <v-card-actions>
              <v-btn 
                rounded 
                :color="copyColor" 
                dark 
                v-clipboard="copyError"
                @success="copyErrorSuccess"
                @error="copyErrorFailed"
                
                style="width:85%; margin:0 auto"
              >
                {{ copyMessage }}
                <v-spacer></v-spacer>
                <v-icon style="margin-left: 5px;" dark>{{ copyIcon }}</v-icon>
              </v-btn>
          </v-card-actions>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="rgba(4, 202, 90, 1)"
              text
              @click="close"
            >
              {{ lbuttons.understood }}
            </v-btn>
          </v-card-actions>
        </v-card>

        
      </v-dialog>
      <v-snackbar
          v-model="offlineNotifier"
          :multi-line="true"
      >
        <v-container>
        <v-row no-gutters>
          <v-col cols="6" align-self="center">{{ lview.problemWithLoadingPage }}</v-col>
          <v-col cols="6">
          <v-btn
            text
            @click="reloadPage()"
            style="color: orange; font-size: 12px;"
          >
            {{ lbuttons.retry }}
          </v-btn>
          </v-col>
        </v-row>
        </v-container>
      </v-snackbar>
  </v-layout>
</template>

<script>
  export default {
    name: "errorDialog",
    data: () => ({
      dialog: false,
      copyError: "",
      copyIcon: "assignment",
      copyMessage: null,
      copyColor: "rgba(4, 202, 90, 1)",

      copyTimeout: false,

      offlineMode: {
          visible: true,
      },
			offlineNotifier: true,
    }),
    mounted: function(){
      this.$nextTick(() => this.dialog = true);
      this.setErrorContainer();
    },
    beforeMount: function(){
      this.copyMessage = this.lview.copyError;
    },
    computed: {
      lview: {
        get: function(){
          return this.$t('views.commons.errorDialog');
        }
      },
      lmessages: {
        get: function(){
          return this.$t('commons.messages');
        }
      },
      lbuttons: {
        get: function(){
          return this.$t('commons.buttons');
        }
      },

      computedTitle: {
        get: function(){
          return this.title == null ? this.lmessages.errorOccuredTitle : this.title;
        }
      },
      computedMessage: {
        get: function(){
          return this.message == null ? this.lmessages.errorOccured : this.message;
        }
      },
      computedDetails: {
        get: function(){
          return this.details == null ? this.lmessages.noDetails : this.details;
        }
      }
    },
    watch: {
      title() {
        this.setErrorContainer();
      },
      message() {
        this.setErrorContainer();
      },
      details() {
        this.setErrorContainer();
      }
    },
    props: {
      title: {
        type: String,
        default: null,
      },
      message: {
        type: String,
        default: null,
      },
      details: {
        type: String,
        default: null
      }
    },
    methods: {
      setErrorContainer(){
        var error = null;
        error = this.computedTitle + "\n\n"
          + this.computedMessage + "\n\n\n"
          + this.lmessages.details 
          + "\n--------------\n" 
          + this.computedDetails;

        this.copyError = error;
      },

      setCopyButtonToDefault(){
        this.copyIcon = "assignment";
        this.copyMessage = this.lview.copyError;
        this.copyColor = "rgba(4, 202, 90, 1)";
      },
      copyErrorSuccess(){
        if(this.copyTimeout != false){
          clearTimeout(this.copyTimeout);
          this.copyTimeout = false;
        }

        this.copyIcon = "check";
        this.copyMessage = this.lview.copied;
        this.copyColor = "success";
        

        this.copyTimeout = setTimeout(this.setCopyButtonToDefault, 1500);
      },
      copyErrorFailed(){
        if(this.copyTimeout != false){
          clearTimeout(this.copyTimeout);
          this.copyTimeout = false;
        }

        this.copyIcon = "close";
        this.copyMessage = this.lview.failedToCopy;
        this.copyColor = "error";

        this.copyTimeout = setTimeout(this.setCopyButtonToDefault, 1500);
      },

      reloadPage(){
        if(this.onlineStatus){
          window.location.reload();
          this.offlineNotifier = false;
        } else {
          this.offlineNotifier = true;
        }
      },

      close(){
        this.dialog = false;
        this.$emit('closed');
      }
    }
  }
</script>

<style scoped>
  .footer{
    background-color: darkslategrey;
    color: white;
  }
</style>