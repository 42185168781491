<template>
    <v-layout style="margin-top: 10px;">
        <v-flex xs12 style="padding-top: 10px;">
            <div style="display: flex; flex-wrap: wrap;">
                <!-- role name field -->
                <v-text-field
                    v-model="roleName"
                    :label="lviews.roleName"
                    :disabled = nameDisabled
                    style="height: 66px;"
                    clearable
                    class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"
                ></v-text-field>
            </div>
        </v-flex>
        <v-list>
            <v-list-item
                :id="globalCheckboxes[moduleItem.id].module"
                v-for="moduleItem in dataTableItems"
                :key="moduleItem.module"
            >
                <v-list-item-content >
                    <div style="display: inline-flex;">
                        <div class = "col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3" style="text-align:center; margin:auto 0;">
                            <div>{{moduleItem.module}}</div>
                        </div>
                        
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2" style="text-align:center; margin:auto 0;">
                            <v-checkbox
                                :id="'checkbox'+globalCheckboxes[moduleItem.id].module"
                                v-model="globalCheckboxes[moduleItem.id].check"
                                :indeterminate="globalCheckboxes[moduleItem.id].indeterminate"
                                @click="()=>{checkAllInRow(moduleItem)}"
                            ></v-checkbox>
                        </div>

                        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-5" style="padding-top:auto; padding-bottom:auto; item-align:center;">
                            <v-select
                                v-model="selectItems[moduleItem.id].selectedItems"
                                :items="selectItems[moduleItem.id].items"
                                multiple
                                item-text="name"
                            >
                                <template v-slot:item="{item}">    
                                        <v-list-item @click="()=>{checkBox(moduleItem, item)}">
                                            <v-simple-checkbox
                                            v-model="moduleItem[item.value]"
                                            style="text-align: center;"
                                            @click="()=>{checkIndeterminate(moduleItem,item)}"
                                            ></v-simple-checkbox>
                                            {{ item.name }}
                                        </v-list-item>
                                </template>
                            </v-select>  
                        </div>
                    </div>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-layout align-center justify-end class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right">
            <v-btn
                primary
                color="white"
                @click="rejectRoleChanges()"
                style='vertical-align:top; margin-right:20px'
                class="smart-button-width"
            >
                {{lviews.cancel}}
            </v-btn>
            <v-btn
                primary
                color="primary"
                @click="saveRoleChanges()"
                style='vertical-align:top;'
                class="smart-button-width"
            >
                {{lviews.save}}
            </v-btn>
        </v-layout>
    </v-layout>
</template>
<script>
import axios from 'axios';
import fob_config from '../fob_config.js';
export default {
    name: 'ModuleConfiguration',
    data() {
        return{
            closeOnContentClick: true,
            roleName:"",
            nameDisabled: false,
            dataTableHeaders:[
                { id: 0, text: '{L Moduły }', align: 'center', sortable: false, value: 'module'},
                { id: 1, text: '{L Dodawanie }', align: 'center', sortable: false, value: 'add' },
                { id: 2, text: '{L Odczyt }', align: 'center', sortable: false, value: 'read' },
                { id: 3, text: '{L Aktualizacja }', align: 'center', sortable: false, value: 'edit' },
                { id: 4, text: '{L Usuwanie }', align: 'center', sortable: false, value: 'delete' },
                { id: 5, text: '{L Zaznacz wszystko }', align: 'center', sortable: false, value: 'check_all' },
            ],
            selectItems:{
                // dokumenty:{
                //     items:[],
                //     selectedItems:[],
                // }
            },
            dataTableItems:[
            //    { 
            //         id: 0, 
            //         module:"Dokumenty", 
            //         add: true, 
            //         read: true, 
            //         edit: false, 
            //         delete: false, 
            //         add_disabled: false, 
            //         read_disabled: false, 
            //         edit_disabled: false, 
            //         delete_disabled: false,
            //         is_open: false,
            //     },
            ],
            globalCheckboxes:[
                // {
                //     module:"",
                //     check:false,
                //     indeterminate:false,
                // }
            ],
            snackbar: {
                visible: false,
                color: "primary",
                message: ""
            },
        };
    },
    methods:{
        checkBox: function(moduleItem,item){
            moduleItem[item.value] = !moduleItem[item.value];
            this.checkIndeterminate(moduleItem,item);
            item.check = !item.check;
        },
        checkIndeterminate: function(item, checkItem){
            if((item.add_disabled||item.add) && (item.read_disabled||item.read) && (item.edit_disabled||item.edit) && (item.delete_disabled||item.delete)){
                this.globalCheckboxes[item.id].indeterminate = false;
                this.globalCheckboxes[item.id].check = true;
            }
            else if(!item.add && !item.read && !item.edit && !item.delete){
                this.globalCheckboxes[item.id].check = false;
                this.globalCheckboxes[item.id].indeterminate = false;
            }
            else{
                this.globalCheckboxes[item.id].check = false;
                this.globalCheckboxes[item.id].indeterminate = true;
            }
            if(!this.selectItems[item.id].selectedItems.find(x => x.name == checkItem.name)){
                this.selectItems[item.id].selectedItems.push(checkItem)
            }
            else{
                var x = this.selectItems[item.id].selectedItems.findIndex(x => x.name == checkItem.name);
                this.selectItems[item.id].selectedItems.splice(x, 1);
            }
        },
        expandRow: function(event){
            if(event.target.className != ""){
                event.stopPropagation();
            }

        },
        saveRoleChanges: async function (){
            var group_id = this.$route.params.group_id;
            let newPermissions = [];
            
            let lang = this.$cookies.get("language");
            if (lang == null){
                lang = "pl";
            }
            let apiPermissionsLink = fob_config.api_url + "/api/v2/admin/permissions";
            let responsePromise = axios({
                method: "GET",
                url: apiPermissionsLink,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                // handle errors
                // #TODO Handle 403 error (Forbiden)
                return null;
            }
            let permissionsData = response.data;
            for (let i = 0; i < this.dataTableItems.length; i++){
                if (this.dataTableItems[i].module == permissionsData.mdl[i][lang]){
                    let permissionSuffixes = [];
                    if(this.dataTableItems[i].add){
                        permissionSuffixes.push("add");
                    }
                    if(this.dataTableItems[i].read){
                        permissionSuffixes.push("view");
                    }
                    if(this.dataTableItems[i].edit){
                        permissionSuffixes.push("change");
                    }
                    if(this.dataTableItems[i].delete){
                        permissionSuffixes.push("delete");
                    }
                    let newId = this.getPermissionsId(permissionSuffixes, permissionsData.mdl[i].permissions)
                    for (let j = 0; j<newId.length; j++){
                        newPermissions.push(newId[j])
                    }
                }
            }
            if (group_id == null || group_id == undefined){
                let groupNewData = {
                    permissions: newPermissions,
                    name: this.roleName,
                    perm_type: 0,
                }
                let apiGroupsPostLink = fob_config.api_url + "/api/v2/admin/groups/";
                responsePromise = axios({
                    method: "POST",
                    url: apiGroupsPostLink,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    data:groupNewData
                });
            }
            else{
                let groupNewData = {
                    permissions: newPermissions
                }
                let apiGroupsLink = fob_config.api_url + "/api/v2/admin/groups/"+group_id;
                responsePromise = axios({
                    method: "PATCH",
                    url: apiGroupsLink,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    data:groupNewData
                });
            }
            response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR');
                // 
                // #TODO handle errors
                return null;
            }
            this.nameDisabled = true;
            this.snackbar.color = "success";
            this.snackbar.message = this.lviews.changesUpdated;
            this.snackbar.visible = true;

            this.$router.go(-1);
        },
        checkAllInRow: function(item){
            let rowCheck = true;
            if(!item.add_disabled && !item.add){
                rowCheck = false;
            }
            if(!item.read_disabled && !item.read){
                rowCheck = false;
            }
            if(!item.edit_disabled && !item.edit){
                rowCheck = false;
            }
            if(!item.delete_disabled && !item.delete){
                rowCheck = false;
            }
            if(!rowCheck){
                if(!item.add_disabled){item.add = true;}
                if(!item.read_disabled){item.read = true;}
                if(!item.edit_disabled){item.edit = true;}
                if(!item.delete_disabled){item.delete = true;}
                
                if(!this.selectItems[item.id].selectedItems.find(x => x.value == 'add')){
                    this.selectItems[item.id].selectedItems.push({name:this.lviews.add,value:"add",check:true})
                }
                if(!this.selectItems[item.id].selectedItems.find(x => x.value == 'read')){
                    this.selectItems[item.id].selectedItems.push({name:this.lviews.read,value:"read",check:true})
                }
                if(!this.selectItems[item.id].selectedItems.find(x => x.value == 'edit')){
                    this.selectItems[item.id].selectedItems.push({name:this.lviews.edit,value:"edit",check:true})
                }
                if(!this.selectItems[item.id].selectedItems.find(x => x.value == 'delete')){
                    this.selectItems[item.id].selectedItems.push({name:this.lviews.delete,value:"delete",check:true})
                }
            }
            else{
                if(!item.add_disabled){item.add = false;}
                if(!item.read_disabled){item.read = false;}
                if(!item.edit_disabled){item.edit = false;}
                if(!item.delete_disabled){item.delete = false;}
                this.selectItems[item.id].selectedItems = [];
            }
        },
        getPermissionsId: function(permissionSuffixes, modulePermissions){
            let newId = [];
            for (let i = 0; i < modulePermissions.length; i++) {
                for (let p = 0; p < permissionSuffixes.length; p++) {
                    if ( modulePermissions[i].codename.includes(permissionSuffixes[p]) ) {
                        newId.push(modulePermissions[i].id)
                    }
                }
            }
            return newId;
        },
        rejectRoleChanges: function(){
            this.$router.go(-1);
        },
        getCookie:function (cname){
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },

        /**
         * Structures group permissions (based on previously fetched groups data and admin permissions)
         * @param group - {Object} - fetched group data
         * @param modulePermissions - {Array of Object} - List of permission data objects for specific module
         * @returns {Object} - Structured group permissions - returned structure is presented below
         * {
         *      add:    {Boolean},
         *      view:   {Boolean},
         *      edit:   {Boolean},
         *      delete: {Boolean}
         * }
         */
        getGroupPermissions: function(group, modulePermissions) {
            let groupPermissionsConfig = {
                add: false,
                view: false,
                change: false,
                delete: false
            };

            if (group.permissions == null) {
                return groupPermissionsConfig;
            }

            for (let i = 0; i < modulePermissions.length; i++) {
                let hasPermission = group.permissions.includes(modulePermissions[i].id);
                if (hasPermission) {
                    let permissionSuffixes = ["add", "view", "change", "delete"];
                    for (let p = 0; p < permissionSuffixes.length; p++) {
                        if ( modulePermissions[i].codename.includes(permissionSuffixes[p]) ) {
                            groupPermissionsConfig[ permissionSuffixes[p] ] = true;
                        }
                    }
                }
            }

            return groupPermissionsConfig;
        },

        fetchPermissionsData: async function(){
            let lang = this.$cookies.get("language");
            if (lang == null){
                lang = "pl";
            }
            let apiPermissionsLink = fob_config.api_url + "/api/v2/admin/permissions";
            let responsePromise = axios({
                method: "GET",
                url: apiPermissionsLink,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                // handle errors
                // #TODO Handle 403 error (Forbiden)
                return null;
            }
            let permissionsData = response.data;
            var add_disable = false;
            var read_disable = false;
            var edit_disable = false;
            var delete_disable = false;

            var group_id = this.$route.params.group_id;
            var id = 0;
            if (group_id == null || group_id == undefined){
                let newItems = [];
                for(var i = 0; i < permissionsData.mdl.length; i++){
                    add_disable = false;
                    read_disable = false;
                    edit_disable = false;
                    delete_disable = false;
                    if(permissionsData.mdl[i].permissions.length != 4){
                        add_disable = true;
                        read_disable = true;
                        edit_disable = true;
                        delete_disable = true;
                        for(var j = 0; j < permissionsData.mdl[i].permissions.length; j++){
                            if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "add"){
                                add_disable = false;
                            }
                            if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "view"){
                                read_disable = false;
                            }
                            if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "change"){
                                edit_disable = false;
                            }
                            if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "delete"){
                                delete_disable = false;
                            }
                        }
                    }
                    newItems.push(
                        {
                            id:id, 
                            module:permissionsData.mdl[i][lang],
                            add:false,
                            read:false,
                            edit:false,
                            delete:false, 
                            add_disabled:add_disable, 
                            read_disabled:read_disable, 
                            edit_disabled:edit_disable, 
                            delete_disabled:delete_disable,
                            is_open: false,
                        });
                    var items = [];
                    if(!add_disable){items.push({
                        name:this.lviews.add,
                        value: "add",
                        check: false,
                    })}
                    if(!read_disable){items.push({
                        name:this.lviews.read,
                        value: "read",
                        check: false,
                    })}
                    if(!edit_disable){items.push({
                        name:this.lviews.edit,
                        value: "edit",
                        check: false,
                    })}
                    if(!delete_disable){items.push({
                        name:this.lviews.delete,
                        value: "delete",
                        check: false,
                    })}
                    this.selectItems[id] = {
                        items:items
                    }
                    this.selectedItems[id] = {
                        items:[]
                    }
                    this.globalCheckboxes.push(
                        {
                            check:false,
                            indeterminate:false
                        }
                    );
                    id = id+1;
                }
                this.dataTableItems = newItems;
                this.roleName = "";
            }
            else{
                let apiGroupLink = fob_config.api_url + "/api/v2/admin/groups/" + group_id;
                let responsePromise = axios({
                    method: "GET",
                    url: apiGroupLink,
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    }
                });
                let response = null;
                try {
                    response = await responsePromise;
                } catch (error) {
                    this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                    // handle errors
                    // #TODO Handle 403 error (Forbiden)
                    return null;
                }

                let groupData = response.data;
                if (groupData.type == 1){
                    this.$emit('set-state', 'ERROR', this.lviews.noSuchRecord);
                }
                else{
                    let newItems = [];
                    for(var i = 0; i < permissionsData.mdl.length; i++){
                        add_disable = false;
                        read_disable = false;
                        edit_disable = false;
                        delete_disable = false;
                        if(permissionsData.mdl[i].permissions.length != 4){
                            add_disable = true;
                            read_disable = true;
                            edit_disable = true;
                            delete_disable = true;
                            for(var j = 0; j < permissionsData.mdl[i].permissions.length; j++){
                                if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "add"){
                                    add_disable = false;
                                }
                                if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "view"){
                                    read_disable = false;
                                }
                                if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "change"){
                                    edit_disable = false;
                                }
                                if(permissionsData.mdl[i].permissions[j].name.split("_").pop() == "delete"){
                                    delete_disable = false;
                                }
                            }
                        }

                        let structuredPermissions = this.getGroupPermissions(groupData, permissionsData.mdl[i].permissions);
                        newItems.push({
                            id: id,
                            module: permissionsData.mdl[i][lang],
                            add: structuredPermissions.add,
                            read: structuredPermissions.view,
                            edit: structuredPermissions.change,
                            delete: structuredPermissions.delete, 
                            add_disabled: add_disable, 
                            read_disabled: read_disable, 
                            edit_disabled: edit_disable, 
                            delete_disabled: delete_disable,
                            is_open: false,
                        });
                        var check = false;
                        var indeterminate = false;
                        if((add_disable||structuredPermissions.add) && (read_disable||structuredPermissions.view) && (edit_disable||structuredPermissions.change) && (delete_disable||structuredPermissions.delete)){
                            check = true;
                        }
                        else if(!structuredPermissions.add && !structuredPermissions.view && !structuredPermissions.change && !structuredPermissions.delete){
                            check = false;
                        }
                        else{
                            indeterminate = true;
                        }
                        this.globalCheckboxes.push(
                            {
                                check:check,
                                indeterminate:indeterminate
                            }
                        );
                        var items = [];
                        if(!add_disable){items.push({
                            name:this.lviews.add,
                            value: "add",
                            check: structuredPermissions.add,
                        })}
                        if(!read_disable){items.push({
                            name:this.lviews.read,
                            value: "read",
                            check: structuredPermissions.view,
                        })}
                        if(!edit_disable){items.push({
                            name:this.lviews.edit,
                            value: "edit",
                            check: structuredPermissions.change,
                        })}
                        if(!delete_disable){items.push({
                            name:this.lviews.delete,
                            value: "delete",
                            check: structuredPermissions.delete,
                        })}
                        var getSelectedItems = [];
                        if(structuredPermissions.add){getSelectedItems.push({
                            name:this.lviews.add,
                            value: "add",
                            check: structuredPermissions.add,
                        })}
                        if(structuredPermissions.view){getSelectedItems.push({
                            name:this.lviews.read,
                            value: "read",
                            check: structuredPermissions.view,
                        })}
                        if(structuredPermissions.change){getSelectedItems.push({
                            name:this.lviews.edit,
                            value: "edit",
                            check: structuredPermissions.change,
                        })}
                        if(structuredPermissions.delete){getSelectedItems.push({
                            name:this.lviews.delete,
                            value: "delete",
                            check: structuredPermissions.delete,
                        })}
                        this.selectItems[id] = {
                            items:items,
                            selectedItems: getSelectedItems
                        }
                        id = id+1;
                    }
                    this.dataTableItems = newItems;
                    this.roleName = groupData.name;
                    this.nameDisabled = true;
                }
            }
        }
    },
    mounted() {
        this.$emit('set-title', this.lviews.mdlConfigTitle);
        this.$emit('set-display', 'BACK_WINDOW');
        let userPermissions = localStorage.getItem("user_permissions");
        if (!userPermissions.includes("fob.admin_view")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return;
        } 

        this.fetchPermissionsData();
    },
    computed: {
        lviews: {
            get: function() {
                return this.$t('views.mdlConfig');
            }
        }
    },
    beforeMount() {
        this.screenWidth = window.innerWidth;

        this.dataTableHeaders[0].text = this.lviews.module;
        this.dataTableHeaders[1].text = this.lviews.add;
        this.dataTableHeaders[2].text = this.lviews.read;
        this.dataTableHeaders[3].text = this.lviews.edit;
        this.dataTableHeaders[4].text = this.lviews.delete;

    },
}
</script>
<style>
#dataTable table thead th {
  height: 32px;
  margin:0px;
  padding:0px;
}
#dataTable table thead tr {
  background: #53d16b;
}
#dataTable table thead th {
  font-weight: bold;
}
#dataTable table tbody tr td:nth-child(1) {
  background: lightgray;
  border-right: 1px solid rgb(84, 84, 84);
}
#dataTable table tbody tr td {
  border-bottom: 1px solid rgb(146, 141, 141);
}
</style>
