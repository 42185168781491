<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<postal-address-edit-dialog
  ref='yourReference'

  ...
></postal-address-edit-dialog>
$refs.youReference.openDialog();

props:
  - countriesDictionary, dictionary of countries for select
	{
	  key: {String} short country name (id)
	  text: {String} full country name
	}

  - locationsDictionary, dictionary of predefined locations to select from [optional]
	{
	  id: {Number}
	  street: {String}
	  streetNumber: {String}
	  propertyNumber: {String}
	  zipCode: {String}
	  city: {String}
	  country: {String} (short country name, id)
	  description: {String} display name of the location in the select
	}

  - title {String} [optional]

  - address, default address to display [optional]
	{
	  street: {String}
	  streetNumber: {String}
	  propertyNumber: {String}
	  zipCode: {String}
	  city: {String}
	  country: {String} (short country name, id)
	}

  - selectAddress {Number}, id of default address to select [optional]

  - manualInput {Boolean} determines state of dialog as DEFAULT
  - selectInput {Boolean} determines state of dialog as SELECT
  - manualAndSelectInput {Boolean} determines state of dialog as MIXED

events:
  - confirm
  - error

errors:
  structure: {
	errorCodeShort,
	errorCodeLong,
	title,
	message,
	details
  }

  errorsId: PSTAD

-->
<template>
	<v-layout row justify-center>
		<v-dialog persistent v-model="dialog" max-width="350">
			<v-card>
				<v-card-title class="headline">{{ computedTitle }}</v-card-title>

				<v-card-text>
					<v-layout justify-space-between row wrap>
						<v-flex xs12 v-if="selectInputEnabled">
							<v-select :clearable="manualInputEnabled"
								v-model="computedSelectedLocationValue"
								:items="locationsDictionary"
								:label="lview.dictionaryLocation"
								item-value="id"
								item-text="description"
								item-color="green accent-4"
								color="rgba(4, 202, 90, 1)"
								:error="selectLocation.error"
								:error-messages="selectLocation.errorMessage == null ? [] : selectLocation.errorMessage"
								@change="onSelectDictionaryLocation">
							</v-select>
						</v-flex>

						<!-- Street -->
						<v-flex xs12>
							<v-text-field :label="lview.streetName"
										  v-model="fields.street.value"
										  :error="fields.street.error"
										  :error-messages="fields.street.errorMessage == null ? [] : fields.street.errorMessage"
										  :disabled="!manualInputEnabled"
										  @change="onFieldChange(fields.street)"></v-text-field>
						</v-flex>

						<!-- Street number, property number -->
						<v-flex xs5 align-center>
							<v-text-field :label="lview.streetNumber"
										  v-model="fields.streetNumber.value"
										  :error="fields.streetNumber.error"
										  :error-messages="fields.streetNumber.errorMessage == null ? [] : fields.streetNumber.errorMessage"
										  :disabled="!manualInputEnabled"
										  @change="onFieldChange(fields.streetNumber)"></v-text-field>
						</v-flex>
						<v-flex xs1 style="line-height:68px; text-align:center; font-size: 18px;">/</v-flex>
						<v-flex xs5>
							<v-text-field :label="lview.propertyNumber"
										  v-model="fields.propertyNumber.value"
										  :error="fields.propertyNumber.error"
										  :error-messages="fields.propertyNumber.errorMessage == null ? [] : fields.propertyNumber.errorMessage"
										  :disabled="!manualInputEnabled"
										  @change="onFieldChange(fields.propertyNumber)"></v-text-field>
						</v-flex>

						<!-- zip code, city, country -->
						<v-flex xs5>
							<v-text-field type="tel"
										  :mask="zipCodeMask"
										  :label="lview.zipCode"
										  v-model="fields.zipCode.value"
										  :error="fields.zipCode.error"
										  :error-messages="fields.zipCode.errorMessage == null ? [] : fields.zipCode.errorMessage"
										  :disabled="!manualInputEnabled"
										  @change="onFieldChange(fields.zipCode)"></v-text-field>
						</v-flex>
						<v-flex xs6>
							<v-text-field :label="lview.city"
										  v-model="fields.city.value"
										  :error="fields.city.error"
										  :error-messages="fields.city.errorMessage == null ? [] : fields.city.errorMessage"
										  :disabled="!manualInputEnabled"
										  @change="onFieldChange(fields.city)"></v-text-field>
						</v-flex>
						<v-flex xs12>
							<v-select required
									  v-model="fields.country.value"
									  :items="countriesDictionary"
									  :label="lview.country"
									  item-value="key"
									  item-text="text"
									  :error="fields.city.error"
									  :error-messages="fields.country.errorMessage == null ? [] : fields.country.errorMessage"
									  :disabled="!manualInputEnabled"
									  @change="onFieldChange(fields.country); onCountryChange()"></v-select>
						</v-flex>
					</v-layout>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1"
						   text
						   @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
					<v-btn color="green darken-1"
						   text
						   @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-layout>
</template>

<script>

	export default {
		name: 'postalAddressEditDialog',
		mounted: function () {
		},
		data() {
			return {
				dialog: false,
				zipCodeMask: undefined,

				countriesSelect: [],

				manualInputEnabled: true,
				selectInputEnabled: false,


				selectLocation: {
					value: null,
					error: false,
					errorMessage: null,
				},
				fields: {
					street: {
						value: null,
						error: false,
						errorMessage: null,
					},
					streetNumber: {
						value: null,
						error: false,
						errorMessage: null,
					},
					propertyNumber: {
						value: null,
						error: false,
						errorMessage: null,
					},
					zipCode: {
						value: null,
						error: false,
						errorMessage: null,
					},
					city: {
						value: null,
						error: false,
						errorMessage: null,
					},
					country: {
						value: null,
						error: false,
						errorMessage: null,
					},
				}
			}
		},
		computed: {
			lview: {
				get: function () {
					return this.$t('views.commons.postalAddressEditDialog');
				}
			},
			lmessages: {
				get: function () {
					return this.$t("commons.messages");
				}
			},
			lbuttons: {
				get: function () {
					return this.$t("commons.buttons");
				}
			},

			computedTitle: {
				get: function () {
					return this.title == null ? this.lview.defaultHeadline : this.title;
				}
			},

			computedSelectedLocationValue: {
				get: function () {
					if (this.selectLocation.value != null) return this.selectLocation.value;

					if (!this.manualInputEnabled) {
						return this.locationsDictionary[0].id;
					}
					return null;
				},
				set: function (value) {
					this.selectLocation.value = value;
				}
			}
		},
		props: {
			countriesDictionary: {
				type: Array,
				required: true
			},

			locationsDictionary: {
				type: Array,
				default: () => [],
			},

			title: {
				type: String,
				default: null
			},

			// Avaible dialog modes
			manualInput: {
				type: Boolean,
				default: false,
			},
			manualAndSelectInput: {
				type: Boolean,
				default: false,
			},
			selectInput: {
				type: Boolean,
				default: false
			},

			address: {
				type: Object,
				default: null,
			},
			selectAddress: {
				type: Number,
				default: null
			}
		},
		watch: {
			computedSelectedLocationValue: function () {
				if (this.computedSelectedLocationValue != null)
					this.assignSelectAddressData();
			}
		},
		methods: {
			openDialog() {
				this.loadPropsData();
				this.$nextTick(() => this.dialog = true);
			},
			closeDialog() {
				this.dialog = false;
			},
			onAcceptDialog() {
				// Validation
				if (this.selectLocation.error || this.selectLocation.errorMessage != null) return;

				var keys = Object.getOwnPropertyNames(this.fields);
				for (var i = 0; i < keys.length; i++) {
					if (this.fields[keys[i]].error || this.fields[keys[i].errorMessage != null]) {
						return;
					}
				}

				var zipCode = null;

				if (this.fields.zipCode.value != null && this.fields.zipCode.value != '') {
					switch (this.fields.country.value) {
						case "PL":
							var front = this.fields.zipCode.value.slice(0, 2);
							var back = this.fields.zipCode.value.slice(2, 5);
							zipCode = front + "-" + back;
							break;
						case "DE":
							zipCode = this.fields.zipCode.value;
							break;
						default:
							zipCode = this.fields.zipCode.value;
					}
				}

				var feedback = {
					street: this.fields.street.value,
					streetNumber: this.fields.streetNumber.value,
					propertyNumber: this.fields.propertyNumber.value,
					zipCode: zipCode,
					city: this.fields.city.value,
					country: this.fields.country.value
				};

				if (this.selectInputEnabled) {
					feedback.addressId = this.computedSelectedLocationValue;
				}

				this.$emit('confirm', feedback);

				this.closeDialog();
			},

			loadState() {
				var count = 0;
				var mode = null;

				if (this.manualInput) {
					count++;
					mode = "DEFAULT";
				}
				if (this.manualAndSelectInput) {
					count++;
					mode = "MIXED";
				}
				if (this.selectInput) {
					count++;
					mode = "SELECT";
				}

				if (count == 0) mode = "DEFAULT";
				else if (count > 1) {
					throw new Error("Multiple dialog states assigned in PostalAddressEditDialog. Pick one only!");
				}

				switch (mode) {
					case "MIXED":
						this.selectInputEnabled = true;
						this.manualInputEnabled = true;
						break;

					case 'SELECT':
						if (this.locationsDictionary.length == 0) {
							throw new Error("Locations Dictionary cannot be empty for state \"selectInput\". Assign proper locations dictionary!")
						}

						this.selectInputEnabled = true;
						this.manualInputEnabled = false;
						break;

					default:
						this.selectInputEnabled = false;
						this.manualInputEnabled = true;
				}
			},

			loadPropsData() {
				this.manualInputEnabled = true;
				this.selectInputEnabled = false;

				this.selectLocation.value = this.selectAddress;
				this.selectLocation.error = false;
				this.selectLocation.errorMessage = null;

				// Reseting address fields
				var keys = Object.getOwnPropertyNames(this.fields);
				keys.forEach((key) => {
					this.fields[key].value = null;
					this.fields[key].error = false;
					this.fields[key].errorMessage = null;
				});

				// Setting address fields to provided in props
				if (this.address != null) {
					if (typeof this.address.country !== 'undefined') {
						this.fields.country.value = this.address.country;
						this.onCountryChange();
					}

					keys = Object.getOwnPropertyNames(this.address);
					keys.forEach((key) => this.fields[key].value = this.address[key]);
				}

				// Reading dialog state
				this.loadState();

				if (this.selectInputEnabled) {
					this.assignSelectAddressData();
				}

				this.onCountryChange();
			},

			/**
			 * Assigns fields values to values of address with given id
			 */
			assignSelectAddressData() {
				var loc = this.getDictionaryLocation(this.computedSelectedLocationValue);
				if (loc == null) {
					this.selectLocation.errorMessage = this.lview.invalidLocation;
					this.$emit(
						'error',
						{
							errorCodeShort: 'PSTAD#1',
							errorCodeLong: 'MISSING_LOCATION',
							title: this.lview.missingLocationErrorTitle,
							message: this.lview.missingLocationError,
							details: null
						}
					);
					return;
				}

				var keys = Object.getOwnPropertyNames(this.fields);
				keys.forEach((key) => {
					this.fields[key].value = (typeof loc[key] === 'undefined') ? null : loc[key];
				});
			},

			getDictionaryLocation(id) {
				for (var i = 0; i < this.locationsDictionary.length; i++) {
					if (this.locationsDictionary[i].id == id) return this.locationsDictionary[i];
				}

				return null;
			},

			onSelectDictionaryLocation() {
				this.selectLocation.error = false;
				this.selectLocation.errorMessage = null;
				this.assignSelectAddressData();
			},

			onFieldChange(field) {
				field.error = false;
				field.errorMessage = null;
				if (this.selectInputEnabled) {
					this.selectLocation.value = null;
				}
			},

			onCountryChange() {
				switch (this.fields.country.value) {
					case "PL":
						this.zipCodeMask = "##-###";
						break;
					case "DE":
						this.zipCodeMask = "#####";
						break;
					default:
						this.zipCodeMask = undefined;
				}
			}
		}
	}

</script>