<template>
    <v-layout class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="min-height: 70px; max-height: 140px;" justify-end>
        <div v-if="screenWidth >= 500" style="display: flex; margin-right: 65px;">
            <div style="padding-top: 12px; margin-right: 12px; font-size: 12px;"> {{ lviews.rowsPerPage }} : </div>
            <v-select
                v-model="itemsPerPage"
                :items="itemsPerPageOptions"
                style="max-width: 70px; padding-top: 0px; margin-right: 35px;"
                @change="changeItemsPerPageNumber"
            ></v-select>
    
            <div style="display: flex;">
                <div style="padding-top: 12px; margin-right: 12px; font-size: 12px;"> {{ firstItemIndex }} - {{ lastItemIndex}} {{ lviews.of }} {{ totalItemsNumber }}</div>

                <v-btn text fab small @click="previousPage" :disabled="pageNumber == 1"> <v-icon> mdi-chevron-left </v-icon> </v-btn>
                <v-btn text fab small @click="nextPage" :disabled="pageNumber >= lastPageNumber"> <v-icon> mdi-chevron-right </v-icon> </v-btn>
            
                <!-- <v-speed-dial
                    v-model="fab"
                    :top="top"
                    :bottom="bottom"
                    :right="right"
                    :left="left"
                    :direction="direction"
                    :open-on-hover="hover"
                    :transition="transition"
                    style="margin-top: 10px; margin-left: 35px; margin-right: 7px;"
                    :class="speedDialClass"
                >
                    <template v-slot:activator>
                        <v-btn
                            v-model="fab"
                            color="primary"
                            fab
                            v-if="canUserAddNewDocuments()"
                        >
                            <v-icon v-if="fab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-plus 
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-tooltip left color="white">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" @click="$emit('redirect-to-import-documents-view')" fab small> 
                                <v-icon> mdi-briefcase-upload </v-icon> 
                            </v-btn>
                        </template>
                        <span style="color: black;"> {{ lviews.importDocuments }} </span>
                    </v-tooltip>

                    <v-tooltip left color="white">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" @click="$emit('open-add-new-document-for-user-dialog')" fab small> 
                                <v-icon> mdi-plus </v-icon> 
                            </v-btn>
                        </template>
                        <span style="color: black;"> {{ lviews.addNewDocument }} </span>
                    </v-tooltip>
                </v-speed-dial> -->
            </div>
        </div>

        <v-flex flex-column v-if="screenWidth < 500">
            <div style="display: flex; float: right;">
                <div style="padding-top: 12px; margin-right: 12px; font-size: 12px;"> {{ lviews.rowsPerPage }} : </div>
                <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageOptions"
                    style="max-width: 70px; padding-top: 0px;"
                    @change="changeItemsPerPageNumber"
                ></v-select>
            </div>

            <div style="display: flex; float: right; margin-right: 65px;">
                <div style="padding-top: 12px; margin-right: 12px; font-size: 12px;"> {{ firstItemIndex }} - {{ lastItemIndex}} {{ lviews.of }} {{ totalItemsNumber }}</div>
                <v-btn text fab small @click="previousPage" :disabled="pageNumber == 1"> <v-icon> mdi-chevron-left </v-icon> </v-btn>
                <v-btn text fab small @click="nextPage" :disabled="pageNumber >= lastPageNumber"> <v-icon> mdi-chevron-right </v-icon> </v-btn>
            
                <!-- <v-speed-dial
                    v-model="fab"
                    :top="top"
                    :bottom="bottom"
                    :right="right"
                    :left="left"
                    :direction="direction"
                    :open-on-hover="hover"
                    :transition="transition"
                    style="margin-top: 10px; margin-left: 35px; margin-right: -15px;"
                    :class="speedDialClass"
                >
                    <template v-slot:activator>
                        <v-btn
                            v-model="fab"
                            color="primary"
                            fab
                            v-if="canUserAddNewDocuments()"
                        >
                            <v-icon v-if="fab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-plus 
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-tooltip left color="white">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" @click="$emit('redirect-to-import-documents-view')" fab small> 
                                <v-icon> mdi-briefcase-upload </v-icon> 
                            </v-btn>
                        </template>
                        <span style="color: black;"> {{ lviews.importDocuments }} </span>
                    </v-tooltip>

                    <v-tooltip left color="white">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" @click="$emit('open-add-new-document-for-user-dialog')" fab small> 
                                <v-icon> mdi-plus </v-icon> 
                            </v-btn>
                        </template>
                        <span style="color: black;"> {{ lviews.addNewDocument }} </span>
                    </v-tooltip>
                </v-speed-dial> -->
            </div>
        </v-flex>
    </v-layout>
</template>

<script>

export default {
    data: function() {
        return {
            innerPageNumber: null,
            itemsPerPage: null,
            screenWidth: null,
        }
    },
    props: {
        lviews: {
            type: Object,
            required: true
        },
        itemsPerPageOptions: {
            type: Array,
            required: true
        },
        pageNumber: {
            type: Number,
            required: true
        },
        itemsPerPageNumber: {
            type: Number,
            required: true
        },
        totalItemsNumber: {
            type: Number,
            required: true
        },
    },
    methods: {
        canUserAddNewDocuments: function() {
            let myPermissions = localStorage.getItem('user_permissions').split(',');
            if (myPermissions.includes("fob.worker_docs_add")) {
                return true;
            }
            return false;
        },

        changeItemsPerPageNumber: function() {
            this.$emit('update-page-size', this.itemsPerPage);
        },

        previousPage: function() {
            this.innerPageNumber--;
            this.$emit('update-page-number', this.innerPageNumber);
        },

        nextPage: function() {
            this.innerPageNumber++;
            this.$emit('update-page-number', this.innerPageNumber);
        },

        handleWindowResize: function(){
            this.screenWidth = window.innerWidth;
        }
    },
    watch: {
        itemsPerPageNumber: {
            handler() {
                this.itemsPerPage = this.itemsPerPageNumber;
            }
        },
        pageNumber: {
            handler() {
                this.innerPageNumber = this.pageNumber;
            }
        },
    },
    computed: {
        paginationRanges: {
            get() {
                let ranges = [];
                if (this.totalItemsNumber == 0) {
                    ranges.push({
                        start: 0,
                        end: 0
                    });
                }
                else {
                    let firstIdx = 1, lastIdx = this.itemsPerPageNumber;
                    while(lastIdx <= this.totalItemsNumber) {
                        ranges.push({
                            start: firstIdx,
                            end: lastIdx
                        });

                        firstIdx += this.itemsPerPageNumber;
                        lastIdx += this.itemsPerPageNumber;
                    }

                    if (firstIdx <= this.totalItemsNumber) {
                        ranges.push({
                            start: firstIdx,
                            end: this.totalItemsNumber
                        });
                    }
                }
                return ranges;
            }
        },
        lastPageNumber: {
            get: function() {
                var lastPNum = Math.round(this.totalItemsNumber / this.itemsPerPageNumber);
                if (lastPNum == 0) {
                    lastPNum = 1;
                }

                if (lastPNum * this.itemsPerPageNumber < this.totalItemsNumber){
                    lastPNum++;
                }
                if (lastPNum < this.pageNumber) {
                    this.$emit('update-page-number', lastPNum);
                }
                return lastPNum;
            }
        },
        firstItemIndex: {
            get: function() {
                if (this.pageNumber > this.lastPageNumber) {
                    this.$emit('update-page-number', this.lastPageNumber);
                    return this.paginationRanges[this.lastPageNumber - 1].start;
                }
                else {
                    return this.paginationRanges[this.pageNumber - 1].start;
                }
            }
        },
        lastItemIndex: {
            get: function() {
                if (this.pageNumber > this.lastPageNumber) {
                    this.$emit('update-page-number', this.lastPageNumber);
                    return this.paginationRanges[this.lastPageNumber - 1].end;
                }
                else {
                    return this.paginationRanges[this.pageNumber - 1].end;
                }
            }
        },
    },
    destroyed: function(){
        window.removeEventListener('resize', this.handleWindowResize);
    },

    mounted: function() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        this.itemsPerPage = this.itemsPerPageNumber;
        this.innerPageNumber = this.pageNumber;
    }
}
</script>

<style scoped>
    /*@keyframes displayoverlay {
        0%  { display: none; opacity: 0.0; },
        1% { display: block; opacity: 0.0; },
        100% { display: block; opacity: 1.0; }
    }

    .worker-documents-overlayer-for-fab {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgb(33,33,33);
        left: 0;
        top: 0;
        z-index: 15;
        visibility: hidden;
        opacity: 0.0;

        transition: visibility 0.2s, opacity 0.2s linear;
    }
    .worker-documents-overlayer-for-fab.overlayer-for-fab-active {
        visibility: visible;
        opacity: 0.46;
    }
    .worker-documents-speed-dial{
        z-index: 0;
    }
    .worker-documents-speed-dial.speed-dial-active {
        z-index: 20;
    }*/
</style>