/* eslint-disable no-console */

import {
    register
} from 'register-service-worker'

import fob_config from './fob_config.js'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                '----- FOB SW READY -----'
            )
        },
        registered(registration) {
            console.log('---- FOB UPDATER HAS BEEN REGISTERED ver: ' + fob_config.service_worker_ver + ' ---- ')
            setInterval(() => {
                console.log('CHECKING FOR NEW CONTENT..')

                registration.update();
            }, fob_config.service_worker_update_timout);
        },
        cached() {

        },
        updatefound(registration) {
            console.log('**** New content is available !! ******');
            document.dispatchEvent(
                new CustomEvent('swUpdated', {
                    detail: registration
                })
            );

        },
        updated(registration) {
            console.log('-----> Content updated!')
            document.dispatchEvent(
                new CustomEvent('swUpdated', {
                    detail: registration
                })
            );
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}


// NEW VERSION SW
/*if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('Service worker is active.');
        },
        registered(registration) {
            console.log('Service worker has been registered.');

            // Routinely check for app updates by testing for a new service worker.
            setInterval(() => {
                registration.update();
            }, fob_config.service_worker_update_timout);
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; please refresh.');

            document.dispatchEvent(
                new CustomEvent('swUpdated', { detail: registration })
            );
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
*/