// i18n is the name of multilanguage vue library
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import langPL from './lang/pl.js'
import langEN from './lang/en.js'
import langDE from './lang/de.js'

Vue.use(VueI18n);
const messages = {
  en: langEN,
  pl: langPL,
  de: langDE
};

export default new VueI18n({
  locale: 'pl',
  messages,
});