<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Tomasz Drzewiecki"
__maintainer__ = "Tomasz Drzewiecki"
__email__ = "tomasz@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<send-employee-message-dialog
  ref="yourReference"

  ...
></send-employee-message-dialog>
$refs.yourReference.openDialog();

props: << TODO - FIX IT >>
  - workers {Array}, determines worker ids to modify in the database
    [
      {
        workerId,
        managerId,
        projectIds
      }
    ]

  - managersDictionary {Array}, determines managers dictionary for reassigning worker's manager [optional]
    [
      {
        managerId {Number}, manager id,
        firstName {String},
        middleName {String} [optional],
        lastName {String}
      }
    ]

  - defaultManagerId {Number}


events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - error

errors:
  structure: {
    errorCodeShort,
    errorCodeLong,
    title,
    message,
    details
  }

  errorsId: CHNGEMP
-->

<template>
    <div>
        <v-dialog persistent v-model="visible" max-width="290">
            <v-card dense class="SendEmployeeMessageDialog">
                <!-- Header -->
                <v-card-title style="text-align:center; padding-bottom:0">
                    <span class="headline">{{ lview.title }}</span>
                </v-card-title>

                <!-- Controls -->
                <v-card-text style="text-align:justify; height: 240px; margin-bottom: 50px;">
                    <!--:value="editDialogs.extraInformation.value"
                     -->
                    <v-textarea :value="textMessage"
                                v-model="textMessage"
                                autofocus
                                rounded
                                solo
                                rows="7"
                                row-height="10"
                                counter="160"
                                class="user-profile__textarea"
                                spellcheck="false"
                                placeholder="Krotka wiadomosc tekstowa"
                                :error="textFieldError"></v-textarea>
                </v-card-text>

                <!-- Buttons -->
                <v-card-actions style="margin-top: 30px;">
                    <v-spacer></v-spacer>

                    <v-btn text color="green darken-1" @click="closeDialog()">
                        {{ lview.cancel }}
                    </v-btn>

                    <v-btn text color="green darken-1" @click="sendMessages()" style="font: bold">
                        {{ lview.send }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'changeEmployeePlansDialog',
        mounted: function () {

        },
        data() {
            return {
                visible: false,
                textMessage: "",

                /* TODO - revise */
                changingLocationEnabled: false,
                pickedChangingLocation: null,

                changingManagerEnabled: false,
                pickedChangingManager: null,
                managersSelect: [
                    /*{
                      managerId: 1,
                      displayName: "Jan Kowalski"
                    }*/
                ],

                pickedChangingReason: null,
                selectForChangingReasons: [],

                tableProjects: [
                    /*{
                      id: {Number},
                      name: {String},
                      status: (DEFAULT|REMOVED|ADDED)
                    }*/
                ],
                textFieldError: false,
            }
        },

        /* TODO - revise */
        computed: {
            lview: { get: function () { return this.$t('views.commons.sendEmployeeMessageDialog'); } },
            lbuttons: { get: function () { return this.$t("commons.buttons"); } },
            lmessages: { get: function () { return this.$t("commons.messages"); } },
        },

        props: {
            phoneDictionary: {
                type: Array,
                required: null,
            },

            currentManager: {
                type: Number,
                default: null
            },

            /* TODO - revise */
            managersDictionary: {
                type: Array,
                default: () => []
            },

            // multilineTextMessage: {
            //     type: String,
            //     required: false,
            //     default: null
            // }
        },

        methods: {
            /* Run at window initiation */
            openDialog() {
                this.loadPropsData();
                // this.multilineTextMessage = ' Skontaktuj sie z ...' <- source of vue warn of overwritting prop value (to fix it, we need to use variable in data() either computed() section )
                //console.log(this.phoneDictionary.length);
                this.visible = true;
            },

            /* Close the window */
            closeDialog() {
                this.visible = false;
            },

            /* Send short messages & close the window */
            sendMessages: async function () {
                //this.$emit('input', this.multilineTextMessage = this.textMessage);
                let that = this;
                if (this.textMessage == "" || this.textMessage == null) {
                    this.textFieldError = true;
                    return;
                }
                else {
                    this.textFieldError = false;
                }

                this.$emit('request-sending-start');
                for (var i = 0; i < this.phoneDictionary.length; i++) {
                    axios({
                        method: "POST",
                        url: localStorage.getItem("current_env") + "/api/send_message/",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                        },
                        data: {
                            phone_numbers: this.phoneDictionary[i],
                            short_message: this.textMessage,
                        }
                    })
                    .then(function (response) {
                        window.alert('Wiadomosc zostala wyslana');
                    })
                    .catch(function (error) {
                        that.$emit('request-sending-end');
                        if (error.response.status == 403) {
                            that.$emit('error', {
                                title: that.lmessages.forbiddenOperation,
                                message: that.lmessages.youDoNotHavePriviledgesToDoThatOperation,
                                errorCodeShort: "CNFBRD#5",
                                errorCodeLong: "REQUEST_FAILED",
                                details: String(error)
                            });
                        }
                        //console.log(error);
                    });
                }

                this.$emit('request-sending-end');
                this.closeDialog();

            },

            /* TODO - revise */
            loadPropsData() {
                /* 
                this.pickedChangingLocation = this.defaultProject;
                this.pickedChangingManager = this.defaultManager;

                this.managersSelect.splice(0, this.managersSelect.length);
                this.managersDictionary.forEach((manager) => {
                    this.managersSelect.push({
                        id: manager.managerId,
                        displayName: this.getManagerDisplayName(manager)
                    });
                });
                */
                if (this.multilineTextMessage != null){
                    this.textMessage = this.multilineTextMessage;
                } else {
                    this.textMessage = "";
                }
            },

            /* TODO - revise */
            getManagerDisplayName(manager) {
                var firstName = manager.firstName;
                var middleName = (typeof manager.middleName === 'undefined' || manager.middleName == null) ? "" : " " + manager.middleName;
                var lastName = " " + manager.lastName;

                return firstName + middleName + lastName;
            },
        }
    };
</script>

<style>
    div.ChangeEmployeePlansDialog .projects-table .v-subheader {
        padding: 0;
    }

    div.ChangeEmployeePlansDialog .projects-table .v-list__tile {
        padding: 0;
    }
</style>

<style scoped>
    .projects-table {
        padding: 0;
    }

    .projects-table-title {
        border-bottom: 1px solid rgba(0,0,0,0.12);
        padding: 5px;
    }
</style>
