<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"

<projects-pick-table
    :projectsList="Array"
    ref='yourReference'
>
$refs.yourReference.getChecked();

props:
- value: Number (0.5 - 5.0) (default: null)
- readonly: true/false (default: false)
- size: Number (defualt: 32)

functions:
- getValue()

events:
- updated

-->

<template>
    <div class="text-center">
        <div style="display:inline-block">
            <v-layout style="margin: 0 auto;" class="text-center">
                <v-btn icon style="margin-top:1.25px;"
                    :color="ratingValue == null ? 'red' : 'grey lighten-2'"
                    @click="ratingValue = null"
                >
                    <v-icon :size="size">mdi-close</v-icon>
                </v-btn>
                <v-rating
                    v-model="ratingValue"
                    :length="5"
                    :empty-icon="(ratingValue == null) ? 'star_outline' : 'star'"
                    full-icon="star"
                    half-icon="star_half"
                    :half-increments="true"
                    :hover="false"
                    :readonly="readonly"
                    color="orange"
                    background-color="grey lighten-2"
                    dense
                    :size="size"
                    :style="'text-align: center; height: ' + (size) + 'px; padding-top:4.61px, overflow:visible'"
                ></v-rating>
            </v-layout>
        </div>
        <div>
            <b>{{ lview.rating }}</b>: <span v-if="ratingValue != null">{{ ratingValue.toFixed(1) }}/5.0</span> <span v-else>{{ lview.none }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "nullableRating",
    data: () => ({
        ratingValue: null
    }),
    mounted: function() {
        this.ratingValue = this.value;
    },
    beforeMount: function() {

    },
    watch: {
        "ratingValue": function(newVal, oldVal) {
            // For v-model property
            this.$emit('input', this.ratingValue);
        },
        "value": function(newVal, oldVal){
            this.ratingValue = this.value;
        }
    },
    computed: {
        lview: {
            get: function(){
                return this.$t('views.commons.nullableRating');
            }
        },
    },
    props: {
        value: {
            type: Number,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        size: {
            type: Number,
            default: 32
        }
    },
    methods: {
        getValue(){
            return this.ratingValue;
        }
    }
}
</script>