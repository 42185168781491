var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticStyle:{"margin-top":"10px"}},[_c('v-data-table',{attrs:{"headers":_vm.dataTableHeaders,"items":_vm.dataTableItems,"id":"dataTable","item-key":"id","group-by":"module","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-flex',{staticStyle:{"padding-top":"10px"},attrs:{"xs12":""}},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('v-text-field',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12",staticStyle:{"height":"66px"},attrs:{"label":_vm.lviews.roleName,"disabled":_vm.nameDisabled,"clearable":""},model:{value:(_vm.roleName),callback:function ($$v) {_vm.roleName=$$v},expression:"roleName"}})],1)])]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"6"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(items[0].module)+" "),_c('v-icon',{on:{"click":function($event){return _vm.checkAllInModule(items[0].module)}}},[_vm._v(_vm._s('mdi-check-all')+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.has_projects)?_c('v-icon',{on:{"click":function () { _vm.clickedProject(item); }}},[_vm._v(_vm._s(item.is_open ? 'mdi-arrow-up' : 'mdi-arrow-down')+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.add",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.add_disabled},model:{value:(item.add),callback:function ($$v) {_vm.$set(item, "add", $$v)},expression:"item.add"}})]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.read_disabled},model:{value:(item.read),callback:function ($$v) {_vm.$set(item, "read", $$v)},expression:"item.read"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.edit_disabled},model:{value:(item.edit),callback:function ($$v) {_vm.$set(item, "edit", $$v)},expression:"item.edit"}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.delete_disabled},model:{value:(item.delete),callback:function ($$v) {_vm.$set(item, "delete", $$v)},expression:"item.delete"}})]}},{key:"item.check_all",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.checkAllInRow(item)}}},[_vm._v(_vm._s('mdi-check-all')+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"width":"20%","padding":"0 0","border-right":"0"},attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.dataTableHeaders,"items":item.projects,"id":"dataTableProjects","item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.add",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.add_disabled},model:{value:(item.add),callback:function ($$v) {_vm.$set(item, "add", $$v)},expression:"item.add"}})]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.read_disabled},model:{value:(item.read),callback:function ($$v) {_vm.$set(item, "read", $$v)},expression:"item.read"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.edit_disabled},model:{value:(item.edit),callback:function ($$v) {_vm.$set(item, "edit", $$v)},expression:"item.edit"}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{staticStyle:{"text-align":"center"},attrs:{"disabled":item.delete_disabled},model:{value:(item.delete),callback:function ($$v) {_vm.$set(item, "delete", $$v)},expression:"item.delete"}})]}},{key:"item.check_all",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.checkAllInRow(item)}}},[_vm._v(_vm._s('mdi-check-all')+" ")])]}}],null,true)})],1)]}}])}),_c('v-layout',{staticClass:"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right",attrs:{"align-center":"","justify-end":""}},[_c('v-btn',{staticClass:"smart-button-width",staticStyle:{"vertical-align":"top","margin-right":"20px"},attrs:{"primary":"","color":"white"},on:{"click":function($event){return _vm.rejectRoleChanges()}}},[_vm._v(" "+_vm._s(_vm.lviews.cancel)+" ")]),_c('v-btn',{staticClass:"smart-button-width",staticStyle:{"vertical-align":"top"},attrs:{"primary":"","color":"primary"},on:{"click":function($event){return _vm.saveRoleChanges()}}},[_vm._v(" "+_vm._s(_vm.lviews.save)+" ")])],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }