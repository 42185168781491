<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<resign-dialog
  ref='yourReference'

  ...
></resign-dialog>
$refs.youReference.openDialog();

props:
  - workers {Array}, determines worker ids to modify in the database
  {
	  id {Number},
	  workerId {Number},
    boardings {Array}
    {
      boardingId: {Number},
      projectId: {Number}
    }
	}
  - assignedProjects {Array}, project to display as updatable
	[
	  {
		id: {Int},
		name: {String}
	  }
	]
  - markedProjectsIds {Array}, Ids of projects to mark as picked

events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - error

errors:
  structure: {
    errorCodeShort,
    errorCodeLong,
    title,
    message,
    details
  }

  errorsId: RMBD

-->

<template>
  <v-dialog persistent v-model="visible" max-width="290">
    <v-card dense>
      <div class="OffBoardingDialog">
        <v-card-title style="text-align:center; padding-bottom:0">
          <span class="headline">{{ lview.title }}</span>
        </v-card-title>

        <v-card-text>
          <v-card elevation="1" align-center class="projects-table">
            <v-card-title primary-title class="projects-table-title" style="position:relative">
                {{ lview.chooseProjects }}
            </v-card-title>

            <v-list
              style="width:100%; max-height: 110px; overflow-y: auto; overflow-x: hidden"
            >
              <v-list-item style="padding:0 5px" v-for="project in tableProjects" :key="project.id">
                  <v-flex xs12>
                      <v-checkbox
                        v-model="checkboxes[project.id.toString()]"
                        color="rgba(4, 202, 90, 1)"
                        style="margin-top: 0; margin-bottom: 0;"
                        :label="project.name"
                      ></v-checkbox>
                  </v-flex>
              </v-list-item>
            </v-list>
          </v-card>

          <!-- TEMPORARY REMOVAL OF NOT YET COMPLETE FUNCTIONALITY - CODE STABILIZATION
						<v-select v-model="pickedResignReason"
								  :items="selectForResignReasons"
								  :label="lview.reason"
								  style="margin-top: 10px;"></v-select>
          -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
          <v-btn color="green darken-1" text @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  mounted: function(){

  },
  data() {
    return {
      visible: false,
      checkboxes: {},
      tableProjects: []
    }
  },
  computed: {
    lview: { get: function() { return this.$t("views.commons.removeBoardingDialog"); } },
    lbuttons: { get: function() { return this.$t("commons.buttons"); } },
    lmessages: { get: function() { return this.$t("commons.messages"); } },
    lerrors: { get: function() { return this.$t('errors'); } },
  },
  props: {
    workers: {
      type: Array,
      required: true
    },
    assignedProjects: {
      type: Array,
      default: () => []
    },
    markedProjectsIds: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    openDialog() {
      if (this.assignedProjects.length == 0) {
        this.$emit("error", {
          errorCodeShort: "OFFBRD#2",
          errorCodeLong: "NO_PROJECTS_PROVIDED",
          title: this.lview.errorNoProjectsAssignedTitle,
          message: this.lview.errorNoProjectsAssignedMessage,
          details: null
        });
      }

      //console.log(">>>>" + JSON.stringify(this.assignedProjects));
      this.setupCheckboxes();
      this.tableProjects.splice(0, this.tableProjects.length);
      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.tableProjects.push({
          id: this.assignedProjects[i].id,
          name: this.assignedProjects[i].name,
          sortName:
            this.assignedProjects[i].id == -1
              ? "#"
              : this.assignedProjects[i].name
        });

        this.tableProjects.sort((a, b) => {
          if (a.sortName < b.sortName) return -1;
          else if (a.sortName == b.sortName) return 0;
          else return 1;
        });
      }

      this.visible = true;
    },

    closeDialog() {
      this.visible = false;
    },

    onAcceptDialog: async function(){
      this.$emit("request-sending-start");

      var errorOccured = false;
      var workerIds = [];
      var projectIds = [];
      var worker;
      var workers = this.workers;

      for (var key in this.checkboxes) {
        if (this.checkboxes[key]) {
          projectIds.push(parseInt(key));
        }
      }

      // Get boardings to update
			var boardings = this.filterBoardings(projectIds);

      var projectAssignmentIds = [];
      for (var i = 0; i < boardings.length; i++) {
        projectAssignmentIds.push(boardings[i].boarding.projectAssignmentId);
      }

      var responsePromise = axios({
          method: "PATCH",
          url: localStorage.getItem("current_env") + "/api/v2/boardings/projects-assignment/",
          headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("jwt")
          },
          data: {
              projects_assignment_id: projectAssignmentIds,
              boarding: {
                  archived: 1
              }
          }
      });

      var packages = [];
      var errors = [];
      var modified = [];
      /*for(var i = 0; i < boardings.length; i++){
        packages.push({
          worker: boardings[i].worker,
          boarding: boardings[i].boarding,
          promise: axios({
            method: "PATCH",
            url: localStorage.getItem("current_env") + "/api/v2/boardings/" + boardings[i].boarding.projectAssignmentId,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            data: {
              boarding: {
                archived: 1
              }
            }
          })
        });
      }*/

      var response = null;
      try {
          response = await responsePromise;
      } catch (error) {
          this.$emit("request-sending-end");
          errorOccured = true;
          if (error.isAxiosError) {
              if (error.response.status == 403) {
                  this.$emit('error', {
                      title: this.lmessages.forbiddenOperation,
                      message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
                      errorCodeShort: "CNFBRD#5",
                      errorCodeLong: "REQUEST_FAILED",
                      details: String(error)
                  });
              }
              else if (error.response.data != null && error.response.data != undefined) {
                  if (error.response.data.boarding != null && error.response.data.boarding != undefined) {
                      if (error.response.data.boarding.non_field_errors != null && error.response.data.boarding.non_field_errors != undefined) {
                          this.$emit('error', {
                            title: this.lerrors.boarding.non_field_errors.header,
                            message: this.lerrors.boarding.non_field_errors.string,
                            errorCodeShort: this.lerrors.boarding.non_field_errors.code,
                            errorCodeLong: this.lerrors.boarding.non_field_errors.code,
                            details: String(error)
                          });
                      }

                      if (error.response.data.boarding.boarding_status != null && error.response.data.boarding.boarding_status != undefined) {
                          this.$emit('error', {
                            title: this.lerrors.boarding.boarding_status.header,
                            message: this.lerrors.boarding.boarding_status.string,
                            errorCodeShort: this.lerrors.boarding.boarding_status.code,
                            errorCodeLong: this.lerrors.boarding.boarding_status.code,
                            details: String(error)
                          });
                      }
                  }
                  if (error.response.data.projects_assignment_id != null && error.response.data.projects_assignment_id != undefined) {
                    this.$emit('error', {
                      title: this.lerrors.projects_assignment_id.header,
                      message: this.lerrors.projects_assignment_id.string,
                      errorCodeShort: this.lerrors.projects_assignment_id.code,
                      errorCodeLong: this.lerrors.projects_assignment_id.code,
                      details: String(error)
                    });									
                  }
              }
              else {
                  this.$emit('error', {
                      title: this.lview.errorOccuredTitle,
                      message: this.lview.errorOccured,
                      errorCodeShort: "CNFBRD#5",
                      errorCodeLong: "REQUEST_FAILED",
                      details: String(error)
                  });
              }
          }
          else {
              errors.push(error);
              this.$emit('error', {
                  title: this.lview.errorOccured,
                  message: this.lview.errorMessage + packages[i].boarding.boardingId,
                  errorCodeShort: this.lview.errorCodeShort,
                  errorCodeLong: this.lview.errorCodeLong,
                  details: String(error)
              });
          }
      }

      /*for (var i = 0; i < packages.length; i++) {
        try {
          await packages[i].promise;

          modified.push({
            boardingId: packages[i].boarding.boardingId,
            worker: packages[i].worker
          });
        } catch (error) {
            
            
          //console.log(JSON.stringify(error));
        }
      }*/

      this.$emit("request-sending-end");

      if (!errorOccured) {
        for (var j = 0; j < boardings.length; j++) {
            modified.push({
                boardingId: boardings[j].boarding.boardingId,
                worker: boardings[j].worker
            });
        }
        
        this.$emit("request-sending-success", modified);
        this.closeDialog();
      }
    },

    /**
     * Filters boarding for provided project ids
     * 
     * @param {Array} projectIds Array of integers
     * @returns {Array} Array of boardings for provided project ids
     */
    filterBoardings: function(projectIds) {
      var result = [];

      var boarding;
      for(var i = 0; i < this.workers.length; i++){
        for(var j = 0; j < this.workers[i].boardings.length; j++){
          boarding = this.workers[i].boardings[j];

          if(projectIds.indexOf(boarding.projectId) != -1){
            result.push({
              boarding: boarding,
              worker: this.workers[i]
            });
          }
        }
      }

      return result;
    },

    setupCheckboxes() {
      this.checkboxes = {};

      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.checkboxes[this.assignedProjects[i].id] = false;
      }
      //console.log(JSON.stringify(this.markedProjectsIds));
      for (var i = 0; i < this.markedProjectsIds.length; i++) {
        this.checkboxes[this.markedProjectsIds[i]] = true;
      }
    }
  }
}
</script>

<style>
div.OffBoardingDialog .projects-table .v-subheader {
  padding: 0;
}

div.OffBoardingDialog .projects-table .v-list__tile {
  padding: 0;
}
</style>

<style scoped>
.projects-table {
  padding: 0;
}

.projects-table-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
</style>