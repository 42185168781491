import isLoginValid from './auth'
import store from './store.js'

// Add here pages you want to be ignored when validating auth token
export const EXCLUDE_FROM_TOKEN_VALIDATION = [
  'login',
  'Password Reset',
  'First Logging',
  'SettingPassword',
];

function shouldRedirectToLoginPage(currentPage){
  if(EXCLUDE_FROM_TOKEN_VALIDATION.indexOf(currentPage) == -1){
    return !isLoginValid();
  }

  return false;
}

export default function beforeRedirect(to, from, next){
  if (localStorage.getItem('user_account_activated') != null){
    let user_account_activated = JSON.parse( localStorage.getItem('user_account_activated') );
    if (!user_account_activated){
      throw "ACCOUNT_INACTIVE";
    }
  }
  if(shouldRedirectToLoginPage(to.name)){
    console.log(">>>>> Token expired, logging out, performing redirection to login page");
    if(shouldRedirectToLoginPage(from.name)){
      store.dispatch("logout");

      var errDict = {
        error: "LOGIN_REQUIRED",
        next: to.fullPath
      };
      if (typeof(to.query.usernameonloginrequired) !== 'undefined'){
        errDict.username = to.query.usernameonloginrequired;
      }

      throw errDict;
    }
    throw "REDIRECTION_CANCELLED";
  }
  next();
}