<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.4"
__author__ = "Marcin Roszczyk, Tomasz Drzewiecki"
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->

<template>
	<div class="about" style="padding:15px">
		<!-- <h4> {{ app_name }}</h4> -->
		<p>{{ lview.header }}</p>
					
		<div class="demo-card-square mdl-card mdl-shadow--2dp">
			<div class="mdl-card__actions mdl-card--border">
				<a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
				   href="https://www.fastonboarding.pl/pl/kontakt/" target="_blank">
					{{ lview.contact }}
				</a>
			</div>
			<br>

			<div class="mdl-card__supporting-text">
				{{ lview.version }}: {{ version }}
				<p>API: {{ current_env }}</p>
			</div>
			<div class="mdl-card__supporting-text">
				(C) Shared Masters 2021
			</div>

			<div class="mdl-card__actions mdl-card--border">
				<a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
				   href="https://www.fastonboarding.pl/pl/polityka-prywatnosci/" target="_blank">
					{{ lview.privacyPolicy }}
				</a>
			</div>

			<div class="mdl-card__actions mdl-card--border">
				<a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
				   href="https://www.fastonboarding.pl/" target="_blank">
					{{ lview.learnMore }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'about',
		mounted: function () {
			this.$emit('set-title', this.lview.pageTitle);
		},
		data() {
			return {
				logged_in: 'Użytkownik nie zautentykowany',
				app_name: 'Fast OnBoarding',
				current_env: localStorage.getItem('current_env')
			}
		},
		props: {
			version: {
				type: String,
				required: true,
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.about'); } },
		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	h1,
	h2 {
		font-weight: normal;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #35495E;
	}
</style>
