<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.9_28_09_2019DEV"
__author__ = "Marcin Roszczyk, Jan Przychodniak"
__maintainer__ = "Marcin Roszczyk"
__email__ = "marcin@sharedmasters.com"
__status__ = "DEV"

-->

<template>
    <v-app id="app">
        <span v-if="isLoggedIn">
            <v-toolbar
                color="primary"
                dark
                class="app-top-toolbar left-offset100"
                v-if="!isMarkedRecordOnDisplayedList"
                :style="customTopBarStyle"
            >
                <v-app-bar-nav-icon class="mobile-only" v-if="topToolbarAsMenu" 
                    @click.stop="mobileDrawer = !mobileDrawer">
                </v-app-bar-nav-icon>
                <v-btn v-else icon @click="goBack()">
                    <v-icon light>arrow_back</v-icon>
                </v-btn>
                <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

                <v-spacer></v-spacer>
                
                <!-- Actions defined by sub view -->
                
                <div v-for="(action, index) in actions" v-bind:key="index">
                    <v-btn :disabled="!onlineStatus" icon v-if="action.isIcon" v-on:click="action.onClick">
                        <v-icon>{{ action.value }}</v-icon>
                    </v-btn>

                    <v-btn :disabled="!onlineStatus" v-else-if="action.value!=null" v-on:click="action.onClick">{{ action.value }}</v-btn>
                </div>
            
                <!-- More actions defined by sub view -->
                <v-menu v-if="moreActions.length > 0">
                    <template v-slot:activator="{ on }">
                        <v-btn dark icon v-on="on" style="margin-left:-2px">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-tile v-for="(item, i) in moreActions" :key="i">
                            <v-list-tile-title :disabled="!onlineStatus" v-on:click="item.onClick">{{ item.name }}</v-list-tile-title>
                        </v-list-tile>
                    </v-list>
                </v-menu>
            </v-toolbar>

            <v-navigation-drawer temporary v-model="mobileDrawer" class="mobile-only" fixed style="z-index: 12;">
                <!--
                <div style="position:relative; height:100%; width:100%;">
                    <v-layout row adjust-center align-center class="navigation-drawer__header">
                        <v-flex offset-xs1 >
                            <h1 class="user-names-label">
                                {{ user.firstName }}
                                <span v-if="user.middleName != null && user.middleName.length > 0">{{ user.middleName }}</span>
                                {{ user.lastName }}
                            </h1>
                            <h2 class="user-role"> {{ user.role }} </h2>
                            <h3 class="user-email">
                                <div v-if="user.email != null && user.email.length > 0">{{ user.email }}</div>
                                <div v-else>{{ lapp.drawer.emailUnassigned }}</div>
                            </h3>
                        </v-flex>
                    </v-layout>

                    <v-divider style="margin-top: 15px;"></v-divider>

                    <v-list dense class="pt-0">
                        <router-link class="navigation-drawer__link"
                            v-for="(item, index) in navigationDrawerItems"
                            v-bind:key="index"
                            :to="item.linkTo"
                        >
                            <v-list-item :key="item.title" @click="onNavigationLinkClick(item.onClick)">
                                <v-list-item-action>
                                    <v-icon>{{ item.leftIcon }}</v-icon>
                                </v-list-item-action>

                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-icon>{{ item.rightIcon }}</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </router-link>
                    </v-list>

                    <div class="navigation-drawer__footer">
                        <v-layout justify-center align-center>
                            <img class="navigation-drawer__footer-img" v-bind:src="'/static/img/ideal_logo.png'" />
                        </v-layout>
                    </div>
                </div>
                -->
                <navigation-side-content
                    ref="navigationSideContent"
                    v-bind:appConfig="appConfig"
                    @navigation-link-clicked="onNavigationLinkClick"
                ></navigation-side-content>
            </v-navigation-drawer>

            <v-navigation-drawer 
                class="desktop-only"
                permanent
                fixed
                v-model="drawer"
            >
                <navigation-side-content
                    ref="navigationSideContent"
                    v-bind:appConfig="appConfig"
                    @navigation-link-clicked="onNavigationLinkClick"
                ></navigation-side-content>
            </v-navigation-drawer>
        </span>

        <!-- Loading overlayer (covers the screen with a shadow and displays a spinner) -->
        <v-layout v-if="state == 'LOADING_OVERLAYER'"
                  class="loading-overlayer"
                  column
                  justify-center
                  align-center>
            <v-progress-circular :size="50" color="rgba(4, 202, 90, 1)" indeterminate></v-progress-circular>

            <div class="title" style="margin-top:10px">{{ loadingOverlayerMessage }}</div>
        </v-layout>

        <!-- Progress overlayer (covers the screen with a shadow and displays progress bar) -->
        <v-layout v-if="state == 'PROGRESS_OVERLAYER'"
                  class="progress-overlayer"
                  column
                  justify-center
                  align-center>
            <div class="title" style="margin-bottom:5px">{{ progressOverlayerMessage }}</div>
            <div class="title">{{ progressBar }}%</div>
            <v-progress-linear v-model="progressBar"
                               color="rgba(4, 202, 90, 1)"
                               style="width:80%; max-width:400px;"></v-progress-linear>
        </v-layout>

        <v-main :style="(isLoggedIn) ? 'margin-top: 56px; overflow-x:auto;' : 'margin-top: 2vh; overflow-x:auto;'" :class="(isLoggedIn) ? 'left-margin' : ''">
            <!-- Global loading -->
            <v-layout v-if="state == 'LOADING'"
                      class="content-middler"
                      column
                      justify-center
                      align-center
                      absolute>
                <v-progress-circular :size="50" color="rgba(4, 202, 90, 1)" indeterminate></v-progress-circular>

                <div class="title" style="margin-top:10px">{{ loadingMessage }}</div>
            </v-layout>

            <!-- Global error -->
            <v-layout v-if="state == 'ERROR'" class="content-middler" column justify-center align-center>
                <v-icon size="100">sentiment_dissatisfied</v-icon>
                <div class="title"
                     style="width:90%; text-align: center; margin-top:10px;">{{ globalErrorMessage }}</div>
            </v-layout>

            <!-- Content -->
            <router-view v-bind:style="{ display: routerViewDisplay, overflowX: 'hidden' }"
                class="app-router-component"
                :tenant="tenant"
                :version="version"
                v-bind:appConfig="appConfig"
                @set-state="setState"
                @set-progress="setProgress"
                @set-display="setDisplay"
                @set-title="setTitle"
                @display-error="addErrorDialog"
                @display-alert="addAlert"
                @set-actions="setActions"
                @set-more-actions="setMoreActions"
                @custom-top-bar-style="setCustomTopBarStyle"
            ></router-view>
        </v-main>

        <!-- Container for default error dialogs -->
        <div ref="errorDialogsContainer" class="text-xs-center">
            <error-dialog v-for="item in errorDialogs"
                          :key="item.id"
                          :title="item.title"
                          :message="item.message"
                          :details="item.details"
                          @closed="removeErrorDialog(item.id)"></error-dialog>
        </div>

        <div ref="alerts" class="alerts-box">
            <alert-with-details :ref="'appAlert' + item.id"
                                class="alerts-box__alert"
                                v-for="item in alerts"
                                :key="item.id"
                                :icon="item.icon"
                                :type="item.type"
                                :text="item.text"
                                :details="item.details"
                                @closed="removeAlert(item.id)"></alert-with-details>
        </div>

        <div>
            <yes-no-dialog
                ref="saveChangesRequestDialog"
                :title="lmessages.goBackTitle"
                :content="lmessages.goBackReloadRequest"
                @yes-click="discardChanges"
                @no-click="cancelGoBack"
            ></yes-no-dialog>
        </div>

        <!--<span v-if="isLoggedIn && applicationMode == 'MANAGER'" :class='(bottomNavModeCheck) ? "" : "mobile-only"'>
            <v-bottom-navigation
                background-color="rgba(4, 202, 90, 1)"
                color="white"
                dark
                style="z-index: 1;"
                fixed
                v-if="bottomNavVisible"
                class="left-offset"
            >-->
                <!--
                    Now you might be wondering, why not just put the whole section of buttons per user type into a single "span" block, and add the v-if there?

                    Try it. I dare you. I double dare you.
                -->

                <!--
                    Bottom navigation for manager (move to a separate file?)
                -->
                
                <!--<v-flex xs4 sm3 md3 lg3 xl3 class="align-self-center" style="text-align:center"
                    v-if='applicationMode == "MANAGER"'
                >
                    <router-link class="bottom-nav__link" to="/boarding">
                        <v-btn color="rgba(4, 202, 90, 1)" text value="boarding" dark>
                            <span :class="boardingButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'"> {{ lapp.bottomNav.boarding }} </span>
                            <v-icon :class="boardingButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'"> check_circle </v-icon>
                        </v-btn>
                    </router-link>
                </v-flex>

                <v-flex xs4 sm6 md6 lg6 xl6
                    v-if='applicationMode == "MANAGER"'
                ></v-flex>

                <v-flex xs4 sm3 md3 lg3 xl3 class="align-self-center" style="text-align:center"
                    v-if='applicationMode == "MANAGER"'
                >
                    <router-link class="bottom-nav__link" to="/employees">
                        <v-btn color="rgba(4, 202, 90, 1)" text value="employees" dark>
                            <span :class="employeesButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'">{{ lapp.bottomNav.employees }}</span>
                            <v-icon :class="employeesButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'"> group </v-icon>
                        </v-btn>
                    </router-link>
                </v-flex>-->


                <!--
                    Bottom navigation for worker (Move to a separate file?)
                -->
                <!--
                <v-flex xs4 sm3 md3 lg3 xl3 class="align-self-center" style="text-align:center"
                    v-if='applicationMode == "WORKER"'
                >
                    <router-link class="bottom-nav__link" to="/user-profile">
                        <v-btn color="rgba(4, 202, 90, 1)" text value="user_profile" dark>
                            <span :class="boardingButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'"> {{ lapp.bottomNav.profile }} </span>
                            <v-icon :class="boardingButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'"> person </v-icon>
                        </v-btn>
                    </router-link>
                </v-flex>

                <v-flex xs4 sm6 md6 lg6 xl6
                    v-if='applicationMode == "WORKER"'
                ></v-flex>

                <v-flex xs4 sm3 md3 lg3 xl3 class="align-self-center" style="text-align:center"
                    v-if='applicationMode == "WORKER"'
                >
                    <router-link class="bottom-nav__link" to="/my-documents">
                        <v-btn color="rgba(4, 202, 90, 1)" text value="documents" dark>
                            <span :class="employeesButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'">{{ lapp.bottomNav.documents }}</span>
                            <v-icon :class="employeesButtonActive? 'bottom-nav-button-clicked':'bottom-nav-button'">mdi-file-document</v-icon>
                        </v-btn>
                    </router-link>
                </v-flex>
                -->
            <!--</v-bottom-navigation>
        </span>-->
    </v-app>
</template>

<script>
    import store from "./store.js";
    import isLoginValid from "./auth";
    import fob_config from "./fob_config";
    import { EXCLUDE_FROM_TOKEN_VALIDATION } from "./before-redirect";

    export default {
        name: "App",
        data: () => ({
            // Available application modes:

            initialPage: true,
            customTopBarStyle: "",

            // - WORKER
            // - MANAGER
            applicationMode: "WORKER",

            tenant: fob_config.app_tenant,
            version: fob_config.app_ver,
            debug: true,

            refreshing: false,
            registration: null,
            updateExists: false,

            drawer: false,
            mobileDrawer: false,
            pageTitle: "Fast OnBoarding",
            pageId: undefined,
            bottomNavActiveButton: undefined,
            bottomNavVisible: true,
            topToolbarAsMenu: true,

            // Determines the state of app
            // - DEFAULT (displays content of desired page)
            // - LOADING_OVERLAYER (places loader over page content)
            // - LOADING (displays loader instead of page content)
            // - PROGRESS_OVERLAYER (displays progress bar over page content)
            // - ERROR (displays error instead of page content)
            state: "DEFAULT",
            routerViewDisplay: "block",
            loadingMessage: null,
            globalErrorMessage: null,
            loadingOverlayerMessage: null,
            onlineStatus: navigator.onLine,

            progressOverlayerMessage: null,
            progressBar: 0,

            // Determines the display of the app
            // - DEFAULT (top and bottom toolbar)
            // - BACK_WINDOW (top toolbar with back button)
            display: "DEFAULT",

            // Action icons on top toolbar
            actions: [
                /*
                    {
                      isIcon: true,
                      value: 'edit',
                      description: 'changing plans'
                      onClick: function() {}
                    }
                    */
            ],
            allActions: [],

            // Three dots on right side of top toolbar
            moreActions: [
                /*{
                    name: "Akcja",
                    isIcon: false,
                    onClick: function() {}
                  }*/
            ],

            links: [
                {
                    name: "Home",
                    to: "/home"
                },
                {
                    name: "Base",
                    to: "/base"
                },
                {
                    name: "Timesheets",
                    to: "/timesheet_list"
                },
                {
                    name: "UserProfile",
                    to: "/user-profile"
                },
                {
                    name: "Login",
                    to: "/login"
                },
                {
                    name: "Logout",
                    to: "/logout"
                },
                {
                    name: "About",
                    to: "/about"
                }
            ],
            user: {
                firstName: null,
                middleName: null,
                lastName: null,
                username: null,
                email: null,
                role: null,
                groups: null
            },

            errorDialogs: [
                /*{
                    id: 1,
                    visible: true,
                    title: "Błąd",
                    message: "Przykładowy błąd",
                    details: "Szczegóły",
                    copyError: ...
                  }*/
            ],

            alerts: [
                /*
                    id {Number},
                    icon {String},
                    type {String},
                    text {String}
                  */
            ],

            boardingButtonActive: true,
            employeesButtonActive: false,
            logoutChecker: null,
        }),
        computed: {
            lapp: {
                get: function () {
                    return this.$t("app");
                }
            },
            lmessages: {
                get: function () {
                    return this.$t("commons.messages");
                }
            },
            lbuttons: {
                get: function () {
                    return this.$t("commons.buttons");
                }
            },

            bottomNavModeCheck() {
                return [ "MANAGER" ].includes(this.applicationMode);
            },

            isLoggedIn() {
                return this.$store.getters.isLoggedIn;
            },

            appConfig: {
                get: function(){
                    return {
                        "applicationMode": this.applicationMode,
                        "user": this.user,
                    }
                }
            },

            isMarkedRecordOnDisplayedList(){
                return this.$store.getters.markedRecord;
            },
            // initializeFirebase() {
            //   initializeFirebase();
            // }
        },
        created() {
            window.addEventListener("resize", this.windowResized);
            document.addEventListener(
                'swUpdated', this.showRefreshUI, { once: true }
            );
            if (navigator.serviceWorker) {  
                navigator.serviceWorker.addEventListener(
                'controllerchange', () => {
                    if (this.refreshing) return;
                    this.refreshing = true;
                    window.location.reload();
                }
                );
            }
        },

        destroyed() {
            window.removeEventListener("resize", this.windowResized);
        },

        watch: {
            $route(to, from) {
                this.initialPage = false;
                this.onRouteUpdate();
            }
        },
        methods: {
            shouldLogoutUser: function() {
                let loginValid = isLoginValid();
                if (!loginValid && !EXCLUDE_FROM_TOKEN_VALIDATION.includes(this.$route.name)) {
                    this.$store.dispatch("logout").then(() => {
                        //console.log("### logged out ###");
                        this.$router.push("/login");
                    });
                }
            },

            windowResized(e) {
                if (window.innerWidth > 800){
                    this.drawer = false;
                }
            },
            showRefreshUI(e) {
                //console.log('------------>>>>> received..')
                /*e.stopPropagation();
                this.registration = e.detail;
                this.updateExists = true;*/
                this.registration = e.detail;
                this.updateExists = true;
            },

            refreshApp() {
                /*window.location.reload();
                this.updateExists = false;
                // if (!this.registration || !this.registration.waiting) {
                //   return;
                // }
                //this.registration.waiting.postMessage("skipWaiting");

                if (this.registration.waiting) {
                    this.registration.waiting.postMessage("skipWaiting");
                }*/
                this.updateExists = false;

                if (!this.registration || !this.registration.waiting) { return; }
                this.registration.waiting.postMessage('skipWaiting');
            },

            onRouteUpdate() {
                this.customTopBarStyle = "";
                var groupsStr = localStorage.getItem('application_user_groups');
                if (groupsStr != null)
                    this.groups = groupsStr.split(',');
                else
                    this.groups = null;

                //console.log(this.groups);
                this.$store.commit('updateMarkedRecordFlag', {isMarked: false})
                var to = this.$router.currentRoute;
                this.hideMenu();
                this.pageId = to.name;

                if (to.name != "error404") this.setActiveBottomNavButton(to.name);
                else this.setActiveBottomNavButton("boarding");

                this.setActions([]);
                this.setMoreActions([]);
                this.setState("default");
                this.setDisplay("default");

                if(to.name == 'boarding'){
                    this.boardingButtonActive = true;
                    this.employeesButtonActive = false;
                } else if (to.name == 'employees'){
                    this.boardingButtonActive = false;
                    this.employeesButtonActive = true;
                } else {
                    this.boardingButtonActive = false;
                    this.employeesButtonActive = false;
                }

                this.whatApplicationUser();

                if (to.name == "error404") {
                    this.addAlert(
                        this.lmessages.pageNotFound,
                        this.lapp.pageNotFoundDetails,
                        undefined,
                        "warning"
                    );
                }
            },

            /*shortScreen(){
                for(var i=0; i<this.actions.length; i++){
                    this.moreActions.push({
                      name: this.actions[i].description,
                      onClick: this.actions[i].onClick,
                      isIcon: this.actions[i].isIcon,
                      value: this.actions[i].value
                    });
                }
                this.actions.splice(0,this.actions.length);
              },

              wideScreen(){
                let invisible = [];
                for(var i=0; i<this.moreActions.length; i++){
                  if(this.moreActions[i].isIcon){
                    this.actions.push({
                      isIcon: this.moreActions[i].isIcon,
                      value: this.moreActions[i].value,
                      description: this.moreActions[i].name,
                      onClick: this.moreActions[i].onClick
                    });
                  }
                  else{
                    invisible.push(this.moreActions[i]);
                  }
                }

                this.moreActions.splice(0,this.moreActions.length);
                invisible.forEach(element => {
                  this.moreActions.push(element);
                });

                //console.log("this.moreActions.length");
                //console.log(this.moreActions.length);
              },*/

            //screenSizing(){
            /*
                  console.log("actions");
                  console.log(this.actions);
                  console.log("moreActions");
                  console.log(this.moreActions);
                */
            /*if(window.innerWidth < 280 && this.actions.length > 0){
                  this.shortScreen();
                  //console.log("wonski ekran");
                }

                else if(window.innerWidth > 280 && this.moreActions.length > 1){
                  this.wideScreen();
                  //console.log("szeeeeroki ekran");
                }*/
            //},

            setState(newState, additionalParams = null) {
                this.state = newState.toUpperCase();
                var isDefault = false;

                switch (this.state) {
                    case "LOADING":
                        this.loadingMessage =
                            typeof additionalParams === "undefined" || additionalParams == null
                                ? this.lmessages.loading
                                : additionalParams;
                        break;
                    case "LOADING_OVERLAYER":
                        this.loadingOverlayerMessage =
                            typeof additionalParams === "undefined" || additionalParams == null
                                ? this.lmessages.loading
                                : additionalParams;
                        isDefault = true;
                        break;
                    case "PROGRESS_OVERLAYER":
                        if (
                            typeof additionalParams === "undefined" ||
                            additionalParams == null
                        ) {
                            this.progressOverlayerMessage = this.lmessages.loading;
                            this.progressBar = 0;
                        } else {
                            if (Array.isArray(additionalParams)) {
                                this.progressOverlayerMessage = additionalParams[0];
                                this.progressBar = additionalParams[1];
                            } else if (typeof additionalParams === "object") {
                                this.progressOverlayerMessage = additionalParams.message;
                                this.progressBar = additionalParams.progress;
                            } else {
                                this.progressOverlayerMessage = additionalParams;
                                this.progressBar = 0;
                            }
                        }
                        isDefault = true;
                        break;
                    case "ERROR":
                        this.globalErrorMessage =
                            typeof additionalParams === "undefined" || additionalParams == null
                                ? this.lmessages.errorOccured
                                : additionalParams;
                        break;
                    default:
                        this.state = "DEFAULT";
                        isDefault = true;
                }

                if (isDefault) this.routerViewDisplay = "block";
                else this.routerViewDisplay = "none";
            },
            setProgress(progress) {
                this.progressBar = progress;
            },
            setDisplay(newDisplay) {
                this.display = newDisplay.toUpperCase();
                switch (this.display) {
                    case "BACK_WINDOW":
                        if(this.initialPage){
                            this.bottomNavVisible = true;
                            this.topToolbarAsMenu = true;
                            this.display = "DEFAULT";
                            break;
                        }

                        this.bottomNavVisible = false;
                        this.topToolbarAsMenu = false;
                        break;
                    default:
                        this.bottomNavVisible = true;
                        this.topToolbarAsMenu = true;
                        this.display = "DEFAULT";
                }

                var to = this.$router.currentRoute;
                var routesWithoutBottomNavNames = ['User Profile'];
                
                if (routesWithoutBottomNavNames.indexOf(to.name) != -1){
                    this.bottomNavVisible = false; // on UserProfile bottom nav should not be visible
                } else {
                    this.bottomNavVisible = true;; // on other views it should be seen
                }
            },
            setTitle(newTitle) {
                this.pageTitle = newTitle;
            },
            setActions(newActions) {
                this.actions = newActions;
            },
            setMoreActions(newActions) {
                this.moreActions = newActions;
            },
            setCustomTopBarStyle(newStyle){
                this.customTopBarStyle = newStyle;
            },

            addErrorDialog(title = null, message = null, details = null) {
                var id;
                if (this.errorDialogs.length > 0) {
                    id = this.errorDialogs[this.errorDialogs.length - 1].id + 1;
                } else id = 0;

                var dialog = {
                    id: id,
                    title: title,
                    message: message,
                    details: details
                };
                this.errorDialogs.push(dialog);

                //this.$nextTick(dialog.visible = true);
                setTimeout(() => (dialog.visible = true));
            },
            copyErrorSuccess() { },
            copyErrorFailed() { },
            removeErrorDialog(dialogId) {
                var dialog;
                for (var i = 0; i < this.errorDialogs.length; i++) {
                    if (this.errorDialogs[i].id == dialogId) {
                        setTimeout(() => {
                            this.errorDialogs.splice(i, 1);
                        }, 200);
                        break;
                    }
                }
            },

            addAlert(text, details = null, icon = undefined, type = undefined) {
                // secure for adding alert with the same text
                for(var i = 0; i < this.alerts.length; i++){
                    if(this.alerts[i].text == text){
                        return;
                    }
                }

                var newAlert = {
                    id: null,
                    icon: icon,
                    type: type,
                    text: text,
                    details: details
                };

                if (this.alerts.length > 0) {
                    newAlert.id = this.alerts[this.alerts.length - 1].id + 1;
                } else {
                    newAlert.id = 0;
                }

                this.alerts.push(newAlert);
            },
            removeAlert(alertId, delay = null) {
                if (delay != null) {
                    setTimeout(() => this.removeAlert(alertId), delay);
                    return;
                }
                for (var i = 0; i < this.alerts.length; i++) {
                    if (this.alerts[i].id == alertId) {
                        this.alerts.splice(i, 1);
                        return true;
                    }
                }
                return false;
            },

            whatApplicationUser: function () {
                this.user.username = localStorage.getItem("application_user_username");

                if (localStorage.getItem("user_profile_first_name") != null) {
                    this.user.firstName = localStorage.getItem("user_profile_first_name");
                } else {
                    this.user.firstName = this.lapp.drawer.unknown;
                }

                if (localStorage.getItem("user_profile_middle_name") != null) {
                    this.user.middleName = localStorage.getItem("user_profile_middle_name");
                } else {
                    this.user.middleName = null;
                }

                if (localStorage.getItem("user_profile_last_name") != null) {
                    this.user.lastName = localStorage.getItem("user_profile_last_name");
                } else {
                    this.user.lastName = this.lapp.drawer.unknown;
                }

                if (localStorage.getItem("user_profile_personal_email") != null) {
                    this.user.email = localStorage.getItem("user_profile_personal_email");
                } else {
                    this.user.email = null;
                }
                
                this.user.role = this.lapp.drawer.manager;
                // if (this.user.middleName === "null") this.user.middleName = null;
                // if (this.user.email === "null") this.user.email = null;
            },
            hideMenu: function () {
                this.drawer = false;
            },
            setActiveBottomNavButton(pageId) {
                switch (pageId) {
                    case "boarding":
                        this.bottomNavActiveButton = "boarding";
                        break;
                    case "employees":
                        this.bottomNavActiveButton = "employees";
                        break;
                    default:
                        this.bottomNavActiveButton = undefined;
                }
            },
            logout: function () {
                this.hideMenu();
                this.$store.dispatch("logout").then(() => {
                    //console.log("### logged out ###");
                    this.$router.push("/login");
                });
            },
            user_profile: function () {
                this.$router.push("/user-profile");
            },
            changeTitle(newTitle) {
                this.pageTitle = newTitle;
            },
            setTopToolbarAsMenu(setActive) {
                this.topToolbarAsMenu = setActive;
            },
            setBottomNavVisible(setVisible) {
                this.bottomNavVisible = setVisible;
            },
            onNavigationLinkClick(componentClickEvent = null) {
                this.hideMenu();
                if (componentClickEvent != null) componentClickEvent();
            },
            async goBack(){
                var page = this.$router.currentRoute;
                if (page.name == "User Profile"){
                    var isEditMode = JSON.parse(localStorage.getItem("isEditMode"));
                    if(isEditMode){
                        this.$refs.saveChangesRequestDialog.openDialog();
                    } else {
                        this.discardChanges();
                    }
                }
                else {
                    this.$router.go(-1);
                }
            },

            discardChanges(){
                localStorage.setItem("isEditMode", JSON.stringify(false)); // reset 'edit mode' identifier
                this.$refs.saveChangesRequestDialog.closeDialog();
                this.$router.go(-1);
            },

            cancelGoBack(){
                this.$refs.saveChangesRequestDialog.closeDialog();
            }
        },
        mounted: function () {
            this.whatApplicationUser();
            this.setActiveBottomNavButton(this.$route.name);

            //this.screenSizing();
            this.logoutChecker = setInterval(this.shouldLogoutUser, 5000);
        },
        beforeMount() {
            this.initialPage = true;
            let language = this.$cookies.get('language');
            if(language != null && language !== 'undefined'){
                this.$i18n.locale = language;
            }
			
			this.language = this.$t('views.login.language');
			this.languageSelect = this.$t('views.login.languageSelect');
			this.languageDictionary = this.$t('views.login.languageDictionary');
			this.selectedLanguage = this.$t('views.login.selectedLanguage');

            window.addEventListener('online', ()=>{this.onlineStatus = true;});
            window.addEventListener('offline', ()=>{this.onlineStatus = false;});
            this.onRouteUpdate();
        },
        beforeDestroy(){
            clearInterval(this.logoutChecker);
            window.removeEventListener('online', ()=>{this.onlineStatus = true;});
            window.removeEventListener('offline', ()=>{this.onlineStatus = false;});
        } 
        
    };
</script>

<style>
    #app .main-toolbar .v-toolbar__content {
        padding-right: 0px;
        min-width: 200px;
        max-width: 100%;
    }
</style>

<style scoped>
    .user-names-label{
        font-size: 20px;
        padding-top: 15px;
        padding-left: 5px;
        -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeSpeed;
    }

    .user-role{
        padding-top: 2px;
        padding-left: 5px;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeSpeed;
    }

    .user-name{
        padding-top: 2px;
        padding-left: 5px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 100px !important;
        -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeSpeed;
    }

    .footer-nav {
        background-color: var(rgba(4, 202, 90, 1));
    }

    .app-top-toolbar{
        position: fixed;
        z-index: 10;
        -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeSpeed;
    }

    .header-nav {
        background-color: var(rgba(4, 202, 90, 1));
    }

    .bottom-nav-button {
        color: rgba(255, 255, 255, 0.7) !important;
        background-color: rgba(4, 202, 90, 1) !important;
    }

    .bottom-nav-button-clicked {
        color: rgba(255, 255, 255, 1) !important;
        background-color: rgba(4, 202, 90, 1) !important;
    }

    .content-middler {
        width: 100%;
        height: calc(100% - 2vh);
    }

    .loading-overlayer {
        width: 100%;
        height: 100%;
        z-index: 300;
        color: #fff;
        position: fixed;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.64);
    }

    .progress-overlayer {
        width: 100%;
        height: 100%;
        z-index: 300;
        color: #fff;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.64);
    }

    .mdl-mini-footer {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 10px 16px;
        color: #9e9e9e;
        background-color: var(rgba(4, 202, 90, 1));
    }

    .navigation-drawer__header {
        height: 100px;
    }

    .navigation-drawer__link {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
        -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-rendering: optimizeSpeed;
    }

    .navigation-drawer__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: transparent;
        height: 130px;
    }

    .navigation-drawer__footer-img {
        width: 100%;
    }

    .bottom-nav__link {
        text-decoration: none;
    }

    .alerts-box {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 150;
        transition: margin-bottom 0.3s ease-out;
    }

    .app-router-component {
        margin-top: 0;
    }
    @media only screen and (min-width: 960px){
        .app-router-component {
            margin-top: 8px;
        }
    }
</style>
