<template>
    <v-layout>
        <v-card-text style="padding-left: 15px; padding-right: 15px; margin-bottom: 10px;">
            <v-row>
                <!-- company name -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.name }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.name"
                        :error="errors.name.isError"
                        :error-messages="errors.name.errorMessage"
                        outlined
                        dense
                        height="10px"
                        required
                        maxlength="100"
                        counter
                        @input="() => { errors.name.valueEntered = true; setFieldError('name', false, ''); }"
                    ></v-text-field>
                </v-flex>
            </v-row>
            <v-row>
                <!-- company address -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.address }} </div>
                        <!-- <v-select required
                                v-model="editDialogs.postalAddress.value.country"
                                :items="countriesSelect"
                                item-value="key"
                                item-text="text"
                                >
                        </v-select> -->
                </v-flex>
            </v-row>
            <v-row>
                <!-- Street  -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.street }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.street"
                        outlined
                        dense
                        height="10px"
                        disabled
                    ></v-text-field>
                </v-flex>
                <!-- Street number -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.streetNumber }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.streetNumber"
                        outlined
                        dense
                        height="10px"
                        disabled
                    ></v-text-field>
                </v-flex>
            </v-row>
            <v-row>
                <!-- Street  -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.zpiCode }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.zpiCode"
                        outlined
                        dense
                        height="10px"
                        disabled
                    ></v-text-field>
                </v-flex>
                <!-- Street number -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.city }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.city"
                        outlined
                        dense
                        height="10px"
                        disabled
                        required="false"
                    ></v-text-field>
                </v-flex>
            </v-row>
            <v-row>
                <!-- company name -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.nip }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.nip"
                        :error="errors.nip.isError"
                        :error-messages="errors.nip.errorMessage"
                        outlined
                        dense
                        height="10px"
                        maxlength="100"
                        counter
                        @input="() => { errors.nip.valueEntered = true; setFieldError('nip', false, ''); }"
                    ></v-text-field>
                </v-flex>
            </v-row>
            <v-row>
                <!-- company email -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div class="required" style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.email }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.email"
                        :error="errors.email.isError"
                        :error-messages="errors.email.errorMessage"
                        outlined
                        dense
                        required
                        height="10px"
                        maxlength="100"
                        counter
                        @input="() => { errors.email.valueEntered = true; setFieldError('email', false, ''); }"
                    ></v-text-field>
                </v-flex>

                <!-- company phone number -->
                <v-flex class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <div style="margin-bottom: 10px; font-family: sans-serif; color: gray; font-weight: 600; font-size: 14px;"> {{ lview.phoneNumber }} </div>
                    <v-text-field
                        class = "col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        v-model="company.phoneNumber"
                        :error="errors.phoneNumber.isError"
                        :error-messages="errors.phoneNumber.errorMessage"
                        outlined
                        dense
                        height="10px"
                        maxlength="100"
                        counter
                        @input="() => { errors.phoneNumber.valueEntered = true; setFieldError('phoneNumber', false, ''); }"
                    ></v-text-field>
                </v-flex>
            </v-row>
        </v-card-text>
    </v-layout>
</template>

<script>
import axios from 'axios';

export default {
    data: function(){
        return {
            errors: {
                name: {
                    isError: false,
                    errorMessage: "",
                    valueEntered: false
                },
                nip: {
                    isError: false,
                    errorMessage: "",
                    valueEntered: false,
                },
                email: {
                    isError: false,
                    errorMessage: "",
                    valueEntered: false,
                },
                phoneNumber: {
                    isError: false,
                    errorMessage: "",
                    valueEntered: false,
                },
                logo: {
                    isError: false,
                    errorMessage: "",
                    valueEntered: false
                }
            },
            company: {
                name: "",
                street: "",
                streetNumber: "",
                zpiCode: "",
                city: "",
                country: "",
                nip: "",
                email: "",
                phoneNumber: ""
            }
        }
    },
    methods: {
        setFieldError: function(field, value, message) {
            this.errors[field].isError = value;
            this.errors[field].errorMessage = message;
        },
    },
    mounted: async function () {
        this.$emit('set-display', 'BACK_WINDOW');
        this.$emit('set-title', this.lview.title);
    },
    beforeDestroy: function(){
        this.$emit('set-display', 'default');
        this.$emit('set-title', '');
    },
    computed: {
        lview: {
            get: function(){
                return this.$t('views.company');
            }
        },
        lerrors: { 
            get: function() { 
                return this.$t('errors'); 
            } 
        },
    }
}
</script>

<style scoped>
    .form-container {
        margin-left: auto; 
        margin-right: auto;
        width: 80%;
        max-width: 350px;
        text-align: center;
    }
    .form-input {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
</style>