<template>
    <div style = 'margin: 5% 2%; padding-top: 10px;'>
        <!-- <v-card v-if="Object.keys(users).length === 0" color="#e0e0e0" max-width="1000" max-height="450">
            <v-card-title class="justify-center">{{ lviews.addUsersNotification }}</v-card-title>
            
            <v-card-text class="text-center">
                <v-flex xs12>
                    <v-icon  large  color="green darken-2" style = 'font-size: 30px; margin-right: 10px;'>  mdi-account-plus</v-icon>
                    <a href =""> {{ lviews.addUsers }} </a>             
                </v-flex>
            </v-card-text>
        </v-card>

        <v-card v-else color="#e0e0e0" max-width="1000" max-height="450">
            <div class="container" style="width:100%;">
                <div class="row" style = "margin:5%;">
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4, stats" style="display:inline-block; margin-right:5%;">
                        <span class = 'admin-panel-text-top-resize'>{{ lviews.activeUsers }}</span>
                        <v-icon class = 'admin-panel-icon-top-resize' color="black" style='float:right;'>  mdi-account-outline</v-icon>
                        <div style='display:flex; justify-content: center; width: 100%;'>
                            <div style = "display:block; width:50%;">
                                <div style="font-size:12px; font-weight: bold;">{{adminsNumber}}</div>
                                <div style="font-size:11px;">{{ lviews.administrators }}</div>
                            </div>
                            <div style = "display:block; width:50%;">
                                <div style="font-size:12px; font-weight: bold;">{{usersNumber}}</div>
                                <div style="font-size:11px;">{{ lviews.usersPanel }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3, stats" style="margin-right:5%;">
                        <span class = 'admin-panel-text-top-resize'>{{ lviews.activeWorkers }}</span>
                        <v-icon class = 'admin-panel-icon-top-resize' color="black" style='float:right;'>  mdi-checkbox-marked-circle-outline</v-icon>
                        <div style='display:flex; justify-content: center; width: 100%;'>
                            <div style = "display:block; width:100%;">
                                <div style="font-size:12px; font-weight: bold;">{{workersNumber}}</div>
                                <div style="font-size:11px;">{{ lviews.workers }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3, stats" style="margin-right:5%;">
                        <span class = 'admin-panel-text-top-resize'>{{ lviews.activeDocuments }}</span>
                        <v-icon class = 'admin-panel-icon-top-resize' color="black" style='float:right;'>  mdi-file-outline</v-icon>
                        <div style='display:flex; justify-content: center; width: 100%;'>
                            <div style = "display:block; width:100%;">
                                <div style="font-size:12px; font-weight: bold;">{{fileSize}} MB</div>
                                <div style="font-size:11px;">{{ lviews.documentsSize }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card> -->

        <v-card flat style='padding-top: 30px;' max-width="1000">
            <div class="container" style="display:inline-flex; width:100%;">
                <div class="row" style="display:inline-block;">
                    <div v-for="(redirectButton,redirectKey) in redirectionButtons" :key="redirectKey" class = 'admin-panel' style='display:inline-block; margin-right:auto; margin-top:auto; vertical-align:top'>
                        <v-card-text v-ripple style='width:100%; height:100%;'>
                            <div @click="redirectButton.onClick" style = 'text-align: center; width:100%; height:100%;'>
                                <v-icon  class = 'admin-panel-icon-resize'  color="green darken-2">  {{ redirectButton.icon }} </v-icon>
                                <div class = "admin-panel-text-resize">{{ redirectButton.name }}</div>
                                <div class = "admin-panel-text-under-resize">{{ redirectButton.description }}</div>  
                            </div>
                        </v-card-text>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>
<script>
export default {
    name: 'AdminPanel',
    data() {
        return{
            users:{
              user1:0,
            },
            usersNumber:10,
            adminsNumber:5,
            workersNumber:1200,
            fileSize:2500,
            redirectionButtons:[
                // {
                //     icon:"mdi-city",
                //     name:"{L Dane firmy }",
                //     description:"{L Zaktualizuj informacje o swojej firmie }",
                //     link:null,
                //     onClick:() => {},
                // },
                // {
                //     icon:" mdi-credit-card",
                //     name:"{L Rozliczenia }",
                //     description:"{L Sprawdź rozliczenia i zarządzaj licencjami }",
                //     link:null,
                //     onClick:() => {},
                // },
                {
                    icon:"mdi-book",
                    name:"{L Słowniki }",
                    description:"{L Definiuj własne typy i statusy }",
                    link:"/config",
                    onClick:() => {},
                },
                {
                    icon:"mdi-key-variant",
                    name:"{L Uprawnienia }",
                    description:"{L Zarządzaj dostępami do modułów }",
                    link: "/permissions",
                    onClick:() => {},
                },
                {
                    icon:"mdi-account-multiple",
                    name:"{L Użytkownicy }",
                    description:"{L Dodaj lub zarządzaj użytkownikami }",
                    link:"/user-list",
                    onClick:() => {},
                }
            ]

        };
    },
    mounted() {
        this.$emit('set-title', this.lviews.adminPanelTitle);
        let userPermissions = localStorage.getItem("user_permissions");
        if (!userPermissions.includes("fob.admin_view")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return;
        } 
        // this.redirectionButtons[0].onClick = () => {
        //     if( this.redirectionButtons[0].link != null ){
        //         this.$router.push(this.redirectionButtons[0].link);
        //     }      
        // };
        // this.redirectionButtons[0].name = this.lviews.officeData;
        // this.redirectionButtons[0].description = this.lviews.officeDataDescription;

        // this.redirectionButtons[1].onClick = () => {
        //     if( this.redirectionButtons[1].link != null ){
        //         this.$router.push(this.redirectionButtons[1].link);
        //     }      
        // };
        // this.redirectionButtons[1].name = this.lviews.settlement;
        // this.redirectionButtons[1].description = this.lviews.settlementDescription;

        this.redirectionButtons[0].onClick = () => {
            if( this.redirectionButtons[0].link != null ){
                this.$router.push(this.redirectionButtons[0].link);
            }      
        };
        this.redirectionButtons[0].name = this.lviews.dictionaries;
        this.redirectionButtons[0].description = this.lviews.dictionariesDescription;

        this.redirectionButtons[1].onClick = () => {
            if( this.redirectionButtons[1].link != null ){
                this.$router.push(this.redirectionButtons[1].link);
            }      
        };
        this.redirectionButtons[1].name = this.lviews.permissions;
        this.redirectionButtons[1].description = this.lviews.permissionsDescription;
        
        this.redirectionButtons[2].onClick = () => {
            if( this.redirectionButtons[2].link != null ){
                this.$router.push(this.redirectionButtons[2].link);
            }      
        };
        this.redirectionButtons[2].name = this.lviews.users;
        this.redirectionButtons[2].description = this.lviews.usersDescription;
    },
    computed: {
        lviews: {
            get: function() {
                return this.$t('views.adminPanel');
            }
        }
    },
    methods: {

    }
}
</script>
<style>
.admin-panel {
        width:250px;
        height:200px;
    }
@media only screen and (max-width: 800px){
        .admin-panel {
            width:140px;
            height:120px;
        }
    }

.admin-panel:hover {
        background-color: lightgrey;
}
.admin-panel-text-resize {
        font-size: 15px;
        font-weight: bold;
    }
@media only screen and (max-width: 800px){
        .admin-panel-text-resize {
            font-size: 12px;
            font-weight: bold;
        }
    }

.admin-panel-text-under-resize {
        font-size: 12px;
        margin: 0px;
        height:50px;
    }
@media only screen and (max-width: 800px){
        .admin-panel-text-under-resize {
            font-size: 8px;
            margin: 0px;
            height:30px;
        }
    }

.admin-panel-icon-resize {
        font-size: 70px !important;
    }
@media only screen and (max-width: 800px){
        .admin-panel-icon-resize {
            font-size: 40px !important;
        }
    }

.admin-panel-text-top-resize {
        font-size: 15px;
    }
@media only screen and (max-width: 800px){
        .admin-panel-text-top-resize {
            font-size: 13px;
        }
    }

.admin-panel-icon-top-resize {
        font-size: 30px !important;
    }
@media only screen and (max-width: 800px){
        .admin-panel-icon-top-resize {
            font-size: 20px !important;
        }
    }

.stats {
        background-color: white;
        color: black;
        /* display:flex;
        flex-direction: row;
        align-items: center;
        text-align: center; */
    }
@media only screen and (max-width: 800px){
        .stats {
            background-color: white;
            color: black;
            margin-bottom:5%;
        }
    }
</style>
<style scoped>
.app-router-component {
    overflow-y: hidden !important;
}
</style>