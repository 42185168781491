<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<confirm-boarding-dialog
  ref='yourReference'

  ...
></confirm-boarding-dialog>
$refs.youReference.openDialog();

props:
  - workers {Array}, determines workers to modify in the database
	{
	  id {Number},
	  workerId {Number},
    boardings {Array}
    {
      boardingId: {Number},
      projectId: {Number}
    }
	}

  - assignedProjects {Array}
  {
    id: {Number},
    name: {String}
  }

  - markedProjectsIds {Array of Number}

events:
  - request-sending-start
  - request-sending-end
  - request-sending-success
  - progress-request-sending-start
  - progress-request-sending-progress
  - progress-request-sending-end
  - progress-request-sending-success
  - error

errors:
  structure: {
    errorCodeShort,
    errorCodeLong,
    title,
    message,
    details
  }

  errorsId: CNFBRD

-->

<template>
    <v-dialog persistent v-model="visible" max-width="290">
        <v-card dense>
            <div class="OnBoardingDialog">
                <v-card-title style="text-align:center; padding-bottom:0">
                    <span class="dialog-title">{{ lview.title }}</span>
                </v-card-title>

                <v-card-text style="padding-top:0">
                    <v-card elevation="1" align-center class="projects-table">
                        <v-card-title primary-title class="projects-table-title" style="position:relative">
                            {{ lview.chooseProjects }}
                        </v-card-title>

                        <v-list
                          style="width:100%; max-height: 110px; overflow-y: auto; overflow-x: hidden"
                        >
                            <v-list-item style="padding:0 5px" v-for="project in tableProjects" :key="project.id">
                                <v-flex xs12>
                                    <v-checkbox
                                        v-model="checkboxes[project.id.toString()]"
                                        color="rgba(4, 202, 90, 1)"
                                        style="margin-top: 0; margin-bottom: 0;"
                                        :label="project.name"
                                    ></v-checkbox>
                                </v-flex>
                            </v-list-item>
                        </v-list>
                    </v-card>

                    <!-- prepend-icon="event" -->

                    <v-layout align-center style="margin-top:10px">
                        <v-checkbox
                            v-model="agreements[0]"
                            hide-details
                            color="rgba(4, 202, 90, 1)"
                            :label="lview.checkbox1"
                            :error="errorStates[0]"
                            style="margin:0px; padding:0px;"
                            @change="markCheckbox(0)"
                        ></v-checkbox>

                        <v-spacer></v-spacer>

                        <v-btn
                            icon
                            small
                            color="rgba(4, 202, 90, 1)"
                            :disabled="workers.length > 1"
                            style="margin-right:0"
                            @click="$refs.contractAttachments.openDialog()"
                        >
                            <v-icon>attach_file</v-icon>
                        </v-btn>

                        <attach-pictures-dialog
                            ref="contractAttachments"
                            :picturesLimit="100"
                            :sizeLimitPerPicture="3145728"
                            :initialAttachments="attachments"
                            :buttonConfirm="lbuttons.upload"
                            @error="onError"
                            @confirm="onAttachmentsConfirm"
                        ></attach-pictures-dialog>
                    </v-layout>

                    <!--<v-layout>
                      <div style="height:20px; margin-top:10px; margin-bottom: 10px;">
                      <v-checkbox
                        v-model="agreements[1]"
                        hide-details
                        color="primary"
                        :label="lview.checkbox2"
                        :error="errorStates[1]"
                        style="margin:0px; padding:0px;"
                        @change="markCheckbox(1)"
                      ></v-checkbox>
                      </div>
                    </v-layout>

                    <v-layout>
                      <div style="height:20px; margin-top:10px; margin-bottom: 10px;">
                      <v-checkbox
                        v-model="agreements[2]"
                        hide-details
                        :label="lview.checkbox3"
                        color="primary"
                        :error="errorStates[2]"
                        style="margin:0px; padding:0px;"
                        @change="markCheckbox(2)"
                      ></v-checkbox>
                      </div>
                    </v-layout>

                    <v-layout>
                      <div style="height:20px; margin-top:10px; margin-bottom: 10px;">
                      <v-checkbox
                        v-model="agreements[3]"
                        hide-details
                        :label="lview.checkbox4"
                        color="primary"
                        :error="errorStates[3]"
                        style="margin:0px; padding:0px;"
                        @change="markCheckbox(3)"
                      ></v-checkbox>
                      </div>
                    </v-layout>-->
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text style="background-color: white; color: rgba(4, 202, 90, 1);" @click="closeDialog()">{{ lbuttons.cancel }}</v-btn>
                    <v-btn text style="background-color: white; color: rgba(4, 202, 90, 1);" @click="onAcceptDialog()">{{ lbuttons.confirm }}</v-btn>
                </v-card-actions>

                <transition name="notification" v-on:enter="hideNotification">
                    <p v-if="notifications.alertCorrect">
                        <v-alert
                          :value="true"
                          color="rgba(4, 202, 90, 1)"
                          icon="check_circle"
                          style="max-width: 350px; font: Verdana; width:70%; margin-left:auto; margin-right:auto; position: fixed;
                                      background-color:white; margin-top: 50px;"
                        >{{ this.$t('views.documents.correctUploadInfo') }}</v-alert>
                    </p>
                </transition>

                <transition name="notification" v-on:enter="hideNotification">
                    <p v-if="notifications.alertFailure">
                        <v-alert
                          :value="true"
                          color="error"
                          icon="warning"
                          style="max-width: 350px; font: Verdana; width:70%; margin-left:auto; margin-right:auto; position: fixed;
                                      background-color:white; margin-top: 50px;"
                        >{{ this.$t('views.documents.errorUploadInfo') }}</v-alert>
                    </p>
                </transition>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "confirm-boarding-dialog",
  mounted: function() {
    if (this.open) this.openDialog();
    else this.closeDialog();
  },
  data() {
    return {
      visible: false,
      checkboxes: {},
      tableProjects: [],

      //agreementOK, questionnaireOK, medicalReportOK, noCriminalOK
      agreements: [false, false, false, false],
      errorStates: [false, false, false, false],

      /*datePicker: {
        visible: false,
        date: new Date()
          .toJSON()
          .slice(0, 10)
          .replace("/-/g", "/")
      },*/

      attachments: [],
      numOfAttachments: null,
      attachmentsProgress: [],

      notifications: {
        alertCorrect: false,
        alertFailure: false,

        failed: false,
        succeeded: false
      },

      photoFormats: ["JPG", "JPEG", "PNG"]
    };
  },
  computed: {
    lview: {
      get: function() {
        return this.$t("views.commons.confirmBoardingDialog");
      }
    },
    lmessages: {
      get: function() {
        return this.$t("commons.messages");
      }
    },
    lbuttons: {
      get: function() {
        return this.$t("commons.buttons");
      }
    },
    lerrors: { get: function() { return this.$t('errors'); } },
  },
  props: {
    workers: {
      type: Array,
      required: true
    },

    // Default date assigned if date is null when opening dialog
    defaultDate: {
      type: String,
      default: new Date()
        .toJSON()
        .slice(0, 10)
        .replace("/-/g", "/")
    },

    assignedProjects: {
      type: Array,
      default: () => []
    },
    markedProjectsIds: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    openDialog() {
      if (this.assignedProjects.length == 0) {
        this.$emit("error", {
          errorCodeShort: "OFFBRD#2",
          errorCodeLong: "NO_PROJECTS_PROVIDED",
          title: this.lview.errorNoProjectsAssignedTitle,
          message: this.lview.errorNoProjectsAssignedMessage,
          details: null
        });
      }

      //if (this.date == null) this.datePicker.date = this.defaultDate;
      //else this.datePicker.date = this.date;

      this.attachments = [];
      this.setupCheckboxes();
      this.tableProjects.splice(0, this.tableProjects.length);
      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.tableProjects.push({
          id: this.assignedProjects[i].id,
          name: this.assignedProjects[i].name,
          sortName:
            this.assignedProjects[i].id == -1
              ? "#"
              : this.assignedProjects[i].name
        });

        this.tableProjects.sort((a, b) => {
          if (a.sortName < b.sortName) return -1;
          else if (a.sortName == b.sortName) return 0;
          else return 1;
        });
      }

      this.visible = true;
    },
    closeDialog() {
      this.visible = false;
    },

    markCheckbox(idx) {
      if (!this.agreements[idx] && !this.errorStates[idx]) {
        // The fields was marked before, but user unmarked it
        this.errorStates[idx] = true;
        this.$forceUpdate();
        return;
      }
      if (this.agreements[idx] && this.errorStates[idx]) {
        this.errorStates[idx] = false;
        this.$forceUpdate();
        return;
      }
    },

    onAcceptDialog: async function() {
      var errorOccured = false;
      if (!this.agreements[0]) {
        /*&& this.agreements[1]
						&& this.agreements[2]
						&& this.agreements[3])) {*/

        for (var i = 0; i < 4; i++) {
          if (!this.agreements[i]) {
            this.errorStates[i] = true;
          }
        }
        this.$forceUpdate();
      } else {

        this.$emit("request-sending-start");

        var errorOccured = false;
        var workerIds = [];
        var projectIds = [];
        var worker;
        var workers = this.workers;

        for (var i = 0; i < workers.length; i++) {
          workerIds.push(workers[i].workerId);
        }
        for (var key in this.checkboxes) {
          if (this.checkboxes[key]) {
            projectIds.push(parseInt(key));
          }
        }

        var boardings = this.filterBoardings(projectIds);

        var projectAssignmentIds = [];
        for(var i = 0; i < boardings.length; i++) {
            projectAssignmentIds.push(boardings[i].projectAssignmentId);
        }

        var responsePromise = axios({
            method: "PATCH",
            url: localStorage.getItem("current_env") + "/api/v2/boardings/projects-assignment/",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            data: {
                projects_assignment_id: projectAssignmentIds,
                boarding: {
                    boarding_status: 3
                }
            }
        });

        if(!navigator.onLine){
          this.$emit('error', {
              title: this.lmessages.networkErrorTitle,
              message: this.lmessages.networkError,
              errorCodeShort: "Error: ",
              errorCodeLong: "Network Error",
              details: ""
          });
          errorOccured = true;
        }

        var packages = [];
        var errors = [];
        var modified = [];

        var response = null;
        try {
            response = await responsePromise;
        } catch (error) {
            this.$emit("request-sending-end");
            errorOccured = true;
            if (error.isAxiosError){
                if (error.response.data != null && error.response.data != undefined) {
                    if (error.response.data.boarding != null && error.response.data.boarding != undefined) {
                        if (error.response.data.boarding.non_field_errors != null && error.response.data.boarding.non_field_errors != undefined) {
                            this.$emit('error', {
                              title: this.lerrors.boarding.non_field_errors.header,
                              message: this.lerrors.boarding.non_field_errors.string,
                              errorCodeShort: this.lerrors.boarding.non_field_errors.code,
                              errorCodeLong: this.lerrors.boarding.non_field_errors.code,
                              details: String(error)
                            });
                        }

                        if (error.response.data.boarding.boarding_status != null && error.response.data.boarding.boarding_status != undefined) {
                            this.$emit('error', {
                              title: this.lerrors.boarding.boarding_status.header,
                              message: this.lerrors.boarding.boarding_status.string,
                              errorCodeShort: this.lerrors.boarding.boarding_status.code,
                              errorCodeLong: this.lerrors.boarding.boarding_status.code,
                              details: String(error)
                            });
                        }
                    }
                    if (error.response.data.projects_assignment_id != null && error.response.data.projects_assignment_id != undefined) {
                      this.$emit('error', {
                        title: this.lerrors.projects_assignment_id.header,
                        message: this.lerrors.projects_assignment_id.string,
                        errorCodeShort: this.lerrors.projects_assignment_id.code,
                        errorCodeLong: this.lerrors.projects_assignment_id.code,
                        details: String(error)
                      });									
                    }
                }

                if (error.response.status == 403) {
                    this.$emit('error', {
                        title: this.lmessages.forbiddenOperation,
                        message: this.lmessages.youDoNotHavePriviledgesToDoThatOperation,
                        errorCodeShort: "CNFBRD#5",
                        errorCodeLong: "REQUEST_FAILED",
                        details: String(error)
                    });
                }
            }
            else {
                errors.push(error);

                this.$emit('error', {
                    title: this.lmessages.requestFailedTitle,
                    message: this.$t("commons.messages.requestBoardingFailed", { id: packages[i].boarding.boardingId }),
                    errorCodeShort: "CNFBRD#5",
                    errorCodeLong: "REQUEST_FAILED",
                    details: String(error)
                });
            }
        }

        if (!errorOccured) {
            for (var boardingIdx = 0; boardingIdx < boardings.length; boardingIdx++) {
                boardings[boardingIdx].onBoardingDate = null;
                boardings[boardingIdx].offBoardingDate = null;
                boardings[boardingIdx].status = 3;
                modified.push({ boardingId: boardings[boardingIdx].boardingId });
            }
            this.$emit("request-sending-end");
        
            if (modified.length > 0) {
              this.$emit("request-sending-success", modified);
              this.closeDialog();
            }
        }
        
        /*for(var i = 0; i < boardings.length; i++){
            packages.push({
              boarding: boardings[i],
              promise: axios({
                method: "PATCH",
                url: localStorage.getItem("current_env") + "/api/v2/boardings/" + boardings[i].projectAssignmentId,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("jwt")
                },
                data: {
                  boarding: {
                    boarding_status: 3
                  }
                }
              })
            });
        }*/

        /*if(!errorOccured){
          for(var i = 0; i < packages.length; i++){
              try {
                await packages[i].promise;

                packages[i].boarding.onBoardingDate = null;
                packages[i].boarding.offBoardingDate = null;
                packages[i].boarding.status = 3;

                modified.push({ boardingId: packages[i].boarding.boardingId });
              } catch (error) {
                
                
              }
          }
 
          this.$emit("request-sending-end");
        
          if(modified.length > 0){
            this.$emit("request-sending-success", modified);
            this.closeDialog();
          }
        }*/
      }
    },

    /**
     * Filters boarding for provided project ids
     * 
     * @param {Array} projectIds Array of integers
     * @returns {Array} Array of boardings for provided project ids
     */
    filterBoardings: function(projectIds) {
      var result = [];

      var boarding;
      for(var i = 0; i < this.workers.length; i++){
        for(var j = 0; j < this.workers[i].boardings.length; j++){
          boarding = this.workers[i].boardings[j];

          if(projectIds.indexOf(boarding.projectId) != -1){
            result.push(boarding);
          }
        }
      }

      return result;
    },

    partialWorkerUpdate: async function(workerIds, requestData) {
      var worker;
      var axiosPromises = [];
      var workerIdsCopy = [];
      for (var i = 0; i < workerIds.length; i++) {
        workerIdsCopy.push(workerIds[i]);

        axiosPromises.push(
          axios({
            method: "PATCH",
            url:
              localStorage.getItem("current_env") +
              "/api/worker/" +
              workerIds[i],
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            data: requestData
          })
        );
      }

      var response;
      var requestError = {
        errors: [],
        message: null
      };
      for (var i = 0; i < axiosPromises.length; i++) {
        try {
          response = await axiosPromises[i];
        } catch (error) {
          requestError.errors.push({
            workerId: workerIdsCopy[i],
            error: error
          });
        }
      }

      if (requestError.errors.length > 0) {
        requestError.message = "Following errors occured: \n";

        for (var i = 0; i < requestError.errors.length; i++) {
          requestError.message +=
            "\nWorker " +
            requestError.errors[i].workerId +
            ": " +
            requestError.errors[i].error;
        }

        throw requestError;
      }
    },

    onAttachmentUploadProgress(progress, uploadTotal) {
      var passProgress = Math.round((progress * 100) / uploadTotal);

      this.$emit("progress-request-sending-progress", passProgress);

      if (passProgress == 100) {
        this.$emit("progress-request-uploaded");
      }
    },

    onAttachmentsConfirm: async function(newAttachments) {
      if (this.workers.length > 1) {
        this.$emit("error", {
          errorCodeShort: "CNFBRD#2",
          errorCodeLong: "TOO_MANY_WORKERS",
          title: this.lmessages.errorOccuredTitle,
          message: this.lview.tooManyWorkersForThisAction
        });

        return;
      }
      if (this.workers.length < 1) return;
      if (newAttachments.length == 0) return;

      if(!navigator.onLine) {
          this.$emit("error", {
            errorCodeShort: "",
            errorCodeLong: "",
            title: this.lmessages.networkErrorTitle,
            message: this.lmessages.networkError
          });
				  return false;
			}

      var worker = this.workers[0];
      //this.attachments = newAttachments;
      var res;

      var formData;

      // Setting progresses of sending all the photos to 0
      this.attachmentsProgress.splice(0, this.attachmentsProgress.length);
      this.numOfAttachments = newAttachments.length;
      for (var i = 0; i < this.numOfAttachments; i++)
        this.attachmentsProgress.push(0);

      var promises = [];

      // Preparing requests
      this.$emit("progress-request-sending-start", this.lview.uploading);

      for (var i = 0; i < newAttachments.length; i++) {
        //console.log(newAttachments[i]);

        let fullName = newAttachments[i].name.toUpperCase();
        let isImage = false;

        this.photoFormats.forEach(extension => {
          if (fullName.includes(extension)) {
            isImage = true;
          }
        });

        if (!isImage) {
          this.notifications.failed = true;
          this.showNotification(this.notifications);
          return;
        }

        let date = new Date();

        formData = new FormData();

        formData.append('workers_ids', [worker.workerId]);
        formData.append('attachments_list', newAttachments[i]);
        formData.append('override_existing_files', false);
        formData.append('upload_with_document_type', false);

        /*formData.append("contract_image", newAttachments[i]);
        formData.append("worker", worker.workerId);
        formData.append("document_type", 0);
        formData.append("document_status", 0);
        formData.append(
          "document_set_id",
          worker.id +
            "_" +
            date.toJSON().slice(0, 10) +
            "_" +
            date.getHours() +
            ":" +
            date.getMinutes() +
            ":" +
            date.getSeconds()
        );
        formData.append("document_name", newAttachments[i].name);*/
        //console.log( worker.id + '_' + date.toJSON().slice(0,10) + "_" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() );

        //console.log("DEBUG confirm boarding dialog");
        promises.push(
          axios({
            method: "POST",
            url: localStorage.getItem("current_env") + "/api/v2/worker-documents/upload",
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("jwt")
            },
            data: formData,
            onUploadProgress: progressEvent => {
              this.onAttachmentUploadProgress(
                progressEvent.loaded,
                progressEvent.total
              );
            }
          })
        );
        //console.log("DEBUG2 confirm boarding dialog");
      }

      // Awaiting promises
      var failedAttachments = [];
      for (var i = 0; i < newAttachments.length; i++) {
        try {
          await promises[i];
        } catch (error) {
          this.$emit("progress-request-uploaded");

          this.$emit("error", {
            errorCodeShort: "CNFBRD#3",
            errorCodeLong: "REQUEST_FAILED",
            title: this.lmessages.requestFailedTitle,
            message: this.$t("commons.messages.requestAttachmentFailed", { name: newAttachments[i].name }),
          });
          failedAttachments.push(newAttachments[i]);
        }
      }

      this.$emit("progress-request-sending-progress", 100);
      this.$emit("progress-request-sending-end");
      this.$emit("progress-request-sending-success");

      if(failedAttachments.length > 0){
        this.attachments = failedAttachments;
        this.$nextTick(() => this.$refs.contractAttachments.openDialog());
        return;
      }

      this.notifications.succeeded = true;
      this.showNotification(this.notifications);
    },

    onError(error) {
      this.$emit("error", error);
    },

    showNotification(notifications) {
      //console.log("showing notification");
      if (notifications.succeeded) {
        setTimeout(() => {
          notifications.alertCorrect = true;
        }, 50);
      } else if (notifications.failed) {
        setTimeout(() => {
          notifications.alertFailure = true;
        }, 50);
      }
    },

    hideNotification() {
      setTimeout(() => {}, 2000);
      //console.log("hiding notification");
      if (this.notifications.succeeded) {
        setTimeout(() => {
          this.notifications.alertCorrect = false;
        }, 3000);
        this.notifications.succeeded = false;
      } else if (this.notifications.failed) {
        setTimeout(() => {
          this.notifications.alertFailure = false;
        }, 3000);
        this.notifications.failed = false;
      }
    },

    setupCheckboxes() {
      this.checkboxes = {};

      for (var i = 0; i < this.assignedProjects.length; i++) {
        this.checkboxes[this.assignedProjects[i].id] = false;
      }


      for (var i = 0; i < this.markedProjectsIds.length; i++) {
        this.checkboxes[this.markedProjectsIds[i]] = true;
      }
    }
  }
};
</script>

<style>
.notification-enter-active {
  transition: opacity 1s;
}

.notification-leave-active {
  transition: opacity 3s;
}

.notification-enter,
.notification-leave-active {
  opacity: 0;
}

div.ResignDialog .projects-table .v-subheader {
  padding: 0;
}

div.ResignDialog .projects-table .v-list__tile {
  padding: 0;
}
</style>

<style scoped>
.projects-table {
  padding: 0;
}

.projects-table-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 5px;
  font-size: 17px !important;
  font-weight: 500 !important;
}

.dialog-title{
  font-size: 19px;
  font-weight: 550;
}
</style>