<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.2"
__author__ = "Adrian Bączek"
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->


<template>
	<div>
		<!-- attaching new file dialog {L} -->
		<attach-pictures-dialog ref="newFileDialog"
								:title="this.lview.attachPicturesInfo"
								@confirm="addingFiles">
		</attach-pictures-dialog>


		<v-card style="height:100%;">

			<div v-if="documents.length > 0">
				<v-flex xs12 sm6 offset-sm3>
					<v-list class="documents-list">


						<v-list two-line>
							<template v-for="(item, index) in documents.slice().reverse()">

								<v-list-item :key="index">
									<v-list-item-avatar>
										<!-- img src="" -->
										<v-icon style="width:50px; height:50px;"> photo </v-icon>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title v-html="item.name"></v-list-item-title>
										<v-list-item-subtitle v-html="item.timestamp"></v-list-item-subtitle>
									</v-list-item-content>

									<!--
										<v-list-item-action @click="showPhoto">
											<v-btn icon ripple>
												<v-icon color="grey lighten-1">info</v-icon>
											</v-btn>
										</v-list-item-action>
									-->
								</v-list-item>

							</template>
						</v-list>

					</v-list>
				</v-flex>
			</div>

			
		</v-card>

		<transition name="notification" v-on:enter="hideNotification">
			<p v-if="notifications.alertCorrect">
				<v-alert type="success" style="max-width: 350px;" :value="true" icon="check_circle" class="notification-alert-frame">
					{{ lview.correctUploadInfo }}
				</v-alert>
			</p>
		</transition>

		<transition name="notification" v-on:enter="hideNotification">
			<p v-if="notifications.alertFailure">
				<v-alert type="error" style="max-width: 350px;" :value="true" icon="warning" class="notification-alert-frame">
					{{ lview.errorUploadInfo }}
				</v-alert>
			</p>
		</transition>

		<div class="boarding-add-new">
			<v-btn class="boarding-add-new__button"
				   fab
				   @click="attachNewFile()">
				<v-icon>add</v-icon>
			</v-btn>
		</div>

	</div>
</template>

<script>
	import axios from "axios";

	export default {
		name: "documentsDialog",
		props: {
            version: {
                type: String,
                required: true,
            },
            appConfig: {
                type: Object,
                required: true
            }
        },
		mounted: async function () {
            /*if(this.appConfig.applicationMode != "MANAGER"){
                this.$store.dispatch("logout").then(() => {
					this.$router.push("/login");
				});
            }*/
			this.$emit('set-display', "BACK_WINDOW");
			this.$emit('set-title', this.lview.title);
			this.getDocumentsList();
		},
		computed: {
			lview: { get: function () { return this.$t('views.documents'); } },
			lmessages: { get: function () { return this.$t('commons.messages'); } },
		},
		data() {
			return {
				dialog: true,
				documents: [
					/*
						[
							{
								slug: (...) {String},
								image : (...) {String},
								name : (...) {String},
								timestamp : (...) {String},
								type : (...) {int},
							}
						]
					*/
				],
				profileId: this.$route.params.profile_id, //id of worker whose documents I'm looking for

				photoFormats: [
					"JPG", "JPEG", "PNG",
				],

				notifications: {
					alertCorrect: false,
					alertFailure: false,

					failed: false,
					succeeded: false,
				}
			}
		},
		methods: {

			// handles resently attached files - using dialog
			addingFiles: async function (files) {
				//sending files via API
				var promises = [];

				for (var i = 0; i < files.length; i++) {
					var form = new FormData();

					let date = new Date();

					form.append('attachments_list', files[i]);
					form.append('workers_ids', this.profileId);
					form.append('upload_with_document_type', false);
					form.append('override_existing_files', false);
					form.append('document_types', 0);

					promises.push(axios({
						method: "POST",
						url: localStorage.getItem("current_env") + '/api/v2/worker-documents/upload',
						headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						},
						data: form,
						/*onUploadProgress: (progressEvent) => {
							this.onAttachmentUploadProgress(progressEvent.loaded, progressEvent.total);
						}*/
					}));
				}

				for (var i = 0; i < files.length; i++) {
					try {
						await promises[i];
					} catch (error) {
						this.$emit('error', {
							errorCodeShort: 'CNFBRD#3',
							errorCodeLong: 'REQUEST_FAILED',
							title: this.$t('commons.messages.errorOccuredTitle'),
							message: this.$t('commons.messages.errorOccured'),
							details: "Failed for: " + files[i].name
						});
						this.notifications.succeeded = false;
						this.notifications.failed = true;
					}
				}

				if (this.notifications.failed) {
					this.showNotification(this.notifications);
				}

				else if (!this.notifications.failed) {
					this.notifications.succeeded = true;
					this.showNotification(this.notifications);
					this.adaptAttachedFiles(files); //updating notifications
				}
			},

			deletePhotoFromList() { },

			attachNewFile() {
				this.$refs.newFileDialog.openDialog();
			},

			createDate(dateString) {
				let date = "";
				for (var i = 0; i < dateString.length; i++) {
					if (dateString[i] == "_") {
						date += " ";
					}
					else {
						date += dateString[i];
					}
				}

				var words = date.split(' ');
				return words[1] + " " + words[2];
			},

			adaptAttachedFiles(files) {
				var date = new Date();
				for (var i = 0; i < files.length; i++) {
					let file = files[i];

					let fullName = file.name.toUpperCase();
					let isImage = false;

					this.photoFormats.forEach(extension => {
						if (fullName.includes(extension)) {
							this.documents.push({
								image: file,
								name: file.name.substr(0, 29),
								timestamp: date.toJSON().slice(0, 10) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(),
								type: 0
							});
							isImage = true;
						}
					});

					if (!isImage) {
						this.notifications.failed = true;
						return;
					}
				}
				this.notifications.succeeded = true;
			},


			// adapts response from API
			adaptResponse(response) {
				var documentTypes = response.data.document_types, docTypeObj;
				for (var i = 0; i < response.data.items.length; i++) {
					docTypeObj = documentTypes.find(x => { return x.id == response.data.items[i].type; });

					this.documents.push({
						slug: response.data.items[i].slug,
						image: null,
						name: response.data.items[i].file_name,
						timestamp: response.data.items[i].created_at,
						type: docTypeObj.name,
					});
				}

				/*for (var i = 0; i < res.length; i++) {
					this.documents.push({
						image: res[i].contract_image,
						name: res[i].document_name,
						timestamp: this.createDate(res[i].document_set_id), //res[i].document_set_id
						type: res[i].document_type
					});
				}*/
				/*
				hardcoded data
				this.documents.push({
					image: "",
					name: "image1.jpg",
					timestamp: "2019-08-06 17:20",
					type: "3"
				});
				this.documents.push({
					image: "",
					name: "image2.jpg",
					timestamp: "2018-09-19 18:45",
					type: "2"
				});
				*/
			},

			getDocumentsList: async function () {
				await axios
					.get(localStorage.getItem("current_env") + "/api/v2/worker-documents/?page=1&per_page=500&worker=" + this.profileId, { //this.profileId
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + localStorage.getItem("jwt")
						}
					})
					.then(response => this.adaptResponse(response))
					.catch(error => {
						if(error.response.status == 403){
							this.$emit("set-state", "ERROR", this.lmessages.forbidden);
						}
						else{
							this.$emit('set-state', 'error', this.lmessages.errorOccured);
						}
					});
			},

			showNotification(notifications) {
				if (notifications.succeeded) {
					setTimeout(() => { notifications.alertCorrect = true; }, 50);
				}
				else if (notifications.failed) {
					setTimeout(() => { notifications.alertFailure = true; }, 50);
				}
			},

			hideNotification() {
				setTimeout(() => { }, 2000);
				if (this.notifications.succeeded) {
					setTimeout(() => { this.notifications.alertCorrect = false; }, 3000);
					this.notifications.succeeded = false;
				}
				else if (this.notifications.failed) {
					setTimeout(() => { this.notifications.alertFailure = false; }, 3000);
					this.notifications.failed = false;
				}
			},
		}
	}
</script>

<style>
	.boarding-add-new {
		position: fixed;
		left: calc(50% - 36px + 128px);
		bottom: 28px;
		z-index: 4;
	}
	@media only screen and (max-width: 800px){
		.boarding-add-new {
			left: calc(50% - 36px);
		}
	}

	.documents-list .v-list__group__header {
		border-bottom: 2px solid rgba(0,0,0,.42);
	}

	.documents-list .v-list__group__header--active {
		border-bottom: 2px solid var(--v-primary-base);
	}

	.documents-list .v-list__group__header .v-icon {
		color: rgba(0,0,0,.42);
	}

	.documents-list .v-list__group__header--active .v-icon {
		color: var(--v-primary-base);
	}

	.documents-list .v-list__group > .v-list__group__header {
		background-color: rgba(0,0,0,0) !important;
	}

	.documents-list .v-list__group__header .employees-list__main-title {
		color: rgba(0,0,0,.42);
	}

	.documents-list .v-list__group__header--active .employees-list__main-title {
		color: var(--v-primary-base);
	}

	.notification-enter-active {
		transition: opacity 1s;
	}

	.notification-leave-active {
		transition: opacity 3s;
	}

	.notification-enter, .notification-leave-active {
		opacity: 0;
	}

	.notification-alert-frame {
		font: Verdana; 
		width:70%; 
		margin-left:15% !important;
		text-align: center !important;
		position: fixed !important;
        bottom: 150px !important; 
	}
</style>