<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"

USAGE
-------
<yes-no-dialog
  ref='yourReference'

  ...
></yes-no-dialog>
$refs.youReference.openDialog();

props:
  - title {String}
  - content {String}
  - yesButton {String} [Optional]
  - noButton {String} [Optional]

events:
  - yes-click
  - no-click

-->

<template>
	<v-dialog persistent v-model="dialog"
			  max-width="295">
		<v-card>
			<v-card-title class="headline">{{ title }}</v-card-title>

			<v-card-text style="text-align:justify">{{ content }}</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="green darken-1"
					   text
					   @click="noClick()">
					{{ noButtonValue }}
				</v-btn>

				<v-btn color="green darken-1"
					   text
					   @click="yesClick()">
					{{ yesButtonValue }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "yesNoDialog",
		mounted() {

		},
		data() {
			return {
				dialog: false,
			}
		},
		computed: {
			lmessages: { get: function () { return this.$t("commons.messages"); } },

			yesButtonValue: function () {
				return (this.yesButton != null) ? this.yesButton : this.lmessages.yes;
			},
			noButtonValue: function () {
				return (this.noButton != null) ? this.noButton : this.lmessages.no;
			}
		},
		props: {
			title: {
				type: String,
				required: true,
			},
			content: {
				type: String,
				required: true,
			},
			yesButton: {
				type: String,
				default: null,
			},
			noButton: {
				type: String,
				default: null
			}
		},
		methods: {
			openDialog() {
				this.dialog = true;
			},
			closeDialog() {
				this.dialog = false;
			},
			yesClick() {
				this.closeDialog();
				this.$emit('yes-click');
			},
			noClick() {
				this.closeDialog();
				this.$emit('no-click');
			},
		}
	};
</script>