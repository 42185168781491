<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "1.2"
__author__ = "Marcin Roszczyk, .."
__maintainer__ = "Shared Masters"
__email__ = "it@sharedmasters.com"
__status__ = "PROD"

-->

<template>
	<div class="home" style="padding:15px">
		<h4>Witaj {{ first_name }}</h4>
		<div class=" mdl-chip__text"> Kolejne kroki do wykonania</div>
		<div class="demo-card-square mdl-card mdl-shadow--2dp">
			<div class="mdl-card__supporting-text">
				<i class="material-icons">update</i> Badanie obowiązkowe..
			</div>
			<div class="mdl-card__actions mdl-card--border">
				<a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">
					Twój Starter
				</a>
				<ul class="demo-list-two mdl-list">
					<li class="mdl-list_item"> Twoja lista spraw do załatwienia</li>
					<li class="mdl-list__item">
						<span class="mdl-list__item-primary-content">
							<span>Przed przyjściem do pracy</span>
							<i class="material-icons">keyboard_arrow_right</i>
						</span>
					</li>
					<li class="mdl-list__item">
						<span class="mdl-list__item-primary-content">
							<span>Pierwszego dnia pracy</span>
							<i class="material-icons">keyboard_arrow_right</i>
						</span>
					</li>
				</ul>


			</div>
			<div class="mdl-card__actions mdl-card--border">
				<a class="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">
					Twoje Finanse
				</a>

				<div>
					<i class="material-icons">account_balance_wallet </i>
					Sprawdź ile zarobiłeś w kwietniu
				</div>

			</div>
		</div>


	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				logged_in: 'Użytkownik nie zautentykowany',
				username: '',
				first_name: '',
				last_name: ''
			}
		},
		props: {
            version: {
                type: String,
                required: true,
            },
            appConfig: {
                type: Object,
                required: true
            }
        },
		mounted() {
			if(this.appConfig.applicationMode != "MANAGER"){
                this.$store.dispatch("logout").then(() => {
					this.$router.push("/login");
				});
            }

			this.$parent.page_name = 'Home'

			this.whatUser()
		},
		methods: {
			whatUser: function () {
				if (localStorage.getItem('jwt') != null) {
					this.logged_in = "Authenticated"
					this.username = localStorage.getItem('username')
					this.first_name = localStorage.getItem('user_profile_first_name')

				} else {
					this.logged_in = 'Użytkownik nie zautentykowany'
					this.username = ''
					//console.log("ERROR ---> missing JWT in localStorage!")
				}
			}
		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	h1,
	h2 {
		font-weight: normal;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		color: #35495E;
	}
</style>
