
export default {
    themes: {
        light: {
            primary: {
                base: "#009f3d",
            
                darken1: "#006f0d",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                lighten1: "#53d16b",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            },
            
            secondary: {
                base: "#ffffff",
            
                darken1: "#cccccc",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                lighten1: "#ffffff",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            },
            
            //accent: {
                // base: "",
            
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            //},
            
            //error: {
                // base: "",
        
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            //},
            
            //info: {
                // base: "",
            
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            //},
            success: '04CA5A'
            /*success: {
                // base: "",
            
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
                },*/
            },
        dark: {
            primary: {
                base: "#009f3d",
            
                darken1: "#006f0d",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                lighten1: "#53d16b",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            },
            
            secondary: {
                base: "#ffffff",
            
                darken1: "#cccccc",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                lighten1: "#ffffff",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            },
            
            // accent: {
                // base: "",
            
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
        
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            // },
            
            // error: {
                // base: "",
            
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            // },
            
            // info: {
                // base: "",
            
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            // },
            
            // success: {
                // base: "",
            
                // darken1: "",
                // darken2: "",
                // darken3: "",
                // darken4: "",
            
                // lighten1: "",
                // lighten2: "",
                // lighten3: "",
                // lighten4: "",
                // lighten5: "",
            // },
        }
    }
}