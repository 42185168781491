// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import Vuex from 'vuex'
import store from './store.js'
import i18n from './i18n'
import VueClipboards from 'vue-clipboards'
import beforeRedirect from './before-redirect'
import VueAnalytics from 'vue-analytics'

import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import theme from './fob_theme.js'

import './fob_register_commons.js'
import './register_components';
import './registerServiceWorker'
import VueCookie from 'vue-cookies'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

// firebase 
import firebase from 'firebase/app';
import 'firebase/messaging';
import { firebaseConfig, vapidKey } from './firebase-messaging-config';

// Custom styles
import './assets/css/main.scss'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueClipboards)

const opts = {
    iconfont: 'mdi', // 'md' || 'mdi' || 'fa' || 'fa4',
    theme,
    options: {
        // This property allows us to use theme colors in styles
        customProperties: true
    }
}
Vue.use(Vuetify)

// Google Analytics config
Vue.use(VueAnalytics, {
    id: 'UA-131753647-2',
    router
})

// Vue Cookie initialization
Vue.use(VueCookie)

// Simplistic Vuetify Dialog initialization (https://www.npmjs.com/package/vuetify-dialog)
Vue.use(VuetifyDialog)

router.beforeEach((to, from, next) => {
    if (to.path != from.path) {
        try {
            beforeRedirect(to, from, next);
        } catch (error) {
            if (typeof(error) == "object") {
                if (error.error == "LOGIN_REQUIRED") {
                    var uri = "/login?next=" + error.next;

                    if (typeof(error.username) !== 'undefined') {
                        uri += ("&username=" + error.username);
                    }

                    var encoded = encodeURI(uri);
                    router.push(encoded);
                }
            } else if (error == "LOGIN REQUIRED") {
                router.push("/login");
            }
        }
    }
});

// const jwt = localStorage.getItem('jwt')
// if (jwt) {
//   Vue.timesheet.$http.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
//   Vue.timesheet.$http.defaults.headers.common['Content-Type'] = 'application/json'
// }

// Vue.directive('mdl', {
//   bind(el) {
//     window.componentHandler.upgradeElement(el);
//   },
// });

/* eslint-disable no-new */


const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    //template: '<App/>',
    render: h => h(App),
    components: {
        App
    },
    vuetify: new Vuetify(opts),

}).$mount('#app')

// initial firebase
const app_firebase = firebase.initializeApp(firebaseConfig);

if (app_firebase.options.apiKey != "") {
    const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

    if (messaging != null) {
        messaging.getToken({ vapidKey: vapidKey });

        messaging.getToken({ vapidKey: vapidKey }).then((currentToken) => {
            if (currentToken) {
                localStorage.setItem('firebase_token', currentToken);
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });

        messaging.onMessage((payload) => {
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
            };

            let notify = new Notification(notificationTitle, notificationOptions);
            notify.onclick = function(event) {
                event.preventDefault();
                window.open(payload.notification.click_action);
            }
        });
    }
}

//console.log('test deploy')