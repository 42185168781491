<!--
SharedMasters 2020 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2020, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"

<projects-pick-table
    :projectsList="Array"
    ref='yourReference'
>
$refs.yourReference.getChecked();

props:
- projectsList
    [
        {
            id: <Integer>,
            name: <String>
        }
    ]
- projectsListChecked
    [
        <Integer>, <Integer>, ...
    ]

functions:
- reload()
- getValue()

-->

<template>
    <div>
        <v-card elevation="1" align-center class="projects-table">
            <v-card-title class="projects-table-title" style="position:relative">
                {L Choose projects}
            </v-card-title>

            <v-card-subtitle class="projects-table-subtitle">
                {{ errorMessages.toString() }}
            </v-card-subtitle>

            <v-list
                style="width:100%; max-height: 110px; overflow-y: auto; 	overflow-x: hidden"
                dense
            >
            <v-list-item style="padding:0 5px" v-for="project in projectsList" :key="project.id">
                <v-flex xs12>
                    <v-checkbox
                        v-model="checkboxes[project.id.toString()]"
                        color="rgba(4, 202, 90, 1)"
                        :label="project.name"
                        style="margin-top: 0; margin-bottom: 0;"
                    ></v-checkbox>
                </v-flex>
            </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "nullableRating",
    data: () => ({
        /**
         * Stores copy of projects given within props
         */
        processedProjectsList: [],
        /**
         * Stores easy reference to project name by its id
         */
        projectNames: {},
        /**
         * Stores checkboxes states
         */
        checkboxes: {}
    }),
    mounted: function() {
        this.reload();
    },
    beforeMount: function() {

    },
    watch: {
    },
    computed: {
    },
    props: {
        /**
         * Array item structure
         *  {
         *      id: <Integer>,
         *      name: <String>    
         *  }
         */
        projectsList: {
            type: Array,
            required: true
        },
        /**
         * Array of integers
         */
        projectsListChecked: {
            type: Array,
            default: () => []
        },
        errorMessages: {
            type: Array,
            default: () => []
        }

    },
    methods: {
        /**
         * Reloads the component
         */
        reload(){
            this.processProjectsList();
            this.processProjectsListChecked();
        },
        /**
         * Retrieves currently selected projects
         */
        getValue() {
            var result = [];
            for (var property in this.checkboxes){
                if(this.checkboxes[property]){
                    result.push(Number.parseInt(property));
                }
            }
            return result;
        },
        processProjectsList() {
            this.processedProjectsList = [];
            this.checkboxes = {};
            this.projectNames = {};

            for(var i = 0; i < this.projectsList.length; i++){
                this.processedProjectsList.push({
                    id: this.projectsList[i].id,
                    name: this.projectsList[i].name
                });
                this.projectNames[this.projectsList[i].id] = this.projectsList[i].name;
                this.checkboxes[this.projectsList[i].id] = false;
            }
        },
        processProjectsListChecked() {
            for(var i = 0; i < this.projectsListChecked.length; i++){
                this.checkboxes[this.projectsListChecked[i]] = true;
            }
        }
    }
}
</script>

<style scoped>
.projects-table {
  padding: 0;
}

.projects-table-title {
    padding: 5px;
    border-bottom: none;
}
.projects-table-subtitle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 5px;
    color: #ff5252 !important
}
</style>

<style>
.projects-table .v-subheader {
  padding: 0;
}

.projects-table .v-list__tile {
  padding: 0;
}

.projects-table .v-list-item .flex {
    min-height: 28px;
    height: 32px;
}
.projects-table .v-list-item {
    min-height: 28px;
    line-height: 1;
    height: 32px;
}
</style>