export const firebaseConfig = {
    apiKey: "AIzaSyCncnAM3rXrp55j7sLEGz6XlRCeeH4hsyY",
    authDomain: "fob-demo.firebaseapp.com",
    projectId: "fob-demo",
    storageBucket: "fob-demo.appspot.com",
    messagingSenderId: "700423545950",
    appId: "1:700423545950:web:dd3503f5d99a85d38d80cf",
    measurementId: "G-0JHG3TEBHF"
};
  
  

export const vapidKey = "BGV7dxx4-OQnzK2cnZP688Ucetrx0O0zcf8KPCkaCAvyzkJPgHJT_nk3VuwNQsnThLFuRZiEdzOPuC7MGWljbwQ";