<template>
    <v-layout style="margin-top: 10px;">
        <!-- <v-btn
            v-model="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :transparent = false
            color="primary"
            fab
            style="position: fixed; bottom: 16px; right: 16px;"
            @click="openAddUser"
        >
            <v-icon>
                mdi-plus 
            </v-icon>
        </v-btn> -->

        <v-data-table
            :headers="dataTablesHeadersRendered"
            :items="dataTablesItems"
            :options.sync="options"
            class="elevation-1"
            hide-default-footer
        >
            <template v-slot:top>
                <v-flex xs12 style="padding-top: 10px;">

                    <!-- users label -->
                    <!-- <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10" style="display: flex; padding-top: 0px; padding-bottom: 0px;">
                        <div 
                            class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1" 
                            style = "margin-top: auto; margin-bottom: auto; background-color:lightgreen; font-weight:bold; font-size: 13px; text-align:center; padding: 0; border-radius: 5px; justify-content: center;  align-items: center;"
                        > 
                            {{ usersNumber }} 
                        </div>

                        <div 
                            class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3" 
                            style = "font-weight:bold;  font-size: 13px; justify-content: center;  align-items: center;"
                        > 
                            {{ lviews.users }} 
                        </div>
                    </div> -->

                    <div style="display: flex; flex-wrap: wrap;">
                        <!-- search field -->
                        <v-text-field
                            v-model="search"
                            :label="lviews.search"
                            style="height: 66px;"
                            clearable
                            append-icon="search"
                            class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"
                        ></v-text-field>

                        <div style="display: flex; height: 66px; padding-top: 0px; padding-bottom: 0px; padding-left: 0px;" class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <!-- status select -->
                            <v-select
                                v-model="selectedStatus"
                                :items="statuses"
                                :label = lviews.status
                                class="col-xl-4 col-lg-4 col-md-4 col-sm-8 col-xs-10"
                                @change="updatePageData"
                            ></v-select>

                            <!-- modify columns dialog trigger button -->
                            <v-btn
                                style="margin-top: 10px;"
                                color="primary"
                                @click="openModifyDataTableColumnsDialog"
                                small
                                fab
                            > 
                                <v-icon> mdi-settings </v-icon> 
                            </v-btn>
                        </div>
                    </div>
                </v-flex>
            </template>

            <template v-slot:[`item.options`]="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-dots-vertical
                        </v-icon>
                    </template>

                    <v-list style="min-width: 250px;">
                        <div
                            v-for="(group, groupIndex) in item.options"
                            :key="groupIndex"
                        >
                            <v-list-item v-for="(groupItem, groupItemKey) in group" :key="groupItemKey" class="worker-document-menu-option" :disabled="groupItem.disabled">
                                <v-flex :style="groupItem.style" @click="() => { groupItem.action(item); }">
                                    <v-icon :style="groupItem.style"> {{ groupItem.icon }} </v-icon>
                                    {{ groupItem.title }}
                                </v-flex>
                            </v-list-item>
                            <v-divider v-if="groupIndex < item.options.length - 1" style="border-bottom: 1px solid lightgray;"></v-divider>
                        </div>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-chip
                    :color="getColor(item.status)"
                    dark
                >
                    {{ item.statusText }}
                </v-chip>
            </template>

            <template v-slot:footer>
                <worker-document-data-table-footer
                    :lviews="lviews"
                    :itemsPerPageOptions="itemsPerPageOptions"

                    :pageNumber="options.page"
                    :itemsPerPageNumber="options.itemsPerPage"
                    @update-page-number="updatePageNumber"
                    @update-page-size="updatePageSize"

                    :totalItemsNumber="serverSideTotalItemsNumber"
                ></worker-document-data-table-footer>
            </template>
        </v-data-table>

        <v-dialog
            v-model="modifyDataTablesColumnsDialog"
            width="350"
            persistent
        >
            <v-card>
                <v-card-title style="font-size: 18px;"> {{lviews.chooseColumns}} </v-card-title>
                <v-list>
                    <v-list-item v-for="(option, index) in dataTableHeadersSelectItems" :key="index">
                        <v-checkbox
                            v-model="dataTableHeadersSelected"
                            :label="option.text"
                            :value="option.value"
                            style="padding:0; margin:0;"
                        ></v-checkbox>
                    </v-list-item>
                </v-list>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { modifyDataTablesColumnsDialog = false; }"> {{lviews.cancel}} </v-btn>
                    <v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { updateDataTablesColumns(); modifyDataTablesColumnsDialog = false; }" :disabled="dataTableHeadersSelected.length == 1"> {{lviews.save}} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar.visible"
            :color="snackbar.color"
        >
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <user-dialog
            ref="userDialog"
            :userId="userDialogUserId"
            :userData="userDialogUserData"
            :roles="userRolesData"
            :dataAccess="dataAccessData"
            @refresh-data="updatePageData"
            @show-overlayer="showOverlayer"
            @hide-overlayer="hideOverlayer"
        ></user-dialog>

        <!-- add new user button -->
        <v-btn
            color="primary"
            fab
            v-if="canUserAddNewUsers"
            @click="createNewUser"
            class="add-new-users-button"
        >
            <v-icon>
                mdi-plus 
            </v-icon>
        </v-btn>

        <!-- user set new value for 'is_active' confirmation dialog -->
        <v-dialog 
            persistent 
            v-model="userActiveStatusChangeDialog.visible"
			max-width="320"
        >
            <v-card>
                <v-card-title class="headline text-h5">{{ userActiveStatusChangeDialog.title }}</v-card-title>

                <v-card-text style="text-align:justify">{{ userActiveStatusChangeDialog.content }}</v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="grey darken-1"
                        text
                        @click="() => { userActiveStatusChangeDialog.visible = false; }">
                        {{ lviews.cancel }}
                    </v-btn>

                    <v-btn color="green darken-1"
                        text
                        @click="changeUserActiveStatus">
                        {{ lviews.yes }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import axios from 'axios';
import fob_config from '../fob_config.js';
export default {
    name: 'UserList',
    data() {
        return{
            fab: false,
            top: false,
            right: true,
            bottom: true,
            left: false,
            dialog: false,
            dialogDelete: false,
            options: {},
            search: '',
            dataTablesHeaders:[
                { id: 0, text: '', align: 'start', sortable: true, value: 'name' },
                { id: 1, text: '', sortable: true, value: 'birthDate' },
                { id: 2, text: '', sortable: true, value: 'role' },
                // Version with data access
                // { id: 3, text: '{L Dostęp do danych }', sortable: true, value: 'dataAccess'},
                // { id: 4, text: '{L Status }', sortable: true, value: 'status'},
                // { id: 5, text: '{L Ostatnio zalogowany }', sortable: true, value: 'lastLogin'},
                // { id: 6, text: '', value: 'options', sortable: false, class:"data-tables-header-field" }
                { id: 3, text: '', sortable: true, value: 'status'},
                { id: 4, text: '', sortable: true, value: 'lastLogin'},
                { id: 5, text: '', value: 'options', sortable: false, class:"data-tables-header-field" }
            ],
            dataTablesItems:[
                /*{
                    name: 'Kowalski Jan',
                    birthDate: '17.10.1982',
                    role: 'Administrator',
                    rolesIds [{Number}, ...],
                    dataAccess: 'Wszystkie rekordy',
                    status: 'Aktywny',
                    lastLogin: '01.07.2021',
                    options:[
                        [
                            { 
                                icon: {String}, 
                                title: "{String}", 
                                action: {Function} 
                            }
                        ],
                    ]
                }*/
            ],
            dataTablesItemsSourceData: [
                // User Profile serialized data
            ],

            dataTableHeadersSelectItems: [
                /**
                 * {
                 *      value: {Integer},
                 *      text: {String}
                 * }
                 */
                {
                    value:0,
                    text:"Nazwisko i imię"
                },
                {
                    value:1,
                    text:"Data urodzenia"
                },
                {
                    value:2,
                    text:"Rola"
                },
                // Version with data access
                // {
                //     value:3,
                //     text:"Dostęp do danych"
                // },
                // {
                //     value:4,
                //     text:"Status"
                // },
                // {
                //     value:5,
                //     text:"Ostatnio zalogowany"
                // }
                {
                    value:3,
                    text:"Status"
                },
                {
                    value:4,
                    text:"Ostatnio zalogowany"
                }
            ],
            modifyDataTablesColumnsDialog: false,
            dataTablesHeadersRendered: [],
            dataTableHeadersSelected: [],
            fab: false,
            statuses: [
                {
                    text: "",
                    value: -1
                },
                {
                    text: "",
                    value: 1
                },
                {
                    text: "",
                    value: 0
                },
            ],
            selectedRoles:[],
            selectedStatus:[],
            screenWidth:null,

            options: {},
            itemsPerPageOptions: [5, 10, 15, 30, 50, 100, 500],
            serverSideTotalItemsNumber: 0,

            preventUpdate: false,
            searchTimeout: null,

            activeUsersNumber: 0,
            administratorsNumber: 0,
            usersNumber: 0,

            snackbar: {
                visible: false,
                color: "primary",
                message: ""
            },

            userDialogUserId: null,
            userDialogUserData: {},

            userRolesData: [],
            dataAccessData: [],
            
            // user active status dialog data
            userActiveStatusChangeDialog: {
                visible: false,
                title: "",
                content: "",
                user: null,
            }
        };
    },
    beforeMount() {
        this.screenWidth = window.innerWidth;
        // Version with data access
        // this.dataTablesHeaders[0].text = this.lviews.firstAndLastName;
        // this.dataTablesHeaders[1].text = this.lviews.birthDate;
        // this.dataTablesHeaders[2].text = this.lviews.role;
        // this.dataTablesHeaders[3].text = this.lviews.dataAccess;
        // this.dataTablesHeaders[4].text = this.lviews.status;
        // this.dataTablesHeaders[5].text = this.lviews.lastLogin;
        // this.dataTableHeadersSelectItems[0].text = this.lviews.firstAndLastName;
        // this.dataTableHeadersSelectItems[1].text = this.lviews.birthDate;
        // this.dataTableHeadersSelectItems[2].text = this.lviews.role;
        // this.dataTableHeadersSelectItems[3].text = this.lviews.dataAccess;
        // this.dataTableHeadersSelectItems[4].text = this.lviews.status;
        // this.dataTableHeadersSelectItems[5].text = this.lviews.lastLogin;

        this.dataTablesHeaders[0].text = this.lviews.firstAndLastName;
        this.dataTablesHeaders[1].text = this.lviews.birthDate;
        this.dataTablesHeaders[2].text = this.lviews.role;
        this.dataTablesHeaders[3].text = this.lviews.status;
        this.dataTablesHeaders[4].text = this.lviews.lastLogin;
        this.dataTableHeadersSelectItems[0].text = this.lviews.firstAndLastName;
        this.dataTableHeadersSelectItems[1].text = this.lviews.birthDate;
        this.dataTableHeadersSelectItems[2].text = this.lviews.role;
        this.dataTableHeadersSelectItems[3].text = this.lviews.status;
        this.dataTableHeadersSelectItems[4].text = this.lviews.lastLogin;
    },
    mounted: async function() {
        this.$emit('set-title', this.lviews.userListTitle);
        this.$emit("set-display", "back_window");
        let userPermissions = localStorage.getItem("user_permissions");
        if ( !userPermissions.includes("fob.admin_view") ) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return;
        }

        this.statuses[0].text = this.lviews.all;
        this.statuses[1].text = this.lviews.active;
        this.statuses[2].text = this.lviews.inactive;

        this.preventUpdate = true;

        this.dataTablesHeadersRendered = [];
        this.dataTableHeadersSelected = [];
        for(var i = 0; i < this.dataTablesHeaders.length; i++){
            this.dataTablesHeadersRendered.push(this.dataTablesHeaders[i]);
            this.dataTableHeadersSelected.push(i);
        }
        window.addEventListener('resize', ()=>{
            this.screenWidth = window.innerWidth;
        });
        let userListData = await this.fetchUserListData();
        
        if (userListData != null) {
            this.adaptUserListData(userListData);
        }
    },
    computed: {
        lviews: {
            get: function() {
                return this.$t('views.userListPanel');
            }
        },
        canUserAddNewUsers: {
            get: function() {
                return true;
            }
        }
    },
    watch: {
        search: function() {
            if (!this.preventUpdate) {
                if (this.searchTimeout != null) {
                    clearTimeout(this.searchTimeout);
                }
                var that = this;
                this.searchTimeout = setTimeout(function(){
                    that.updatePageData();
                }, 500);
            }
            else {
                this.preventUpdate = false;
            }
        },
    },
    methods: {
        /**
         * Opens dedicated to create new / edit user dialog.
         */
        createNewUser: function() {
            this.userDialogUserId = null;
            this.userDialogUserData = {};
            this.$nextTick(() => { this.$refs.userDialog.openDialog(); });
        },

        /**
         * Opens dedicated to user edit dialog.
         * @param user User data object to edit. Its structure is presented below
         */
        editUserRow: function(user) {
            let originalUserData = this.dataTablesItemsSourceData.find(x => { return x.application_user.id == user.userId; });
            this.userDialogUserId = user.userId;
            this.userDialogUserData = { ...user, ...originalUserData };
            this.$nextTick(() => { this.$refs.userDialog.openDialog(); });
        },

        /**
         * Sends API request to server (in order to send activation account mail to user)
         * @param user - adapted user data. Its structure is presented below
         * 
         * {
         *      username: {String},
         *      name: {String},
         *      birthDate: {String},
         *      role: {String},
         *      dataAccess: {String},
         *      status: {Boolean},
         *      statusText: {String},
         *      lastLogin: {String},
         *      options: {Array of objects}
         * }
         */
        sendActivationMail: async function(user) {
            this.$emit('set-state', "LOADING_OVERLAYER", this.lviews.sendingEmail);
            let response = null;
            
            try {
                response = await axios({
                    url: fob_config.api_url + "/api/v2/admin/user-activation-mail/" + user.userId,
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    }
                });
            } catch (error) {
                this.$emit("set-state", "default");
                if (error.isAxiosError) {
                    switch(error.response.status) {
                        case 400:
                            this.snackbar.color = "error";
                            this.snackbar.message = this.lviews.invalidUserIdentifier;
                            this.snackbar.visible = true;
                            break;
                        case 423:
                            this.snackbar.color = "error";
                            this.snackbar.message = this.lviews.couldNotSendMailServerBusy;
                            this.snackbar.visible = true;
                            break;
                        case 500:
                            this.snackbar.color = "error";
                            this.snackbar.message = this.lviews.couldNotSendMail;
                            this.snackbar.visible = true;
                            break;
                    }
                }
            }

            if (response != null) {
                this.$emit("set-state", 'default');
                this.snackbar.color = "success";
                this.snackbar.message = this.lviews.mailSent;
                this.snackbar.visible = true;
            }
        },

        /**
         * Sends request with new user active status.
         */
        changeUserActiveStatus: async function() {
            let requestData = {
                auth_user: {
                    user_active: this.userActiveStatusChangeDialog.user.blocked ? 1 : 0
                }
            };

            let response = null;
            if (this.userActiveStatusChangeDialog.user.blocked) {
                this.$emit("set-state",  "LOADING_OVERLAYER", this.lviews.unblockingAccountInProgress);
            }
            else {
                this.$emit("set-state",  "LOADING_OVERLAYER", this.lviews.blockingAccountInProgress);
            }

            try {
                response = await axios({
                    url: fob_config.api_url + "/api/v2/admin/user/" + this.userActiveStatusChangeDialog.user.userId,
                    method: "PATCH",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
                        'Content-Type': 'application/json'
                    },
                    data: requestData
                });
            } catch (error) {
                response = null;
                this.$emit('set-state', 'default');

                switch(error.response.status) {
                    case 400:
                        this.$emit('display-error', this.lviews.errorOccurred, this.lviews.invalidRequestData);
                        break;
                    case 401:
                        this.$router.push("/login?next=/user-list");
                        break;
                    case 403:
                        this.$router.push("/login?next=/user-list");
                        break;
                    case 404:
                        if (requestData.auth_user.user_active) {
                            this.$emit('display-error', this.lviews.errorOccurred, this.lviews.couldNotFindUserToUnblock);
                        }
                        else {
                            this.$emit('display-error', this.lviews.errorOccurred, this.lviews.couldNotFindUserToBlock);
                        }
                        break;
                    case 429:
                        this.$emit('display-error', this.lviews.waitAMinute, this.lviews.tooManyRequestsSent);
                        break;
                    default:
                        this.$emit('display-error', this.lviews.errorOccurred, this.lviews.errorOccurredThatsAllWeKnow);
                        break;
                }
            }
            this.$emit('set-state', 'default');

            if (response != null) {
                // handle response
                let index = this.dataTablesItems.findIndex(x => { return x.userId == this.userActiveStatusChangeDialog.user.userId; });
                this.dataTablesItems[index].blocked = !this.dataTablesItems[index].blocked;
                console.log(this.dataTablesItems[index].options);
                if (this.dataTablesItems[index].blocked) {
                    this.dataTablesItems[index].options[0][2].title = this.lviews.unblock; 
                }
                else {
                    this.dataTablesItems[index].options[0][2].title = this.lviews.block; 
                }
                this.userActiveStatusChangeDialog.visible = false;
            }
        },

        /**
         * Opens modify user block status dialog
         */
        openModifyBlockedUserStatusDialog: async function(user) {
            if (user.blocked) {
                this.userActiveStatusChangeDialog.title = this.lviews.unblockUser;
                this.userActiveStatusChangeDialog.content = this.lviews.areYouSureYouWantToUnblockUser.replace("{fullname}", user.name).replace("{username}", user.username);
            }
            else {
                this.userActiveStatusChangeDialog.title = this.lviews.blockUser;
                this.userActiveStatusChangeDialog.content = this.lviews.areYouSureYouWantToBlockUser.replace("{fullname}", user.name).replace("{username}", user.username);
            }

            this.userActiveStatusChangeDialog.user = user;
            this.userActiveStatusChangeDialog.visible = true;
        },

        /** 
         * Determine user permissions and assign options.
         * @param isUserActivated - {Boolean} is this user account activated.
         * @param userBlocked {Boolean} Information whether specific user is blocked or not.
         * */ 
        checkUserPermissions: function(isUserActivated, userBlocked) {
            let optionsToAssign = [];
            let tmpOptions = [];
            let usersPermissions = localStorage.getItem("user_permissions");
            if (usersPermissions.includes("fob.admin_change")) {
                tmpOptions.push(
                    { icon: "mdi-border-color", title: this.lviews.editData, action: this.editUserRow, disabled: false },
                    { icon: "mdi-email-outline", title: this.lviews.sendActivationMail, action: this.sendActivationMail, disabled: isUserActivated},
                    // { icon: "mdi-lock", title: "{L Zresetuj hasło }", action: "" , disabled: false},
                );
                // More options
                if (userBlocked){
                    tmpOptions.push(
                        { icon: "mdi-replay", title: this.lviews.unblock, action: this.openModifyBlockedUserStatusDialog, disabled: false},
                    )
                }
                else{
                    tmpOptions.push(
                        { icon: "mdi-replay", title: this.lviews.block, action: this.openModifyBlockedUserStatusDialog, disabled: false},
                    );
                }    
            }
            optionsToAssign.push(
                tmpOptions
            )
            return optionsToAssign;
        },
        // fetches users data from server
        updatePageData: async function() {
            let userListData = await this.fetchUserListData();
            this.adaptUserListData(userListData);
        },

        /**
         * Updates page number
         * @param newPageNumber - {Number} new page number
         */
        updatePageNumber: async function(newPageNumber) {
            this.options.page = newPageNumber;
            this.updatePageData();
        },

        /**
         * Updates items per page number
         * @param newPageSize - {Number} new items per page number
         */
        updatePageSize: async function(newPageSize) {
            this.options.itemsPerPage = newPageSize;
            this.updatePageData();
        },

        openModifyDataTableColumnsDialog: function() {
            this.dataTableHeadersSelected = [];
            for(var i = 0; i < this.dataTablesHeadersRendered.length; i++) {
                this.dataTableHeadersSelected.push(this.dataTablesHeadersRendered[i].id);
            }

            this.modifyDataTablesColumnsDialog = true;
        },
        updateDataTablesColumns: function(){
            this.dataTablesHeadersRendered = [];
            this.dataTableHeadersSelected.sort((a, b) => { return a < b ? -1 : 1; });
            for(var i = 0; i < this.dataTableHeadersSelected.length; i++){
                this.dataTablesHeadersRendered.push(
                    this.dataTablesHeaders[ this.dataTableHeadersSelected[i] ]
                );
            }
        },
        getColor (status) {
            if (status) return 'success';
            else return 'red';
        },
        fetchUserListData: async function() {
            let apiLink = fob_config.api_url + "/api/v2/admin/user/?page=" + this.options.page + "&per_page=" + this.options.itemsPerPage;
            

            if (this.search != null && this.search != "") {
                apiLink += ("&search=" + this.search);
            }

            if (this.selectedStatus != null && (this.selectedStatus.toString() == "0" || this.selectedStatus.toString() == "1")) {
                apiLink += ("&active_users=" + this.selectedStatus.toString());
            }

            let responsePromise = axios({
                method: "GET",
                url: apiLink,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                // handle errors
                // #TODO Handle 403 error (Forbiden)
                return null;
            }
            return response.data;
        },
        adaptUserListData: async function(userListData) {
            this.activeUsersNumber = 0;
            this.usersNumber = userListData.users_overall;
            // this.administratorsNumber = userListData.administrators_overall;
            this.dataTablesItems = [];
            this.serverSideTotalItemsNumber = userListData.total;

            let rolesData = await this.fetchUserRoles();
            this.userRolesData = rolesData.roles;
            this.dataAccessData = rolesData.data_sharing_roles;
            this.dataTablesItemsSourceData = [];

            userListData.items.user_profile.forEach(item => {
                this.dataTablesItemsSourceData.push(item);

                let optionsToAssign = this.checkUserPermissions(item.application_user.account_activated);
                let newRoles = [], newRolesIds = [];
                let userDataSharingRoles = [], userDataSharingRolesIds = [];
                for(var i = 0; i < item.application_user.groups.length; i++){
                    // getting user roles 
                    for(var j = 0; j < rolesData.roles.length; j++){
                        if (item.application_user.groups[i] == rolesData.roles[j].id) {
                            newRoles.push(rolesData.roles[j].name);
                            newRolesIds.push(rolesData.roles[j].id);
                        }
                    }

                    // getting user data sharing roles
                    for (let j = 0; j < rolesData.data_sharing_roles.length; j++) {
                        if (item.application_user.groups[i] == rolesData.data_sharing_roles[j].id) {
                            userDataSharingRoles.push(rolesData.data_sharing_roles[j].name);
                            userDataSharingRolesIds.push(rolesData.data_sharing_roles[j].id);
                        }
                    }
                }
                if (newRoles.length > 0) {
                    newRoles = newRoles.join(", ");
                } else {
                    newRoles = this.lviews.lackOfData;
                }

                if (userDataSharingRoles.length > 0) {
                    userDataSharingRoles = userDataSharingRoles.join(", ");
                } else {
                    userDataSharingRoles = this.lviews.lackOfData;
                }

                var active = '';
                if (item.application_user.account_activated){
                    active = true;
                }
                else {
                    active = false;
                }

                this.dataTablesItems.push(
                    {
                        userId: item.application_user.id,
                        username: item.application_user.username,
                        name: item.last_name + " " + item.first_name,
                        birthDate: item.birth_date,
                        role: newRoles,
                        rolesIds: newRolesIds,
                        dataAccess: userDataSharingRoles,
                        dataAccessIds: userDataSharingRolesIds,
                        status: active,
                        statusText: active ? this.lviews.activeStatus : this.lviews.inactiveStatus,
                        lastLogin: item.application_user.last_login ? item.application_user.last_login : '-',
                        deletable: item.application_user.deletable,
                        blocked: !item.application_user.is_active,
                        options: optionsToAssign
                    }
                );
                //console.log(item);
            });
        },
        fetchUserRoles: async function(){
            let apiLink = fob_config.api_url + "/api/v2/admin/user-roles";
            let responsePromise = axios({
                method: "GET",
                url: apiLink,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            });
            let response = null;
            try {
                response = await responsePromise;
            } catch (error) {
                this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
                // handle errors
                // #TODO Handle 403 error (Forbiden)
                return null;
            }
            return response.data;
        },
        openAddUser(){
            this.$router.push('/add-user');
        },

        // prop methods for user-dialog

        /**
         * Shows loading overlayer when sending request
         */
        showOverlayer: function(message) {
            this.$emit("set-state", "LOADING_OVERLAYER", message);
        },

        /**
         * Hides loading overlayer after receiving response
         */
        hideOverlayer: function() {
            this.$emit("set-state", "DEFAULT");
        }
    }
}
</script>
<style>
    .worker-document-menu-option {
        background-color: white;
    }
    .worker-document-menu-option:hover {
        background-color: #e4e4e4;
    }
</style>
<style scoped>
    .document-name-cut-when-too-long {
        max-width: 170px;
        overflow: hidden; 
        white-space: nowrap; 
        text-overflow: ellipsis;
    }
    .data-tables-header-field {
    background-color: rgb(83,241,107)!important;
    color: rgb(62, 51, 51) !important;
    }
    .add-new-users-button {
        position: fixed !important;
        bottom: 25px !important;
        right: 25px !important;
    }
</style>