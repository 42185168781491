<template>
    <v-container class="permissions-container" style="min-width:350px;">
        <v-row
            key= 1
            justify="space-around"
        >
        <div class="groups-table">
            <v-card-actions style='background: #bdbdbd; margin: auto; height: 40px; margin-top: 20px; margin-left:10px; margin-right:10px;'>
                <v-card-text color="black" style = 'font-weight:bold;' text>
                    {{lview.mdlRole}}
                </v-card-text>
            </v-card-actions>

            <!-- Dialog to delete MDL group -->
            <v-dialog
                v-model="dialogMdlDelete"
                width="350px"
                persistent
            >
                <v-card >
                    <v-card-title>
                        <span> {{lview.deleteGroup}} </span>
                    </v-card-title>

                    <v-card-text>
                        <span> {{lview.deleteGroupContent}} </span>
                        <br/><br/>
                        <strong> {{lview.groupName}}: </strong> <div> {{ editedMdlName }} </div>
                    </v-card-text>
                    
                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeMdlDelete" text :disabled="dialogMdlDeleteDisabled">
                            {{lview.cancelButton}}
                        </v-btn>
                        <v-btn @click="deleteMdlItemConfirm" color="red" text :disabled="dialogMdlDeleteDisabled">
                            <strong> {{lview.deleteButton}} </strong>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Dialog to duplicate MDL group -->
            <v-dialog
            v-model="dialogMdlDuplicate"
            width="350px"
            persistent
            >
                <v-card >
                    <v-card-title>
                        <span> {{lview.duplicateGroup}} </span>
                    </v-card-title>

                    <v-card-text>
                        <span> {{lview.duplicateGroupContent}} </span>
                        <strong> {{ editedMdlName }}</strong>?
                    </v-card-text>
                    
                    <v-text-field
                        v-model="dialogMdlDuplicateGroupName"
                        :disabled="dialogMdlDuplicateInputDisabled"
                        :label="lview.groupName"
                        style="margin-left: 7%; margin-right: 5%;"
                        @input="dialogMdlDuplicateNewGroupName"
                    ></v-text-field>                

                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeMdlduplicate" text :disabled="dialogMdlDuplicateCancelDisabled">
                            {{lview.cancelButton}}
                        </v-btn>
                        <v-btn @click="confirmMdlduplicate" color="primary" text :disabled="dialogMdlDuplicateSaveDisabled">
                            <strong> {{lview.createButton}} </strong>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-simple-table
                fixed-header
                :height="heightTableMdl"
                style="margin-bottom: 20px; margin-left:10px; margin-right:10px;"
            >
                <template v-slot:default>
                <thead>
                    <tr>
                        <th> {{lview.headerName}} </th>
                        <th style="width:100px; text-align:center"> {{lview.headerUsers}} </th>
                        <th style="width:130px; text-align:center"> {{lview.headerActions}} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in mdl_groups"
                    :key="item.id"
                    >
                    <td>{{ item.name }}</td>
                    <td style="width: 100px; text-align:center">{{ item.users }}</td>
                    <td style="width:130px; text-align:center">
                        <template>                            
                            <v-icon
                                v-if="displayEdit"
                                class="mr-2"
                                @click="$router.push('/mdl-config/' + item.id)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                v-if="displayAdd"
                                class="mr-2"
                                @click="duplicateMdlItem(item)"
                                display="true"
                            >
                                mdi-content-copy
                            </v-icon>
                            <v-icon
                                v-if="displayDelete"
                                class="mr-2"
                                @click="deleteMdlItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>

            <div style="text-align:center">
                <v-btn
                    fab
                    dark
                    small
                    style="margin-bottom: 20px"
                    v-if="displayAdd"
                    color="primary"
                    @click="$router.push('/mdl-config/')"
                >
                    <v-icon>mdi-plus</v-icon>
                <!--{{lview.addGroup}}-->
                </v-btn>
            </div>
        </div>

        <!-- DS GROUPS -->
        <div class="groups-table">
            <v-card-actions style='background: #bdbdbd; margin: auto; height: 40px; margin-top: 20px; margin-left:10px; margin-right:10px;'>
                <v-card-text color="black" style = 'font-weight:bold;' text>
                    {{lview.dsRole}}
                </v-card-text>
            </v-card-actions>

            <!-- Dialog to delete DS group -->
            <v-dialog
                v-model="dialogDsDelete"
                width="350px"
                persistent
            >
                <v-card >
                    <v-card-title>
                        <span> {{lview.deleteGroup}} </span>
                    </v-card-title>

                    <v-card-text>
                        <span> {{lview.deleteGroupContent}} </span>
                        <br/><br/>
                        <strong> {{lview.groupName}}: </strong> <div> {{ editedDsName }} </div>
                    </v-card-text>
                    
                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDsDelete" text :disabled="dialogDsDeleteDisabled">
                            {{lview.cancelButton}}
                        </v-btn>
                        <v-btn @click="deleteDsItemConfirm" color="red" text :disabled="dialogDsDeleteDisabled">
                            <strong> {{lview.deleteButton}} </strong>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Dialog to duplicate DS group -->
            <v-dialog
            v-model="dialogDsDuplicate"
            width="350px"
            persistent
            >
                <v-card>
                    <v-card-title>
                        <span> {{lview.duplicateGroup}}</span>
                    </v-card-title>

                    <v-card-text>
                        <span> {{lview.duplicateGroupContent}} </span>
                        <strong> {{ editedDsName }}</strong>?
                    </v-card-text>
                    
                    <v-text-field
                        v-model="dialogDsDuplicateGroupName"
                        :disabled="dialogDsDuplicateInputDisabled"
                        :label="lview.groupName"
                        style="margin-left: 7%; margin-right: 5%;"
                        @input="dialogDsDuplicateNewGroupName"
                    ></v-text-field>                

                    <v-progress-linear
                        v-if="loading"
                        indeterminate
                        color="primary"
                    ></v-progress-linear>
                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeDsduplicate" text :disabled="dialogDsDuplicateCancelDisabled">
                            {{lview.cancelButton}}
                        </v-btn>
                        <v-btn @click="confirmDsduplicate" color="primary" text :disabled="dialogDsDuplicateSaveDisabled">
                            <strong> {{lview.createButton}} </strong>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-simple-table
                fixed-header
                :height="heightTableDs"
                style="margin-bottom: 20px; margin-left:10px; margin-right:10px;"
            >
                <template v-slot:default>
                <thead>
                    <tr>
                        <th> {{lview.headerName}} </th>
                        <th style="width:100px; text-align:center"> {{lview.headerUsers}} </th>
                        <th style="width:130px; text-align:center"> {{lview.headerActions}} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in ds_groups"
                    :key="item.id"
                    >
                    <td>{{ item.name }}</td>
                    <td style="width:100px; text-align:center">{{ item.users }}</td>
                    <td style="width:130px; text-align:center">
                        <template>
                            <v-icon
                                v-if="displayEdit"
                                class="mr-2"
                                @click="$router.push('/ds-config/' + item.id)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                v-if="displayAdd"
                                class="mr-2"
                                @click="duplicateDsItem(item)"
                            >
                                mdi-content-copy
                            </v-icon>
                            <v-icon
                                v-if="displayDelete"
                                class="mr-2"
                                @click="deleteDsItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>

            <div style="text-align:center">
                <v-btn
                    fab
                    dark
                    small
                    style="margin-bottom: 20px"
                    v-if="displayAdd"
                    color="primary"
                    @click="$router.push('/ds-config/')"
                >
                    <v-icon>mdi-plus</v-icon>
                <!--{{lview.addGroup}}-->
                </v-btn>
            </div>
        </div>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

  export default {
    data: () => ({
      loading: false,
      headers: [
        { id: 0, text: '', value: 'name' },
        { id: 1, text: '', value: 'users' },
        { id: 2, text: '', value: 'actions' },
      ],      
      // ds data
      dialogDsDelete: false,
      dialogDsDeleteDisabled: false,
      dialogDsDuplicateGroupName: '',
      dialogDsDuplicate: false,
      dialogDsDuplicateSaveDisabled: true,
      dialogDsDuplicateCancelDisabled: false,
      dialogDsDuplicateInputDisabled: false,
      ds_groups: [],
      editedDsIndex: -1,
      editedDsId: -1,
      editedDsName: '',
      heightTableDs: null,

      // mdl data
      dialogMdlDuplicate: false,
      dialogMdlDeleteDisabled: false,
      dialogMdlDuplicateGroupName: '',
      dialogMdlDuplicateSaveDisabled: true,
      dialogMdlDuplicateCancelDisabled: false,
      dialogMdlDuplicateInputDisabled: false,
      dialogMdlDelete: false,
      mdl_groups: [],
      editedMdlIndex: -1,
      editedMdlId: -1,
      editedMdlName: '',
      heightTableMdl: null,

      // display icons
      displayEdit: true,
      displayDuplicate: true,
      displayDelete: true,
      displayAdd: true,
    }),

    methods: {
      // mdl methods  
      checkLenMdlTable() {
          if (this.mdl_groups.length >=7) {
              this.heightTableMdl = '350px';
          } else {
              this.heightTableMdl = null;
          }
      },  
      deleteMdlItem (item) {
        this.dialogMdlDelete = true;
        this.editedMdlIndex = this.mdl_groups.indexOf(item);
        this.editedMdlId = item.id;
        this.editedMdlName = item.name;
      },

      async deleteMdlItemConfirm () {
        let deleteResponse = null;
        this.loading = true;
        this.dialogMdlDeleteDisabled = true;

        if (this.editedMdlId != -1) {
            try {
                deleteResponse = await axios({
                    method: "DELETE",
                    url: localStorage.getItem("current_env") + "/api/v2/admin/groups/" + this.editedMdlId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                });
            } catch (error) {
                if (error.isAxiosError) {
                    if (error.response.status == 403) {
                        this.closeMdlDelete();  
                        this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
                        return;  
                    }
                }

                this.closeMdlDelete();
                this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
                return;
            }

            if (deleteResponse.status == 204) {
                this.mdl_groups.splice(this.editedMdlIndex, 1);
                this.checkLenMdlTable();
            }
        }

        this.closeMdlDelete();
      },

      closeMdlDelete () {
        this.dialogMdlDelete = false;
        this.loading = false;
        this.dialogMdlDeleteDisabled = false;

        this.$nextTick(() => {
          this.editedMdlIndex = -1;
          this.editedMdlId = -1;
          this.editedMdlName = '';
        });
      },

      dialogMdlDuplicateNewGroupName () {
        if (this.dialogMdlDuplicateGroupName.length > 0) {
            this.dialogMdlDuplicateSaveDisabled = false;   
        } else {
            this.dialogMdlDuplicateSaveDisabled = true;      
        }
      },

      duplicateMdlItem (item) {
        this.dialogMdlDuplicate = true;
        this.editedMdlIndex = this.mdl_groups.indexOf(item);
        this.editedMdlId = item.id;
        this.editedMdlName = item.name;
      },

      async confirmMdlduplicate () {
          this.loading = true;
          this.dialogMdlDuplicateSaveDisabled = true;
          this.dialogMdlDuplicateCancelDisabled = true;
          this.dialogMdlDuplicateInputDisabled = true;

          if (this.editedMdlId != -1) {
              let addResponse;
            try {
                addResponse = await axios({
                    method: "POST",
                    url: localStorage.getItem("current_env") + "/api/v2/admin/groups-duplicate/" + this.editedMdlId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    data: {
                        name: this.dialogMdlDuplicateGroupName,
                    }
                });
            } catch (error) {
                if (error.isAxiosError) {
                    if (error.response.status == 403) {
                        this.closeMdlduplicate();  
                        this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
                        return;  
                    }

                    if (error.response.data.name != null && error.response.data.name != undefined) {
                        this.$emit('display-error', this.lerrors.permissions.name.header, this.lerrors.permissions.name.string, String(error));
                        this.closeMdlduplicate();
                        return;                    
                    }
                }

                // default error
                this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
                this.closeMdlduplicate();
                return;
            }

            if (addResponse.status == 201) {
                let response = addResponse.data;

                this.mdl_groups.push({
                    'id': response.id,
                    'name': response.name,
                    'users': response.users_count
                });

                this.checkLenMdlTable();
            }
          }

          this.closeMdlduplicate();  
      },

      closeMdlduplicate () {
        this.dialogMdlDuplicate = false;
        this.loading = false;
        this.dialogMdlDuplicateCancelDisabled = false;
        this.dialogMdlDuplicateInputDisabled = false;
        this.dialogMdlDuplicateSaveDisabled = true;
        this.dialogMdlDuplicateGroupName = '';

        this.$nextTick(() => {
          this.editedMdlIndex = -1;
          this.editedMdlId = -1;
          this.editedMdlName = '';
        });
      },

      // ds methods
      checkLenDsTable() {
          if (this.ds_groups.length >=7) {
              this.heightTableDs = '350px';
          } else {
              this.heightTableDs = null;
          }
      },  
      dialogDsDuplicateNewGroupName () {
        if (this.dialogDsDuplicateGroupName.length > 0) {
            this.dialogDsDuplicateSaveDisabled = false;   
        } else {
            this.dialogDsDuplicateSaveDisabled = true;      
        }
      },

      duplicateDsItem (item) {
        this.dialogDsDuplicate = true;
        this.editedDsIndex = this.ds_groups.indexOf(item);
        this.editedDsId = item.id;
        this.editedDsName = item.name;
      },

      async confirmDsduplicate () {
          this.loading = true;
          this.dialogDsDuplicateSaveDisabled = true;
          this.dialogDsDuplicateCancelDisabled = true;
          this.dialogDsDuplicateInputDisabled = true;

          if (this.editedDsId != -1) {
              let addResponse;
            try {
                addResponse = await axios({
                    method: "POST",
                    url: localStorage.getItem("current_env") + "/api/v2/admin/groups-duplicate/" + this.editedDsId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                    data: {
                        name: this.dialogDsDuplicateGroupName,
                    }
                });
            } catch (error) {
                if (error.isAxiosError) {
                    if (error.response.status == 403) {
                        this.closeDsduplicate();  
                        this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
                        return;  
                    }

                    if (error.response.data.name != null && error.response.data.name != undefined) {
                        this.$emit('display-error', this.lerrors.permissions.name.header, this.lerrors.permissions.name.string, String(error));
                        this.closeDsduplicate();
                        return;                    
                    }
                    
                }

                // default error
                this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
                this.closeDsduplicate();
                return;
            }

            if (addResponse.status == 201) {
                let response = addResponse.data;

                this.ds_groups.push({
                    'id': response.id,
                    'name': response.name,
                    'users': response.users_count
                });

                this.checkLenDsTable();
            }
          }
          this.closeDsduplicate();  
      },

      closeDsduplicate () {
        this.dialogDsDuplicate = false;
        this.loading = false;
        this.dialogDsDuplicateCancelDisabled = false;
        this.dialogDsDuplicateInputDisabled = false;
        this.dialogDsDuplicateGroupName = '';
        this.dialogDsDuplicateSaveDisabled = true;

        this.$nextTick(() => {
          this.editedDsIndex = -1;
          this.editedDsId = -1;
          this.editedDsName = '';
        });
      },

      deleteDsItem (item) {
        this.dialogDsDelete = true;
        this.editedDsIndex = this.ds_groups.indexOf(item);
        this.editedDsId = item.id;
        this.editedDsName = item.name;
      },

      async deleteDsItemConfirm () {
        let deleteResponse = null;
        this.loading = true;
        this.dialogDsDeleteDisabled = true;

        if (this.editedDsId != -1) {
            try {
                deleteResponse = await axios({
                    method: "DELETE",
                    url: localStorage.getItem("current_env") + "/api/v2/admin/groups/" + this.editedDsId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    },
                });
            } catch (error) {
                if (error.isAxiosError) {
                    if (error.response.status == 403) {
                        this.closeDsDelete();  
                        this.$emit('display-error', this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.header, this.lerrors.permissions.youDoNotHavePriviledgesToDoThatOperation.string, String(error));
                        return;  
                    }
                }

                this.closeDsDelete();
                this.$emit('display-error', this.lerrors.permissions.default_error.header, this.lerrors.permissions.default_error.string, String(error));
                return;
            }

            if (deleteResponse.status == 204) {
                this.ds_groups.splice(this.editedDsIndex, 1);
                this.checkLenDsTable();
            }
        }

        this.closeDsDelete()
      },

      closeDsDelete () {
        this.dialogDsDelete = false;
        this.loading = false;
        this.dialogDsDeleteDisabled = false;

        this.$nextTick(() => {
          this.editedDsIndex = -1;
          this.editedDsId = -1;
          this.editedDsName = '';
        })
      },

    },
    computed: {
        lview: {
            get: function(){
                return this.$t('views.permissions');
            }
        },
        lerrors: { 
            get: function() { 
                return this.$t('errors'); 
            } 
        },
    },
    mounted: async function () {
        this.$emit('set-title', this.lview.title);
        this.$emit('set-display', "BACK_WINDOW");

        // check permissions
        let userPermissions = localStorage.getItem("user_permissions");

        if (!userPermissions.includes("fob.admin_view")) {
            this.$emit('set-state', 'ERROR', this.lview.youDoNotHavePriviledgesToThisView);
            return;
        } 

        if (!userPermissions.includes("fob.admin_add")) {
            this.displayAdd = false;
            this.displayDuplicate = false;
        }

        if (!userPermissions.includes("fob.admin_change")) {
            this.displayEdit = false;
        } 

        if (!userPermissions.includes("fob.admin_delete")) {
            this.displayDelete = false;
        } 

        this.headers[0].text = this.lview.headerName;
        this.headers[1].text = this.lview.headerUsers;
        this.headers[2].text = this.lview.headerActions;

        let groupResponse = null;
        try {
            groupResponse = await axios({
                method: "GET",
                url: localStorage.getItem("current_env") + "/api/v2/admin/groups/",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                },
            });
        } catch (error) {
            this.$emit('set-state', 'DEFAULT');
            this.$emit('set-state', 'ERROR', 'Nie można załadować danych.');
            return;
        }
        
        this.$emit('set-state', 'DEFAULT');

        if (groupResponse) {
            let response = groupResponse.data;

            if (response.mdl_groups != null) {
                for (let i=0; i < response.mdl_groups.length; i++) {
                    this.mdl_groups.push({
                       'id': response.mdl_groups[i].id,
                       'name': response.mdl_groups[i].name,
                       'users': response.mdl_groups[i].users_count
                    });
                }
            }
            
            this.checkLenMdlTable();

            if (response.ds_groups != null) {
                for (let i=0; i < response.ds_groups.length; i++) {
                    this.ds_groups.push({
                        'id': response.ds_groups[i].id,
                       'name': response.ds_groups[i].name,
                       'users': response.ds_groups[i].users_count
                    });
                }
            }            

            this.checkLenDsTable();
        }
    },
  }
</script>

<style scoped>

.groups-table {
    width: 50%;
}

@media screen and (max-width: 1100px) {
    .groups-table {
        width: 100%;
    }
}

</style>