<!--
SharedMasters 2019 - Fast OnBoarding Application

__copyright__ = "Copyright (C) 2019, Shared Masters sp. z o.o."
__license__ = "Fast OnBoarding can not be copied and/or distributed without the express permission of Shared Masters sp. z o.o."
__version__ = "0.0.1-pre-beta"
__author__ = "Jan Przychodniak, .."
__maintainer__ = "Jan Przychodniak"
__email__ = "jan@sharedmasters.com"
__status__ = "DEV"


USAGE
-------
<attach-pictures-dialog
  ref="yourReference"

  ...
></attach-pictures-dialog>
$refs.yourReference.openDialog();

props:
  - title: title of the dialog [optional]
  - buttonCancel: text on cancel button [optional]
  - buttonConfirm: text on confirm button [optional]
  - attachmentsPrefix: prefix for attachments added through camera [optional]
  - picturesLimit: number of pictures the dialog will allow [optional]
  - sizeLimitPerPicture: size limit (in bytes) that each picture can have [optional]
  - totalSizeLimit: size limit (in bytes) for all pictures in total [optional]
  - roundTo: units to round the sizes to when displaying error messages. Allowed units: B, KB, MB, GB [optional]
  - initialAttachments: initially displayed attachments

events:
  - error
  - confirm

errors:
  structure: {
	errorCodeShort,
	errorCodeLong,
	title,
	message,
	details
  }

  errorsId: ATCHPIC

-->

<template>
	<v-dialog persistent v-model="dialog"
			  width="350px">
		<v-card>
			<v-card-title class="headline"
						  primary-title>
				{{ computedTitle }}
			</v-card-title>

			<v-card-actions>
				<!--
				<v-flex xs6>
				  <v-btn text style="width:100%" color="primary" small disabled>
					<v-icon style="padding-right: 5px">camera_alt</v-icon> {{ lview.attachFromCamera }}
				  </v-btn>
				</v-flex>
				-->
				<v-flex xs6>
					<v-btn text
						   style="width:100%"
						   color="rgba(4, 202, 90, 1)"
						   small
						   @click="$refs.imageInput.click()">
						<v-icon style="padding-right: 5px">attach_file</v-icon> {{ lview.attachFromFiles }}
					</v-btn>
				</v-flex>
			</v-card-actions>

			<!-- This one formally does not exist... It's here since its selection window is incredibly useful. -->
			<input ref="imageInput"
				   type="file"
				   accept="image/*"
				   multiple
				   style="display:none"
				   @change="onFilesInputChange" />

			<v-card-text style="padding:20px 6px">
				<v-container style="max-height:300px; padding:0px" class="scroll-y" v-if="attachments.length > 0">
					<v-list dense>
						<v-list-item v-for="(item, index) in attachments"
									 :key="index"
									 class="attach-pictures-dialog__list-tile">
							<v-list-item-content class="text-no-wrap text-truncate">
								{{ item.name }}
							</v-list-item-content>

							<v-list-item-action style='min-width:30px'>
								<v-btn icon
									   ripple
									   @click="disattach(index)">
									<v-icon color="rgba(4, 202, 90, 1)">cancel</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-container>

				<v-layout v-else
						  justify-center
						  align-center>
					{{ lview.noFilesAttached }}
				</v-layout>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="rgba(4, 202, 90, 1)" text @click="closeDialog()">{{ computedCancel }}</v-btn>
				<v-btn color="rgba(4, 202, 90, 1)" text @click="onAccept()">{{ computedConfirm }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

	export default {
		name: 'attachPicutresDialog',
		mounted() {
		},
		data() {
			return {
				dialog: false,

				attachments: [
					/*{
					  name: 'photo1.png',
					  file: {},
					},
					{
					  name: 'photo2.png',
					  file" {},
					},*/
				],
			}
		},
		computed: {
			lview: { get: function () { return this.$t('views.commons.attachPicturesDialog'); } },
			lbuttons: { get: function () { return this.$t('commons.buttons'); } },

			computedTitle: {
				get: function () {
					return (this.title == null) ? this.lview.defaultTitle : this.title;
				}
			},

			computedAttachmentsPrefix: {
				get: function () {
					return (this.attachmentsPrefix == null) ? '' : this.attachmentsPrefix
				}
			},

			computedCancel: {
				get: function () {
					return (this.buttonCancel == null) ? this.lbuttons.cancel : this.buttonCancel;
				}
			},

			computedConfirm: {
				get: function () {
					return (this.buttonConfirm == null) ? this.lbuttons.confirm : this.buttonConfirm;
				}
			},

			computedSingleFileLimit: {
				get: function () {
					return (this.sizeLimitPerPicture < 0) ? null : this.sizeLimitPerPicture;
				}
			},

			computedTotalSizeLimit: {
				get: function () {
					return (this.totalSizeLimit < 0) ? null : this.totalSizeLimit;
				}
			},

			computedNumOfPicturesLimit: {
				get: function () {
					return (this.picturesLimit < 0) ? null : this.picturesLimit;
				}
			},

			computedInitialAttachments: {
				get: function () {
					return this.initialAttachments == null ? [] : this.initialAttachments;
				}
			}
		},
		props: {
			title: {
				type: String,
				default: null,
			},
			buttonCancel: {
				type: String,
				default: null,
			},
			buttonConfirm: {
				type: String,
				default: null,
			},
			attachmentsPrefix: {
				type: String,
				default: null,
			},
			picturesLimit: {
				type: Number,
				default: null,
			},
			sizeLimitPerPicture: {
				type: Number,
				default: null,
			},
			totalSizeLimit: {
				type: Number,
				default: null
			},
			roundTo: {
				type: String,
				default: "MB",
			},
			initialAttachments: {
				type: Array,
				default: null
			}
		},
		methods: {
			openDialog() {
				this.dialog = true;
				this.attachments = [];

				for (var i = 0; i < this.computedInitialAttachments.length; i++) {
					this.attachments.push(this.computedInitialAttachments[i]);
				}
			},

			closeDialog() {
				this.dialog = false;
			},

			onAccept() {
				this.closeDialog();
				this.$emit("confirm", this.attachments);
			},

			onFilesInputChange(e) {

				var files = e.target.files;

				// Checking for exceeding number of files limit
				if (this.computedNumOfPicturesLimit != null && files.length + this.attachments.length > this.computedNumOfPicturesLimit) {
					this.$emit(
						'error',
						{
							errorCodeShort: "ATCHPIC#1",
							errorCodeLong: "TOO_MANY_FILES",
							title: this.lview.tooManyFilesTitle,
							message: this.$t('views.commons.attachPicturesDialog.tooManyFilesText', {
								numberOfFiles: files.length + this.attachments.length,
								maxNumberOfFiles: this.computedNumOfPicturesLimit
							}),
							details: null
						}
					);

					this.$refs.imageInput.value = null;
					return;
				}

				var file;
				var totalSize = 0;
				var errs = [];
				var validated = [];

				// Assigning current total size
				for (var i = 0; i < this.attachments.length; i++) {
					totalSize += this.attachments[i].size;
				}

				for (var i = 0; i < files.length; i++) {
					//console.log(files[i]);
					file = files[i];

					// Checking for exceeding file size limit
					if (this.sizeLimitPerPicture != null && file.size > this.sizeLimitPerPicture) {
						errs.push({
							fileName: file.name,
							errType: 'FILE_SIZE'
						})
					}
					else {
						validated.push(file);
						totalSize += file.size;

						// Verifying total size limit
						if (this.computedTotalSizeLimit != null && totalSize > this.computedTotalSizeLimit) {
							this.$emit(
								'error',
								{
									errorCodeShort: "ATCHPIC#2",
									errorCodeLong: "TOTAL_FILES_SIZE_TOO_BIG",
									title: this.lview.totalFilesSizeTooBigTitle,
									message: this.$t(
										'views.commons.attachPicturesDialog.totalFilesSizeTooBigText',
										{
											maxAttachmentsSize: this.roundSize(this.computedTotalSizeLimit)
										}
									),
									details: null
								}
							);

							this.$refs.imageInput.value = null;
							return;
						}
					}
				}

				// Verifying whether any errors occured
				if (errs.length > 0) {
					var error =
						this.$t(
							'views.commons.attachPicturesDialog.fileTooBigText',
							{
								sizeLimit: this.roundSize(this.computedSingleFileLimit)
							}
						)
						+ (':<br />');
					error += this.arrayToTextList(errs, "fileName");
					this.$emit(
						'error',
						{
							errorCodeShort: "ATCHPIC#3",
							errorCodeLong: "FILES_TOO_BIG",
							title: this.lview.fileTooBigTitle,
							message: error,
							details: null
						}
					);
				}

				// Adding validated items to currently stored list
				for (var i = 0; i < validated.length; i++) {
					this.attachments.push(validated[i]);
				}

				this.$refs.imageInput.value = null;
			},

			arrayToTextList(array, key = null) {
				var result = "";

				for (var i = 0; i < array.length; i++) {
					if (i != 0) {
						result += "<br />";
					}

					if (key != null)
						result += ('- ' + array[i][key]);
					else
						result += ('- ' + array[i]);
				}

				return result;
			},

			disattach(index) {
				this.attachments.splice(index, 1);
			},

			roundSize(size) {
				switch (this.roundTo) {
					case 'GB':
						size /= Math.pow(2, 30);
						return (size.toFixed(2)) + "GB";
					case 'KB':
						size /= Math.pow(2, 10);
						return (size.toFixed(2)) + "KB";
					case 'B':
						return size + "B";
					default:
						size /= Math.pow(2, 20);
						return (size.toFixed(2)) + "MB";
				}
			}
		}
	}
</script>

<style>
</style>

<style scoped>
</style>