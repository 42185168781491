<template>
    <v-layout style="margin-top: 10px;" v-cloak @drop.prevent="dragFile" @dragover.prevent>
        <div :class="workerDocumentsOverlayerForFabActive"></div>

        <v-speed-dial
            v-model="fab"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :direction="direction"
            :open-on-hover="hover"
            :transition="transition"
            style="position: fixed; bottom: 16px; right: 16px;"
            :class="speedDialClass"
        >
            <template v-slot:activator>
                <v-btn
                    v-model="fab"
                    color="primary"
                    fab
                    v-if="canUserAddNewDocuments()"
                >
                    <v-icon v-if="fab">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-plus 
                    </v-icon>
                </v-btn>
            </template>

            <v-tooltip left color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" @click="redirectToImportDocumentsView" fab small> 
                        <v-icon> mdi-briefcase-upload </v-icon> 
                    </v-btn>
                </template>
                <span style="color: black;"> {{ lviews.importDocuments }} </span>
            </v-tooltip>

            <v-tooltip left color="white">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="primary" @click="openAddNewDocumentForUserDialog()" fab small> 
                        <v-icon> mdi-plus </v-icon> 
                    </v-btn>
                </template>
                <span style="color: black;"> {{ lviews.addNewDocument }} </span>
            </v-tooltip>
        </v-speed-dial>

        <v-data-table
            :headers="dataTablesHeadersRendered"
            :items="dataTablesItems"
            item-key="name"
            class="elevation-1"
            :loading="fetchingData"
            :options.sync="options"
            :server-items-length="serverSideTotalItemsNumber"
            :footer-props="{'items-per-page-options':itemsPerPageOptions}"
            hide-default-footer
        >
            <template v-slot:top>
                <v-flex xs12 style="padding-top: 10px;">
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12" style="display: inline-block; padding-top: 0px; padding-bottom: 0px;">
                        
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" style="display: inline-block; padding-top: 0px; padding-bottom: 0px;">
                            <v-text-field
                                v-model="search"
                                :label="lviews.search"
                                style="height: 66px;"
                                clearable
                                append-icon="search"
                            ></v-text-field>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" style="display: inline-block; padding-top: 0px; padding-bottom: 0px;">
                            <fob-worker-autocompleter
                                v-model="paginationWorkerIdFilter"
                                @input="fetchPageData();"
                            ></fob-worker-autocompleter>
                        </div>
                        
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12" style="display: inline-block; padding-top: 0px; padding-bottom: 0px;">
                        <div style="width: 100%; display: flex;">
                            <v-select
                                v-model="selectedDocumentTypes"
                                :items="documentTypesItems"
                                multiple
                                class="col-xl-10 col-lg-10 col-md-10 col-sm-6 col-xs-6"
                                :label="lviews.documentTypeLong"
                                style="overflow: word-wrap; position: relative;"
                                @change="fetchPageData"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div v-if="selectedDocumentTypes.length == documentTypesItems.length">
                                        <span v-if="index == 0"> 
                                            {{ lviews.allTypesSelected }}
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span v-if="index < 2" style="margin-right: 10px;">{{ item.text }} &nbsp;</span>
                                        <span
                                            v-else-if="index === 2"
                                            class="grey--text caption"
                                        >(+{{ selectedDocumentTypes.length - 2 }})</span>
                                    </div>
                                </template>
                            </v-select>

                            <v-btn
                                style="margin-top: 10px;"
                                color="primary"
                                @click="openModifyDataTableColumnsDialog"
                                small
                                fab
                            > <v-icon> mdi-settings </v-icon> 
                            </v-btn>
                        </div>
                    </div>
                </v-flex>
            </template>

            <!--<template v-slot:footer.append>
                <v-btn
                    color="primary"
                    dark
                    class="ma-2"
                    fab
                    @click="openAddNewDocumentForUserDialog">
                    <v-icon> mdi-plus </v-icon>
                </v-btn>

                <v-btn
                    color="primary"
                    dark
                    class="ma-2"
                    @click="redirectToImportDocumentsView">
                    {{ lviews.importDocuments }}
                </v-btn>
            </template>-->
            <template v-slot:footer>
                <worker-document-data-table-footer
                    :lviews="lviews"
                    :itemsPerPageOptions="itemsPerPageOptions"

                    :pageNumber="options.page"
                    :itemsPerPageNumber="options.itemsPerPage"
                    @update-page-number="updatePageNumber"
                    @update-page-size="updatePageSize"

                    :totalItemsNumber="serverSideTotalItemsNumber"
                ></worker-document-data-table-footer>
            </template>

            <template v-slot:[`item.documentName`]="{ item }">
                 <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" style="display: flex;">
                            <v-icon :style="getItemStyle(item)"> {{ getItemIcon(item) }} </v-icon>
                            <div class="document-name-cut-when-too-long" style="margin-top: 2px; margin-left: 2px;"> {{ item.documentName }} </div>
                        </div>
                    </template>
                    <span>{{ item.documentName }}</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.options`]="{ item }">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            style="z-index:0"
                        >
                            mdi-dots-vertical
                        </v-icon>
                    </template>

                    <v-list style="min-width: 250px;">
                        <div
                            v-for="(group, groupIndex) in item.options"
                            :key="groupIndex"
                        >
                            <v-list-item v-for="(groupItem, groupItemKey) in group" :key="groupItemKey" class="worker-document-menu-option">
                                <v-flex :style="groupItem.style" @click="() => { groupItem.action(item); }">
                                    <v-icon :style="groupItem.style"> {{ groupItem.icon }} </v-icon>
                                    {{ groupItem.title }}
                                </v-flex>
                            </v-list-item>
                            <v-divider v-if="groupIndex < item.options.length - 1" style="border-bottom: 1px solid lightgray;"></v-divider>
                        </div>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

        <!-- edit datatables header dialog -->
        <v-dialog
            v-model="modifyDataTablesColumnsDialog"
            width="350"
            persistent
        >
            <v-card>
                <v-card-title> {{ lviews.chooseDataTableColumns }} </v-card-title>
                <v-list>
                    <v-list-item v-for="(option, index) in dataTableHeadersSelectItems" :key="index">
                        <v-checkbox
                            v-model="dataTableHeadersSelected"
                            :label="option.text"
                            :value="option.value"
                            style="padding:0; margin:0;"
                        ></v-checkbox>
                    </v-list-item>
                </v-list>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { modifyDataTablesColumnsDialog = false; }"> {{ lviews.cancel }} </v-btn>
                    <v-btn text color="primary" class="align-self-center" style="margin-bottom: 15px;" @click="() => { updateDataTablesColumns(); modifyDataTablesColumnsDialog = false; }" :disabled="dataTableHeadersSelected.length == 1"> {{ lviews.modify }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- fullscreen dialog with "view document" option -->
        <v-dialog
            v-model="viewDocument.on"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card style="height:100%">
                <v-toolbar
                    dark
                    color="primary"
                >
                    <v-toolbar-title> <div class="document-preview-cut-name-when-too-long">{{ viewDocument.documentName }}</div> </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-items>
                        <v-btn
                            icon
                            dark
                            @click="() => { downloadDocument(viewDocument); }"
                        >
                            <v-icon>mdi-download</v-icon>
                        </v-btn>

                        <v-btn
                            icon
                            dark
                            @click="viewDocument.on = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-container class="align-content-center pdf-viewer-container pdf-viewer-height-without-toolbar" style="padding:0;">
                    <v-layout v-if="!viewDocument.errorOccurred && viewDocument.possibleToRender" style="height:100%">
                        <iframe 
                            v-if="viewDocument.documentObjectStream != null"
                            class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12" 
                            style="margin-left: auto; margin-right: auto; zoom: 0.75; padding:0; border:none"
                            :src="viewDocument.documentObjectStream">
                        </iframe>
                        <div id="image-document-viewer-div" :class="viewDocument.imageResized" v-if="viewDocument.imageObjectStream != null" @click="imageClick">
                            <img 
                                :src="viewDocument.imageObjectStream"
                                class="image-document-viewer"
                            >
                        </div>
                    </v-layout>

                    <v-layout v-else-if="!viewDocument.possibleToRender && !viewDocument.errorOccurred" class="content-middler" column justify-center align-center>
                        <v-icon size="100" style="color: red; border: 2px solid red; border-radius: 50%;"> mdi-block-helper </v-icon>
                        <div style="width:90%; text-align: center; margin-top:10px; font-size: 20px; font-weight: 650;">{{ lviews.couldNotDisplayDocument }}</div>
                    </v-layout>

                    <v-layout v-else class="content-middler" column justify-center align-center>
                        <v-icon size="100">sentiment_dissatisfied</v-icon>
                        <div class="title"
                            style="width:90%; text-align: center; margin-top:10px;">{{ viewDocument.globalErrorMessage }}</div>
                    </v-layout>
                </v-container>
            </v-card>
        </v-dialog>

        <!-- change document name dialog -->
        <v-dialog
            v-model="changeDocumentNameDialog.visible"
            width="350"
            persistent
        >
            <v-card style="height: 190px;">
                <v-card-title>
                    <span> {{ changeDocumentNameDialog.dialogTitle }} </span>
                    <v-spacer></v-spacer>
                    <v-btn @click="changeDocumentNameDialog.visible = false;" text>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-text-field
                    v-model="changeDocumentNameDialog.newName"
                    style="margin-left: 20px; margin-right: 20px;"
                    maxlength="200"
                    counter
                ></v-text-field>

                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                ></v-progress-linear>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="changeDocumentNameDialog.visible = false;" text :disabled="disabled_button">
                        {{ lviews.cancel }}
                    </v-btn>
                    <v-btn text @click="saveNewDocumentName" color="primary" :disabled="changeDocumentNameDialog.previousName == changeDocumentNameDialog.newName || changeDocumentNameDialog.newName.length == 0 || disabled_button">
                        {{ lviews.save }}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <!-- change document type dialog -->
        <v-dialog
            v-model="changeDocumentTypeDialog.visible"
            scrollable
            width="350px"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span> {{ changeDocumentTypeDialog.dialogTitle }} </span>
                    <v-spacer></v-spacer>
                    <v-icon @click="changeDocumentTypeDialog.visible = false;">mdi-close</v-icon>
                </v-card-title>

                <!--<v-list>
                    <v-list-item v-for="(option, index) in documentTypesItems" :key="index">
                        <v-checkbox
                            v-model="changeDocumentTypeDialog.newType"
                            :label="option.text"
                            :value="option.value + 1"
                            style="padding:0; margin:0;"
                        ></v-checkbox>
                    </v-list-item>
                </v-list>-->
                <v-card-text>
                    <v-radio-group v-model="changeDocumentTypeDialog.newType">
                        <v-radio
                            v-for="(option, index) in documentTypesItems"
                            :key="index"
                            :label="`${option.text}`"
                            :value="option.value + 1"
                            style="padding:0; margin:0;"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>

                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                ></v-progress-linear>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="changeDocumentTypeDialog.visible = false;" text :disabled="disabled_button">
                        {{ lviews.cancel }}
                    </v-btn>
                    <v-btn text @click="saveNewDocumentType" color="primary" :disabled="changeDocumentTypeDialog.previousType == changeDocumentTypeDialog.newType || disabled_button">
                        {{ lviews.save }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- replace document dialog -->
        <v-dialog
            v-model="replaceDocumentDialog.visible"
            width="350px"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span> {{ replaceDocumentDialog.dialogTitle }} </span>
                    <v-spacer></v-spacer>
                    <v-icon @click="replaceDocumentDialog.visible = false;">mdi-close</v-icon>
                </v-card-title>

                <v-file-input
                    :label="lviews.chooseNewFile"
                    outlined
                    dense
                    v-model="replaceDocumentDialog.uploadedFile"
                    style="margin: 0 20px;"
                ></v-file-input>

                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                ></v-progress-linear>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="replaceDocumentDialog.visible = false;" text :disabled="disabled_button">
                        {{ lviews.cancel }}
                    </v-btn>
                    <v-btn text @click="saveReplacedDocument" color="primary" :disabled="replaceDocumentDialog.uploadedFile == null || disabled_button" >
                        {{ lviews.save }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- add new document for chosen worker dialog -->
        <v-dialog
            v-model="addNewDocumentForUserDialog.visible"
            width="450px"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span> {{ addNewDocumentForUserDialog.dialogTitle }} </span>
                </v-card-title>

                <fob-worker-autocompleter
                    v-model="addNewDocumentForUserDialog.user"
                    prependIcon="mdi-account"
                    style="margin: 0 20px;"
                ></fob-worker-autocompleter>

                <v-file-input
                    :label="lviews.chooseNewFile"
                    outlined
                    dense
                    v-model="addNewDocumentForUserDialog.uploadedFile"
                    style="margin: 0 20px;"
                ></v-file-input>

                <v-select
                    v-model="addNewDocumentForUserDialog.uploadedFileType"
                    :items="documentTypesItems"
                    :label="lviews.documentTypeLong"
                    style="overflow: word-wrap; margin: 0 20px;"
                ></v-select>

                <v-checkbox
                    v-model="addNewDocumentForUserDialog.overrideExistingFiles"
                    :label="lviews.overrideExistingFiles"
                    style="margin: 0 20px;"
                ></v-checkbox>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="() => { 
                            addNewDocumentForUserDialog.visible = false; 
                            addNewDocumentForUserDialog.uploadedFile = null;
                            addNewDocumentForUserDialog.chosenWorker = null;
                        }" 
                    text>
                        {{ lviews.cancel }}
                    </v-btn>
                    <v-btn text @click="addNewDocumentForConcreteUser" color="primary" :disabled="addNewDocumentForUserDialog.uploadedFile == null || addNewDocumentForUserDialog.user == null || addNewDocumentForUserDialog.uploadedFileType == null" >
                        {{ lviews.save }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete specific document dialog -->
        <v-dialog
            v-model="deleteChosenDocumentDialog.visible"
            width="350px"
            persistent
        >
            <v-card >
                <v-card-title>
                    <span> {{ deleteChosenDocumentDialog.dialogTitle }} </span>
                </v-card-title>

                <v-card-text>
                    <span> {{ deleteChosenDocumentDialog.content }} </span>
                    <br/><br/>
                    <strong> {{ lviews.assignedTo }} </strong> : <div> {{ deleteChosenDocumentDialog.assignedTo }} </div>
                    <br/>
                    <strong> {{ lviews.documentName }} </strong> : <div> {{ deleteChosenDocumentDialog.documentName }} </div>
                </v-card-text>
                
                <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteChosenDocumentDialog.noClick" text :disabled="disabled_button">
                        {{ lviews.cancel }}
                    </v-btn>
                    <v-btn @click="deleteChosenDocumentDialog.yesClick" color="red" text :disabled="disabled_button">
                        <strong> {{ lviews.delete }} </strong>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- duplicate document name -->
        <v-dialog
            v-model="duplicateFilenameDialog.visible"
            width="350px"
            persistent
        >
            <v-card>
                <v-card-title>
                    <span> {{ duplicateFilenameDialog.dialogTitle }} </span>
                </v-card-title>

                <v-card-text>
                    <div> {{ duplicateFilenameDialog.proposedFilename }} </div>
                    <v-text-field
                        v-model="duplicateFilenameDialog.newFilename"
                        :label="lviews.search"
                        style="height: 66px;"
                        clearable
                        :error="duplicateFilenameDialog.newFilenameError"
                        :error-messages="duplicateFilenameDialog.newFilenameErrorMessage"
                    ></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="duplicateFilenameDialog.noClick" text>
                        {{ lviews.cancel }}
                    </v-btn>
                    <v-btn @click="duplicateFilenameDialog.yesClick" color="primary" text>
                        <strong> {{ lviews.ok }} </strong>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar.visible"
            :color="snackbar.color"
        >
            {{ snackbar.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-layout>
</template>

<script>
import axios from 'axios';
import fob_config from '../fob_config.js';

export default {
    name: 'WorkersDocuments',
    data: function(){
        return {
            search: "",
            preventUpdate: false,
            searchTimeout: null,
            selectedDocumentTypes: [],
            fetchingData: false,
            serverSideTotalItemsNumber: 3,
            options: {},
            loading: false,
            disabled_button: false,

            dataTablesHeaders: [
                { id: 0, text: '', align: 'start', sortable: true, value: 'fullname', class:"data-tables-header-field" },
                { id: 1, text: '', sortable: true, value: 'birthDate', class:"data-tables-header-field" },
                { id: 2, text: '', sortable: true, value: 'createdAt', class:"data-tables-header-field" },
                { id: 3, text: '', sortable: true, value: 'type', class:"data-tables-header-field" },
                { id: 4, text: '', sortable: true, value: 'documentName', class:"data-tables-header-field" },
                { id: 5, text: '', value: 'options', sortable: false, class:"data-tables-header-field" }
            ],
            itemsPerPageOptions: [5, 10, 15, 30, 50, 100, 500],
            dataTablesHeadersRendered: [],
            dataTablesItems: [
                /**
                 * {
                 *      id: {Number},
                 *      fullname: {String},
                 *      birthDate: {String},
                 *      createdAt: {String},
                 *      type: {String},
                 *      slug: {String},
                 *      documentName: {String},
                 *      fileMimeType: {String},
                 *      options: [
                 *          {
                 *              title: {String},
                 *              action: {Function}
                 *          }
                 *      ]
                 * }
                 */
            ],
            documentTypesItems: [
                /**
                 * {
                 *      value: {Number},
                 *      text: {String}
                 * }
                 */
            ],

            viewDocument: {
                on: false,
                documentName: "",
                slug: "",
                documentObjectStream: null,
                errorOccurred: false,

                imageObjectStream: null,
                imageResized: "resized",
                globalErrorMessage: "",
                possibleToRender: true,
            },

            modifyDataTablesColumnsDialog: false,
            dataTableHeadersSelected: [],
            dataTableHeadersSelectItems: [
                /**
                 * {
                 *      value: {Integer},
                 *      text: {String}
                 * }
                 */
            ],

            changeDocumentNameDialog: {
                previousName: "",
                visible: false,
                newName: "",
                dialogTitle: "",
                slug: ""
            },

            changeDocumentTypeDialog: {
                previousType: null,
                visible: false,
                newType: null,
                dialogTitle: "",
                slug: ""
            },

            replaceDocumentDialog: {
                visible: false,
                uploadedFile: null,
                dialogTitle: "",
                slug: "",
            },

            snackbar: {
                visible: false,
                color: "primary",
                message: ""
            },

            addNewDocumentForUserDialog: {
                visible: false,
                uploadedFile: null,
                uploadedFileType: null,
                overrideExistingFiles: false,

                dialogTitle: "",//"{L Add new document for user dialog }",
                user: null
            },

            paginationWorkerIdFilter: null,
            deleteChosenDocumentDialog: {
                visible: false,
                dialogTitle: "",
                content: "",
                assignedTo: "",

                documentName: null,
                documentSlug: null,

                yesClick: () => {},
                noClick: () => {}
            },

            // v-speed-dial data
            direction: 'top',
            fab: false,
            fling: false,
            hover: false,
            tabs: null,
            top: false,
            right: true,
            bottom: true,
            left: false,
            transition: 'slide-y-reverse-transition',

            duplicateFilenameDialog: {
                dialogTitle: "",
                proposedFilename: "",

                visible: false,
                newFilename: "",

                yesClick: () => {},
                noClick: () => {},

                newFilenameError: false,
                newFilenameErrorMessage: "",
            },
        }
    },
    watch: {
        options: {
            handler () {
                this.saveDataTableConfigToLocalStorage("options");
                this.fetchPageData();
            },
            deep: true,
        },
        search: {
            handler() {
                this.saveDataTableConfigToLocalStorage("search");
            }
        },
        selectedDocumentTypes: {
            handler() {
                this.saveDataTableConfigToLocalStorage("selectedTypes");
            }
        },
        // paginationWorkerIdFilter: {
        //     handler() {
        //         this.saveDataTableConfigToLocalStorage("worker");
        //     }
        // }
        dataTableHeadersSelected: {
            handler() {
                this.saveDataTableConfigToLocalStorage("dataTablesHeaders");
            }
        },
        search: function() {
            if (!this.preventUpdate) {
                if (this.searchTimeout != null) {
                    clearTimeout(this.searchTimeout);
                }
                var that = this;
                this.searchTimeout = setTimeout(function(){
                    that.fetchPageData();
                }, 500);
            }
            else {
                this.preventUpdate = false;
            }
        },
    },
    methods: {
        dragFile(e){
            let userPermissions = localStorage.getItem("user_permissions");
            if (!userPermissions.includes("fob.worker_docs_add")) {   
                return;
            }
            else{
                let droppedFiles = e.dataTransfer.files;
                if(!droppedFiles) return;
                let fileList = [];
                for (var i=0; i<droppedFiles.length; i++){
                    fileList.push(droppedFiles[i]);
                }
                this.openAddNewDocumentForUserDialog(fileList[0])
            }

        },
        determineUserPermissionsToDocument: function(permissionsToDocument) {
            let optionsToAssign = [];
            optionsToAssign.push(
                [
                    { icon: "mdi-eye", title: this.lviews.preview, action: this.showDocument },
                    { icon: "mdi-download", title: this.lviews.download, action: this.downloadDocument }
                ]
            );

            if (permissionsToDocument.change) {
                optionsToAssign.push(
                    [
                        { icon: "mdi-backup-restore", title: this.lviews.update, action: this.openReplaceDocumentDialog },
                        { icon: "edit", title: this.lviews.changeDocumentName, action: this.openChangeDocumentNameDialog },
                        { icon: "mdi-folder-move", title: this.lviews.changeType, action: this.openChangeDocumentTypeDialog },
                    ]
                );
            }

            if (permissionsToDocument.delete) {
                optionsToAssign.push(
                    [
                        { icon: "mdi-delete", title: this.lviews.delete, action: this.openDeleteChosenDocumentDialog, style: {color: 'red'} },
                    ]
                );
            }
            return optionsToAssign;
        },

        canUserAddNewDocuments: function() {
            let myPermissions = localStorage.getItem('user_permissions').split(',');
            if (myPermissions.includes("fob.worker_docs_add")) {
                return true;
            }
            return false;
        },

        startLoading(){
            this.loading = true;
            this.disabled_button = true;
        },

        stopLoading(){
            this.loading = false;
            this.disabled_button = false;
        },

        saveDataTableConfigToLocalStorage: function(changedElementName) {
            var configStringified = localStorage.getItem("workers_documents_configuration");

            var dataTableConfiguration;
            if (configStringified == null) {
                dataTableConfiguration = {
                    options: this.options,
                    search: this.search,
                    selectedTypes: this.selectedDocumentTypes,
                    worker: this.paginationWorkerIdFilter,
                    dataTableColumnsRendered: this.dataTablesHeadersRendered,
                    dataTableColumnsSelected: this.dataTableHeadersSelected,
                }
            }
            else {
                dataTableConfiguration = JSON.parse(configStringified);
            }

            var newConfig = {};
            switch (changedElementName) {
                case "options":
                    newConfig = { ...dataTableConfiguration, options: this.options };
                    break;
                case "search":
                    newConfig = { ...dataTableConfiguration, search: this.search };
                    break;
                case "selectedTypes":
                    newConfig = { ...dataTableConfiguration, selectedTypes: this.selectedDocumentTypes };
                    break;
                //case "worker": 
                //    newConfig = { ...dataTableConfiguration, worker: this.paginationWorkerIdFilter };
                //    break;
                case "dataTablesHeaders":
                    newConfig = {
                        ...dataTableConfiguration, 
                        dataTableColumnsRendered: this.dataTablesHeadersRendered, 
                        dataTableColumnsSelected: this.dataTableHeadersSelected 
                    };
                    break;
            }

            configStringified = JSON.stringify(newConfig);
            localStorage.setItem("workers_documents_configuration", configStringified);
        },

        fetchPageData: async function(){
            let myPermissions = localStorage.getItem('user_permissions');
            if (!myPermissions.includes("fob.worker_docs_view")) {
                return;
            }

            this.fetchingData = true;

            var sortBy, sortDesc;
            if (this.options.sortBy.length == 0) {
                sortBy = undefined;
                sortDesc = undefined;
            } 
            else {
                sortDesc = this.options.sortDesc[0] ? 1 : 0;
                switch(this.options.sortBy[0]){
                    case "fullname":
                        sortBy = 0;
                        break;
                    case "birthDate":
                        sortBy = 1;
                        break;
                    case "createdAt":
                        sortBy = 2;
                        break;
                    case "type":
                        sortBy = 3;
                        break;
                    case "documentName":
                        sortBy = 4;
                        break;
                    default:
                        sortBy = undefined;
                        break;
                }
            }

            if (this.options.page == null || this.options.page == undefined) {
                this.options.page = 1;
            }

            if (this.options.itemsPerPage == null) {
                this.options.itemsPerPage = 10;
            }

            var apiLink = localStorage.getItem("current_env") + "/api/v2/worker-documents/?page=" + this.options.page + "&per_page=" + this.options.itemsPerPage;
            if (this.search != null && this.search != "") {
                apiLink += ("&search=" + this.search);
            }
            if (this.selectedDocumentTypes.length > 0){
                apiLink += ("&types=" + this.selectedDocumentTypes.toString());
            }
            if (sortBy != undefined && sortDesc != undefined) {
                apiLink += ("&sort_by=" + sortBy.toString() + "&sort_desc=" + sortDesc.toString());
            }
            if (this.paginationWorkerIdFilter != null) {
                apiLink += ("&worker=" + this.paginationWorkerIdFilter.toString());
            }

            var response = null;
            try{
                response = await axios({
                    url: apiLink,
                    method: "GET",
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    }
                });
            } catch (error) {
                if (error.isAxiosError) {
                    switch(error.response.status){
                        case 403:
                            this.$emit('set-state', "ERROR", this.lviews.lackOfPermission);
                            break;
                        case 400:
                            this.$emit('set-state', "ERROR", this.lviews.incorrectRequest);
                            break;
                        default:
                            this.$emit('set-state', "ERROR");
                            break;
                    }
                }
                console.log(error);
                return;
            }

            if (response == null){
                return;
            }

            let responseDocumentTypes = null;
            try {
                responseDocumentTypes = await axios({
                    "url": fob_config.api_url + "/api/v2/worker-documents/document-types",
                    "method": "GET",
                    "headers": {
                        "Authorization": "Bearer " + localStorage.getItem("jwt")
                    }
                });
            } catch (error) {
                return;
            }

            if (responseDocumentTypes == null){
                return;
            }

            this.documentTypesItems = [];
            responseDocumentTypes.data.document_types.forEach(element => {
                this.documentTypesItems.push(
                    {
                        value: element.id,
                        text: element.name
                    }
                );
            });
            this.documentTypesItems.sort((a, b) => { return a.value < b.value ? -1 : 1;} );

            // let myPermissions = localStorage.getItem('user_permissions').split(',');
            // let optionsToAssign = [];

            // if (myPermissions.includes("fob.worker_documents_view")) {
            //     optionsToAssign.push(
            //         [
            //             { icon: "mdi-eye", title: this.lviews.preview, action: this.showDocument },
            //             { icon: "mdi-download", title: this.lviews.download, action: this.downloadDocument }
            //         ]
            //     )
            // }

            // if (myPermissions.includes("fob.worker_documents_change")) {
            //     optionsToAssign.push(
            //         [
            //             { icon: "mdi-backup-restore", title: this.lviews.update, action: this.openReplaceDocumentDialog },
            //             { icon: "edit", title: this.lviews.changeDocumentName, action: this.openChangeDocumentNameDialog },
            //             { icon: "mdi-folder-move", title: this.lviews.changeType, action: this.openChangeDocumentTypeDialog },
            //         ]
            //     )
            // }

            // if (myPermissions.includes("fob.worker_documents_delete")) {
            //     optionsToAssign.push(
            //         [
            //             { icon: "mdi-delete", title: this.lviews.delete, action: this.openDeleteChosenDocumentDialog, style: {color: 'red'} },
            //         ]
            //     )
            // }

            /*
            [
                { icon: "mdi-eye", title: this.lviews.preview, action: this.showDocument },
                { icon: "mdi-download", title: this.lviews.download, action: this.downloadDocument }
            ],
            [
                { icon: "mdi-backup-restore", title: this.lviews.update, action: this.openReplaceDocumentDialog },
                { icon: "edit", title: this.lviews.changeDocumentName, action: this.openChangeDocumentNameDialog },
                { icon: "mdi-folder-move", title: this.lviews.changeType, action: this.openChangeDocumentTypeDialog },
            ],
            [
                { icon: "mdi-delete", title: this.lviews.delete, action: this.openDeleteChosenDocumentDialog, style: {color: 'red'} },
            ]
            */

            this.serverSideTotalItemsNumber = response.data.total;
            this.dataTablesItems = [];
            for (var i = 0; i < response.data.items.length; i++) {
                let optionsToAssign = this.determineUserPermissionsToDocument(response.data.items[i].permissions);

                let documentType = this.documentTypesItems.find(x => { return response.data.items[i].type == x.value; });

                if (response.data.items[i].worker_company_assignment.worker.user_profile != null) {
                    this.dataTablesItems.push(
                        {
                            id: i, // TO RECONSIDER
                            fullname: response.data.items[i].worker_company_assignment.worker.user_profile.last_name + " " +  
                                    response.data.items[i].worker_company_assignment.worker.user_profile.first_name +
                                    (response.data.items[i].worker_company_assignment.worker.user_profile.middle_name != null? response.data.items[i].worker_company_assignment.worker.user_profile.middle_name : ""),
                            birthDate: response.data.items[i].worker_company_assignment.worker.user_profile.birth_date,
                            createdAt: response.data.items[i].created_at.split('/').join('-'),
                            type: documentType.text,
                            slug: response.data.items[i].slug,
                            documentName: response.data.items[i].file_name,
                            fileMimeType: response.data.items[i].file_mime_type,
                            options: optionsToAssign
                        }
                    );
                }
                else {
                    this.dataTablesItems.push(
                        {
                            id: i, // TO RECONSIDER
                            fullname: this.lviews.unassigned,
                            birthDate: this.lviews.unassigned,
                            createdAt: response.data.items[i].created_at.split('/').join('-'),
                            type: documentType.text,
                            slug: response.data.items[i].slug,
                            documentName: response.data.items[i].file_name,
                            fileMimeType: response.data.items[i].file_mime_type,
                            options: optionsToAssign
                        }
                    );
                }
            }
            
            this.fetchingData = false;
        },

        downloadDocument: async function(item){
            this.$emit('set-state', "LOADING_OVERLAYER");
            var xhr = new XMLHttpRequest();
            var that = this;
            xhr.onload = function() {
                that.$emit('set-state', 'default');
                var reader = new FileReader();
                reader.onloadend = function() {
                    const linkSource = reader.result;
                    const downloadLink = document.createElement('a');

                    document.body.appendChild(downloadLink);
                    downloadLink.href = linkSource;
                    downloadLink.target = '_self';
                    downloadLink.download = item.documentName;
                    downloadLink.click();
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = function(){
                that.$emit('set-state', 'default');
                that.$emit('display-error', that.lmessages.errorOccurredTitle, that.lviews.couldNotDownloadDocument);
            };
            xhr.open('GET', fob_config.api_url + "/api/v2/worker-documents/" + item.slug + "/download");
            xhr.responseType = 'blob';
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('jwt'));
            xhr.send();
        },

        showDocument: function(item){
            this.viewDocument.errorOccurred = false;
            this.viewDocument.slug = item.slug;
            this.viewDocument.possibleToRender = true;

            this.$emit('set-state', "LOADING_OVERLAYER");
            var xhr = new XMLHttpRequest();
            var that = this;
            xhr.onload = function() {
                that.$emit('set-state', 'default');
                if (xhr.response.type == "application/pdf"){
                    var src = "/libs/pdfjs/web/viewer.html?file=" + fob_config.api_url + "/api/v2/worker-documents/" + item.slug + "/serve";

                    that.viewDocument.imageObjectStream = null;
                    that.viewDocument.documentObjectStream = src;
                    that.viewDocument.possibleToRender = true;
                }
                else if(xhr.response.type.includes("image")){
                    var reader = new FileReader();
                    reader.onloadend = function() {
                        that.viewDocument.documentObjectStream = null;
                        that.viewDocument.imageObjectStream = reader.result;
                        that.viewDocument.possibleToRender = true;
                    }
                    reader.readAsDataURL(xhr.response);
                }
                else{
                    that.viewDocument.possibleToRender = false;
                    that.viewDocument.errorOccurred = false;
                }
            };
            xhr.onerror = function(){
                that.$emit('set-state', 'default');
                that.viewDocument.errorOccurred = true;
                that.viewDocument.possibleToRender = false;
            };
            xhr.open('GET', fob_config.api_url + "/api/v2/worker-documents/" + item.slug + "/serve");
            xhr.responseType = 'blob';
            xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('jwt'));
            xhr.send();

            this.viewDocument.documentName = item.documentName;
            this.viewDocument.on = true;
        },

        // updating new document name
        saveNewDocumentName: async function(){
            if (this.changeDocumentNameDialog.previousName != this.changeDocumentNameDialog.newName) {
                this.startLoading();
                let requestData = new FormData();
                requestData.append("new_file_name", this.changeDocumentNameDialog.newName);
                
                try {
                    let response = await axios({
                        method: "PATCH",
                        url: localStorage.getItem("current_env") + "/api/v2/worker-documents/" + this.changeDocumentNameDialog.slug,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("jwt")
                        },
                        data: requestData
                    });

                    if (response.status == 200) {
                        let updatedRecordIdx = this.dataTablesItems.findIndex(x => { return x.slug == this.changeDocumentNameDialog.slug; });

                        if (updatedRecordIdx != -1 && response.data) {
                            if (response.data.file_name != null){
                                this.dataTablesItems[updatedRecordIdx].documentName = response.data.file_name;
                            }
                            if (response.data.type != null){
                                let doc_type = this.documentTypesItems.find(x => { return x.value == response.data.type; });
                                this.dataTablesItems[updatedRecordIdx].type = doc_type.text;
                            }
                            if (response.data.slug != null){
                                this.dataTablesItems[updatedRecordIdx].slug = response.data.slug;
                            }
                        }
                        this.snackbar.color = "primary";
                        this.snackbar.message = this.lviews.recordUpdatedSuccessfully;
                        this.snackbar.visible = true;
                    }
                    else if (response.status == 304) {
                        this.snackbar.color = "warning";
                        this.snackbar.message = this.lviews.recordNotModified;
                        this.snackbar.visible = true;
                    }
                } catch (error) {
                    if (error.isAxiosError) {
                        this.changeDocumentNameDialog.visible = false;
                        switch (error.response.status){
                            case 403:
                                this.snackbar.color = "error";
                                this.snackbar.message = this.lviews.lackOfPermissionToUpdateDocument;
                                this.snackbar.visible = true;
                                break;
                            case 400:
                                if (error.response.data.file_name != undefined && error.response.data.file_name != null) {
                                    this.duplicateFilenameDialog.dialogTitle = this.lviews.duplicateFilenameTitle;
                                    this.duplicateFilenameDialog.proposedFilename = this.lviews.proposedFilename;

                                    this.duplicateFilenameDialog.newFilenameErrorMessage = "";
                                    this.duplicateFilenameDialog.yesClick = () => { 
                                        this.changeDocumentNameDialog.newName = this.duplicateFilenameDialog.newFilename;
                                        this.saveNewDocumentName();
                                        
                                        this.duplicateFilenameDialog.dialogTitle = "";
                                        this.duplicateFilenameDialog.proposedFilename = "";
                                        this.duplicateFilenameDialog.visible = false;

                                        this.duplicateFilenameDialog.noClick = null;
                                        this.duplicateFilenameDialog.yesClick = null;
                                    };

                                    this.duplicateFilenameDialog.noClick = () => {
                                        this.duplicateFilenameDialog.noClick = null;
                                        this.duplicateFilenameDialog.yesClick = null;

                                        this.duplicateFilenameDialog.dialogTitle = "";
                                        this.duplicateFilenameDialog.proposedFilename = "";
                                        this.duplicateFilenameDialog.visible = false;
                                    };

                                    this.duplicateFilenameDialog.newFilename = error.response.data.file_name[0].string;
                                    this.duplicateFilenameDialog.visible = true;
                                }
                                else {
                                    this.snackbar.color = "error";
                                    this.snackbar.message = this.lviews.incorrectRequest;
                                    this.snackbar.visible = true;
                                }
                                break;
                            default:
                                this.$emit('set-state', 'ERROR');
                                break;
                        }
                        return;
                    }
                    console.log(error);
                }
                this.stopLoading();
                this.changeDocumentNameDialog.visible = false;
            }
        },
        openChangeDocumentNameDialog: function(item){
            this.changeDocumentNameDialog.previousName = item.documentName;
            this.changeDocumentNameDialog.newName = item.documentName;
            this.changeDocumentNameDialog.slug = item.slug;
            this.changeDocumentNameDialog.visible = true;
        },

        // updating document type
        saveNewDocumentType: async function(){
            if (this.changeDocumentTypeDialog.previousType != this.changeDocumentTypeDialog.newType){
                this.startLoading();
                let requestData = new FormData();
                requestData.append("new_file_type", (this.changeDocumentTypeDialog.newType - 1));

                try {
                    let response = await axios({
                        method: "PATCH",
                        url: localStorage.getItem("current_env") + "/api/v2/worker-documents/" + this.changeDocumentTypeDialog.slug,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("jwt")
                        },
                        data: requestData
                    });

                    if (response.status == 200) {
                        let updatedRecordIdx = this.dataTablesItems.findIndex(x => { return x.slug == this.changeDocumentTypeDialog.slug; });
                        if (updatedRecordIdx != -1 && response.data) {
                            if (response.data.file_name != null){
                                this.dataTablesItems[updatedRecordIdx].documentName = response.data.file_name;
                            }
                            if (response.data.type != null){
                                let doc_type = this.documentTypesItems.find(x => { return x.value == response.data.type; });
                                this.dataTablesItems[updatedRecordIdx].type = doc_type.text;
                            }
                            if (response.data.slug != null){
                                this.dataTablesItems[updatedRecordIdx].slug = response.data.slug;
                            }
                        }
                        this.snackbar.color = "primary";
                        this.snackbar.message = this.lviews.recordUpdatedSuccessfully;
                        this.snackbar.visible = true;
                    }
                    else if (response.status == 304){
                        this.snackbar.color = "warning";
                        this.snackbar.message = this.lviews.recordNotModified;
                        this.snackbar.visible = true;
                    }
                } catch (error) {
                    // TODO handling errors
                    if (error.isAxiosError) {
                        this.changeDocumentTypeDialog.visible = false;
                        switch (error.response.status){
                            case 403:
                                this.snackbar.color = "error";
                                this.snackbar.message = this.lviews.lackOfPermissionToUpdateDocument;
                                this.snackbar.visible = true;
                                break;
                            case 400:
                                this.snackbar.color = "error";
                                this.snackbar.message = this.lviews.incorrectRequest;
                                this.snackbar.visible = true;
                                break;
                            default:
                                this.$emit('set-state', 'ERROR');
                                break;
                        }
                        return;
                    }
                    console.log(error);
                }
                this.stopLoading();
                this.changeDocumentTypeDialog.visible = false;
            }
        },
        openChangeDocumentTypeDialog: function(item){
            let foundTypeObj = this.documentTypesItems.find(x => { return x.text == item.type; });
            this.changeDocumentTypeDialog.previousType = foundTypeObj.value + 1;
            this.changeDocumentTypeDialog.newType = foundTypeObj.value + 1;
            this.changeDocumentTypeDialog.slug = item.slug;
            this.changeDocumentTypeDialog.visible = true;
        },

        // replacing concrete document
        openReplaceDocumentDialog: function(item){
            this.replaceDocumentDialog.slug = item.slug;
            this.replaceDocumentDialog.uploadedFile = null;
            this.replaceDocumentDialog.visible = true;
        },
        saveReplacedDocument: async function(){
            if (this.replaceDocumentDialog.uploadedFile != null) {
                try {
                    this.startLoading();
                    let requestData = new FormData();
                    requestData.append("new_file", this.replaceDocumentDialog.uploadedFile);

                    let response = await axios({
                        method: "PATCH",
                        url: localStorage.getItem("current_env") + "/api/v2/worker-documents/" + this.replaceDocumentDialog.slug,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': "Bearer " + localStorage.getItem("jwt")
                        },
                        data: requestData
                    });

                    if (response.status == 200){
                        let updatedRecordIdx = this.dataTablesItems.findIndex(x => { return x.slug == this.replaceDocumentDialog.slug; });
                        if (updatedRecordIdx != -1 && response.data) {
                            if (response.data.file_name != null){
                                this.dataTablesItems[updatedRecordIdx].documentName = response.data.file_name;
                            }
                            if (response.data.type != null){
                                let doc_type = this.documentTypesItems.find(x => { return x.value == response.data.type; });
                                this.dataTablesItems[updatedRecordIdx].type = doc_type.text;
                            }
                            if (response.data.slug != null){
                                this.dataTablesItems[updatedRecordIdx].slug = response.data.slug;
                            }
                        }
                        this.snackbar.color = "primary";
                        this.snackbar.message = this.lviews.recordUpdatedSuccessfully;
                        this.snackbar.visible = true;
                    }
                    else if (response.status == 304){
                        this.snackbar.color = "warning";
                        this.snackbar.message = this.lviews.recordNotModified;
                        this.snackbar.visible = true;
                    }
                } catch (error) {
                    if (error.isAxiosError) {
                        this.replaceDocumentDialog.visible = false;
                        switch (error.response.status){
                            case 403:
                                this.snackbar.color = "error";
                                this.snackbar.message = this.lviews.lackOfPermissionToUpdateDocument;
                                this.snackbar.visible = true;
                                break;
                            case 400:
                                this.snackbar.color = "error";
                                this.snackbar.message = this.lviews.incorrectRequest;
                                this.snackbar.visible = true;
                                break;
                            default:
                                this.$emit('set-state', 'ERROR');
                                break;
                        }
                        return;
                    }
                    console.log(error);
                }
                this.stopLoading();
                this.replaceDocumentDialog.visible = false;
            }
        },

        redirectToImportDocumentsView: function(){
            this.$router.push("/import-documents");
        },

        imageClick: function(){
            if(this.viewDocument.imageResized == ""){
                this.viewDocument.imageResized = "resized";
            }
            else{
                this.viewDocument.imageResized = "";
            }
        },

        updateDataTablesColumns: function(){
            this.dataTablesHeadersRendered = [];
            this.dataTableHeadersSelected.sort((a, b) => { return a < b ? -1 : 1; });
            for(var i = 0; i < this.dataTableHeadersSelected.length; i++){
                this.dataTablesHeadersRendered.push(
                    this.dataTablesHeaders[ this.dataTableHeadersSelected[i] ]
                );
            }
        },

        openAddNewDocumentForUserDialog: function(draggedFile = null) {
            this.addNewDocumentForUserDialog.uploadedFile = draggedFile;
            this.addNewDocumentForUserDialog.uploadedFileType = null;

            this.addNewDocumentForUserDialog.user = null;
            this.addNewDocumentForUserDialog.visible = true;
        },

        setAutocompleterModelValue: function(newModelValue) {
            this.addNewDocumentForUserDialog.user = newModelValue;
        },

        addNewDocumentForConcreteUser: async function(e, documentName = null){
            this.$emit("set-state", "LOADING_OVERLAYER", this.lviews.uploadingInProgress);

            let formData = new FormData();
            var workerIds = [];
            workerIds.push(this.addNewDocumentForUserDialog.user);

            formData.append("workers_ids", workerIds);

            if (documentName != null) {
                formData.append("attachments_list", this.addNewDocumentForUserDialog.uploadedFile, documentName);
            } else {
                formData.append("attachments_list", this.addNewDocumentForUserDialog.uploadedFile);
            }

            formData.append("override_existing_files", this.addNewDocumentForUserDialog.overrideExistingFiles);
            formData.append("upload_with_document_type", true);
            var documentTypes = [];
            documentTypes.push(this.addNewDocumentForUserDialog.uploadedFileType);
            formData.append("documents_types", documentTypes);

            let responsePromise = axios({
                url: localStorage.getItem("current_env") + "/api/v2/worker-documents/upload-solo",
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                },
                data: formData
            });

            try {
                let response = await responsePromise;

                if (response.status == 201) {
                    this.$emit('set-state', 'default');
                    this.addNewDocumentForUserDialog.visible = false;
                    this.snackbar.color = "primary";
                    this.snackbar.message = this.lviews.documentAddedSuccessfully;
                    this.snackbar.visible = true;
                    setTimeout(() => { this.fetchPageData(); }, 100);
                }
            } catch (error) {
                this.$emit('set-state', 'default');
                this.addNewDocumentForUserDialog.visible = false;
                if (error.isAxiosError) {
                    switch (error.response.status) {
                        case 403:
                            this.snackbar.color = "error";
                            this.snackbar.message = this.lviews.lackOfPermissionToUpdateDocument;
                            this.snackbar.visible = true;
                            break;
                        case 400:
                            if (error.response.data.file_name != null && error.response.data.file_name != undefined) {
                                this.duplicateFilenameDialog.dialogTitle = this.lviews.duplicateFilenameTitle;
                                this.duplicateFilenameDialog.proposedFilename = this.lviews.proposedFilename;

                                this.duplicateFilenameDialog.newFilenameErrorMessage = "";
                                this.duplicateFilenameDialog.yesClick = () => { 
                                    this.addNewDocumentForConcreteUser(null, this.duplicateFilenameDialog.newFilename); 
                                    this.duplicateFilenameDialog.dialogTitle = "";
                                    this.duplicateFilenameDialog.proposedFilename = "";
                                    this.duplicateFilenameDialog.visible = false;
                                };

                                this.duplicateFilenameDialog.noClick = () => {
                                    this.duplicateFilenameDialog.noClick = null;
                                    this.duplicateFilenameDialog.yesClick = null;

                                    this.duplicateFilenameDialog.dialogTitle = "";
                                    this.duplicateFilenameDialog.proposedFilename = "";
                                    this.duplicateFilenameDialog.visible = false;
                                };

                                this.duplicateFilenameDialog.newFilename = error.response.data.file_name[0].string;
                                this.duplicateFilenameDialog.visible = true;
                            }
                            else {
                                this.snackbar.color = "error";
                                this.snackbar.message = this.lviews.incorrectRequest;
                                this.snackbar.visible = true;
                            }
                            break;
                        default:
                            this.$emit('set-state', 'ERROR');
                            break;
                    }
                }
                else {
                    this.snackbar.color = "error";
                    this.snackbar.message = this.lviews.couldNotDownloadDocument;
                    this.snackbar.visible = true;
                }
            }
        },

        openDeleteChosenDocumentDialog: function(item) {
            this.deleteChosenDocumentDialog.dialogTitle = this.lviews.deleteDocument;
            this.deleteChosenDocumentDialog.content = this.lviews.areYouSureYouWantToDeleteThisDocument;
            this.deleteChosenDocumentDialog.assignedTo = item.fullname;
            this.deleteChosenDocumentDialog.documentName = item.documentName;
            this.deleteChosenDocumentDialog.documentSlug = item.slug;

            this.deleteChosenDocumentDialog.yesClick = this.deleteChosenDocument;
            this.deleteChosenDocumentDialog.noClick = () => { this.deleteChosenDocumentDialog.visible = false; };

            this.deleteChosenDocumentDialog.visible = true;
        },
        deleteChosenDocument: async function() {
            let responsePromise = axios({
                url: localStorage.getItem("current_env") + "/api/v2/worker-documents/" + this.deleteChosenDocumentDialog.documentSlug,
                method: "DELETE",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                },
            });

            try {
                this.startLoading();
                var response = await responsePromise;

                if (response.status == 200) {
                    let updatedRecordIdx = this.dataTablesItems.findIndex(x => { return x.slug == this.deleteChosenDocumentDialog.documentSlug; });

                    if (updatedRecordIdx != -1) {
                        this.dataTablesItems.splice(updatedRecordIdx, 1);
                    }

                    this.snackbar.color = "primary";
                    this.snackbar.message = this.lviews.recordUpdatedSuccessfully;
                    this.snackbar.visible = true;
                } 
                else if (response.status == 304) {
                    this.snackbar.color = "warning";
                    this.snackbar.message = this.lviews.recordNotModified;
                    this.snackbar.visible = true;
                }
            } catch (error) {
                if (error.isAxiosError) {
                    switch (error.response.status) {
                        case 403:
                            this.snackbar.color = "error";
                            this.snackbar.message = this.lviews.lackOfPermissionToUpdateDocument;
                            this.snackbar.visible = true;
                            break;
                        case 400:
                            this.snackbar.color = "error";
                            this.snackbar.message = this.lviews.incorrectRequest;
                            this.snackbar.visible = true;
                            break;
                        default:
                            this.$emit('set-state', 'ERROR');
                            break;
                    }
                }
            }
            this.stopLoading();
            this.deleteChosenDocumentDialog.visible = false;
        },

        getItemStyle: function(item) {
            switch(item.fileMimeType) {
                case 'application/pdf':
                    return {'color': 'red'};
                case 'image/png':
                    return {'color': '#22c9dc'};
                case 'image/jpg':
                    return {'color': '#22c9dc'};
                case 'image/jpeg':
                    return {'color': '#22c9dc'};
                default:
                    return {'color': 'gray'};
            }
        },

        getItemIcon: function(item){
            switch(item.fileMimeType) {
                case 'application/pdf':
                    return 'mdi-file-pdf';
                case 'image/png':
                    return 'mdi-file-image';
                case 'image/jpg':
                    return 'mdi-file-image';
                case 'image/jpeg':
                    return 'mdi-file-image';
                default:
                    return 'mdi-file';
            }
        },

        openModifyDataTableColumnsDialog: function() {
            this.dataTableHeadersSelected = [];
            for(var i = 0; i < this.dataTablesHeadersRendered.length; i++) {
                this.dataTableHeadersSelected.push(this.dataTablesHeadersRendered[i].id);
            }

            this.modifyDataTablesColumnsDialog = true;
        },

        // event handlers for "worker-document-data-table-footer" component
        updatePageNumber: function(newPageNumber) {
            this.options.page = newPageNumber;
            this.fetchPageData();
        },

        updatePageSize: function(newPageSize) {
            this.options.itemsPerPage = newPageSize;
            this.fetchPageData();
        },
    },
    computed: {
        lapp: {
            get: function () {
                return this.$t("app");
            }
        },
        lviews: {
            get: function() {
                return this.$t("views.documents");
            }
        },
        lmessages: {
            get: function() {
                return this.$t("commons.messages");
            }
        },

        workerDocumentsOverlayerForFabActive: {
            get: function() { 
                if(this.fab){
                    return "worker-documents-overlayer-for-fab overlayer-for-fab-active";
                }
                else{
                    return "worker-documents-overlayer-for-fab";
                }
            }
        },

        speedDialClass: {
            get: function(){
                if(this.fab){
                    return "worker-documents-speed-dial speed-dial-active";
                }
                else {
                    return "worker-documents-speed-dial";
                }
            }
        }
    },
    props: {

    },
    mounted: function () {
        this.$emit('set-title', this.lviews.workersDocuments);
        let myPermissions = localStorage.getItem('user_permissions');

        if (!myPermissions.includes("fob.worker_docs_view")) {
            this.$emit("set-state", "error", this.lviews.lackOfPermission);
            return;
        }

        this.viewDocument.globalErrorMessage = this.lmessages.errorOccured;

        //console.log(this.lviews.workerFullName);
        //console.log(this.dataTablesHeaders[0].text);
        this.dataTablesHeaders[0].text = this.lviews.workerFullName;
        this.dataTablesHeaders[1].text = this.lviews.workerDateBirth;
        this.dataTablesHeaders[2].text = this.lviews.documentCreatedAt;
        this.dataTablesHeaders[3].text = this.lviews.documentTypeShort;
        this.dataTablesHeaders[4].text = this.lviews.documentName;

        for (var i = 0; i < this.dataTablesHeaders.length - 1; i++){
            this.dataTableHeadersSelectItems.push(
                {
                    value: i,
                    text: this.dataTablesHeaders[i].text
                }
            );
            console.log(this.dataTablesHeaders[i].text)
        }

        this.dataTablesHeadersRendered = [];
        this.dataTableHeadersSelected = [];
        for(var i = 0; i < this.dataTablesHeaders.length; i++){
            this.dataTablesHeadersRendered.push(this.dataTablesHeaders[i]);
            this.dataTableHeadersSelected.push(i);
        }

        // loading data table configuration if it exists
        let savedConfigurationStr = localStorage.getItem("workers_documents_configuration");
        if (savedConfigurationStr != null) {
            let savedConfigurationObj = JSON.parse(savedConfigurationStr);
            this.options = savedConfigurationObj.options;
            this.search = savedConfigurationObj.search;
            this.selectedDocumentTypes = savedConfigurationObj.selectedTypes;
            this.paginationWorkerIdFilter = savedConfigurationObj.worker;

            this.dataTablesHeadersRendered = savedConfigurationObj.dataTableColumnsRendered;
            this.dataTableHeadersSelected = savedConfigurationObj.dataTableColumnsSelected;
        }

        this.changeDocumentNameDialog.dialogTitle = this.lviews.changeDocumentName;
        this.changeDocumentTypeDialog.dialogTitle = this.lviews.changeDocumentType;
        this.replaceDocumentDialog.dialogTitle = this.lviews.updateDocumentFile;
        this.addNewDocumentForUserDialog.dialogTitle = this.lviews.addNewDocument;

        this.fetchPageData();

        this.updateDataTablesColumns();
    },
}
</script>

<style>
    .v-list-item { 
        min-width: 80px;
    }
    .worker-document-menu-option {
        background-color: white;
    }
    .worker-document-menu-option:hover {
        background-color: #e4e4e4;
    }
</style>

<style scoped>
.pdf-viewer-container {
    padding-top: 0px;
}
.pdf-viewer-height-without-toolbar {
    height: calc(100% - 56px);
}
@media only screen and (min-width: 960px){
    .pdf-viewer-height-without-toolbar {
        height: calc(100% - 64px);
    }
}

.documents-search-bar-container{
    width: 60% !important;
    border-radius: 10px !important;
}
.data-tables-header-field {
    background-color: rgb(83,241,107)!important;
    color: rgb(62, 51, 51) !important;
}
.content-middler {
    width: 100%;
    height: calc(100% - 2vh);
}

#image-document-viewer-div{
    max-width: 100%;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
}

.image-document-viewer{
    cursor:pointer;
}
#image-document-viewer-div.resized .image-document-viewer{
    max-width:100%;
}

.document-name-cut-when-too-long {
    max-width: 170px;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
}

.document-preview-cut-name-when-too-long {
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
}

@keyframes displayoverlay {
    0%  { display: none; opacity: 0.0; },
    1% { display: block; opacity: 0.0; },
    100% { display: block; opacity: 1.0; }
}

.worker-documents-overlayer-for-fab {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(33,33,33);
    left: 0;
    top: 0;
    z-index: 15;
    visibility: hidden;
    opacity: 0.0;

    transition: visibility 0.2s, opacity 0.2s linear;
}
.worker-documents-overlayer-for-fab.overlayer-for-fab-active {
    visibility: visible;
    opacity: 0.46;
}
.worker-documents-speed-dial{
    z-index: 2;
}
.worker-documents-speed-dial.speed-dial-active {
    z-index: 20;
}
</style>